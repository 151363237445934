import React, {useContext, useEffect, useRef, useState} from 'react';
import {Form} from 'grommet';
import {CalculatorContext} from '@animalia/contexts';

import {AnimalStep, RaceStep, ClientStep, InsuranceStep, OfferSent} from '../steps';

import StepModel from './StepModel';
import StepperNavigation from './StepperNavigation';

import {
  updateForm,
  submitForm,
  getInsuranceValues,
  prevStep,
  insuranceStep,
  clientStep,
  raceStep,
  getErrors,
  prefillCalculatorForm,
  setActiveStep,
  getGaDataFormName,
  getGaDataName,
  getGaDataId,
  getGaDataPetAge,
  getGaDataFranchise,
  getGaDataPetAgeWidget,
} from '@animalia/actions';
import {calculatorSchema} from '@animalia/validators';
import {useHistory, useLocation} from 'react-router';
import {getOfferOptionsData} from '@animalia/components/actions/buttons';
import {useTranslation} from 'react-i18next';

const steps = [
  {
    ref: 'widget-step-1',
    Component: AnimalStep,
    fields: ['pet_species', 'pet_age_category'],
  },
  {
    ref: 'widget-step-1-2',
    Component: RaceStep,
    fields: [
      'pet_breed',
      'pet_animal_id',
      'pet_animal_id_2',
      'holder_zip_code',
      'holder_city',
    ]
  },
  {
    ref: 'widget-step-2',
    Component: ClientStep,
    fields: ['insurance_franchise', 'insurance_type'],
  },
  {
    ref: 'widget-step-3-guest',
    Component: InsuranceStep,
    fields: [
      'pet_name',
      'holder_title',
      'holder_first_name',
      'holder_last_name',
      'holder_email',
      'holder_mobile_phone',
      'holder_phone',
    ],
  },
  {
    ref: 'offer-sent',
    Component: OfferSent,
  },
];
const Stepper = ({pet, closeModalAndGoTo, i18n, user, agentCode}) => {
  const history = useHistory();
  const location = useLocation();
  const agentCodeUrl = new URLSearchParams(window.location.search).get(
    'codeAgent'
  );
  const {t} = useTranslation();
  const [offerQuid, setOfferQuid] = useState(null);
  const {
    calculator: {form, selectedInsuranceValues},
    dispatch,
  } = useContext(CalculatorContext);
  const formRef = useRef(form);
  const gaCheckedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);
  // handlers
  const onUpdateForm = data => updateForm(dispatch, data);
  const onSubmitForm = async data => {
    let fields;
    if (form.step === 'widget-step-2') {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'click_insurance_req_subscribe_btn',
        });
      }

      fields = [
        ...steps
          .filter(item => item.ref.match(/widget-step-(1|2)/))
          .flatMap(item => item.fields),
      ];
    } else if (form.step === 'widget-step-3-guest') {
      fields = [
        ...steps
          .filter(item => item.ref.match(/widget-step-(1|2|3-guest)/))
          .flatMap(item => item.fields),
      ];
    }

    if (!fields) {
      return false;
    }

    const errors = await getErrors(form, calculatorSchema, fields);
    if (errors?.length) {
      for (const error of errors) {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'generate_form_error',
            page_title: document.title,
            page_location: window.location.href,
            page_referrer: window.location.href,
            page_referrer:
              form.step == 'widget-step-1'
                ? document.referrer
                : window.location.href,
            page_language: i18n.language?.slice(0, 2),
            logged_in: !!user?.loggedin,
            // hostname_of_parent_window: window.opener?.location?.hostname,
            form_name: getGaDataFormName('start-widget'),
            form_error: `${error?.path}: ${t(error?.message)}`,
            step_id: getGaDataId('start-widget', form.step),
            step_name: getGaDataName('start-widget', form.step),
            type_of_pet: formRef.current?.pet_species,
            age_of_pet: !!pet
              ? getGaDataPetAge(pet?.birthdate)
              : getGaDataPetAgeWidget(formRef.current?.pet_birthdate),
            franchise: getGaDataFranchise(formRef.current?.insurance_franchise),
            first_prime: formRef.current?.insurance_cost
              ? formRef.current?.insurance_cost / 100
              : null,
            insurance_coverage: formRef.current?.insurance_type,
            disaster_type: '',
            agent_code: agentCodeUrl || '0000',
          });
        }
      }
      setError(errors);
      return false;
    } else {
      setError(null);
    }

    setIsLoading(true);

    submitForm({
      dispatch: dispatch,
      data: data,
      closeModalAndGoTo: closeModalAndGoTo,
      petId: pet?.id,
      goToOfferSent: null,
      language: i18n?.language?.substr(0, 2),
      pet: pet,
      user: user,
      setIsLoading: setIsLoading,
      setOfferQuid: setOfferQuid,
    });
  };

  const goToPrevStep = () => prevStep(dispatch, steps, form.step);
  const goToFirstStep = () => {
    if (pet?.id)
      history.push({
        pathname: '/',
        state: {
          from: window.location.pathname,
        },
      });
    else setActiveStep(dispatch, 'widget-step-1');
  };

  const goToNextStep = action => async () => {
    const {fields} = steps.find(item => item.ref === form.step);

    if (!fields) {
      return false;
    }

    const errors = await getErrors(form, calculatorSchema, fields);

    if (errors?.length) {
      for (const error of errors) {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'generate_form_error',
            page_title: document.title,
            page_location: window.location.href,
            page_referrer: window.location.href,
            page_referrer:
              form.step == 'widget-step-1'
                ? document.referrer
                : window.location.href,
            page_language: i18n.language?.slice(0, 2),
            logged_in: !!user?.loggedin,
            // hostname_of_parent_window: window.opener?.location?.hostname,
            form_name: getGaDataFormName('start-widget'),
            form_error: `${error?.path}: ${t(error?.message)}`,
            step_id: getGaDataId('start-widget', form.step),
            step_name: getGaDataName('start-widget', form.step),
            type_of_pet: formRef.current?.pet_species,
            age_of_pet: getGaDataPetAgeWidget(formRef.current?.pet_birthdate),
            franchise: getGaDataFranchise(formRef.current?.insurance_franchise),
            first_prime: formRef.current?.insurance_cost
              ? formRef.current?.insurance_cost / 100
              : null,
            insurance_coverage: formRef.current?.insurance_type,
            disaster_type: '',
            agent_code: agentCodeUrl || '0000',
          });
        }
      }
      setError(errors);
      return false;
    } else {
      setError(null);
    }

    switch (action) {
      case 'calculate':
        insuranceStep(dispatch, form);
        break;

      case 'sendOfferByMail':
        clientStep(dispatch, form);
        break;
      case 'races':
        raceStep(dispatch, form);
        break;
      case 'sendOfferByMailMyAccount':
        submitForm({
          dispatch: dispatch,
          data: {value: form},
          closeModalAndGoTo: null,
          petId: pet?.id,
          goToOfferSent: true,
          language: i18n?.language,
          pet: pet,
          user: user,
          setIsLoading: setIsLoading,
          setOfferQuid: setOfferQuid,
          step: 'widget-step-3',
        });
        break;

      default:
        break;
    }

    // GA onClick event
    switch (action) {
      case 'calculate':
        if (window.datalayer) {
          window.dataLayer.push({
            event: 'click_insurance_req_calculate_btn',
          });
        }
        break;
      case 'sendOfferByMail':
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'click_insurance_req_email_btn',
          });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (pet) {
      prefillCalculatorForm(dispatch, pet);
    } else if (agentCode) {
      dispatch({
        type: 'SET_FORM',
        data: {
          ...form,
          agent_code: agentCodeUrl || '0000',
        },
      });
    }
  }, []);

  useEffect(() => {
    formRef.current = form;
    if (error) {
      (async () => {
        const {fields} = steps.find(item => item.ref === form.step);
        const errors = await getErrors(form, calculatorSchema, fields);
        if (errors?.length) {
          setError(errors);
          return false;
        } else {
          setError(null);
        }
      })();
    }
  }, [form]);

  // use effects
  useEffect(() => {
    if (form.pet_species && form.pet_age_category) {
      getInsuranceValues(dispatch, form);
    }
  }, [form.pet_species, form.pet_age_category]);

  useEffect(() => {
    // if (form.insurance_type) {
    //   const choices = getOfferOptionsData(form, selectedInsuranceValues, t);
    //   const selected = choices.find(item => item.value === form.insurance_type);
    //   const annualCost = parseFloat(selected.price_per_month) * 100 * 12;
    //   const annualCostFederalTimber = annualCost * 1.05;

    //   dispatch({
    //     type: 'SET_FORM',
    //     data: {
    //       ...form,
    //       insurance_cost: annualCostFederalTimber,
    //     },
    //   });
    // }
  }, [form.insurance_type]);

  useEffect(() => {
    //if GTM not loaded than return (for widget)
    if (!window.dataLayer) return;

    if (gaCheckedRef.current) {
      window.dataLayer.push({
        event: 'page_view',
        page_title: document.title,
        page_location: window.location.href,
        page_referrer: window.location.href,
        page_language: i18n.language?.slice(0, 2),
        form_name: getGaDataFormName('start-widget', form.step),
        step_id: getGaDataId('start-widget', form.step),
        step_name: getGaDataName('start-widget', form.step),
      });
    } else {
      gaCheckedRef.current = true;
    }

    window.dataLayer.push({
      event: 'enter_step',
      page_title: document.title,
      page_location: window.location.href,
      page_referrer:
        form.step == 'widget-step-1' ? document.referrer : window.location.href,
      page_language: i18n.language?.slice(0, 2),
      logged_in: !!user?.loggedin,
      form_name: getGaDataFormName('start-widget', form.step),
      form_error: false,
      step_id: getGaDataId('start-widget', form.step),
      step_name: getGaDataName('start-widget', form.step),
      type_of_pet: formRef.current?.pet_species,
      age_of_pet: !!pet
        ? getGaDataPetAge(pet?.birthdate)
        : getGaDataPetAgeWidget(formRef.current?.pet_age_category),
      franchise: getGaDataFranchise(formRef.current?.insurance_franchise),
      first_prime: formRef.current?.insurance_cost
        ? formRef.current?.insurance_cost / 100
        : null,
      insurance_coverage: formRef.current?.insurance_type,
      disaster_type: '',
      agent_code: agentCodeUrl || '0000',
    });
  }, [form.step]);

  return (
    <div
      style={{
        width: '100%',
        marginTop: '-10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 500,
        height: 575,
        position: 'relative',
      }}>
      <Form
        id="form"
        value={form}
        onChange={onUpdateForm}
        onSubmit={onSubmitForm}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
        }}>
        <StepperNavigation steps={steps} pet={pet} user={user}>
          {steps.map(({ref, Component}) => (
            <StepModel
              offerQuid={offerQuid}
              key={`stepmodel_${ref}`}
              reference={ref}
              pet={pet}
              user={user}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              formValue={form}
              dispatch={dispatch}
              Component={Component}
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
              goToFirstStep={goToFirstStep}
              closeModalAndGoTo={closeModalAndGoTo}
              error={error}
            />
          ))}
        </StepperNavigation>
      </Form>
    </div>
  );
};

export default Stepper;
