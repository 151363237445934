import * as yup from 'yup';

export default yup.object().shape({
  species: yup
    .string()
    .required('offer.error.pet_species_required')
    .matches(/(cat|dog)/),
  gender: yup
    .string()
    .required('offer.error.pet_gender_required')
    .matches(/(male|female)/),
  name: yup
    .string()
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase')
    .test('len', 'offer.error.more_char', val => val?.length >= 2)
    .required(),
  birthdate: yup.date().required('offer.error.pet_birthdate_required'),
  animal_type: yup
    .string()
    .matches(/(breed|bastard|unknown)/)
    .required('offer.error.pet_is_purebred_required'),
  animal_id: yup.string().when(['animal_type', 'other_race'], {
    is: (animal_type, other_race) => animal_type !== 'unknown' && !other_race,
    then: yup.string().required('offer.error.pet_animal_id_required'),
  }),
  animal_id_2: yup.string().when(['animal_type', 'other_race_2'], {
    is: (animal_type, other_race_2) =>
      animal_type == 'bastard' && !other_race_2,
    then: yup.string().required('offer.error.pet_animal_id_required'),
  }),
  other_race: yup
    .string()
    .transform(value => (!value ? null : value))
    .nullable(true)
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(
      /^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/,
      'offer.error.only_one_uppercase'
    ),
  other_race_2: yup
    .string()
    .transform(value => (!value ? null : value))
    .nullable(true)
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(
      /^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/,
      'offer.error.only_one_uppercase'
    ),
  weight: yup
    .number()
    .transform(value => (!value ? null : value))
    .nullable(true)
    .when(['animal_type', 'other_race'], {
      is: (animal_type, other_race) => animal_type !== 'breed' || !!other_race,
      then: yup
        .number()
        .required('offer.error.pet_weight_required')
        .typeError('offer.error.pet_weight_number_required'),
    }),
  activity: yup
    .string()
    .oneOf(['passive', 'normal', 'active'])
    .required('offer.error.general'),
  sterilized: yup.boolean().required('offer.error.general'),
  options: yup
    .string()
    .matches(/(none|grain-free|sensitive)/)
    .required('offer.error.general'),
  product: yup
    .number('offer.error.general')
    .required('offer.error.general')
    .nullable(),
  percentageQuantity: yup
    .number()
    .oneOf([80, 90, 100, 110, 120])
    .required('offer.error.general'),
  firstDeliveryDate: yup.object().required(),
  acceptcgu: yup.boolean().oneOf([true]).required(),
});
