import React from 'react';
import * as Icons from '@animalia/icons'
import { Anchor } from 'grommet'
import styles from './arrowAnchor.module.sass'
import classNames from 'classnames'

const ArrowAnchor = ({ absolute, pin = 'left', ...rest }) => {
  const classes = classNames([
    absolute && styles.absolute,
    absolute && styles[pin],
  ])

  return (
    <Anchor
      reverse
      size='small'
      className={classes}
      margin={{ bottom: 'small' }}
      icon={<Icons.Chevron />}
      { ...rest }
    />
  );
};

export default ArrowAnchor;
