import { Logo } from '@animalia/icons'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WidgetTightBackLink, Caption, PageHeader, Title } from '../..'
import styles from './WidgetTightOfferFieldContainer.module.sass'

const WidgetTightOfferFieldContainer = ({ 
  children,
  onClick,
  backText, 
  noBack,
  logo,
  title,
  suptitle,
  subtitle,
  centered,
  noMargins
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.module}>
      <div className={centered ? styles.centered : styles.container}>
        <div className={styles.childrenWrapper}> 

        { logo &&
          <div className={styles.logo}>
            <Logo accent='brand' width='100' />
          </div>
        }

          {
            !noBack && (
              <div className={styles.back}>
                <WidgetTightBackLink 
                  onClick={onClick}
                  text={backText || t('common.back') + 'kds'} 
                />
              </div>
            )
          }
          
          <div className={classNames([styles.titles, noMargins && styles.noMargins])}>
            {
              suptitle &&
                <div className={styles.suptitle}>
                  <Caption align='center'>
                    <div style={{ fontSize: '0.65rem' }}>
                      { suptitle }
                    </div>
                  </Caption>
                </div>
            }

            <div className={styles.title}>
              { 
                title && 
                  <Title align='center'>
                    <div style={{ fontSize: '0.875rem' }}>{ title }</div>
                  </Title>
              }
            </div>

            {
              subtitle &&
                <div className={styles.subtitle}>
                  <Caption align='center'>
                    <div style={{ fontSize: '0.75rem' }}>{ subtitle }</div>
                  </Caption>
                </div>
            }
          </div>
          <div className={styles.children}>
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}

export default WidgetTightOfferFieldContainer
