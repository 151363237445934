import * as yup from 'yup'

const ifNullThen = schema => value => {
  if (!value) {
    return schema
  }
}

let addressSchema =  yup.object().shape({
  title: yup.string().when('address_id', ifNullThen(yup.string().required('offer.error.title_required').matches(/(mr|ms|mrs)/))),
  first_name: yup.string().nullable().when('address_id', ifNullThen(yup.string().required('offer.error.first_name_required'))),
  last_name: yup.string().nullable().when('address_id', ifNullThen(yup.string().required('offer.error.last_name_required'))),
  addressee: yup.string().nullable().when('address_id', ifNullThen(yup.string().required())),
  address_id: yup.string().nullable(),
  line: yup.string().nullable().when('address_id', (addressId, field) => addressId ? field : field.required('offer.error.line_required')),
  line_2: yup.string().nullable().nullable(),
  city: yup.string().nullable().when('address_id', (addressId, field) => addressId ? field : field.required('offer.error.city_required')),
  post_address_strid: yup.string().nullable().when('address_id', (addressId, field) => addressId ? field : field.required('offer.error.general')),
  post_address_house_key: yup.string().nullable().when('address_id', (addressId, field) => addressId ? field : field.required('offer.error.general')),
  zip_code: yup.string().nullable().when('address_id', (addressId, field) => addressId ? field : field.required('offer.error.zip_code_required')),
  country: yup.string().nullable().when('address_id', (addressId, field) => addressId ? field : field.required('offer.error.general')),
  update_delivery_addresses_for: yup.array().of(yup.number()).nullable(),
  valid_form: yup.string().nullable(),
})

export default addressSchema