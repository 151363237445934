module.exports = {
  conclusionTitle: 'Merci pour votre message',
  conclusionMsg: 'Nous avons transmis votre demande de changement à la Vaudoise Assurance. La modification sera effectuée dans les prochains jours.',
  deleteNumber: 'Supprimer la liaison avec ce numéro de police',
  changeBankData: 'Changement d’informations bancaires pour les remboursements',
  changeAddress: 'Changement d’adresse pour l\'assurance',
  changeOwner: 'Changement de propriétaire de {{name}}',
  declareSinister: 'Déclarer un sinistre pour {{name}}',
  noContractNumberSubtext: "{{name}} n'a pas encore d'assurance santé associée",
  ContractNumberSubtext: 'Numéro de police {{police}}',
  not_complete: 'Vous êtes en train d\'établir une offre d\'assurance',
  waiting: 'Votre proposition d\'assurance a été envoyée avec succès. Vous allez voir ici le numéro de votre police ces prochains jours.', 
  addContractNumber:
    "Saisir le numéro de police de l'assurance santé Animalia existante",
  addContractNumberDescription:
    "Afin de pouvoir lier votre compte Animalia à l'assurance santé Animalia de votre compagnon à 4 pattes, veuillez saisir ici son numéro de police complet (sans espaces).",
  addContractNumberPopupTitle:
    "Veuillez saisir le numéro de police de l'assurance santé Animalia de {{name}}",
  addContractNumberFieldLabel: 'Numéro de police Animalia',
  contractNotFound:
    "Ce numéro de police n'existe pas. Veuillez svp saisir le numéro de police se trouvant sur votre police et qui finit par ...330x.",
  petNameMismatch:
    "Oups, ce numéro de police ne correspond pas à votre animal ou son nom ne correspond pas à celui sur sa police d'assurance. Vous trouverez le nom enregistré et le numéro de police sur votre police d'assurance.",
  contractUpdated:
    "Le contrat d'assurance de votre animal est maintenant lié à son profil.",
  apiConnectionError:
    "La connexion avec les systèmes de l'assurance Animalia est en maintenance pour le moment. Veuillez svp ressayer plus tard (could not connect to API).",
  unauthorized:
    "La connexion avec les systèmes de l'assurance Animalia est en maintenance pour le moment. Veuillez svp ressayer plus tard (api call unauthorized).",
  apiError:
    "La connexion avec les systèmes de l'assurance Animalia est en maintenance pour le moment. Veuillez svp ressayer plus tard (api error).",
  serverError:
    "La connexion avec les systèmes de l'assurance Animalia est en maintenance pour le moment. Veuillez svp ressayer plus tard (server error).",
  undefined:
    "Ce n'est pas un numéro valable. Veuillez svp saisir le numéro de police se trouvant sur votre police et qui finit par ...330x.",
  checkPolicyNumber:
    "Ce n'est pas un numéro valable. Veuillez svp saisir le numéro de police se trouvant sur votre police et qui finit par ...330x.",

  comment: '---THE FOLLOWING STRINGS ARE NEW ---',
  addInvoice: 'Envoyer une facture pour {{name}}',
  addInvoiceButton: 'Ajouter facture',
  addDocumentButton: 'Document',
  addClaimButton: 'Nouveau sinistre',
  petFiles: 'Dossier de {{name}}',
  editInsurance: "Formulaires pour modifier l'assurance (adresse, IBAN, propriétaire)",
  invoicedOn: 'Facture du {{date}}',
  xInvoices: '{{count}} facture',
  xInvoices_plural: '{{count}} factures',
  steps: {
    disaster: 'Sinistre',
    medicalFile: 'Dossier méd',
    invoice: 'Facture',
  },
  types: {
    vaccine: 'Vaccins ou frais de prévention',
    health: 'Maladie ou autres',
    accident: 'Accident',
    external: 'Déclaré hors ligne',
  },
  disaster: {
    illnessTip:
      'CGA 2008 à 2019: La castration, le détartrage, la crémation et tous les cas qui ne sont pas liés à un accident doivent être déclarés sous "maladie ou autres". CGA dès 2021: Tous les cas qui ne concernent ni un accident ni des frais de prévention, doivent être déclarés sous "maladie ou autres"',
    intro: {
      title: "De quel type de sinistre s'agit-il ?",
      description:
        'Si la facture est liée à plusieurs sinistres ou animaux, veuillez s.v.p. vous référer ici au premier sinistre et recommencez ensuite la procédure autant de fois que nécessaire.',
      new: 'Un nouveau sinistre',
      existing: 'Un sinistre existant',
      vaccine: 'Vaccins ou frais de prévention',
    },
    type: {
      title: "De quel type de sinistre s'agit-il ?",
      description:
        "Veuillez s.v.p. choisir s'il s'agit d'un accident, d'une maladie ou d'un autre cas.",
      description2: 'Attention : pour les vaccins ou d\'autres frais de prévention (frais de castration ou de stérilisation, de détartrage, de vermifugation et compléments alimentaires prescrites par le vétérinaire),',
      accident: 'Accident',
      illness: 'Maladie ou autres',
      vaccine:
        'vous pouvez directement envoyer la facture, sans déclaration de sinistre.',
    },
    existing: {
      title: 'Sinistres existants',
      description:
        'Si vous avez déclaré le sinistre sur cette plateforme, sélectionnez-le ci-dessous.',
      info:
        "J'ai déclaré le sinistre par un autre moyen (ancien site Internet, e-mail, téléphone, etc.)",
      disasters: 'Sinistres',
      noDisasters: "Il n'y a pas encore de sinistre déclaré pour {{name}}.",
    },
    title: {
      title: 'Intitulé du sinistre',
      description:
        "Cette désignation vous permettra d'identifier rapidement le sinistre dans le dossier de {{name}}. Entrez des mots clés simples et clairs qui font référence au sinistre.",
      shortDescription: 'Intitulé du sinistre',
    },
    existingDisasterTitle: {
      title: 'Intitulé du sinistre',
      description:
        "Cette désignation vous permettra d'identifier rapidement le sinistre dans le dossier de {{name}}. Entrez des mots clés simples et clairs qui font référence au sinistre.",
      shortDescription: 'Intitulé du sinistre',
      year: 'Année du sinistre',
      type: 'Type de sinistre',
    },
    diagnosticAccident: {
      title: 'Est-ce que votre vétérinaire a établi un diagnostic ?',
      description:
        "Par diagnostic, on entend l'identification, par votre vétérinaire, de la nature de l'affection dont votre animal est atteint.",
      diagnostic: 'Diagnostic du vétérinaire',
    },
    diagnosticIllness: {
      title: 'Est-ce que votre vétérinaire a établi un diagnostic ?',
      description:
        "Par diagnostic, on entend l'identification, par votre vétérinaire, de la nature de l'affection dont votre animal est atteint.",
      diagnostic: 'Diagnostic',
      symptomsOptionnal: 'Symptômes (optionnel)',
      symptoms: 'Symptômes',
      illnessStartLabel: 'Date de début des symptômes',
    },
    date: {
      title: "Date et heure de l'accident",
      description:
        "Veuillez s.v.p. entrer la date et l'heure de l'accident de manière aussi précise que possible. Si l'accident ne s'est pas produit en votre présence, vous pouvez ajouter une approximation.",
      date: 'Date de l’accident',
      hour: 'Heure',
      interval: 'Écart estimé',
      exact_time: 'heure exacte',
      in_hour: "dans l'heure",
      between_1_4: 'entre 1 et 4 heures',
      between_4_6: 'entre 4 et 6 heures',
      in_day: 'dans la journée',
      in_week: 'dans la semaine',
      dateTip:
        "Si l'accident ne s'est pas produit en votre présence, vous pouvez ajouter une approximation",
    },
    illnessTreatment: {
      title: 'Quelle est la thérapie prescrite ?',
      description:
        'Par thérapie, on entend le traitement médical préconisé (y c. médicaments, pansements, bandages, examens complémentaires).',
      treatment: 'Thérapie prescrite',
    },
    howAccidentHappened: {
      title: "Déroulement de l'accident",
      description:
        'Décrivez le déroulement le plus exactement que possible afin que nous puissions évaluer le cas le plus rapidement que possible.',
      howItHappened: 'Description précis du déroulement',
    },
    anyThirdParty: {
      title: "Une tierce personne est-elle impliquée dans l'accident ?",
      description:
        "Par tierce personne, on entend tout être vivant (humain ou animal) ayant causé ou participé à l'accident.",
      howItHappened: 'Déroulement précis de l’accident',
      known: "Oui et je peux l'identifier",
      unknown: "Oui mais je ne sais pas qui c'est",
      none: 'Non',
      knownTip:
        "Une autre personne ou un animal a été impliqué dans l'accident et vous connaissez ses coordonnées de contact, respectivement ceux de son propriétaire.",
      unknownTip:
        "Une autre personne ou un animal (sauvage) a été impliqué dans l'accident mais vous n'avez aucune information de contact.",
      noneTip:
        "Aucune autre personne ou animal a été impliqué dans l'accident.",
    },
    thirdParty: {
      title: 'Informations sur la tierce personne',
      description:
        'Veuillez s.v.p. remplir, de la manière la plus exhaustive, les données de la personne impliquée. Si un autre animal a été impliqué, veuillez entrer les coordonnées de son/sa propriétaire.',
      lastname: 'Nom *',
      firstname: 'Prénom *',
      street: 'Adresse',
      number: 'No',
      zip: 'NPA',
      city: 'Localité',
      country: 'Pays',
      email: 'Adresse e-mail',
      phone: 'Numéro de téléphone',
    },
    comments: {
      title: 'Remarques',
      description:
        'Toute information peut nous aider à reconstituer le cas et donc accélérer son traitement.',
      comment: 'Remarques (optionnel)',
    },
    summary: {
      title: 'Récapitulatif de la déclaration',
      description:
        "Veuillez s.v.p. vérifier l'exactitude de votre déclaration. Vous pouvez encore la modifier en cliquant sur le stylo jaune à côté de chaque information.",
      description2: 'Ensuite, veuillez confirmer et envoyer la déclaration.',
      cguAdditional:
        "La soumission de l'annonce du sinistre et le traitement du sinistre sont soumis à la Déclaration relative à la <a href='https://www.vaudoise.ch/data' target='blank'>protection des données de Vaudoise Assurances</a>.",
      cgu:
        'Je certifie avoir fourni des réponses complètes et conformes à la réalité.',
      type: 'Type de sinistre',
      label: 'Titre du sinistre',
      comment: 'Remarques',
      symptoms: 'Symptômes',
      treatment: 'Thérapie prescrite',
      diagnosis: 'Diagnostic de votre vétérinaire',
      occurred_at: "Date et l'heure de l'accident",
      interval: 'Écart estimé',
      claim_year: 'Année',
      animal: 'Animal',
      sequence: 'Déroulement',
      third_party: 'Données tierce personne impliquée',
      third_party_status: 'Tierce personne impliquée',
    },
  },
  medicalFile: {
    add: {
      title: 'Dossier médical de {{name}}',
      description:
        "S'il s'agit du tout premier sinistre que vous nous déclarez pour {{name}}, nous avons besoin de son dossier / historique médical fourni par votre vétérinaire. Pour gagner du temps et si vous êtes en sa possession, vous pouvez le télécharger ci-dessous.",
      add: 'Ajouter dossier médical',
      addLater: 'Télécharger les documents plus tard',
      alreadyDone: "Le dossier a déjà été envoyé ou je l'enverrai plus tard",
      confirmImage: "Confirmer le fichier ou l'image",
      addImage: 'Ajouter un document',
      browse: 'Parcourir',
      dropFile: 'Déposer un fichier',
      maxPDF: "Vous pouvez télécharger qu'un seul fichier pdf. Il peut contenir plusieurs pages.",
      cantAddImageWhenThereAreAlreadyAPdf: "Vous pouvez télécharger qu'un seul fichier pdf. Il peut contenir plusieurs pages.",
      cantAddPDFWhenThereAreAlreadyImages: "Vous pouvez ajouter uniquement des images. Si vous désirez ajouter un pdf, vous devez supprimer l'image déjà téléchargé.",
      maxSizePDF: 'La taille maximum est de 6Mo',
      check:
        'Veuillez s.v.p. vous assurer que tous les éléments du document soient bien visibles et lisibles.',
      rotate: 'Pivoter',
      delete: 'Supprimer',
      fileFormatNotSupported: 'Format non supportlé'
    },
    title: {
      title: 'Nom du document',
      description:
        "Donnez un nom au document qui explique en un ou deux mot de quel document il s'agit.",
      titleField: 'Nom du document',
    },
    summary: {
      title: 'Récapitulatif du dossier médical',
      animal: 'Animal',
      files: 'Fichiers',
      label_medicalFile: 'Nom du fichier',
    },
  },
  invoices: {
    invoiceTitle: 'Facture {{date}}',
    intro: {
      title: 'Téléchargez votre facture',
      description:
        'Téléchargez un document PDF ou une ou plusieurs images. Validez chaque image après avoir contrôlé que tous les éléments sont bien visibles et lisibles (cliquez pour ceci sur la loupe jaune) et répétez ceci pour chaque page de la facture.',
      useExisting: 'Choisir une facture récemment téléchargée',
      later: 'Je vais envoyer mes factures plus tard',
      add: 'Ajouter une nouvelle facture',
    },
    existing: {
      title: 'Factures récemment téléchargées',
      description:
        "Si vous avez récemment téléchargé la facture pour un autre sinistre ou animal, vous la trouverez ci-dessous. Un nouveau téléchargement n'est dans ce cas pas nécessaire.",
    },
    details: {
      title: 'Montant total de la facture',
      description:
        'A des fins de contrôle et de réconciliation, veuillez s.v.p. saisir le montant total T.T.C. de la facture téléchargée. Attention : Ne tenez pas compte du fait que la facture concerne plusieurs animaux ou sinistres, ou encore des traitements non-remboursés.',
      total: 'Total facture T.T.C.',
      invoiceSubject: 'Cette facture concerne également',
      many_pets: "d'autres animaux",
      many_disasters: "d'autres sinistres",
      vaccine_too: 'vaccins ou frais de prévention',
    },
    year: {
      title: 'Année de traitement',
      description: 'Dans quelle année est-ce que le traitement a eu lieu?',
      instruction: 'Vous pouvez choisir une ou plusieurs années.',
    },
    summary: {
      title: 'Récapitulatif de la facture',
      invoice: 'Facture',
      animal: 'Animal',
      amount: 'Montant T.T.C. de la facture',
      involves_multiple_pets: "Concerne d'autres animaux",
      involves_multiple_claims: "Concerne d'autres sinistres",
      involves_a_vaccine: 'Concerne des vaccins ou des frais de prévention',
      billing_years: 'Année de traitement',
    },
    confirmation: {
      title: 'Confirmation d’envoi',
      description:
        'Vos données et documents concernant le sinistre "{{disaster}}" de {{name}} ont bien été envoyées à notre service des prestations.',
      delay: 'Le délai actuel de traitement est estimé au :',
      delayTip:
        "Vous pouvez réduire ce délai au minimum en téléchargeant le dossier médical ainsi que les résultats d'analyse(s) dans le dossier médical de votre animal.",
      delayNotice:
        'Ce délai est à titre indicatif et dépendra des informations complémentaires nécessaires pour traiter la facture.',
      pleaseRepeat:
        '<strong>Si les factures transmises concernent plusieurs animaux et/ou sinistres</strong>, merci de répéter la procédure autant de fois que nécessaire.',
      newInvoiceForThisPet: 'Autre facture pour {{name}}',
      newInvoiceForAnotherPet: 'Facture pour un autre animal',
      medicalFilesForThisPet: 'Dossier de {{name}}',
      backToMyPets: 'Retourner à "Mes animaux"',
      otherActions: 'Autres actions',
      sameInvoiceForAnotherPet: 'Même facture pour un autre animal',
      sameInvoiceForAnotherDisaster:
        'Même facture pour un autre sinistre de {{name}}',
    },
    otherpets: {
      title: "Qui d'autre est concerné par cette facture ?",
      description:
        "Veuillez sélectionner l'autre animal concerné par la facture. Si plusieurs autres animaux sont concernés, répétez la procédure ensuite autant de fois que nécessaire.",
    },
  },
  edit: {
    title: "Modifier l'assurance santé de {{name}}",
    description:
      "Vous pouvez nous annoncer tout changement concernant l'assurance Animalia ci-dessous. Si vous ne trouvez pas ce que vous cherchez, veuillez nous contacter par e-mail à animalia@vaudoise.ch.",
  },
  documents: {
    title: 'Dossier de {{name}}',
    description:
      'Si le sinistre a été enregistré sur cette plateforme, vous le trouverez ci-dessous.',
    tip:
      'Si, par le passé, vous avez déclaré un sinistre en utilisant notre ancien site web, par e-mail ou par téléphone, il ne sera pas répertorié sur la liste ci-dessous. Il vous faut revenir en arrière et choisir l\'option "Un sinistre existant déclaré par un autre moyen".',
    help:
      "J'ai déclaré le sinistre, mais il n'apparait pas dans la liste ci-dessous.",
    medicalFilesAreBeingProcessedTitle:
      "Le dossier médical de {{name}} est en train d'être transmis à notre service des prestations.",
    medicalFilesAreBeingProcessedDescription:
      'Nous vérifions votre document. Il sera affiché ici sous peu.',
    filesAreBeingProcessedTitle:
      "La facture de {{name}} est en train d'être transmise à notre service des prestations.",
    filesAreBeingProcessedDescription:
      'Nous vérifions votre document. Il sera affiché ici sous peu.',

    sinisters: 'Sinistres de {{name}}',
    medicalFiles: 'Dossier médical de {{name}}',
    noDocuments: "Il n'y a encore pas de dossier médical pour {{name}}",
    noDisasters: "Il n'y a encore pas de sinistre déclaré pour {{name}}",
    declareSinister: 'Déclarer un sinistre',
    addMedicalFiles: 'Ajouter des documents au dossier médical',
    medicalFile: 'Dossier médical',
    seeMedicalFiles: 'Consulter le dossier médical',
    xImages: '{{count}} image',
    xImages_plural: '{{count}} images',
    vaccines: 'Vaccins et frais de prévention',
  },
  claimDocument: {
    description: '',
    amount: 'Montant de la facture',
    billing_years: 'Année de traitement',
    estimated_processing_time: 'Date de traitement prévisionnel',
  },
  asideSummary: {
    amount: 'Total facture T.T.C.',
    involves_multiple_pets: "Concerne d'autres animaux",
    involves_multiple_claims: "Concerne d'autres sinistres",
    involves_a_vaccine: 'Concerne des vaccins ou frais de prévention',
    billing_years: 'Année de traitement',
    type: 'Type de sinistre',
    label: 'Titre du sinistre',
    label_medicalFile: 'Titre du document',
    comment: 'Remarques',
    symptoms: 'Symptômes',
    third_party_status: 'Tierce personne impliquée',
    treatment: 'Thérapie prescrite',
    diagnosis: 'Diagnostic',
    occurred_at: 'Date et heure',
    interval: 'Écart estimé',
    claim_year: 'Année',
    animal: 'Animal',
    sequence: 'Déroulement précis',
    third_party: 'Données de la tierce personne impliquée',
  },
  vaccines: {
    title: 'Vaccins ou frais de prévention',
  },
  deleteModal: {
    title: 'Supprimer le document',
    description:
      'Une fois supprimé, vous ne pourrez plus retrouver le document. Êtes-vous sûr de bien vouloir continuer ?',
    actionYes: 'Oui, supprimer le document',
    actionNo: "Non, j'aimerais garder ce document",
  },
  address: {
    my_details_change: 'Changement d\'adresse pour l\'assurance',
    change_address_for: 'Changer cette adresse également pour',
    change_for_all: 'Tous mes animaux et leur service',
    change_for_pet: 'Les livraisons Abo Gourmand de'
  },
  owner: {
    owner_change: 'Transfert de propriétaire de',
    owner_needed_details: 'Veuillez s.v.p. remplir les données de la nouvelle ou du nouveau propriétaire. Afin que {{ name }} soit toujours couvert, nous allons la/le contacter afin de lui soumettre une nouvelle offre d\'assurance.',
    contact_new_owner: 'A quelle date est-ce que le changement de propriétaire aura lieu ?',
    transfer_pet: 'Transférer aussi le propriétaire de',
    transferred_at: 'Date du changement de propriétaire',
    keep_insurance: 'Est-ce que le nouveau propriétaire souhaite conserver l\'assurance Animalia ?'
  },
  bank_details: {
    my_bank_details_change: 'Changement de coordonnées bancaires',
    my_bank_details_description: 'Veuillez saisir ici le code IBAN de votre compte sur lequel vous désirez recevoir les remboursements.'
  }
};
