import React, {useState, useEffect} from 'react';
import {Storage} from 'aws-amplify';
import {Box, Image, Text, Layer} from 'grommet';
import styled from 'styled-components';

const Wrapper = styled.a`
  position: relative;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  padding-top: 135%;
`;

const Title = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const Page = ({url, prefix, path, placeholder}) => {
  const [title, setTitle] = useState(placeholder);
  const [showLightbox, setShowlightbox] = useState(false);
  const [s3Url, setUrl] = useState(null);

  const file = url || s3Url || '';

  useEffect(() => {
    if (prefix && path) {
      const filePath = path.split(prefix)[1];

      Storage.get(prefix + filePath, {
        level: 'private',
      })
        .then(resourceUrl => {
          const fileName = resourceUrl.split('/').pop();
          if (fileName.indexOf('.pdf') > -1) {
            setTitle(fileName.substring(fileName.lastIndexOf('?'), -1));
          }
          setUrl(resourceUrl);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [path, prefix]);

  return (
    <>
      {showLightbox && (
        <Layer
          margin="large"
          onEsc={() => setShowlightbox(false)}
          onClickOutside={() => setShowlightbox(false)}>
          <Image
            fit="contain"
            fill
            src={file}
            onClick={() => setShowlightbox(false)}
          />
        </Layer>
      )}
      <Wrapper
        style={{
          backgroundImage: file.indexOf('.pdf') === -1 ? `url(${file})` : '',
          pointerEvents: s3Url === null ? 'none' : null,
        }}
        href={s3Url || 'javascript:void(0)'}
        onClick={e => {
          e.preventDefault();
          if (file.indexOf('.pdf') === -1) {
            setShowlightbox(true);
          } else {
            const filePath = path.split(prefix)[1];
            Storage.get(prefix + filePath, {
              level: 'private',
              download: true,
            }).then(fileBlob => {
              /**
               * Download
               */
              const url = URL.createObjectURL(fileBlob.Body);
              const a = document.createElement('a');
              a.href = url;
              const fileName = file.split('/').pop();
              a.download =
                fileName.substring(fileName.lastIndexOf('?'), -1) || 'download';
              const clickHandler = () => {
                setTimeout(() => {
                  URL.revokeObjectURL(url);
                  a.removeEventListener('click', clickHandler);
                }, 150);
              };
              a.addEventListener('click', clickHandler, false);
              a.click();
              return a;
            });
          }
        }}
        target="_blank">
        {title && (
          <Title>
            <Text size="10px" truncate>
              {title}
            </Text>
          </Title>
        )}
      </Wrapper>
    </>
  );
};

export default Page;
