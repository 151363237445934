module.exports = {
  calculatorTitle: 'Assicurazione sanitaria',
  calculatorDescription: 'Calcoli in pochi clic il premio per il suo compagno a quattro zampe.',
  petSpecies: '',
  petAgeCategory: 'Età', // Dropdown, selection d'âge
  ageCategory: 'da', // InsuranceType Step suptitle / format: {{ pet_species }} da {{ pet_age_category }}
  offerSent: 'Nous vous avons envoyé un e-mail avec l\'offre.', //ce texte n'est plus utilisé, juste? c'est maintenant thanks et offer_in_mailbox?
  conditions: 'Nel quadro della richiesta di un’offerta o della stipulazione di un contratto online, la Vaudoise Assicurazioni tratta i dati personali su server in Germania ai fini della creazione, dello stoccaggio e della messa a disposizione online dell’offerta, e negli Stati Uniti (Mandrill di <a href="https://www.brevo.com/it/legal/privacypolicy/" target="_blank">MailChimp</a>) per l’invio delle e-mail, nonché per le relative analisi statistiche e di rendimento. I dati personali vengono cancellati entro trenta giorni dopo un secondo tentativo infruttuoso. Queste informazioni completano la <a href="https://www.vaudoise.ch/it/service-center/informazioni-giuridiche/protezione-dei-dati" target="_blank">dichiarazione relativa alla protezione dei dati personali della Vaudoise Assicurazioni</a> pure applicabile.', 
  thanks: 'Grazie per la sua richiesta', // TODO: titre remerciement après envoie de l'offre depuis le widget
  offer_in_mailbox: 'Le abbiamo inviato un\'e-mail con il link all\'offerta per {{pet_name}}. Può leggere i dettagli dell\'offerta (benefici, premi, condizioni) e stamparli o stipulare direttamente l\'assicurazione.', // NOTE: texte remerciement après envoie de l'offre depuis le widget
  plus_8_warning: 'Il suo animale ha già più di 8 anni. Si noti che le malattie che esistevano prima della stipula dell\'assicurazione non sono assicurate.',
  yearsRange: {
    u2: '3 mesi a 2 anni',
    u4: '3 a 4 anni',
    u6: '5 a 6 anni',
    u8: '7 a 8 anni',
    u10: '9 a 10 anni',
    u12: '11 a 12 anni',
    o13: '13 anni e più'
  },
  race: {
    suptitle: 'Razza e luogo di residenza',
    pet_mixed: 'Meticcio',
    pet_breed: 'Razza pura',
    pet_unknown: 'Sconosciuta',
    breed: 'Razza',
    breed_2: 'Seconda Razza',
    zipcode: 'Codice postale',
    city: 'Luogo'
  },
  navigation: {
    goToInsuranceStep: 'Calcolare il premio',
    submitFromInsuranceStep: 'Stipulare l’assicurazione',
    sendOfferByEmail: 'Inviare l’offerta per e-mail',
    prev: 'Cambiare i criteri',
    next: 'Seguente',
    ok: 'Ritorno alla calcolatrice', // TODO: label du bouton de la page de remerciement après envoie de l'offre depuis le widget
    seeOffer: 'Vedere l\'offerta'
  },
  user: {
    petName: 'Nome dell\'animale',
    lastName: 'Cogome *',
    firstName: 'Nome *',
    emailAddress: 'Indirizzo e-mail',
    phoneNumber: 'Telefono',
  },
  offer_conclusion_1: 'Può leggere i dettagli dell\'offerta per {{pet_name}} (benefici, premi, condizioni) e stamparli o stipulare direttamente l\'assicurazione.',
  offer_conclusion_2: 'PS: Le abbiamo inviato anche un\'e-mail con il link all\'offerta.'
}