import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './WidgetTightRadioButton.module.sass'
import { OfferTip, ButtonText } from '../..'
import { useTranslation } from 'react-i18next'

const WidgetTightRadioButton = ({
  children,
  name,
  value,
  label,
  translatedLabel,
  description,
  icon,
  iconMargin = true,
  iconSmall,
  onChange,
  checked,
  flex,
  price,
  info,
  left,
  loading,
  clickable,
  marginBottom = '0'
}) => {
  const { t } = useTranslation()
  const [ id ] = useState(`${name}-${value}`)
  const [ hasDescription ] = useState(typeof description !== 'undefined')
  const align = left ? styles.left : styles.center

  const inputClasses = () => classNames([
    styles.input,
    hasDescription && styles.hasDescription,
    iconSmall && styles.iconSmall,
    iconMargin && styles.iconMargin,
    loading && styles.loading,
    clickable && styles.clickable
  ].filter(item => !!item))

  const containerClasses = () => classNames([
    align, 
    flex && styles.flex
  ].filter(item => !!item))

  return (
    <>
      <input
        readOnly
        className={inputClasses()}
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label 
        className={styles.label}
        htmlFor={id}
        style={{ marginBottom: marginBottom }}
      >
        <div>
          <div className={containerClasses()}>
            { icon }
            <div className={styles.labelWithCheck}>
              { t(translatedLabel) || label }
              {
                info && <span style={{ marginLeft: '10px' }}>
                  <OfferTip plain content={info} align='left' place='right' />
                </span>
              }
            </div>
          </div>
          { 
            description 
              && 
                <div className={styles.right}>
                  { description }
                </div>
          }
          {
            price 
              && 
                <div className={styles.price}>
                  { price }
                </div>
          }
        </div>
        {
          children
            &&
              <div className={styles.children}>
                { children }
              </div>
        }
      </label>
    </>
  )
}

export default WidgetTightRadioButton
