module.exports = {
  calculatorTitle: 'Assurance de santé',
  calculatorDescription: 'Calculez en trois clics la prime d\'assurance pour votre compagnon à quatre pattes',
  petSpecies: '',
  petAgeCategory: 'Âge', // Dropdown, selection d'âge
  ageCategory: 'âgé de', // InsuranceType Step suptitle / format: {{ pet_species }} âgé de {{ pet_age_category }}
  offerSent: 'Nous vous avons envoyé un e-mail avec l\'offre.', //ce texte n'est plus utilisé, juste? c'est maintenant thanks et offer_in_mailbox?
  conditions: 'En demandant une offre ou en concluant en ligne, Vaudoise Assurances traite vos données personnelles sur des serveurs en Allemagne pour la création, le stockage et la mise à disposition en ligne de l\'offre, et aux Etats-Unis (par Mandrill de <a href="https://www.brevo.com/fr/legal/privacypolicy/" target="_blank">MailChimp</a>) pour l\'envoi des courriels et les analyses statistiques et de performance y relatives. Vos données sont effacées dans les trente jours suivant une deuxième relance infructueuse. Ces informations complètent la <a href="https://www.vaudoise.ch/fr/service-center/informations-legales/protection-des-donnees" target="_blank">Déclaration relative à la protection des données personnelles de Vaudoise Assurances</a> qui est applicable au surplus.',
  thanks: 'Merci pour votre demande d\'offre', // titre remerciement aprs envoie de l'offre depuis le widget
  offer_in_mailbox: 'Nous vous avons envoyé un e-mail avec le lien sur l\'offre pour {{pet_name}}. Vous pourrez prendre connaissance des détails de l\'offre (prestations, primes, conditions) et l\'imprimer ou directement conclure l\'assurance.', // @Calvin
  plus_8_warning: 'Votre animal a déjà plus de 8 ans. Veuillez svp prendre note du fait que les maladies déjà existantes avant la conclusion de l\'assurance ne seront pas assurées.',
  yearsRange: {
    u2: '3 mois à 2 ans',
    u4: '3 ans à 4 ans',
    u6: '5 ans à 6 ans',
    u8: '7 ans à 8 ans',
    u10: '9 ans à 10 ans',
    u12: '11 ans à 12 ans',
    o13: '13 ans et plus'
  },
  race: {
    suptitle: 'Race et lieu de résidence',
    pet_mixed: 'Croisée', 
    pet_breed: 'Pure race',
    pet_unknown: 'Inconnue',
    breed: 'Race',
    breed_2: 'Deuxième Race',
    zipcode: 'Code postal',
    city: 'Lieu'
  },
  navigation: {
    goToInsuranceStep: 'Calculer la prime',
    submitFromInsuranceStep: 'Conclure l\'assurance',
    sendOfferByEmail: 'Recevoir l\'offre par e-mail',
    prev: 'Changer les critères',
    next: 'Suivant',
    ok: 'Retour au calculateur', // TODO: label du bouton de la page de remerciement après envoie de l'offre depuis le widget, Karin: dès que le bouton menera à la landingpage de l'offre, le texte sera: 'Voir l'offre
    seeOffer: 'Voir l\'offre'
  },
  user: {
    petName: 'Nom de l\'animal',
    lastName: 'Votre nom *',
    firstName: 'Votre prénom *',
    emailAddress: 'Adresse e-mail',
    phoneNumber: 'Numéro de téléphone',
  },
  offer_conclusion_1: 'Vous pourrez prendre connaissance des détails de l\'offre pour {{pet_name}} (prestations, primes, conditions) et l\'imprimer ou directement conclure l\'assurance en ligne.',
  offer_conclusion_2: 'PS: Nous vous avons également envoyé un e-mail avec le lien sur l\'offre.'
}