const initialState = {
  form: {
    step: 'widget-step-1',
    holder_title: '',
    holder_email: '',
    holder_first_name: '',
    holder_last_name: '',
    holder_phone: '',
    holder_city: '',
    holder_zip_code: '',
    insurance_type: '',
    insurance_month_cost: null,
    insurance_franchise: 25000,
    pet_species: '',
    pet_age_category: '',
    pet_name: '',
    pet_animal_id: '',
    pet_animal_id_2: '',
    agent_code: '',
    email_offer: false,
  },
  selectedInsuranceValues: []
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_FORM' :
    
    let step = action.step
      ? { step: action.step }
      : {}

    return {
      ...state,
      form: {
        ...state.form,
        ...action.data,
        ...step
      }
    } 

    case 'SET_CODE_AGENT_AND_PET_ID' :

    return {
      ...state,
      form: {
        ...state.form,
        agent_code: action.codeAgent?.slice(0, 4),
        promo_code: action.promo,
        pet_id: action.petId,
      }
    } 

    case 'SELECT_HEALTH' :

    return {
      ...state,
      form: {
        ...state.form,
        insurance_franchise: action.data,
        pet_id: action.petId,
        insurance_type: 'classic'
      }
    } 

    case 'SET_ACTIVE_STEP' :

    return {
      ...state,
      form: {
        ...state.form,
        step: action.data
      }
    }

    case 'SET_INSURANCE_VALUES' :

    return {
      ...state,
      selectedInsuranceValues: [
        ...action.data
      ]
    }

    case 'SET_EMAIL_OFFER_STATE' :

    return {
      ...state,
      form: {
        ...state.form,
        email_offer: action.data
      }
    }

    default :
      throw new Error()
  }
}

export { 
  initialState, 
  reducer 
}
