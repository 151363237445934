import React from 'react';
import {Box} from 'grommet';
import styled from 'styled-components';

const Body = styled(Box)`
  width: 100%;
  max-width: 426px;
  margin: 0 auto;
  text-align: ${props => (props.center ? 'center' : null)};

  ${props =>
    props.large
      ? `
    @media (min-width: 768px) {
      max-width: 910px;
    }
    `
      : null}
`;

const PageBody = ({children, ...rest}) => <Body {...rest}>{children}</Body>;

export default PageBody;
