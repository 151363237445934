import { Select } from 'grommet'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './LangSelect.module.sass'

const LangSelect = ({ name, id, disabled }) => {
  const { t } = useTranslation()

  const valueKeyConfig = { 
    key: 'value', 
    reduce: true 

  }
  
  const options = [
    {
      label: 'Français',
      value: 'fr'
    },
    {
      label: 'Deutsch',
      value: 'de'
    },
    {
      label: 'Italiano',
      value: 'it'
    }
  ]

  return (
    <div className={styles.container}>
      <Select
        plain
        labelKey='label'
        name={name}
        id={id}
        disabled={disabled}
        valueKey={valueKeyConfig}
        options={options}
      />
    </div>
  )
}

export default LangSelect
