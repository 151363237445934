import currency from 'currency.js'

export const matchKey = (form, key, parseValuesInt = false) => {
  if(parseValuesInt) {
    return (item) => parseInt(item[key]) === parseInt(form[key])
  } else {
    return (item) => item[key] === form[key]
  }
}

export const getPriceCentsToString = (price, bar = false) => {
  const cents = price%100
  const francs = (price-cents)/100
  const centsString = cents.toString().length < 2 ? cents.toString() + '0' : cents.toString()
  const needBar = cents === 0
  return `${francs}.${needBar && bar ? '-' : centsString}`
}

export const getPricePerMonth = (price, monthString, option) => {
  const priceString = generatePriceString(price, true)
  const bigPriceString =
    !option?.disabled 
      ? `${priceString} / ${monthString}` 
      : ''

  return bigPriceString
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const generateNamesObject = (names, prefix) => {
  let object = {}
  names.forEach(name => {
    object[name] = prefix 
      ? prefix + '_' + name 
      : name
  })
  return object
}

export const summaryAddressInHTML = (formValue, prefixName) => {
  const names = generateNamesObject(['first_name', 'last_name', 'line', 'line_2', 'zip_code', 'city'], prefixName)
  return `
    ${formValue[names.first_name] || formValue[names.last_name] ? `<div>${formValue[names.first_name] || ''} ${formValue[names.last_name] || ''}</div>` : ''}
    ${formValue[names.line] ? `<div>${formValue[names.line]}</div>` : ''}
    ${formValue[names.line_2] ? `<div>${formValue[names.line_2]}</div>` : ''}
    <div>${formValue[names.zip_code] ? formValue[names.zip_code] : '' } ${formValue[names.city] ? formValue[names.city] : ''}</div>
  `
}

export const cleanFormWithSchema = (form, schema) => {
  let res = form
  const keys = Object.keys(schema.fields)

  Object.keys(form).forEach(key => {
    if (!keys.find(item => item === key)) {
      delete form[key]
    } else if (
      key === 'holder_post_address_strid' ||
      key === 'holder_post_address_house_key'
    ) {
      form[key] = form[key] && form[key].toString()
    }
  })

  return res
}

export const prefixNameConverter = (form, prefixIn, prefixOut) => {
  let newForm = {}
  Object.entries(form).forEach(([key, value]) => {
    const newKey =
      prefixIn
        ? prefixOut
          ? key.replace(prefixIn, prefixOut)
          : key.replace(`${prefixIn}_`, '')
        : `${prefixIn}_${key}`
    
    newForm[newKey] = value
  })
  return newForm
}

export const getAddressFields = (form, prefix) => {
  let address = {}

  const fields = [
    'title',
    'first_name',
    'last_name',
    'city',
    'line',
    'line_2',
    'post_address_id',
    'post_address_strid',
    'post_address_house_key',
    'address_id',
    'zip_code',
    'country'
  ]

  const formKeys = generateNamesObject(fields, prefix)
  fields.forEach(item => {
    address[item] = form[formKeys[item]]
  })

  return address
}

export const currencyCHFFormat = (output, swissFormat) => {
  return swissFormat ? output.replace('.00', '.-') : output
}

export const generatePriceString = (intPrice, fromCents) => {
  return currency(intPrice, { 
    symbol: 'CHF ',
    separator: '\'',
    decimal: '.',
    fromCents: fromCents
  }).format()
}

export const currencyOutput = value => currency(value, { 
  symbol: 'CHF ',
  separator: '\'',
  decimal: '.',
  fromCents: true,
  increment: .1
}).format()

export const getPriceAndCalculData = (price, frequency, swissFormat, getRawValues) => {
  let surprimeMultiplier = 0
  let percent = null
  const freq = parseInt(frequency)

  const currencyOutput = value => currency(value, { 
    symbol: swissFormat ? 'CHF ' : '',
    separator: '\'',
    decimal: '.',
    fromCents: true,
    increment: .1
  }).format()

  if (freq && freq > 1) {
    surprimeMultiplier = freq / 100
    percent = freq + '%'
  }

  const annualPrice = price*12
  const surprime = Math.round(annualPrice*surprimeMultiplier / 10) * 10
  const priceWithSurprime = annualPrice + surprime
  const federalTimber = Math.round((priceWithSurprime*0.05) / 10) * 10
  const totalPrice = priceWithSurprime + federalTimber

  return {
    percent: percent,
    monthPrice: getRawValues ? price : currencyOutput(price),
    annualPrice: getRawValues ? annualPrice : currencyOutput(annualPrice),
    surprime: getRawValues ? surprime : currencyOutput(surprime),
    priceWithSurprime: getRawValues ? priceWithSurprime : currencyOutput(priceWithSurprime),
    federalTimber: getRawValues ? federalTimber : currencyOutput(federalTimber),
    totalPrice: getRawValues ? totalPrice : currencyOutput(totalPrice)
  }
}

export const getStringifiedObjectValue = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  const a = s.split('.')
  for (const i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (k in o) {
          o = o[k]
      } else {
          return
      }
  }
  return o
}

export const cleanEmptyFormData = formValue => {
  Object.entries(formValue).forEach(([key, value]) => {
    // check if value isn't defined and if it isn't a false from boolean 
    if (!value && value !== false) {
      delete formValue[key]
    }
  })
}

const getArrayIds = array => [
  ...array.map(item => item.id)
]

export const arraysEqual = (aArr, bArr) => {
  const a = getArrayIds(aArr)
  const b = getArrayIds(bArr)

  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }

  return true
}

export const hideOfferScreens = moment => 
  moment().isSame('2022-03-14', 'day') &&
  moment().isBetween(
    moment('19:00:00', 'hh:mm:ss'),
    moment('23:59:59', 'hh:mm:ss')
  )