import React, {useState, useContext, useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {Modal} from '@animalia/components';
import {Delivery} from '@animalia/models';
import * as Icons from 'grommet-icons';
import { axios } from '@animalia/instances'

import { 
  UserContext,
  PetsContext
} from '@animalia/contexts';

import {
  Box,
  Button,
  TextArea,
  Accordion,
  AccordionPanel,
  RadioButton,
  Select,
  FormField,
} from 'grommet';

function optionize(reason) {
  return {
    value: reason.id,
    code: reason.code,
    label: reason.description,
    options:
      reason.displayable_children.length > 1
        ? reason.displayable_children.map(optionize)
        : [],
  };
}

const DeleteOrderLayer = ({pet, order, deliveries}) => {
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [choice, setChoice] = useState(null);
  const [date, setDate] = useState(null);
  const {user} = useContext(UserContext);
  const {dispatch} = useContext(PetsContext);
  const {t, i18n} = useTranslation();

  const tree = reason => {
    if (reason.options.length === 0) {
      //render checkbox
      return (
        <Box pad={{vertical: 'small'}} key={reason.value}>
          <RadioButton
            id={reason.code}
            label={
              reason.label[
                typeof reason.label[i18n.language] !== 'undefined'
                  ? i18n.language
                  : 'fr'
              ]
            }
            value={reason.value}
            checked={reason.value === choice}
            onChange={() => {
              setChoice(reason.value);
            }}
          />
        </Box>
      );
    } else {
      //rended accordion and
      return (
        <AccordionPanel
          key={reason.value}
          label={
            reason.label[
              typeof reason.label[i18n.language] !== 'undefined'
                ? i18n.language
                : 'fr'
            ]
          }>
          <Box pad={{top: 'small', left: 'small', bottom: 'small'}}>
            <Accordion>{reason.options.map(tree)}</Accordion>
          </Box>
        </AccordionPanel>
      );
    }
  };

  useEffect(() => {
    axios.get('/reasons?group=order').then(response => {
      const reasonsList = response.data.reasons.map(optionize);
      setReasons(reasonsList);
    });
  }, []);

  if (typeof deliveries[0] === 'undefined' || typeof order === 'undefined') {
    return null;
  }

  const deliveriesList = [...deliveries]
    .map(d => Delivery(d))
    .filter(d => d.isEditable())
    .map(delivery => {
      delivery.label = new Date(delivery.delivered_at).toLocaleDateString(
        i18n.language,
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      );
      return delivery;
    });

  deliveriesList.unshift({
    id: 'now',
    delivered_at: 'now',
    label: t('common.immediately'),
  });

  return (
    <Box>
      <Button
        primary
        reverse
        gap="xxsmall"
        size="small"
        color="status-critical"
        label={t('order.deleteOrder')}
        onClick={() => setShow(true)}
        icon={<Icons.FormClose />}
      />
      {show && (
        <Modal
          handleClose={() => {
            setShow(false);
          }}
          title={
            <Trans i18nKey="animal.deleteOrderFor">
              Arrêter mon abonnement pour {{name: pet.name}}
            </Trans>
          }>
          <FormField
            label={t('order.lastDelivery')}
            background="light-1"
            margin="none">
            <Select
              size="small"
              plain
              options={deliveriesList}
              onChange={e => {
                setDate(e.value.delivered_at);
              }}
              valueKey="id"
              labelKey="label"
              placeholder={t('order.selectLastDelivery')}
            />
          </FormField>
          <Accordion>{reasons.map(tree)}</Accordion>
          <FormField label={t('common.your_comment')}>
            <Box background="light-1">
              <TextArea
                onChange={e => {
                  setComment(e.target.value);
                }}
              />
            </Box>
          </FormField>
          <Button
            label={t('order.validateDate')}
            disabled={choice === null}
            primary
            onClick={() => {
              axios
                .delete(`/pets/${pet.id}/orders/${order.id}/cancel`, {
                  data: {
                    // if "now", the webservice requires to use null
                    last_delivery_date: date !== 'now' ? date : null,
                    reason_id: choice,
                    reason_comment: comment,
                  },
                })
                .then(response => {
                  if (response.data.canceled === true) {
                    setShow(false);
                    dispatch({
                      type: 'CANCEL_ORDER',
                      id: pet.id,
                      date: choice,
                    });
                  } else {
                    setShow(false);
                    alert(t('message.error'));
                  }
                })
                .catch(err => {
                  console.log(err);
                  alert(t('message.error'));
                });
            }}
          />
        </Modal>
      )}
    </Box>
  );
};

export default DeleteOrderLayer;
