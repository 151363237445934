
  module.exports = {
  title: "Allevatore",
  breederSpace: "Spazio allevatore",
  login: {
    createBreederAccount: "Creare un account allevatore",
  },
  species: {
    cat: "Gatto",
    dog: "Cane",
  },
  personal_data: {
    breeder_infos: "Informazioni allevatore",
    personal_data: "Dati personali",
    social_reason: "Ragione sociale",
    social_reason_label: "Nome dell'allevamento resp. ragione sociale",
    races: "Razze",
    breeding: "Allevamento",
    tel: "Telefono",
    email: "E-mail",
    message: "Messaggio",
    send_request: "Inviare la richiesta",
  },
  confirmation: {
    askSent: "La sua richiesta è stata inviata con successo",
    askSentMessage: "La contatteremo al più presto",
    backToHome: "Tornare alla home",
  },
  declined: {
    askDeclined: "La sua richiesta è stata rifiutata",
    askDeclinedMessage: "La sua richiesta purtroppo è stata rifiutata perché non soddisfa tutti i nostri criteri di accettazione.<br><br>Siamo sinceramente dispiaciuti.",
    backToHome: "Tornare alla home",
  },
  dashboard: {
    myBreederSpace: "Il mio spazio allevatore",
    myCoordinates: "Le mie coordinate",
    myAttestations: "I miei attestati",
    breeding: "Allevamento",
    race: "Razza",
    address: "Indirizzo",
    conventionNumber: "Numero di convenzione",
    email: "E-mail",
    howToEdit: "Come modificare le mie informazioni?",
    client: "Cliente",
    petName: "Nome dell'animale",
    attestationNumber: "Numero attestato",
    insuranceDate: "Data assicurazione",
    attestation: "Attestato",
    cga: "CGA",
    declareNewPet: "Notificare un nuovo animale",
    howEditCertificate: "Come modificare un attestato?",
    doWantEditACertificate: "Desidera modificare un attestato?",
    editCertificateInformations: "Per modificare un attestato, la preghiamo di contattarci all'indirizzo <a href='mailto:breeders@animalia.ch?subject={{agentCode}} – {{breederName}}'>breeders@animalia.ch</a> indicando il numero dell'attestato, le modifiche da apportare e le ragioni delle modifiche.",
    doWantEditInformations: "Desidera modificare i dati del suo allevamento?",
    editInformations: "Per modificare i dati del suo allevamento, la preghiamo di contattarci all'indirizzo <a href='mailto:breeders@animalia.ch?subject={{agentCode}} – {{breederName}}'>breeders@animalia.ch</a>",
  },
  certificates: {
    myClient: "Il mio cliente",
    theyPet: "Il suo animale",
    title: "Appellativo",
    mr: "Signor",
    mrs: "Signora",
    communicationLanguage: "Lingua di comunicazione",
    firstname: "Nome",
    lastname: "Cognome",
    email: "E-mail",
    birthdate: "Data di nascita",
    pet: "Animale",
    petName: "Nome dell'animale",
    race: "Razza",
    exitDate: "Data di uscita",
    gender: "Genere",
    male: "Maschio",
    female: "Femmina",
    dog: "Cane",
    cat: "Gatto",
    createCertificate: "Creare l'attestato",
    cga1: "L'allevatore dichiara di essere formalmente autorizzato dal suo cliente a trasmettere le informazioni qui sopra alla Vaudoise",
    cga2: "Attenzione: una volta validato, l'attestato può essere modificato solo da Animalia.",
    created: "Il suo attestato è stato creato con successo.",
    createdMessage: "Lo troverà  nel suo spazio allevatore.",
    backToBreederSpace: "Tornare allo spazio allevatore",
    petNameAlreadyExist: "Il nome dell'animale è già  stato utilizzato",
    petBirthdateTooOldMax120Days: "La data di nascita dell'animale non può essere superiore a 120 giorni",
    petExitDateMax120DaysBirthdate: "La data di uscita dell'animale deve essere compresa tra la data di nascita e 120 giorni dopo la data di nascita.",
    petBirthdateMin6Months: "La data di nascita dell'animale deve essere superiore a 6 mesi",
    petExitDateMax56DaysBirthdate: "La data di uscita dell'animale deve essere compresa tra la data di nascita e 56 giorni dopo la data di nascita.",
    petExitDateMax180DaysBirthdate: "La data di uscita dell'animale deve essere compresa tra la data di nascita e 180 giorni dopo la data di nascita.",
    petNameTip: "Nome dell'animale assegnato dal cliente",
  }
}