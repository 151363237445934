module.exports = {
  noInvoices: 'Nessuna fattura',
  noInvoicesDescription: 'Non ci sono ancora fatture sul vostro conto.',
  refno: 'No di ordine',
  total: 'Prezzo',

  paymentModal: {
    successTitle: 'Grazie per il vostro pagamento',
    successMessage: 'Abbiamo ricevuto il vostro pagamento e vi ringraziamo per questo.',
    failureTitle: 'Oops! Mancato pagamento',
    failureMessage: 'l vostro attuale metodo di pagamento non sembra funzionare. Si prega di registrare un altro metodo di pagamento e tornare a questa pagina per riprovare.',
  },
  payNow: 'Paga ora',
  closeFaildModal: 'Non adesso',
  payementMethod: 'Aggiungere un nuovo metodo di pagamento',
  status: {
    pending: 'In attesa',
    failed: 'Fallimento del trattamento',
    completed: 'Pagato',
    'no-default-card': 'Nessun mezzo di pagamento registrato',
    refunded: 'Rimborsato',
  },
};
