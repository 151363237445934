import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CalculatorContext } from '@animalia/contexts'
import { WidgetButton } from '@animalia/components'
import { isFirst, isFormSend, prevStep, insuranceStep, clientStep } from '@animalia/actions'

const StepperNavigation = ({ children, steps, pet }) => {
  const { t } = useTranslation()
  const { calculator: { form }, dispatch } = useContext(CalculatorContext)
  
  // navigation functions 
  const goToPrevStep = () => prevStep(dispatch, steps, form.step)
  const goToInsuranceStep = () => insuranceStep(dispatch, form)
  const goToClientStep = () => clientStep(dispatch, form)

  // labels 
  const labels = {
    goToInsuranceStep: t('calculator.navigation.goToInsuranceStep'),
    submitFromInsuranceStep: t('calculator.navigation.submitFromInsuranceStep'),
    sendOfferByEmail: t('calculator.navigation.sendOfferByEmail'),
    prev: t('calculator.navigation.prev'),
    next: t('calculator.navigation.next')
  }

  // components 
  const NavigationButtons = () => {
    switch (form.step) {
      case 'widget-step-1':
        
        return (
          <>
            <WidgetButton
              fill
              primary
              onClick={goToClientStep} 
              label={labels.goToInsuranceStep} 
            />
          </>
        )

      case 'widget-step-2':
        return (
          <>
            <WidgetButton 
              fill
              primary
              form='form'
              type='submit' 
              label={labels.submitFromInsuranceStep}
            />
            {
              !pet &&
                <WidgetButton 
                  fill
                  primary
                  onClick={goToInsuranceStep}
                  label={labels.sendOfferByEmail}
                />
            }
          </>
        )
      case 'widget-step-3-guest':
        return (
          <WidgetButton
            fill
            primary
            form='form'
            type='submit' 
            label={labels.next}
          />
        )
      default :
        return null
    }
  }

  return (
    <div style={{ width: '100%' }}>
      { children }
    </div>
  )
}

export default StepperNavigation
