module.exports = {
  error: 'Erreur',
  warning: 'Attention',

  apiError: 'Il y a eu une erreur de traitement de la demande.',
  fieldValidationError: 'Un ou plusieurs champ sont invalides.',

  petNotFound: "L'animal recherché n'a pas été trouvé",
  petsNotFound: "Il n'y a pas d'animaux",

  insuranceNumberDelete: {
    title: "Suppression de connexion avec l'assurance {{name}}",
    content:
      "Attention: vous supprimez uniquement la connexion avec le numéro de police de {{name}}. Le contrat d'assurance reste en vigueur. Si vous voulez le résilier, veuillez svp vous référer aux Conditions Générales d'Assurances (CGA).",
  },

  card: {
    cancelled: 'Opération de paiement annulée',
    error: 'Veuillez svp vérifier les informations de votre moyen de paiement.',
  },

  latePayment: {
    title: 'Oups, vous avez encore une facture ouverte !',
    content:
      'Une facture est restée impayée. Veuillez svp la régler avant de saisir un nouvel Abo Gourmand.',
  },

  quitWarning: {
    title: 'Êtes-vous sûr de vouloir quitter le formulaire?',
    description:
      'Retourner à ‘{{destination}}’ vous fera perdre toutes les données non soumises du formulaire.',
    continue: 'Oui, je veux quitter',
    cancel: 'Non, continuer le formulaire',
  },
};
