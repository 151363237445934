export default {
  fr: {
    common: require('./lang/fr/common.js'),
    animal: require('./lang/fr/animal.js'),
    assistance: require('./lang/fr/assistance.js'),
    menu: require('./lang/fr/menu.js'),
    message: require('./lang/fr/messages.js'),
    personnal: require('./lang/fr/personnal.js'),
    delivery: require('./lang/fr/delivery.js'),
    order: require('./lang/fr/order.js'),
    invoices: require('./lang/fr/invoices.js'),
    user: require('./lang/fr/user.js'),
    auth: require('./lang/fr/auth.js'),
    insurance: require('./lang/fr/insurance.js'),
    urls: require('./lang/fr/urls.js'),
    calculator: require('./lang/fr/calculator.js'),
    offer: require('./lang/fr/offer.js'),
    prestations: require('./lang/fr/prestations.js'),
    redirections: require('./lang/fr/redirections.js'),
    breeder: require('./lang/fr/breeder.js'),
  },
  de: {
    common: require('./lang/de/common.js'),
    animal: require('./lang/de/animal.js'),
    assistance: require('./lang/de/assistance.js'),
    menu: require('./lang/de/menu.js'),
    message: require('./lang/de/messages.js'),
    personnal: require('./lang/de/personnal.js'),
    delivery: require('./lang/de/delivery.js'),
    order: require('./lang/de/order.js'),
    invoices: require('./lang/de/invoices.js'),
    user: require('./lang/de/user.js'),
    auth: require('./lang/de/auth.js'),
    insurance: require('./lang/de/insurance.js'),
    urls: require('./lang/de/urls.js'),
    calculator: require('./lang/de/calculator.js'),
    offer: require('./lang/de/offer.js'),
    prestations: require('./lang/de/prestations.js'),
    redirections: require('./lang/de/redirections.js'),
    breeder: require('./lang/de/breeder.js'),
  },
  it: {
    common: require('./lang/it/common.js'),
    animal: require('./lang/it/animal.js'),
    assistance: require('./lang/it/assistance.js'),
    menu: require('./lang/it/menu.js'),
    message: require('./lang/it/messages.js'),
    personnal: require('./lang/it/personnal.js'),
    delivery: require('./lang/it/delivery.js'),
    order: require('./lang/it/order.js'),
    invoices: require('./lang/it/invoices.js'),
    user: require('./lang/it/user.js'),
    auth: require('./lang/it/auth.js'),
    insurance: require('./lang/it/insurance.js'),
    urls: require('./lang/it/urls.js'),
    calculator: require('./lang/it/calculator.js'),
    offer: require('./lang/it/offer.js'),
    prestations: require('./lang/it/prestations.js'),
    redirections: require('./lang/it/redirections.js'),
    breeder: require('./lang/it/breeder.js'),
  },
};
