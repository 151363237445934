module.exports = {
  conclusionTitle: 'Vielen Dank für Ihre Mitteilung',
  conclusionMsg: 'Wir haben Ihre Anfrage an die Vaudoise Versicherung weitergeleitet. Die Änderung wird in den nächsten Tagen vorgenommen.',
  deleteNumber: 'Die Verbindung mit dieser Policennummer trennen',
  changeBankData: 'Die Bankverbindung für Rückerstattungen ändern',
  changeAddress: 'Adresse ändern für die Versicherung',
  changeOwner: '{{name}} wechselt seinen Besitzer',
  declareSinister: 'Leistungsfall melden für {{name}}',
  not_complete: 'Sie sind dabei, eine Versicherungsofferte zu erstellen',
  waiting: 'Ihr Versicherungsantrag wurde erfolgreich übermittelt. Hier wird in den nächsten Tagen Ihre Policennummer angezeigt.', 
  noContractNumberSubtext:
    ' {{name}} hat noch keine verbundene Gesundheitsversicherung',
  ContractNumberSubtext: 'Police Nr. {{police}}',
  addContractNumber:
    'Policennummer der bestehenden Animalia Gesundheitsversicherung eingeben',
  addContractNumberDescription:
    'Um Ihr Animalia-Konto mit der Animalia-Gesundheitsversicherung Ihres Vierbeiners zu verbinden, geben Sie hier bitte seine vollständige Policennummer ein (ohne Leerschläge).',
  addContractNumberPopupTitle:
    'Bitte geben Sie die Policennummer der Animalia-Gesundheitsversicherung von {{name}} ein.',
  addContractNumberFieldLabel: 'Animalia Policennummer',
  contractNotFound:
    'Diese Policennummer existiert nicht. Bitte geben Sie die Nummer auf Ihrer Versicherungspolice ein, die auf 330x endet.',
  petNameMismatch:
    'Ups, diese Policennummer gehört nicht zu Ihrem Tier oder sein Name stimmt nicht mit dem auf seiner Versicherungspolice überein. Sie finden den bei uns registrierten Namen und die Policennummer auf Ihrer Versicherungspolice.',
  contractUpdated:
    'Der Versicherungsvertrag von Ihrem Tier ist jetzt mit seinem Profil verbunden.',
  apiConnectionError:
    'Die Verbindung zu den Systemen der Animalia Versicherung wird gerade gewartet. Bitte versuchen Sie es später nochmals (Could not connect to API).',
  unauthorized:
    'Die Verbindung zu den Systemen der Animalia Versicherung wird gerade gewartet. Bitte versuchen Sie es später nochmals (api call unauthorized).',
  apiError:
    'Die Verbindung zu den Systemen der Animalia Versicherung wird gerade gewartet. Bitte versuchen Sie es später nochmals (api error).',
  serverError:
    'Die Verbindung zu den Systemen der Animalia Versicherung wird gerade gewartet. Bitte versuchen Sie es später nochmals (server error).',
  undefined:
    'Dies ist keine gültige Policennummer. Bitte geben Sie die Nummer auf Ihrer Versicherungspolice ein, die auf 330x endet.',
  checkPolicyNumber:
    'Dies ist keine gültige Policennummer. Bitte geben Sie die Nummer auf Ihrer Versicherungspolice ein, die auf 330x endet.',

  comment: '---THE FOLLOWING STRINGS ARE NEW ---',
  addInvoice: 'Eine Rechnung für {{name}} senden',
  addDocumentButton: 'Dokument hinzufügen',
  addInvoiceButton: 'Rechnung hinzufügen',
  addClaimButton: 'Neuer Leistungsfall',
  petFiles: '{{name}}s Dossier',
  editInsurance: 'Formulare für eine Versicherungsänderung (Adresse, IBAN, Tierhalter)',
  invoicedOn: 'Rechnung vom {{date}}',
  xInvoices: '{{count}} Rechnung',
  xInvoices_plural: '{{count}} Rechnungen',
  steps: {
    disaster: 'Leistungsfall',
    medicalFile: 'Krankenakte',
    invoice: 'Rechnung',
  },
  types: {
    vaccine: 'Impfungen oder Präventionskosten',
    health: 'Krankheit oder andere Fälle',
    accident: 'Unfall',
    external: 'Offline gemeldet',
  },
  disaster: {
    illnessTip:
      'Deklarieren Sie bitte unter Krankheit alle Fälle von Krankheit, Kastration, Zahnsteinentfernung, Kremierung und alle anderen Fälle, die nicht ein Unfall sind.',
    intro: {
      title: 'Um welche Art von Leistungsfall handelt es sich?',
      description:
        'Falls die Rechnung mehrere Leistungsfälle oder Tiere betrifft, geben Sie bitte einen Fall nach dem anderen ein. Wiederholen Sie den Prozess so oft wie nötig.',
      new: 'Neuer Leistungsfall',
      existing: 'Bereits deklarierter Leistungsfall',
      vaccine: 'Impfungen oder Präventionskosten',
    },
    type: {
      title: 'Um welche Art von Leistungsfall handelt es sich?',
      description:
        'Bitte wählen Sie aus, ob es sich um einen Unfall, um eine Krankheit oder um einen anderen Fall handelt.',
      description2:
        'Achtung: Für Impfungen oder andere Präventionskosten (Kastration oder Sterilisation, Zahnsteinentfernung, Entwurmung und vom Tierarzt verschriebene Futterergänzugsmittel) müssen Sie keinen Leistungsfall deklarieren.',
      accident: 'Unfall',
      illness: 'Krankheit oder andere Fälle',
      vaccine: 'Sie können uns direkt die Rechnung senden.',
      illnessTip:
        'AVB 2019 und vorher: Deklarieren Sie bitte unter Krankheit alle Fälle von Krankheit, Kastration, Zahnsteinentfernung, Kremierung und alle anderen Fälle, die nicht ein Unfall sind. Ab AVB 2021: Deklarieren Sie bitte unter Krankheit alle Fälle von Krankheit und anderen Fällen, die nicht ein Unfall sind oder nicht unter Präventionskosten fallen.',
    },
    existing: {
      title: 'Deklarierte Leistungsfälle',
      description:
        'Falls Sie den Leistungsfall auf dieser Plattform deklariert haben, wählen Sie ihn bitte in der Liste unten aus.',
      info:
        'Ich habe den Leistungsfall über einen anderen Kanal deklariert (alte Webseite, E-Mail, Telefon, usw.)',
      disasters: 'Leistungsfälle',
      noDisasters:
        'Es sind noch keine Leistungsfälle für {{name}} registriert.',
    },
    title: {
      title: 'Bezeichnung des Leistungsfalles',
      description:
        'Diese Bezeichnung wird Ihnen helfen, sich auch in Zukunft gut in {{name}}s Dossier zurechtzufinden. Erfassen Sie für sich klare Schlüsselwörter, die Sie auch später noch verstehen werden.',
      shortDescription: 'Bezeichnung des Leistungsfalles',
    },
    existingDisasterTitle: {
      title: 'Bezeichnung des Leistungsfalles',
      description:
        'Diese Bezeichnung wird Ihnen helfen, sich auch in Zukunft gut in {{name}}s Dossier zurechtzufinden. Erfassen Sie für sich klare Schlüsselwörter, die Sie auch später noch verstehen werden.',
      shortDescription: 'Bezeichnung des Leistungsfalles',
      year: 'Jahr des Leistungsfalles',
      type: 'Leistungstyp',
    },
    diagnosticAccident: {
      title:
        'Hat Ihre Tierärztin / Ihr Tierarzt bereits eine Diagnose gestellt?',
      description:
        'Unter Diagnose versteht man die Feststellung und Bestimmung (durch die Tierärztin / den Tierarzt) einer körperlichen oder psychischen Beeinträchtigung.',
      diagnostic: 'Diagnose Ihrer Tierärztin / Ihres Tierarztes',
    },
    diagnosticIllness: {
      title:
        'Hat Ihre Tierärztin / Ihr Tierarzt bereits eine Diagnose gestellt?',
      description:
        'Unter Diagnose versteht man die Feststellung und Bestimmung einer körperlichen oder psychischen Beeinträchtigung (durch die Tierärztin / den Tierarzt).',
      diagnostic: 'Diagnose',
      symptomsOptionnal: 'Symptome (optional)',
      symptoms: 'Symptome',
      illnessStartLabel: 'Symptombeginn',
    },
    date: {
      title: 'Zeitpunkt des Unfalls',
      description:
        'Bitte geben Sie den Zeitpunkt des Unfalls so genau wie möglich an. Falls der Unfall nicht in Ihrer Anwesenheit passiert ist, können Sie uns die geschätzte Abweichung des Unfallzeitpunktes angeben.',
      date: 'Datum',
      hour: 'Zeit',
      interval: 'Geschätze Abweichung',
      exact_time: 'genauer Zeitpunkt',
      in_hour: 'innerhalb einer Stunde',
      between_1_4: 'innerhalb von 4 Stunden',
      between_4_6: 'innerhalb von 6 Stunden',
      in_day: 'innerhalb des Tages',
      in_week: 'innerhalb der Woche',
      dateTip:
        'Falls der Unfall nicht in Ihrer Anwesenheit passiert ist, können Sie uns die geschätzte Abweichung des Unfallzeitpunktes angeben',
    },
    illnessTreatment: {
      title: 'Welches ist die verschriebene Behandlung?',
      description:
        'Unter Behandlung versteht man Medikamente, Verbände, weitere Untersuchungen und allgemein das weitere Vorgehen.',
      treatment: 'Verschriebene Behandlung',
    },
    howAccidentHappened: {
      title: 'Unfallhergang und -verlauf',
      description:
        'Bitte beschreiben Sie den Unfallhergang und -verlauf so genau wie möglich. Dies hilft uns, den Leistungsfall so schnell wie möglich zu bearbeiten.',
      howItHappened: 'Beschreibung des Unfallhergangs und -verlaufs',
    },
    anyThirdParty: {
      title: 'War eine Drittperson in den Unfall involviert?',
      description:
        'Unter Drittperson versteht man ein Lebewesen (Mensch oder Tier), welches den Unfall verursacht hat oder daran beteiligt war.',
      howItHappened: 'Beschreibung des Unfallhergangs und -verlaufs',
      known: 'Ja, ich kann die Drittperson identifizieren',
      unknown: 'Ja, aber ich weiss nicht wer es ist',
      none: 'Nein',
      knownTip:
        'Eine Drittperson oder ein anderes Tier waren in den Unfall involviert. Sie wissen, wie die Person oder der Tierhalter kontaktiert werden kann.',
      unknownTip:
        'Eine Drittperson oder ein anderes Tier (ev. Wildtier) waren in den Unfall involviert. Sie kennen die Person oder Tierhalter nicht und haben keine Kontaktangaben',
      noneTip:
        'Es war keine Drittperson oder anderes Tier in den Unfall involviert',
    },
    thirdParty: {
      title: 'Angaben zur involvierten Drittperson',
      description:
        'Bitte vervollständigen Sie die Personalien der involvierten Drittperson. Falls es sich um ein anderes Tier handelt, geben Sie bitte die Daten seines Besitzers an.',
      lastname: 'Name *',
      firstname: 'Vorname *',
      street: 'Adresse',
      number: 'Nr.',
      zip: 'PLZ',
      city: 'Ort',
      country: 'Land',
      email: 'E-Mail',
      phone: 'Telefonnummer',
    },
    comments: {
      title: 'Bemerkungen',
      description:
        'Jede zusätzliche Information kann uns helfen, den Fall zu analysieren und so die Bearbeitung zu beschleunigen.',
      comment: 'Bemerkungen (optional)',
    },
    summary: {
      title: 'Zusammenfassung Leistungsfall',
      description:
        'Bitte überprüfen Sie die Richtigkeit Ihrer Angaben. Sie können die Informationen noch korrigieren, indem Sie auf den gelben Stift neben jeder Information klicken.',
      description2:
        'Bestätigen Sie danach die Angaben und senden Sie die Deklaration.',
      cguAdditional:
        "Die Einreichung des Leistungsfalles und die Bearbeitung desselben unterstehen den <a href='https://www.vaudoise.ch/de/data' target='blank'>Datenschutzbestimmungen der Vaudoise Versicherung.</a>",
      cgu:
        'Ich bestätige, dass ich die Fragen wahrheitsgetreu und vollständig beantwortet habe.',
      type: 'Leistungstyp',
      label: 'Bezeichnung',
      comment: 'Bemerkungen',
      symptoms: 'Symptome',
      treatment: 'Verschriebene Behandlung',
      diagnosis: 'Diagnose',
      occurred_at: 'Datum und Zeit des Unfalls',
      interval: 'Geschätze Abweichung',
      claim_year: 'Jahr',
      animal: 'Tier',
      sequence: 'Unfallhergang und -verlauf',
      third_party: 'Daten der involvierten Drittperson',
      third_party_status: 'Involvierte Drittperson',
    },
  },
  medicalFile: {
    add: {
      title: '{{name}}s Krankenakte',
      description:
        'Falls es sich bei diesem Leistungsfall um den ersten für {{name}} deklarierten Fall handelt, benötigen wir die Krankenakte. Sie können die Bearbeitung beschleunigen, indem Sie uns die Krankenakte und allfällige Analyseresultate hochladen, falls diese in Ihrem Besitz sind.',
      add: 'Krankenakte hochladen',
      addLater: 'Dokumente später hochladen',
      alreadyDone:
        'Die Krankenakte wurde bereits eingereicht oder ich werde sie später senden',
      confirmImage: 'Bild oder Datei bestätigen',
      addImage: 'Bild hinzufügen',
      maxPDF: "Sie können nur eine PDF-Datei hochladen. Diese kann aber mehrere Seiten beinhalten.",
      cantAddImageWhenThereAreAlreadyAPdf: "Sie können nur eine PDF-Datei hochladen. Diese kann aber mehrere Seiten beinhalten.",
      cantAddPDFWhenThereAreAlreadyImages: "Bitte wählen Sie ein Bild aus. Falls Sie ein pdf hochladen möchten, löschen Sie bitte zuerst das bereits hochgeladene Bild.",
      maxSizePDF: 'Die Grösse des Dokuments darf 6Mo nicht übersteigen',
      browse: 'Von diesem Computer',
      dropFile: 'Datei hierhin ziehen',
      check:
        'Bitte überprüfen Sie, dass alle Elemente des Dokumentes gut sicht- und lesbar sind. ',
      rotate: 'Drehen',
      delete: 'Löschen',
      fileFormatNotSupported: 'Dieses Dateiformat wird nicht unterstützt',
    },
    title: {
      title: 'Bezeichnung Dokument',
      description:
        'Geben Sie dem Dokument eine Bezeichnung, die kurz erklärt, worum es sich handelt.',
      titleField: 'Bezeichnung Dokument',
    },
    summary: {
      title: 'Zusammenfassung Krankenakte',
      animal: 'Tier',
      files: 'Dateien',
      label_medicalFile: 'Bezeichnung',
    },
  },
  invoices: {
    invoiceTitle: 'Rechnung {{date}}',
    intro: {
      title: 'Hochladen Ihrer Behandlungsrechnung',
      description:
        'Laden Sie entweder ein PDF-Dokument oder ein oder mehrere Bilder hoch. Validieren Sie jedes Bild nachdem Sie kontrolliert haben, dass alle Elemente gut sicht- und lesbar sind (klicken Sie dafür auf die gelbe Lupe) und wiederholen Sie dies für jede weitere Seite der Rechnung.',
      useExisting: 'Eine kürzlich hochgeladene Rechnung auswählen',
      later: 'Ich werde die Behandlungsrechnung später senden',
      add: 'Eine neue Rechnung hochladen',
    },
    existing: {
      title: 'Kürzlich hochgeladene Rechnungen',
      description:
        'Falls Sie die Rechnung kürzlich bereits für ein anderes Tier oder einen anderen Leistungsfall hochgeladen haben, finden Sie sie in der Liste unten. Sie müssen die Rechnung so nicht nochmals hochladen.',
    },
    details: {
      title: 'Gesamtbetrag der Rechnung',
      description:
        'Bitte geben Sie den Gesamtbetrag der hochgeladenen Rechnung ein. Achtung: Es ist nicht wichtig, ob die Rechnung mehrere Leistungsfälle oder Tiere betrifft, oder ob alle Leistungen später zurückerstattet werden. Geben Sie einfach den Gesamtbetrag inklusive Mehrwertsteuer ein.',
      total: 'Gesamtbetrag der Rechnung (inkl. MwSt)',
      invoiceSubject: 'Diese Behandlungsrechnung betrifft auch',
      many_pets: 'andere Tiere',
      many_disasters: 'andere Leistungsfälle',
      vaccine_too: 'Impfungen oder Präventionskosten',
    },
    year: {
      title: 'Behandlungsjahr',
      description: 'In welchem Jahr fand die Behandlung statt?',
      instruction: 'Sie können mehrere Jahre auswählen.',
    },
    summary: {
      title: 'Zusammenfassung der Rechnung',
      invoice: 'Rechnung',
      animal: 'Tier',
      amount: 'Gesamtbetrag der Rechnung (inkl. Mwst.)',
      involves_multiple_pets: 'Betrifft auch andere Tiere',
      involves_multiple_claims: 'Betrifft auch andere Leistungsfälle',
      involves_a_vaccine: 'Betrifft Impfungen oder Präventionskosten',
      billing_years: 'Behandlungsjahr',
    },
    confirmation: {
      title: 'Sendebestätigung',
      description:
        'Ihre Daten und Dokumente betreffend dem Leistungsfall "{{disaster}}" von {{name}} wurden an unsere Leistungsabteilung gesandt.',
      delay:
        'Der Bearbeitungszeitpunkt wird aktuell auf dieses Datum geschätzt:',
      delayTip:
        'Sie können die Bearbeitungszeit verkürzen, indem Sie die Krankenakte Ihres Tieres und allfällige Analyseresultate hochladen.',
      delayNotice:
        'Dieser Termin dient lediglich als Hinweis und hängt davon ab, ob zusätzliche Informationen für die Bearbeitung eingefordert werden müssen.',
      pleaseRepeat:
        '<strong>Falls die eingereichte Rechnung mehrere Leistungsfälle oder Tiere betrifft</strong>, wiederholen Sie das Vorgehen bitte so viele Male wie nötig.',
      newInvoiceForThisPet: 'Andere Rechnung für {{name}}',
      newInvoiceForAnotherPet: 'Rechnung für ein anderes Tier',
      medicalFilesForThisPet: 'Dossier von {{name}}',
      backToMyPets: 'Zurück zu "Mein Rudel"',
      otherActions: 'Andere Optionen',
      sameInvoiceForAnotherPet: 'Gleiche Rechnung für ein anderes Tier',
      sameInvoiceForAnotherDisaster:
        'Gleiche Rechnung für einen anderen Leistungsfall von {{name}}',
    },
    otherpets: {
      title: 'Wer ist noch von dieser Rechnung betroffen?',
      description:
        'Bitte wählen Sie das Tier, welches ebenfalls von dieser Rechnung betroffen ist. Falls es sich um mehrere Tiere handelt, wiederholen Sie das Vorgehen danach so viele Male wie nötig.',
    },
  },
  edit: {
    title: 'Änderung von {{name}}s Gesundheitsversicherung',
    description:
      'Sie können uns hier alle Änderungen, welche die Gesundheitsversicherung betreffen, melden. Falls Sie nicht finden, was Sie benötigen, senden Sie uns bitte ein E-Mail an animalia@vaudoise.ch.',
  },
  documents: {
    title: '{{name}}s Dossier',
    description:
      'Falls der Leistungsfall auf dieser Plattform deklariert wurde, finden Sie ihn in der Liste unten.',
    tip:
      'Falls Sie den Leistungsfall in der Vergangenheit bereits über einen anderen Kanal deklariert haben (alte Webseite, E-Mail, Telefon, …), wird er nicht in der Liste aufgeführt. Bitte gehen Sie zurück und klicken Sie auf die Option "Bereits über anderen Kanal deklarierter Leistungsfall".',
    help:
      'Ich habe einen Leistungsfall deklariert, dieser erscheint aber nicht in der Liste unten.',

    medicalFilesAreBeingProcessedTitle:
      'Die Krankenakte von {{name}} wird uns übermittelt',
    medicalFilesAreBeingProcessedDescription:
      'Wir überprüfen ihr Dokument. Es wird in Kürze hier angezeigt.',
    filesAreBeingProcessedTitle:
      'Die Behandlungsrechnung von {{name}} wird uns übermittelt',
    filesAreBeingProcessedDescription:
      'Wir überprüfen ihr Dokument. Es wird in Kürze hier angezeigt.',
    sinisters: 'Leistungsfälle von {{name}}',
    medicalFiles: '{{name}}s Krankenakte',
    noDocuments: 'Es wurde noch keine Krankenakte für {{name}} hochgeladen.',
    noDisasters: 'Es wurden noch keine Leistungsfälle von {{name}} deklariert.',
    declareSinister: 'Leistungsfall deklarieren',
    addMedicalFiles: 'Dokumente zur Krankenakte hinzufügen',
    medicalFile: 'Krankenakte',
    seeMedicalFiles: 'Krankenakte ansehen',
    xImages: '{{count}} Bild',
    xImages_plural: '{{count}} Bilder',
    vaccines: 'Impfungen und Präventionskosten',
  },
  claimDocument: {
    description: '',
    amount: 'Gesamtbetrag der Rechnung',
    billing_years: 'Behandlungsjahr',
    estimated_processing_time: 'Voraussichtliches Bearbeitungsdatum',
  },
  asideSummary: {
    amount: 'Gesamtbetrag der Rechnung (inkl. Mwst.)',
    involves_multiple_pets: 'Betrifft andere Tiere',
    involves_multiple_claims: 'Betrifft andere Leistungsfälle',
    involves_a_vaccine: 'Betrifft Impfungen und Präventionskosten',
    billing_years: 'Behandlungsjahr',
    type: 'Leistungstyp',
    label: 'Bezeichnung',
    label_medicalFile: 'Bezeichnung',
    third_party_status: 'Involvierte Drittperson',
    comment: 'Bemerkungen',
    symptoms: 'Symptome',
    treatment: 'Verschriebene Behandlung',
    diagnosis: 'Diagnose Ihrer Tierärztin / Ihres Tierarztes',
    occurred_at: 'Datum und Zeit',
    interval: 'Geschätzte Abweichung',
    claim_year: 'Jahr',
    animal: 'Tier',
    sequence: 'Unfallhergang und -verlauf',
    third_party: 'Daten der involvierte Drittperson',
  },
  vaccines: {
    title: 'Impfungen und Präventionskosten',
  },
  deleteModal: {
    title: 'Dokument löschen',
    description:
      'Wenn Sie dieses Dokument löschen, finden Sie es nachher nicht mehr. Sind Sie sicher, dass Sie es löschen möchten?',
    actionYes: 'Ja, ich möchte das Dokument löschen',
    actionNo: 'Nein, ich möchte das Dokument behalten',
  },
address: {
    my_details_change: 'Adressänderung Versicherung',
    change_address_for: 'Diese Adresse auch ändern für',
    change_for_all: 'Alle meine Tiere und ihre Services',
    change_for_pet: 'Die Gourmet-Abo-Lieferungen für'
  },
  owner: {
    owner_change: 'Halterwechsel von',
    owner_needed_details: 'Bitte füllen Sie die Angaben der neuen Halterin oder des neuen Halters vollständig aus. Damit {{ name }} gut versichert bleibt, werden wir ihm/ihr in den nächsten Tagen eine Versicherungsofferte senden.',
    contact_new_owner: 'Per welchem Datum findet der Halterwechsel statt?',
    transfer_pet: 'Der Halterwechsel gilt auch für',
    transferred_at: 'Datum Halterwechsel',
    keep_insurance: 'Möchte der neue Besitzer die Animalia Gesundheitsversicherung behalten ?'
  },
  bank_details: {
    my_bank_details_change: 'Änderung der Zahlungsverbindung',
    my_bank_details_description: 'Bitte geben Sie hier die IBAN-Nummer Ihres Kontos an, auf welches Sie die Rückerstattungen erhalten möchten.'
  }
};
