import { axios } from "@animalia/instances"

export const cleanEmptyValuesBeforeSubmit = form => {
  Object
    .entries(form)
    .forEach(([key, value]) => {
      if (
        !value 
        && key !== 'pet_age_category'
        && key !== 'holder_address_id'
        && key !== 'pet_animal_id'
        && key !== 'pet_weight'
        && key !== 'pet_animal_id_2'
        && key !== 'pet_other_race'
        && key !== 'pet_other_race_2'
        && key !== 'promo_code'
        && key !== 'agent_code'
        && key !== 'holder_iban'
        && key !== 'holder_bank_id'
        && key !== 'holder_bank_name'
        && key !== 'holder_bank_zip_code'
        && key !== 'holder_bank_city'
      ) {
        delete form[key]
      }
    })
}

export const getErrors = async (form, validator, fields) => {
  if (!validator || !fields?.length) {
    return null
  }

  const errors = await Promise.all(
    fields.map(async field => {
      try {
        await validator.validateAt(field, form)
      } catch (error) {
        return error
      }
    })
  )

  return errors.filter(item => !!item)
}

export const ibanValidation = value => {
  return new Promise(resolve => {
    (async () => {
      const query = value?.replace(/ /g,'')
      if (!query || query == 'CH') {
        resolve(true)
      }
      try {
        await axios.get(`/banks/iban?iban=${query}`)
        resolve(true)
      } catch (error) {
        if (error.response.status == 404) {
          resolve(true)
        } else {
          resolve(false)
        }
      }
    })()
  })
}