import React, { createContext, useReducer, useMemo } from 'react'
import { reducer, initialState } from './gtmEventsReducer.js'

const GtmEventsContext = createContext()

const GtmEventsContextProvider = ({ children }) => {
  const [gtmEvents, dispatch] = useReducer(reducer, initialState)

  const contextValue = useMemo(
    () => ({ gtmEvents, dispatch }),
    [gtmEvents, dispatch],
  )

  return (
    <GtmEventsContext.Provider value={contextValue}>
      { children }
    </GtmEventsContext.Provider>
  )
}

export {
  GtmEventsContext, 
  GtmEventsContextProvider
}
