import React from 'react';

export default () => (
  <svg
    width="79"
    height="69"
    viewBox="0 0 79 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.1149 48.6554C30.0639 48.6554 31.6438 46.2591 31.6438 43.3032C31.6438 40.3473 30.0639 37.951 28.1149 37.951C26.1659 37.951 24.586 40.3473 24.586 43.3032C24.586 46.2591 26.1659 48.6554 28.1149 48.6554Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M50.5822 48.7731C52.5312 48.7731 54.1111 46.3768 54.1111 43.4209C54.1111 40.465 52.5312 38.0687 50.5822 38.0687C48.6333 38.0687 47.0533 40.465 47.0533 43.4209C47.0533 46.3768 48.6333 48.7731 50.5822 48.7731Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M52 43C52.5523 43 53 42.3284 53 41.5C53 40.6716 52.5523 40 52 40C51.4477 40 51 40.6716 51 41.5C51 42.3284 51.4477 43 52 43Z"
      fill="white"
    />
    <path
      d="M29 43C29.5523 43 30 42.3284 30 41.5C30 40.6716 29.5523 40 29 40C28.4477 40 28 40.6716 28 41.5C28 42.3284 28.4477 43 29 43Z"
      fill="white"
    />
    <path
      d="M39.525 62.7122C39.1298 62.7123 38.7453 62.5838 38.4296 62.3462C38.1138 62.1085 37.8839 61.7746 37.7747 61.3948C35.5562 62.6434 33.1216 63.4612 30.5992 63.805C31.7029 64.2802 32.7783 64.8186 33.82 65.4177C35.4127 66.3376 35.8808 66.7999 37.1136 67.1822C38.0634 67.4814 39.0607 67.6011 40.0543 67.5351C41.2192 67.4408 42.3464 67.0785 43.348 66.4764C44.1632 65.9824 44.209 65.7353 44.9948 65.1825C45.6676 64.712 46.7181 64.1238 48.2802 63.798C45.8176 63.4278 43.4447 62.6055 41.2812 61.3724C41.1754 61.7572 40.9463 62.0966 40.6291 62.3386C40.3119 62.5806 39.924 62.7119 39.525 62.7122V62.7122Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M63.5297 25.807C65.798 17.7136 65.9168 9.16858 63.8744 1.01526C56.2827 2.67057 49.3395 6.50662 43.8973 12.0525C47.145 12.4348 51.308 13.5017 55.3462 16.2483C59.3997 19.0079 61.8852 22.4827 63.5297 25.807Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M23.8014 16.219C27.849 13.4629 32.0296 12.3983 35.2809 12.0184C29.8408 6.48274 22.9045 2.65364 15.3214 1C13.2846 9.12882 13.3969 17.6476 15.6472 25.7199C17.2905 22.4181 19.7702 18.9633 23.8014 16.219Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M66.227 33.0107C65.6189 31.011 64.8425 28.4596 63.5297 25.807C63.5265 25.8163 63.5237 25.8257 63.5215 25.8352C63.5237 25.8257 63.5265 25.8163 63.5297 25.807C61.8829 22.4875 59.3998 19.008 55.3462 16.2484C51.3115 13.5017 47.1451 12.4348 43.8973 12.0525L43.8773 12.0725L43.8973 12.0525C42.5983 11.8991 41.2903 11.8362 39.9826 11.8643C38.4126 11.7846 36.8388 11.8362 35.2774 12.0184L35.315 12.0572L35.2774 12.0184C32.0261 12.3984 27.8455 13.4629 23.7979 16.219C19.7502 18.9751 17.287 22.4181 15.6438 25.72C15.652 25.7529 15.6614 25.787 15.6708 25.8199C15.6614 25.787 15.652 25.7529 15.6438 25.72C14.3134 28.3937 13.5264 30.9663 12.9171 32.9813C11.5232 37.5688 7.29085 51.4821 15.1521 59.2127C19.9596 63.9391 26.8904 63.9944 28.2678 63.9768C29.0481 63.966 29.827 63.9087 30.6004 63.805L30.5263 63.7709L30.6039 63.805C33.1263 63.4612 35.5609 62.6434 37.7794 61.3948L41.2859 61.3724C43.4494 62.6055 45.8223 63.4278 48.2849 63.798L48.4061 63.7709L48.2802 63.798C49.14 63.9252 50.0072 63.9947 50.8763 64.0062C52.2549 64.0238 59.1857 63.9685 63.992 59.2422C71.8532 51.5115 67.6209 37.5935 66.227 33.0107Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M39.525 62.7122C39.924 62.7118 40.3119 62.5805 40.6291 62.3385C40.9463 62.0965 41.1754 61.7571 41.2812 61.3724C40.6867 61.0353 40.11 60.6678 39.5532 60.2714C38.9805 60.6768 38.3868 61.0518 37.7747 61.3947C37.8839 61.7745 38.1138 62.1085 38.4295 62.3461C38.7453 62.5838 39.1298 62.7123 39.525 62.7122Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M43.8667 53.8448C43.6314 52.9543 42.7551 52.4897 42.5139 52.3744C42.2928 52.2673 42.0281 52.1732 41.1612 52.0803C39.4509 51.8968 38.7686 52.0803 37.29 52.1156C36.8409 52.117 36.3967 52.2094 35.9843 52.3873L35.9373 52.4097C35.702 52.5273 34.8198 52.9896 34.5845 53.88C34.3834 54.6446 34.761 55.3151 35.1138 55.9386C35.4387 56.4928 35.8691 56.978 36.3807 57.3666C36.5267 57.2481 36.6963 57.1622 36.8783 57.1149C36.9653 57.0925 37.4664 56.9643 37.8193 57.2325C38.1416 57.476 38.2722 57.9936 38.1216 58.5464C38.523 58.7368 38.9633 58.8306 39.4073 58.8205C39.8154 58.8076 40.2162 58.709 40.5836 58.5311C40.3484 57.9159 40.4754 57.3148 40.8307 57.0455C41.1859 56.7761 41.6858 56.9055 41.7717 56.9278C41.9872 56.984 42.185 57.0937 42.3469 57.2466C42.742 56.8503 43.0761 56.3976 43.3385 55.9033C43.7514 55.121 44.0537 54.5329 43.8667 53.8448Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M37.5956 57.0992C37.2042 56.7372 36.6484 56.9102 36.5519 56.9404C36.3501 57.0043 36.1619 57.1202 36 57.2802C36.077 57.3564 36.1539 57.4278 36.2257 57.4961C36.7998 58.0359 37.3412 58.544 37.9309 58.8727C38.0979 58.1264 37.9531 57.4278 37.5956 57.0992Z"
      fill="currentColor"
    />
    <path
      d="M40.5811 57.0757C40.0186 57.4015 39.8179 58.1285 40.1873 58.8727C40.8546 58.6145 41.4833 58.3025 42.0624 57.9422C42.3931 57.7507 42.7063 57.5425 43 57.319C42.7419 57.134 42.4266 57.0014 42.0831 56.9334C41.9443 56.9063 41.1474 56.7513 40.5811 57.0757Z"
      fill="currentColor"
    />
    <line
      x1="47.9339"
      y1="52.5044"
      x2="77.9339"
      y2="48.5044"
      stroke="currentColor"
    />
    <line x1="48" y1="55.5" x2="78.2655" y2="55.5" stroke="currentColor" />
    <line
      y1="-0.5"
      x2="30.2655"
      y2="-0.5"
      transform="matrix(-0.991228 -0.132164 -0.132164 0.991228 30.2655 53)"
      stroke="currentColor"
    />
    <line
      y1="-0.5"
      x2="30.2655"
      y2="-0.5"
      transform="matrix(-1 0 0 1 30.2655 56)"
      stroke="currentColor"
    />
  </svg>
);
