module.exports = {
  pet: {
    add_pet: 'Tier hinzufügen',
    add_pet_caption: 'Stellen Sie uns Ihren vierbeinigen Freund vor.',
    species: 'Tier',
    dog: 'Hund',
    cat: 'Katze',
    gender: 'Geschlecht',
    male: 'Männchen',
    female: 'Weibchen',
    select_pet: 'Welches Tier möchten Sie versichern?',
    select_pet_description:
      'Wählen Sie es aus oder erstellen Sie ein neues Profil',
    portrait: 'Daten Ihres Vierbeiners',
    portrait_caption: '',
    name: 'Rufname des Tieres', //C'est affiché où? Si dans le résumé de droite --> Rufname des Tieres, Si comme titre du champs du nom --> Sein Rufname
    name_long: 'Rufname des Tieres', //C'est affiché où? Si dans le résumé de droite --> Rufname des Tieres, Si comme titre du champs du nom --> Sein Rufname
    birthdate: 'Sein Geburtsdatum',
    birthdate_tip: 'Wir versichern Tiere ab 3 Monaten.',
    is_pure_breed: '',
    race: 'Rasse',
    pet_mixed: 'Mischling',
    pet_breed: 'Reinrassig',
    pet_not_breed: 'Mischling',
    pet_unknown: 'Unbekannt',
    fill_two_races: '',
    fill_race: 'Rasse eingeben',
    are_two_races_known:
      'Connaissez-vous les deux races principales de votre compagnon?',
    first_race: 'Erste Rasse',
    second_race: 'Zweite Rasse',
    which_pet_weight: 'Ungefähres Gewicht in Kilogramm',
    weight: 'Gewicht im Erwachsenenalter in kg',
    pet_age_calcul_info:
      'Das Alter des Tieres wird wie folgt berechnet: Aktuelles Jahr minus Geburtsjahr (z.B. 2023 - 2017 = 6)',
  },
  insurance: {
    protection: 'Versicherung',
    protection_type: 'Deckung',
    select_protection: '',
    classic_condensed: 'Classic',
    comfort_condensed: 'Comfort',
    compact_condensed: 'Compact',
    compact: 'Animalia Compact',
    compact_description: 'Deckung bei Unfall',
    classic: 'Animalia Classic',
    classic_description:
      'Deckung bei Unfall und bei akuten und chronischen Krankheiten',
    comfort: 'Animalia Comfort',
    comfort_description:
      'Deckung bei Unfall, akuten, chronischen und angeborenen Krankheiten sowie bei Erbkrankheiten',
    franchise: 'Selbstbehalt',
    insurance: 'Versicherung',
    payment: 'Zahlung',
    select_payment: '',
    annual: 'Jährlich',
    annual_cost: 'Jahresprämie total',
    biannual: 'Halbjährlich',
    month: 'Monat',
    monthly: 'Halbjährlich',
    monthly_cost: 'Indikative Monatsprämie',
    monthly_cost_info: 'Indikative Monatsprämie',
    monthly_cost_tip: '',
    quarterly: 'Vierteljährlich',
    enters_in_force_at: 'Vertragsbeginn',
    agent_code: 'Agentencode',
    agency: 'Promocode',
    agency_tip:
      'Falls Sie Anrecht auf eine Ermässigung haben, wird diese direkt von der Rechnung in Abzug gebracht.',
    my_details: 'Ihre Kontaktangaben',
    needed_details: '',
    new_address: 'Eine Adresse hinzufügen',
    plan_not_available:
      'Diese Deckung kann mit diesem Selbstbehalt nicht abgeschlossen werden',
    are_you_pet_keeper:
      "Êtes-vous le détenteur de l'animal ou prenez-vous l'assurance pour une personne tierce ?",
    im_keeper: "Je suis le détenteur de l'animal",
    im_not_keeper: "Je prends une assurance pour l'animal de quelqu'un d'autre",
    refund_account: 'Konto für die Rückerstattungen',
    no_iban: 'IBAN',
    bank_name: 'Name der Bank',
    bic: '',
    line: 'Adresse',
    line_2: 'Adresszusatz',
    zip_code: 'PLZ',
    city: 'Ort',
    conclude: 'Versicherung abschliessen',
    insurance_of: 'Die Versicherung von',
    insurance_owner: 'Versicherungsnehmer',
    complete_profile: 'Profil vervollständigen',
    offer_page_description: 'offer_page_description Lorem Ipsum....',
    concluded_description: 'concluded_description Lorem Ipsum....',
    offer_already_concluded:
      'Diese Offerte wurde bereits abgeschlossen. Sie können den Abschluss nicht mehr tätigen.',
    offer_concluded_line:
      'Wir freuen uns, {{pet_name}} in unserer Animalia-Familie zu begrüssen und stehen Ihnen von nun an zur Seite.',
    offer_concluded_line_2:
      'Sie werden die Policendokumente und die Rechnung in den nächsten Tagen per Post erhalten. Danach können Sie uns ganz einfach über Ihr Animalia-Kundenkonto Leistungsfälle deklarieren und Rechnungen einsenden.',
    offer_concluded_line_3:
      'Ein kleines Dankeschön für Ihr Vertrauen: Rabattgutscheine und Partnerangebote für Sie und Ihren Gefährten auf vier Pfoten.',
    offer_banner_cta: 'Rejoignez la communauté Animalia',
    offer_fressnapf: 'GUTSCHEIN FRESSNAPF',
    offer_shop: 'GUTSCHEIN SHOP ANIMALIA',
    assistance: 'Assistance 24/7',
    assistance_btn: 'Entdecken Sie die Assistance 24/7',
    assistance_link: 'https://shop.animalia.ch/de/product/assistance-24-7/',
    assistance_desc_sale:
      'Profitieren Sie von unserem Winter-Aktion Angebot und schliessen Sie zusätzlich eine  Assistance 24/7 für nur CHF 1.- * ab!​ Geben Sie dazu folgenden Rabattcode ein: ​',
    offer_fressnapf_url:
      'https://prismic-io.s3.amazonaws.com/animalia/cba94bc9-65a9-4945-962a-4b3d7afc034e_22-10-019_animalia_gutschein_130x85_de_lay_v3_020222_5.pdf',
    offer_shop_url:
      'https://prismic-io.s3.amazonaws.com/animalia/05a8375a-b565-4942-a5c6-e1efccc393f3_21_ANIMALIA_SHOP_FLYER_A7_DE.pdf',
    assistance_url: 'https://www.animalia.ch/de/service/assistance',
    assistance_desc:
      'Für nur CHF 79 mehr pro Jahr erhalten Sie eine durchgehende Unterstützung bei dringenden medizinischen oder administrativen Fragen zu Ihrem Tier. Darüber hinaus profitieren Sie von zusätzlichen Versicherungsleistungen wie z.B. Transportkosten für Ihr Tier in Notfällen. Lassen Sie sich diese Gelegenheit nicht entgehen und erfahren Sie alles über die Assistance 24/7.',
    cga:
      'Ich bestätige hiermit, dass ich von den <a href="https://prismic-io.s3.amazonaws.com/animalia/3a327fa3-f270-44dc-8eab-88252b5c10c7_CGA_Animalia_2021_D_WEB.pdf" target="_blank">Allgemeinen Versicherungsbedingungen (AVB) </a> Kenntnis genommen habe, ihnen zustimme und verstanden habe, dass die Prämien mit fortlaufendem Alter meines Tieres regelmässig erhöht werden.',
    tonivet_label: 'Entdecken Sie das exklusive Angebot von Tonivet Kroketten',
    tonivet_link:
      'https://shop.animalia.ch/de/croquettes-tonivet-lexcellence-veterinaire/',
    cga_1:
      'Ich erkläre hiermit, die vorhergehenden Fragen wahrheitsgetreu beantwortet zu haben, damit der Animalia-Vertrag mit der Vaudoise Rechtsgültigkeit erlangt.',
    cga_2:
      'Ich ermächtige die Vaudoise, von den Angaben Gebrauch zu machen, die ihr im Rahmen der Vertragsverwaltung und insbesondere bei der Bearbeitung von Schadenfällen zukommen. Falls nötig, kann sie diese Angaben an Rückversicherer oder an andere Versicherer weitergeben und von diesen oder Dritten Auskünfte einholen.',
    breed_tip: 'Beide Elternteile haben die selbe Rasse',
    bastard_tip: 'Die Eltern haben unterschiedliche Rassen',
    unknown_tip:
      'Die Eltern haben insgesamt drei oder mehr verschiedene Rassen oder Sie kennen die Rassen nicht',
    federal_timber_of: 'Eidg. Stempelsteuer 5% von',
    surprime: 'Kosten Teilzahlung',
    annual_cost_basic: 'Jahresprämie',
    not_complete: 'Eine Versicherungsofferte ist in Bearbeitung',
    waiting:
      'Ihr Versicherungsantrag wurde erfolgreich übermittelt. Hier wird in den nächsten Tagen Ihre Policennummer angezeigt.',
    fill_offer: 'Offerte anschauen und abschliessen',
    login_title: 'Haben Sie bereits ein Animalia Kundenkonto?',
    login_description:
      'Um den Abschluss Ihrer Versicherung fertigzustellen, eröffnen Sie im nächsten Schritt bitte ein Animalia Kundenkonto oder loggen Sie sich in Ihr bestehendes Konto ein. Sie sehen darin zukünftig Informationen Ihrer abgeschlossenen Versicherungsverträge und können uns die Behandlungsrechnungen Ihres Vierbeiners einfach und rasch einreichen.',
    login_yes: 'Ja, ich habe schon ein Kundenkonto',
    login_no: 'Nein, ich habe noch kein Kundenkonto',
    save_success:
      'Der Abschluss wurde erfolgreich übermittelt. Der:die Versicherungsnehmer:in erhält die Police in den nächsten Tagen.',
    num: 'Nr.',
    api_found: 'Adresse von der Schweizer Post validiert',
    api_not_found:
      'Diese Adresse existiert so nicht bei der Schweizer Post. Bitte überprüfen Sie Ihre Eingabe nochmals.',
    start_date: 'Vertragsbeginn',
    end_date:
      'Vertragsablauf (stillschweigende Verlängerung um jeweils ein Jahr)',
    cga_min: 'AVB',
    maintenance_title: 'Wartungsarbeiten',
    maintenance_description:
      'Wir informieren Sie, dass der Onlineabschluss zur Zeit (und bis ungefähr Mitternacht) nicht möglich ist. Dies wegen Wartungsarbeiten eines Services der Post, welcher Ihre Postadresse prüft. <br><br> Sie können aber gerne in der Zwischenzeit eine Offerte bestellen.',
    maintenance_button: 'Offerte bestellen',
    discount_hotelcard_link: 'https://hotelcard.com/de/animalia-30',
    discount_hotelcard_label: 'Exklusives Angebot von Hotelcard',
  },
  error: {
    general: 'Bitte das Feld ausfüllen',
    min_18: 'Sie müssen mindestens 18 Jahre alt sein',
    pet_species_required: 'Bitte wählen Sie eine Tierart aus',
    pet_gender_required: 'Bitte wählen Sie das Geschlecht Ihres Tieres aus',
    pet_name_required: 'Bitte geben Sie den Rufnamen Ihres Vierbeiners an',
    pet_birthdate_required:
      'Bitte geben Sie das Geburtsdatum Ihres Vierbeiners an',
    pet_is_purebred_required:
      'Bitte geben sie an, ob Ihr Tier reinrassig oder Mischling ist oder eine unbekannte Rasse hat.',
    pet_two_races_known_required:
      'Bitte geben Sie die beiden Rassen Ihres Vierbeiners an',
    pet_animal_id_required: 'Bitte wählen Sie eine Rasse aus',
    pet_weight_required:
      'Bitte geben Sie das ungefähre Gewicht Ihres Vierbeiners in kg an',
    pet_weight_number_required:
      'Bitte geben Sie das Gewicht Ihres Vierbeiners in Zahlen an',
    insurance_franchise_required:
      'Bitte wählen Sie den gewünschten Selbstbehalt aus',
    insurance_type_required: 'Bitte wählen Sie die gewünschte Deckung aus',
    insurance_payment_frequency_required:
      'Bitte wählen Sie die gewünschte Zahlungsart aus',
    insurance_enters_in_force_at_required:
      'Bitte geben Sie das gewünschte Beginndatum ein',
    title_required: 'Bitte wählen Sie eine Anrede',
    first_name_required: 'Bitte geben Sie Ihren Vornamen ein',
    last_name_required: 'Bitte geben Sie Ihren Nachnamen ein',
    line_required: 'Bitte geben Sie Ihre Adresse ein',
    zip_code_required: 'Bitte geben Sie die Postleitzahl ein',
    city_required: 'Bitte geben Sie den Ort ein',
    birthdate_required: 'Bitte geben Sie Ihr Geburtsdatum ein',
    holder_phone_required: 'Bitte geben Sie Ihre Telefonnummer ein',
    bank_not_found:
      'Wir konnten die Bank nicht erkennen. Bitte erfassen Sie die Daten der Bank manuell.',
    iban_required:
      'Bitte geben Sie die IBAN-Nummer ein (z.B. CH80 0000 0000 0000 0000 0)',
    iban_invalid:
      'Ihre IBAN-Nummer ist nicht gültig. Bitte korrigieren Sie die Eingabe (z.B. CH80 0000 0000 0000 0000 0)',
    bank_name_required: 'Bitte geben Sie den Namen der Bank an',
    bic_required: '',
    bank_address_required: '',
    bank_zip_code_required: 'Bitte geben Sie die Postleitzahl der Bank ein',
    bank_city_required: 'Bitte geben Sie den Ort der Bank ein',
    max_char_4: 'Der Agentencode besteht aus 4 Zahlen',
    email_required: '',
    email_invalid: 'Dieses feld muss eine gültige e-mail sein.',
    more_char: 'Dieses Feld muss mindestens 2 Buchstaben enthalten.',
    firstname_error: 'Der Vorname ist ungültig',
    lastname_error: 'Der Name ist ungültig',
    uppercase: 'Der erste Buchstabe muss gross geschrieben werden',
    all_uppercase: 'Dieses Feld kann nicht nur Grossbuchstaben enthalten',
    wrong_iban:
      'Diese IBAN-Nummer ist nicht gültig. Bitte korrigieren Sie Ihre Eingabe.',
    only_one_uppercase: 'Dieses Feld darf nur einen Grossbuchstaben enthalten',
    no_special_case: 'Dieses Feld darf keine Spezialzeichen enthalten',
  },
  navigation: {
    calculate: 'Prämie berechnen',
    submit_offer: 'Versicherung abschliessen',
    send_offer_by_email: 'Offerte per Mail erhalten ',
    next: 'Weiter',
  },
};
