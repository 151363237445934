import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './WidgetTightOfferRadio.module.sass'
import WidgetTightRadioButton from '../radio/WidgetTightRadioButton'
import currency from 'currency.js'

const generatePriceString = (intPrice, fromCents) => currency(intPrice, { 
  symbol: 'CHF ',
  separator: '\'',
  decimal: '.',
  fromCents: fromCents
}).format()

const WidgetTightOfferRadio = (option, { checked }) => {
  const { t } = useTranslation()
  const price =  option.price_per_month
  
  const labels = {
    month: t('offer.insurance.month')
  }

  const priceString = generatePriceString(price)

  const bigPriceString = () => 
    !option.disabled 
      ? `${priceString} / ${labels.month}` 
      : t(option.not_available)

  return (
    <div className={styles.radioContent}>
      <WidgetTightRadioButton
        readOnly
        left
        name={option.name}
        label={option.label}
        checked={checked}
        price={bigPriceString()}
        info={!option.disabled && option.info}
      />
    </div>
  )
}

export default WidgetTightOfferRadio
