module.exports = {
  deliveryAddress: 'Adresse de livraison',
  billingAddress: 'Adresse de facturation',
  defineNewAddress: 'Définir une nouvelle adresse',
  addNewCard: 'Ajouter une carte de crédit ou PostFinance',
  addNewTwint: 'Ajouter un compte Twint',
  addNewPowerPay: 'Ajouter un compte PowerPay',
  paymentMethod: 'Moyen de paiement',
  firstname: 'Prénom *',
  lastname: 'Nom *',
  line1: 'Adresse *',
  line2: "Complément d'adresse",
  zipcode: 'NPA *',
  city: 'Localité *',
  state: 'État',
  country: 'Pays',
  thisIsThesePetsHouse: "C'est la maison de",
};
