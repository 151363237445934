module.exports = {
  myAccount: 'Mein Konto',
  editMyProfile: 'Mein Profil ändern',
  title: 'Anrede *',
  firstName: 'Vorname *',
  firstAndLastName: 'Anrede & Vorname *',
  lastName: 'Name *',
  email: 'E-Mail *',
  phone: 'Telefonnummer',
  home_phone: 'Festnetz',
  mobile_phone: 'Mobiltelefon',
  birthdate: 'Geburtsdatum',
  subscribeToMailchimp:
    "Ich möchte den Newsletter von Animalia erhalten (Tipps, Gewinnspiele, Angebote, News usw.)",
  subscribeToMailchimpInfo:
    "Mit dem Abonnieren des Newsletters akzeptieren Sie unsere Datenschutzrichtlinien und die für den Versand des Newsletters notwendige Weitergabe von Daten an Dritte.",
  mrs: 'Frau',
  mr: 'Herr',
  locale: 'Sprache *',
  myPets: 'Mein Rudel',
  seeMyPets: 'Mein Rudel ansehen',
  myInsurance: 'Meine Versicherung',
  declareSinister: 'Einen Leistungsfall melden',
  sendInvoice: 'Eine Behandlungsrechnung einreichen',
  editInsurance: 'Meine Versicherung ändern',
  myAboGourmand: 'Meine Gourmet-Abo',
  editMyAboGourmand: 'Mein Gourmet-Abo ändern',
  editMyData: 'Meine Daten verwalten',
  myInvoices: 'Meine Rechnungen Gourmet-Abo ',
  myInvoicesText: 'Ihre letzten Transaktionen auf einen Blick',
  myCards: 'Meine Zahlungsmittel Gourmet-Abo',
  myCardsText: 'Ihr elektronisches Portemonnaie',
  myAddresses: 'Meine Lieferadresse Gourmet-Abo',
  myAddressesText: 'Name, Vorname und Lieferadresse',
  modalEditMyAddressTitle: 'Lieferandresse ändern',
  logout: 'Logout',
  delete: {
    button: 'Mein Animalia-Konto löschen',
    title: 'Animalia-Konto löschen',
    content:
      'Wenn Sie Ihr Animalia-Konto löschen, werden alle damit verbundenen Daten (Ihre Daten und die Daten Ihrer Tiere) gelöscht. Wir können diese danach nicht wiederherstellen und Sie müssen ein neues Konto erstellen. Sind Sie sicher, dass Sie das möchten?',
    confirm: 'Ja, ich lösche meine Daten',
    cancel: 'Nein, ich behalte mein Konto',
  },
};
