module.exports = {
  privacyPolicy: 'https://www.animalia.ch/it/privacy-policy',
  cgv: 'https://www.animalia.ch/it/condizioni-generali-di-vendita',
  cgu: 'https://www.animalia.ch/it/condizioni-generali-di-utilizzo',
  insurance: {
    changeOwner:
      'https://assurance.animalia.ch/_files/content/pdf/cambiamento_del_detentore_dellanimale_it.pdf',
    changeBank:
      'https://assurance.animalia.ch/it/contatto/trasmissione-degli-estremi-bancari-o-postali',
    sinister: 'https://assurance.animalia.ch/it/contatto/notifica-di-sinistro',
    changeAddress:
      'https://assurance.animalia.ch/it/contatto/cambiamento-d-indirizzo-oppure-di-cognome',
  },
  contact: 'https://www.animalia.ch/it/contact'
};
