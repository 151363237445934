module.exports = {
  myAccount: 'Mon compte',
  editMyProfile: 'Modifier mon profil',
  title: 'Titre *',
  firstName: 'Prénom *',
  lastName: 'Nom *',
  firstAndLastName: 'Prénom & Nom *',
  email: 'E-mail *',
  phone: 'Téléphone',
  home_phone: 'Téléphone fixe',
  mobile_phone: 'Téléphone mobile',
  birthdate: 'Date de naissance',
  subscribeToMailchimp:
    "Je souhaite recevoir les newsletters d'Animalia (conseils, concours, offres, nouveautés, etc.)",
  subscribeToMailchimpInfo:
    "En vous abonnant à la newsletter d'Animalia, vous acceptez la politique de confidentialité et le transfert de données à un système tiers nécessaire à l'envoi de la newsletter.",
  mrs: 'Madame',
  mr: 'Monsieur',
  locale: 'Langue de communication *',
  myPets: 'Mes animaux',
  seeMyPets: 'Voir mes animaux',
  myInsurance: 'Mon assurance',
  declareSinister: 'Déclarer un sinistre',
  sendInvoice: 'Envoyer une facture',
  editInsurance: 'Modifier l\'assurance',
  myAboGourmand: 'Mon Abo Gourmand',
  editMyAboGourmand: 'Gérer mon Abo Gourmand',
  editMyData: 'Gérer mes données',
  myInvoices: 'Mes factures Abo Gourmand',
  myInvoicesText: 'Jetez un œil à vos dernières transactions',
  myCards: 'Mes moyens de paiement Abo Gourmand',
  myCardsText: 'Votre portemonnaie électronique',
  myAddresses: 'Mon adresse de livraison Abo Gourmand',
  myAddressesText: 'La maison de votre boule de poils ',
  modalEditMyAddressTitle: "Modifier l'adresse de livraison",
  logout: 'Déconnexion',
  delete: {
    button: 'Supprimer mon compte Animalia',
    title: 'Suppression du compte',
    content:
      'Vous êtes en train de supprimer toutes vos données de votre compte Animalia (vos données et les données de vos animaux). Nous ne pourrons pas réactiver votre compte et vous devrez en créer un nouveau.  Êtes-vous sûr de vouloir faire ça ?',
    confirm: 'Oui, je supprime mon compte',
    cancel: 'Non, je garde mon compte',
  },
};
