module.exports = {
  nextDeliveryOn: 'Nächste Lieferung am {{date}}',
  date: 'Lieferdatum',
  dateRecurring: 'Möchten Sie auch die zukünftigen Lieferungen anpassen?',
  dateSelector: 'Wählen Sie bitte das neue Lieferdatum',
  address: 'Lieferadresse',
  editDeliveryDateFor: 'Lieferdatum für {{name}} ändern',
  adjustAllDates: 'Ja, für alle zukünftigen Lieferungen anpassen.',
  adjustOneDate: 'Nein, ich möchte nur diese eine Lieferung anpassen.',

  status: {
    pending: 'Ausstehend',
    planned: 'In Vorbereitung',
    failed: 'Fehler',
    cancelled: 'Abgebrochen',
    refunded: 'Zurückerstattet',
    standby: 'Zahlung ausstehend',
    'payment-on-hold': 'Zahlung ausstehend',
    'completed-paid': 'Versandt',
    'completed-unpaid': 'Versandt',
    'completed-refunded': 'Versandt',
  },
  editLater: 'Geben Sie hier bitte Ihre Lieferadresse an. Und falls Sie mal innerhalb der Schweiz verreisen oder Ihren vierbeinigen Freund bei Nachbarn oder Freunden lassen, ist das kein Problem: Sie können die Lieferadresse jederzeit in Ihrem Kundenkonto anpassen, auch für nur eine einzige Lieferung. Praktisch, oder?',
  paymentMessage: 'Nur noch ein Klick und Ihr Fellknäuel kann sich über seine neuen, leckeren Kroketten freuen. Keine Sorge! Sie können das Abo jederzeit wieder in Ihrem Kundenkonto oder bei unserem Kundenservice kündigen.<br><br>Kontaktieren Sie uns bei Fragen zu den Zahlungsmitteln oder falls Sie Hilfe bei der Zahlung benötigen.'
};