import { insuranceValues } from "@animalia/assets"
import { findAndSetAgeRange } from ".."
import { matchKey } from "./utils"

export const convertPriceToCents = price => {
  const price100 = price * 100
  const price100round = Math.round(price100)

  return price100round
}

// Calculator view 
export const setCodeAgentAndPetId = (dispatch, params) => {
  dispatch({
    type: 'SET_CODE_AGENT_AND_PET_ID',
    codeAgent: params.get('codeAgent'),
    petId: params.get('petId'),
    promo: params.get('promo')
  }) 
}

// Stepper 
// on each form update 
export const updateForm = (dispatch, data) => {
  let newData = data

    // if (data.insurance_type) {
    //   const selectedInsuranceType = 
    //     insuranceValues
    //       .filter(matchKey(data, 'pet_species'))
    //       .filter(matchKey(data, 'pet_age_category'))
    //       .find(matchKey(data, 'insurance_franchise', true))

    //   const price = convertPriceToCents(selectedInsuranceType[data.insurance_type])

    //   newData.insurance_month_cost = price
    // }

  dispatch({
    type: 'SET_FORM',
    data: newData
  })
}

export const setActiveStep = (dispatch, data) => {
  dispatch({
    type: 'SET_ACTIVE_STEP',
    data: data
  })
}

// Navigation 
export const setInsuranceValues = (dispatch, data) => {

  dispatch({
    type: 'SET_INSURANCE_VALUES',
    data: data
  })
}

// form 
export const selectClassicPrice = (dispatch, data) => {
  dispatch({
    type: 'SELECT_HEALTH',
    data: data
  })
}

export const setEmailOffer = (dispatch, value) => {
  dispatch({ 
    type: 'SET_EMAIL_OFFER_STATE',
    data: value
  })
}

export const prefillCalculatorForm = (dispatch, pet) => {
  const data = {
    pet_species: pet.species,
    pet_age_category: findAndSetAgeRange(null, pet.birthdate)
  }
  dispatch({
    type: 'SET_FORM',
    data: data,
    step: !!pet && 'widget-step-1-2'
  })
}