import React, {useContext} from 'react';
import {Anchor, Box} from 'grommet';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import { UserContext } from '@animalia/contexts';
import styled from 'styled-components';

const MenuItem = styled(Anchor)`
  font-weight: 500;
  font-size: 14px;
  color: currentColor;
  text-transform: uppercase;
  text-decoration: none;
`;

export default function() {
  const {user} = useContext(UserContext);
  const {t} = useTranslation();
  const history = useHistory();

  if (user.loggedin === true) {
    return (
      <Box direction="row" align="center" gap="medium">
        <MenuItem
          id="myProfile"
          onClick={() => {
            history.push({
              pathname: '/user',
              state: {
                from: window.location.pathname
              }
            });
          }}>
          {t('menu.myProfile')}
        </MenuItem>
        <MenuItem
          id="myPets"
          onClick={() => {
            history.push({
              pathname: '/',
              state: {
                from: window.location.pathname
              }
            });
          }}>
          {t('menu.myPets')}
        </MenuItem>
      </Box>
    );
  } else {
    return (
      <Box direction="row" align="center" gap="medium">
        <MenuItem
          onClick={() => {
            history.push({
              pathname: '/',
              state: {
                from: window.location.pathname
              }
            });
          }}>
          {t('Login')}
        </MenuItem>
      </Box>
    );
  }
}
