import * as yup from 'yup'

let insuranceStepSchema = yup.object().shape({
  insurance_franchise: yup.string().required('offer.error.general'),
  insurance_type: yup.string().when('insurance_franchise', {
    is: '50000',
    then: yup.string().oneOf(['compact', 'classic', 'comfort']).required('offer.error.general'),
    otherwise: yup.string().oneOf(['classic', 'comfort']).required('offer.error.general')
  })
})

export default insuranceStepSchema