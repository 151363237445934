module.exports = {
  deliveryAddress: 'Lieferadresse',
  billingAddress: 'Rechnungsadresse',
  defineNewAddress: 'Neue Adresse erfassen',
  addNewCard: 'Kredit- oder PostFinancekarte erfassen',
  addNewTwint: 'Twint-Konto erfassen',
  addNewPowerPay: 'PowerPay-Konto erfassen',
  paymentMethod: 'Zahlungsmittel',
  firstname: 'Vorname *',
  lastname: 'Name *',
  line1: 'Adresse *',
  line2: 'Adresszusatz',
  zipcode: 'PLZ *',
  city: 'Ortschaft *',
  state: 'Kanton',
  country: 'Land',
  thisIsThesePetsHouse: 'Dies ist das Haus von',
};
