module.exports = {
  myaccount: 'Kunden-konto',
  'create-ag': {
    title: 'Gourmet-Abo berechnen',
    subtitle: 'Für welchen Ihrer vierbeinigen Lieblinge möchten Sie ein Gourmet-Abo berechnen?',
    cta: 'Gourmet-Abo berechnen'
  },
  'edit-ag': {
    title: 'Gourmet-Abo anpassen',
    subtitle: 'Für welchen Ihrer vierbeinigen Lieblinge möchten Sie das Gourmet-Abo anpassen?',
    cta: 'Gourmet-Abo anpassen'
  },
  'calculate-insurance': {
    title: 'Gesundheitsversicherung berechnen',
    subtitle: 'Für welchen Ihrer vierbeinigen Lieblinge möchten Sie eine Gesundheitsversicherung berechnen?',
    cta: 'Gesundheitsversicherung berechnen'
  },
  'declare-sinister': {
    title: 'Leistungsfall melden',
    subtitle: 'Für welchen Ihrer vierbeinigen Lieblinge möchten Sie einen Leistungsfall melden?',
    cta: 'Leistungsfall melden'
  },
  'send-invoice': {
    title: 'Behandlungsrechnung einreichen',
    subtitle: 'Für welchen Ihrer vierbeinigen Lieblinge möchten Sie eine Behandlungsrechnung einreichen?',
    cta: 'Rechnung einreichen'
  },
  'edit-insurance': {
    title: 'Gesundheitsversicherung anpassen',
    subtitle: 'Für welchen Ihrer vierbeinigen Lieblinge möchten Sie die Versicherung anpassen?',
    cta: 'Versicherung anpassen'
  },
};
