
import { cleanEmptyValuesBeforeSubmit } from '@animalia/actions'
import { axios } from '@animalia/instances'

const createOffer = async ({ form: { value }, petId, language, pet, user, newStep }) => {

  let response, 
    formValue, 
    stepToSend = (newStep || value.step) === 'widget-step-3'
      ? petId
        ? 'widget-step-3-logged-in'
        : 'widget-step-3-guest'
      : value.step

  if (petId && user) {
    formValue = {
      ...value,
      step: stepToSend,
      pet_name: pet?.name,
      pet_species: pet?.species,
      pet_gender: pet?.gender,
      pet_birthdate: pet?.birthdate,
      pet_animal_type: pet?.animal_type,
      pet_animal_id: pet?.animal_id,
      pet_other_race: pet?.other_race,
      pet_animal_id_2: pet?.animal_id_2,
      pet_other_race_2: pet?.other_race_2,
      pet_weight: pet?.weight,
      holder_title: user?.attributes?.['custom:title'],
      holder_first_name: user?.attributes?.['given_name'],
      holder_last_name: user?.attributes?.['family_name'],
      holder_mobile_phone: user?.attributes?.['phone_number'],
      holder_email: user?.attributes?.['email'],
      lang: language
    }
  } else {
    formValue = {
      ...value,
      lang: language
    }
  }
  cleanEmptyValuesBeforeSubmit(formValue)
  try {
    if (petId) {
      formValue.pet_id = petId
      response = await axios.post('/quotes', formValue)
    } else {
      response = await axios.post('/public/quotes', formValue, {
        headers: {
          'Authorization': 'Bearer ' + process.env.REACT_APP_OFFER_GUEST_TOKEN
        }
      })
    }
  
    // return qid 
    return response?.data?.quote?.qid
  } catch (error) {
    throw error
  }
}

export default createOffer 