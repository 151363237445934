module.exports = {
  conclusionTitle: 'Grazie per il suo messaggio',
  conclusionMsg: 'Abbiamo inoltrato la sua richiesta di modifica alla Vaudoise Assicurazioni. Il cambiamento avverrà nei prossimi giorni.',
  deleteNumber: 'Cancellare il raccordo con questo numero di polizza',
  changeBankData: 'Modifica delle informazioni bancarie per i rimborsi',
  changeAddress: "Modificare l'indirizzo",
  changeOwner: 'Modificare il proprietario di {{name}}',
  declareSinister: 'Annunciare un sinistro per {{name}}',
  not_complete: 'Sta preparando un\'offerta assicurativa',
  waiting: 'La sua domanda di assicurazione è stata presentata con successo. Vedrà qui il suo numero di polizza nei prossimi giorni.', 
  noContractNumberSubtext:
    "{{name}} non ha ancora un'assicurazione sanitaria associata",
  ContractNumberSubtext: 'Numero di polizza {{police}}',
  addContractNumber:
    'Inserire il numero di polizza della sua assicurazione sanitaria Animalia esistente',
  addContractNumberDescription:
    'Per collegare il suo conto Animalia all’assicurazione Animalia del suo compagno a quattro zampe, la preghiamo di inserire qui il numero di polizza completo (senza spazi).',
  addContractNumberPopupTitle:
    "La preghiamo di inserire il numero di polizza dell'assicurazione sanitaria Animalia di {{name}}",
  addContractNumberFieldLabel: 'Numero di polizza Animalia',
  contractNotFound:
    'Questo numero di polizza non esiste. Inserisca per favore il numero che si trova sulla sua polizza. Si tratta del numero che termina con ...330x.',
  petNameMismatch:
    'Oops, questo numero di polizza non corrisponde al suo animale o il nome non corrisponde a quello riportato sulla polizza. Trova il nome registrato e il numero sulla sua polizza.',
  contractUpdated:
    'La polizza assicurativa è ora legata al profilo del vostro animale.',
  apiConnectionError:
    'Problema di connessione (Could not connect to API). La preghiamo di riprovare più tardi.',
  unauthorized:
    'Problema di connessione (api call unauthorized). La preghiamo di riprovare più tardi.',
  apiError:
    'Problema di connessione (api error). La preghiamo di riprovare più tardi.',
  serverError:
    'Problema di connessione (server error). La preghiamo di riprovare più tardi.',
  undefined:
    'Questo numero non è valido. Inserisca il numero di polizza che si trova sulla sua polizza. Si tratta del numero che termina con ...330x.',
  checkPolicyNumber:
    'Questo numero non è valido. Inserisca il numero di polizza che si trova sulla sua polizza. Si tratta del numero che termina con ...330x.',
  comment: '---THE FOLLOWING STRINGS ARE NEW ---',
  addInvoice: 'Inviare una fattura per {{name}}',
  addInvoiceButton: 'Aggiungere fattura',
  addDocumentButton: 'Aggiungere documento',
  addClaimButton: 'Nuovo sinistro',
  petFiles: 'File di {{name}}',
  editInsurance: 'Moduli per cambiare l\'assicurazione (indirizzo, IBAN, proprietario)',
  invoicedOn: 'Fattura di {{date}}',
  xInvoices: '{{count}} fattura',
  xInvoices_plural: '{{count}} fatture',
  steps: {
    disaster: 'Sinistro',
    medicalFile: 'Cartella clinica',
    invoice: 'Fattura',
  },
  types: {
    vaccine: 'Vaccini o spese di prevenzione',
    health: 'Malattia o altro',
    accident: 'Infortunio',
    external: 'Segnalato offline',
  },
  disaster: {
    illnessTip:
      'La sterilizzazione/castrazione, l’ablazione del tartaro, la cremazione e tutti i casi non legati a un infortunio devono essere dichiarati alla voce «Malattia o altro».',
    intro: {
      title: 'Di che tipo di sinistro si tratta?',
      description:
        'Se la fattura riguarda più sinistri o animali, la preghiamo di fare riferimento al primo e di ripetere in seguito la procedura quanto necessario.',
      new: 'Un nuovo sinistro ',
      existing: 'Un sinistro esistente',
      vaccine: 'Vaccini o spese di prevenzione',
    },
    type: {
      title: 'Di che tipo di sinistro si tratta?',
      description:
        'Si prega di indicare se si tratta di un infortunio, di una malattia o di un altro caso.',
      description2: 'Attenzione: per i vaccini e le spese di prevenzione (castrazione o sterilizzazione, ablazione del tartaro, sverminazione e complementi alimentari prescritti dal veterinario),',
      accident: 'Infortunio',
      illness: 'Malattia o altro',
      vaccine:
        'può inviare direttamente la fattura, non serve una notifica del sinistro.',
      illnessTip:
        'CGA dal 2008 al 2019: La sterilizzazione/castrazione, l’ablazione del tartaro, la cremazione e tutti i casi non legati a un infortunio devono essere dichiarati alla voce «Malattia o altro». CGA da 2021: Tutti i casi non legati a un infortunio o spese di prevenzione devono essere dichiarati alla voce «Malattia o altro»',
    },
    existing: {
      title: 'Sinistri esistenti',
      description:
        'Se ha notificato il sinistro in questa piattaforma, lo selezioni qui sotto.',
      info:
        'Ho dichiarato il sinistro in altro modo (vecchio sito, e-mail, telefono ecc.).',
      disasters: 'Sinistri',
      noDisasters: 'Non sono ancora stati notificati sinistri per {{name}}.',
    },
    title: {
      title: 'Designazione del sinistro',
      description:
        'Questa designazione le consente di individuare rapidamente il sinistro nell’incarto di {{name}}. Indichi parole semplici e chiare in riferimento al sinistro.',
      shortDescription: 'Designazione del sinistro',
    },
    existingDisasterTitle: {
      title: 'Designazione del sinistro',
      description:
        'Questa designazione le consente di individuare rapidamente il sinistro nell’incarto di {{name}}. Indichi parole semplici e chiare in riferimento al sinistro.',
      shortDescription: 'Designazione del sinistro',
      year: 'Anno del sinistro',
      type: 'Tipo di sinistro',
    },
    diagnosticAccident: {
      title: 'Diagnosi del veterinario ?',
      description:
        'Per diagnosi, intendiamo l’individuazione, da parte del veterinario, della natura della malattia di cui soffre l’animale.',
      diagnostic: 'Diagnosi del veterinario',
    },
    diagnosticIllness: {
      title: 'Diagnosi del veterinario ',
      description:
        'Per diagnosi, intendiamo l’individuazione, da parte del veterinario, della natura della malattia di cui soffre l’animale.',
      diagnostic: 'Diagnosi del veterinario',
      symptomsOptionnal: 'Sintomi (opzionale)',
      symptoms: 'Sintomi',
      illnessStartLabel: 'Data di inizio dei sintomi',
    },
    date: {
      title: 'Data e ora dell’infortunio',
      description:
        'La preghiamo di indicare la data e l’ora dell’infortunio in modo quanto più preciso possibile. Se l’infortunio non si è verificato in sua presenza, può aggiungere un’approssimazione.',
      date: 'Data dell’infortunio',
      hour: 'Ora',
      interval: 'Scarto stimato',
      exact_time: 'ora esatta',
      in_hour: 'un’ora al massimo',
      between_1_4: 'tra 1 e 4 ore',
      between_4_6: 'tra 4 e 6 ore',
      in_day: 'un giorno al massimo',
      in_week: 'una settimana al massimo',
      dateTip:
        'Se l’infortunio non si è verificato in sua presenza, può aggiungere un’approssimazione',
    },
    illnessTreatment: {
      title: 'Qual è la terapia prescritta?',
      description:
        'Per terapia, intendiamo il trattamento veterinario previsto (inclusi i farmaci, le medicazioni, i bendaggi, gli esami complementari).',
      treatment: 'Terapia prescritta',
    },
    howAccidentHappened: {
      title: 'Svolgimento dell’infortunio',
      description:
        'Descriva lo svolgimento nel modo più esatto possibile per consentirci di valutare il caso quanto prima.',
      howItHappened: 'Descrizione precisa dello svolgimento',
    },
    anyThirdParty: {
      title: 'Un terzo è coinvolto nell’infortunio?',
      description:
        'Per terzo, intendiamo un altro essere vivente (persona o animale) che abbia causato l’infortunio o vi abbia concorso.',
      howItHappened: 'Descrizione precisa dello svolgimento',
      known: 'Sì e posso identificarlo',
      unknown: 'Sì, ma non so chi sia',
      none: 'No',
      knownTip:
        'Un’altra persona o un animale è coinvolta/o e lei conosce le sue coordinate, rispettivamente quelle del proprietario.',
      unknownTip:
        'Un’altra persona o un animale (selvatico) è coinvolta/o ma lei non dispone di alcuna informazione di contatto.',
      noneTip: 'Nessun’altra persona o nessun animale è coinvolta/o.',
    },
    thirdParty: {
      title: 'Informazioni sul terzo coinvolto',
      description:
        'La preghiamo di compilare in modo quanto più possibile esaustivo i dati concernenti il terzo coinvolto. Se si tratta di un animale, vanno riportate le coordinate del/della proprietario/a.',
      lastname: 'Cognome *',
      firstname: 'Nome *',
      street: 'Indirizzo',
      number: 'No',
      zip: 'NPA',
      city: 'Località',
      country: 'Paese',
      email: 'Indirizzo e-mail',
      phone: 'Numero di telefono',
    },
    comments: {
      title: 'Osservazioni',
      description:
        'Qualsiasi informazione può aiutarci a ricostruire il caso e dunque accelerarne la gestione.',
      comment: 'Osservazioni (opzionale)',
    },
    summary: {
      title: 'Riepilogo della notifica',
      description:
        'La preghiamo di verificare l’esattezza della notifica. Può ancora apportare modifiche cliccando la penna gialla accanto a ogni informazione.',
      description2:
        'In seguito, la invitiamo a confermare e inviare la notifica.',
      cguAdditional:
        "La presentazione del sinistro e il trattamento del sinistro sono soggetti alla <a href='https://www.vaudoise.ch/data' target='blank'>dichiarazione sulla protezione dei dati della Vaudoise Assicurazioni.</a>.",
      cgu: 'Confermo di aver fornito risposte complete e conformi alla realtà',
      type: 'Tipo di sinistro',
      label: 'Designazione del sinistro',
      comment: 'Osservazioni',
      symptoms: 'Sintomi',
      treatment: 'Terapia prescritta',
      diagnosis: 'Diagnosi del veterinario',
      occurred_at: 'Data e ora dell’infortunio',
      interval: 'Scarto stimato',
      claim_year: 'Anno',
      animal: 'Animale',
      sequence: 'Svolgimento',
      third_party: 'Dati del terzo coinvolto',
      third_party_status: 'Terzo coinvolto',
    },
  },
  medicalFile: {
    add: {
      title: 'Cartella clinica di {{name}}',
      description:
        'Se si tratta del primo sinistro notificato per PETNAME, necessitiamo dell’incarto/dello storico fornito dal veterinario. Per guadagnare tempo, se ne è già in possesso può caricarlo qui sotto.',
      add: 'Aggiungere cartella clinica',
      addLater: 'Scarichi i documenti più tardi',
      alreadyDone:
        'La cartella clinica è già stato inviato o lo invierò in un secondo tempo.',
      confirmImage: "Confermare l'immagine o il file",
      addImage: 'Aggiungere un’immagine',
      maxPDF: "Può scaricare solo un file pdf. Può contenere diverse pagine.",
      cantAddImageWhenThereAreAlreadyAPdf: "Può scaricare solo un file pdf. Può contenere diverse pagine.",
      cantAddPDFWhenThereAreAlreadyImages: "Può aggiungere solo immagini. Se vuole aggiungere un pdf, deve cancellare l'immagine già caricata.",
      maxSizePDF: 'Il documento non deve superare i 6 MB.',
      browse: 'Sfogliare',
      dropFile: 'Caricare un file',
      check:
        'La preghiamo di verificare che tutti gli elementi del documento siano ben visibili e leggibili.',
      rotate: 'Girare',
      delete: 'Eliminare',
      fileFormatNotSupported: 'Formato non supportato',
    },
    title: {
      title: 'Designazione del documento',
      description:
        'Dia al documento un nome che spieghi in una o due parole di cosa si tratta.',
      titleField: 'Designazione del documento',
    },
    summary: {
      title: 'Riepilogo della cartella clinica',
      animal: 'Animale',
      files: 'Files',
      label_medicalFile: 'Designazione',
    },
  },
  invoices: {
    invoiceTitle: 'Fattura {{date}}',
    intro: {
      title: 'Caricare le fatture',
      description:
        'Carichi un documento PDF oppure una o più immagini. Dopo aver verificato che tutti gli elementi siano ben visibili e leggibili (cliccando la lente gialla), convalidi ogni immagine.',
      useExisting: 'Selezionare una fattura caricata di recente',
      later: 'Invierò le mie fatture in un secondo tempo.',
      add: 'Aggiungere una nuova fattura',
    },
    existing: {
      title: 'Fatture caricate di recente',
      description:
        'Se di recente ha caricato la fattura per un altro sinistro o un altro animale, la trova qui sotto. In tal caso, non è necessario caricarla di nuovo.',
    },
    details: {
      title: 'Importo totale della fattura',
      description:
        'Per questioni di controllo, la preghiamo di inserire l’importo totale (IVA inclusa) della fattura caricata. Attenzione: non importa se la fattura concerne più animali o sinistri, né trattamenti non rimborsati.',
      total: 'Totale fattura (IVA inclusa)',
      invoiceSubject: 'Questa fattura concerne anche',
      many_pets: 'altri animali',
      many_disasters: 'altri sinistri',
      vaccine_too: 'vaccini o spese di prevenzione',
    },
    year: {
      title: 'Anno del trattamento',
      description: 'In quale anno è stato effettuato il trattamento?',
      instruction: 'Può selezionare uno o più anni.',
    },
    summary: {
      title: 'Riepilogo della fattura',
      invoice: 'Fattura',
      animal: 'Animale',
      amount: 'Importo totale della fattura (IVA inclusa)',
      involves_multiple_pets: 'Concerne altri animali',
      involves_multiple_claims: 'Concerne altri sinistri',
      involves_a_vaccine: 'Concerne vaccini o spese di prevenzione',
      billing_years: 'Anno di trattamento',
    },
    confirmation: {
      title: 'Conferma di invio',
      description:
        'I dati e i documenti concernenti il sinistro "{{disaster}}" di {{name}} sono stati inviati al nostro servizio Prestazioni.',
      delay: 'Stima del termine previsto per la gestione:',
      delayTip:
        'Può ridurre al minimo questo termine caricando la cartella clinica e i risultati di eventuali analisi.',
      delayNotice:
        'Questo termine è indicativo e dipende dalle informazioni che saranno necessarie per la gestione della fattura.',
      pleaseRepeat:
        '<strong>Se la fattura inviata concerne più animali e/o sinistri</strong>, la preghiamo di ripetere la procedura quanto necessario.',
      newInvoiceForThisPet: 'Altra fattura per {{name}}',
      newInvoiceForAnotherPet: 'Fattura per un altro animale',
      medicalFilesForThisPet: 'Cartella clinica di {{name}}',
      backToMyPets: 'Indietro a "I miei animali"',
      otherActions: 'Altro',
      sameInvoiceForAnotherPet: 'Stessa fattura per un altro animale',
      sameInvoiceForAnotherDisaster:
        'Stessa fattura per un altro sinistro di {{name}}',
    },
    otherpets: {
      title: 'Chi riguarda questa fattura?',
      description:
        'La preghiamo di selezionare l’animale interessato dalla fattura. Se essa riguarda più animali, la invitiamo a ripetere la procedura quanto necessario.',
    },
  },
  edit: {
    title: 'Modificare l’assicurazione di {{name}}',
    description:
      'Qui sotto, può segnalarci qualsiasi cambiamento concernente l’assicurazione Animalia. Se non trova quello che cerca, la preghiamo di contattarci all’indirizzo animalia@vaudoise.ch.',
  },
  documents: {
    title: 'File di {{name}}',
    description:
      'Se il sinistro è stato registrato su questa piattaforma, lo trova qui sotto.',
    tip:
      'Se in passato ha notificato un sinistro utilizzando il nostro vecchio sito internet, per e-mail o al telefono, esso non figurerà nell‘elenco qui sotto. Occorre tornare indietro e selezionare "Ho dichiarato il sinistro in altro modo".',
    help: 'Ho notificato il sinistro, ma non appare nell’elenco qui sotto.',
    medicalFilesAreBeingProcessedTitle:
      'La cartella clinica di {{name}} sta per essere inviata al nostro servizio Prestazioni.',
    medicalFilesAreBeingProcessedDescription:
      'Stiamo verificando il suo documento, che sarà visualizzabile a breve qui.',
    filesAreBeingProcessedTitle:
      'La fattura di {{name}} sta per essere inviata al nostro servizio Prestazioni.',
    filesAreBeingProcessedDescription:
      'Stiamo verificando il suo documento, che sarà visualizzabile a breve qui.',

    sinisters: 'Sinistri di {{name}}',
    medicalFiles: 'Cartella clinica di {{name}}',
    noDocuments: 'Non sono ancora stati caricati cartelle medice per {{name}}',
    noDisasters: 'Non sono ancora stati notificati sinistri per {{name}}',
    declareSinister: 'Notificare un sinistro',
    addMedicalFiles: 'Aggiungere documenti alla cartella clinica',
    medicalFile: 'Cartella clinica',
    seeMedicalFiles: 'Consultare la cartella clinica',
    xImages: '{{count}} immagine',
    xImages_plural: '{{count}} immagini',
    vaccines: 'Vaccini e spese di prevenzione',
  },
  claimDocument: {
    description: '',
    amount: 'Importo della fattura',
    billing_years: 'Anno di trattamento',
    estimated_processing_time: 'Data prevista per il trattamento',
  },
  asideSummary: {
    amount: 'Importo della fattura (IVA inclusa)',
    involves_multiple_pets: 'Concerne altri animali',
    involves_multiple_claims: 'Concerne altri sinistri',
    involves_a_vaccine: 'Concerne vaccini o spese di prevenzione',
    billing_years: 'Anno di trattamento',
    type: 'Tipo di sinistro',
    label: 'Designazione del sinistro',
    label_medicalFile: 'Designazione del documento',
    comment: 'Osservazioni',
    symptoms: 'Sintomi',
    third_party_status: 'Terzo coinvolto',
    treatment: 'Terapia prescritta',
    diagnosis: 'Diagnosi del veterinario',
    occurred_at: 'Data e ora',
    interval: 'Scarto stimato',
    claim_year: 'Anno',
    animal: 'Animale',
    sequence: 'Svolgimento',
    third_party: 'Dati del terzo coinvolto',
  },
  vaccines: {
    title: 'Vaccini o spese di prevenzione',
  },
  deleteModal: {
    title: 'Eliminare il documento',
    description:
      'Una volta eliminato, non sarà più possibile recuperare il documento. Desidera procedere?',
    actionYes: 'Sì, eliminare il documento',
    actionNo: 'No, preferisco conservare il documento',
  },
address: {
    my_details_change: 'Cambio di indirizzo per l\'assicurazione',
    change_address_for: 'Cambiare questo indirizzo anche per',
    change_for_all: 'Tutti i miei animali e i loro servizi',
    change_for_pet: 'Consegne Abbonamente Gourmet da'
  },
  owner: {
    owner_change: 'Cambiamento del detentore di',
    owner_needed_details: 'Si prega di compilare i dettagli del nuovo proprietario. Per garantire che {{ name }} sia ancora coperto, contatteremo il nuovo proprietario e faremo una nuova offerta assicurativa.',
    contact_new_owner: 'Quando avverrà il cambiamento del detentore?',
    transfer_pet: 'Trasferisca anche il proprietario di',
    transferred_at: 'Data del cambiamento del detentore',
    keep_insurance: 'Il nuovo proprietario desidera mantenere l\'assicurazione Animalia?'
  },
  bank_details: {
    my_bank_details_change: 'Cambio di coordinate bancarie',
    my_bank_details_description: 'Inserisca qui il codice IBAN del conto su cui desidera ricevere i rimborsi.'
  }
};