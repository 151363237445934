import React from 'react'

const StepModel = ({ Component, reference, pet, user, formValue, error, dispatch, goToPrevStep, goToNextStep, goToFirstStep, closeModalAndGoTo, isLoading, setIsLoading, offerQuid }) => {
  
  if (formValue.step === reference) return (
    <Component
      pet={pet}
      user={user}
      offerQuid={offerQuid}
      formValue={formValue} 
      dispatch={dispatch}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      goToPrevStep={goToPrevStep}
      goToNextStep={goToNextStep}
      goToFirstStep={goToFirstStep}
      closeModalAndGoTo={closeModalAndGoTo}
      error={error}
    />
  )

  return null
}

export default StepModel
