function Order(order) {
  const STATUS_ACTIVE = 'active'; //Active
  const STATUS_INACTIVE = 'inactive'; //New
  const STATUS_STANDBY = 'standby'; //En attente de paiement
  const STATUS_CANCELLED = 'cancelled'; //Annulée

  let self = {
    ...order,

    isActive: () => self.status === STATUS_ACTIVE,

    isInactive: () => self.status === STATUS_INACTIVE,

    isStandBy: () => self.status === STATUS_STANDBY,

    isCancelled: () => self.status === STATUS_CANCELLED,

    hasOpenDeliveries: () => {
      return (
        self.deliveries.filter(
          delivery =>
            ['pending', 'payment-on-hold', 'planned'].indexOf(
              delivery.status
            ) >= 0
        ).length > 0
      );
    },

    needUpdate: pet => {
      if (!self.updated_at) {
        return false;
      }

      return (
        new Date(pet.updated_at).setSeconds(0, 0) >
        new Date(self.updated_at).setSeconds(0, 0)
      );
    },

    nextDelivery: () => {
      if (self.deliveries && self.deliveries.length > 0) {
        return self.deliveries.find(
          delivery =>
            ['pending', 'payment-on-hold', 'planned'].indexOf(
              delivery.status
            ) >= 0
        );
      }

      return false;
    },

    discount: () => {
      let coupon = null;
      let formatedPrice, price;

      if (typeof self.coupons === 'undefined' || self.coupons.length === 0) {
        return null;
      }

      coupon = self.coupons[0];

      // is coupon still valid ?
      const dateLimit = new Date(self.created_at).setMonth(
        new Date(self.created_at).getMonth() + coupon.duration
      );

      if (new Date() > dateLimit) {
        return null;
      }

      return {
        value: coupon.value,
        type: coupon.type,
        duration: coupon.duration,
      };
    },
  };

  return self;
}

export default Order;
