import { axios } from '@animalia/instances'
import * as yup from 'yup'
import moment from 'moment'

let newCertificateSchema = yup.object().shape({
  breed_id: yup.string().required('offer.error.general'),
  owner_email: yup.string().required('offer.error.general'),
  owner_birthdate: yup.date().required('offer.error.general'),
  owner_title: yup.string().oneOf(['mr', 'mrs']).required('offer.error.general'),
  owner_first_name: yup.string().required('offer.error.general'),
  owner_last_name: yup.string().required('offer.error.general'),
  owner_street: yup.string().required('offer.error.general'),
  owner_street_number: yup.string().required('offer.error.general'),
  owner_zip_code: yup.string().required('offer.error.general'),
  owner_city: yup.string().required('offer.error.general'),
  owner_country: yup.string().required('offer.error.general'),
  owner_post_address_strid: yup.string().required('offer.error.general'),
  owner_post_address_house_key: yup.string().required('offer.error.general'),
  pet_gender: yup.string().oneOf(['male', 'female']).required('offer.error.general'),
  pet_species: yup.string().oneOf(['dog', 'cat']).required('offer.error.general'),
  pet_name: yup.string()
    .test('checkPetNameUnique', 'breeder.certificates.petNameAlreadyExist', async value => {
      const certificates = await axios.get('/breeders/certificates')
      return !certificates?.data?.certificates?.find(certificate => certificate.pet_name === value)
    })
    .required('offer.error.general'),
  pet_birthdate: yup.date()
    .min(
      // min substract 6 months
      moment().subtract(6, 'months').toDate(),
      'breeder.certificates.petBirthdateMin6Months'
    )
    .required('offer.error.general'),
  pet_exit_date: yup.date()
      .when('pet_birthdate', (pet_birthdate, schema) => {
        return schema
          .min(
            moment(pet_birthdate).add(56, 'days').toDate(),
            'breeder.certificates.petExitDateMax56DaysBirthdate'
          )
          .max(
            moment(pet_birthdate).add(6, 'months').toDate(),
            'breeder.certificates.petExitDateMax180DaysBirthdate'
          )
      })
      .required('offer.error.general'),
  locale: yup.string()
    .oneOf(['de', 'fr', 'it'])
    .required('offer.error.general'),
})

export default newCertificateSchema
