import { TextInput } from 'grommet'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import { generateNamesObject, setAddressFromAPI, setAddressFromRegistredAddress, setDate, setUserId } from '@animalia/actions'
import { WidgetTightFormField, TitleSelect, LangSelect, WidgetTightFlexibleGrid } from '../..'
import { it, fr, de  } from 'date-fns/locale'
import axios from 'axios'

const addressSpliter = string => {
  if (string.includes(',')) {
    return [
      string,
      ...string.split(',').sort((a,b) => b.length - a.length)
    ]
  }
  // returns array with [0]:full string, [1]:address line, [2]:No street
  return string && string.match(/^(.+)\s(\d+(\s*[^\d\s]+)*)$/) || [string, string, '']
}

const WidgetTightAddressSingle = ({ prefixName, formValue, form, dispatch, exclude, error, user, suggestionsRequest, setOpenForm, setIsLoading, isRegistred, object, langSelect, concluded }) => {
  const ref = useRef(null)
  const { t, i18n } = useTranslation()
  const [line1, setLine1] = useState('')
  const [addressInfo, setAddressInfo] = useState(false)
  const [preventSuggestionOpening, setPreventSuggestionOpening] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [isAddressLoading, setIsAddressLoading] = useState(false)
  
  const locales = { it, fr, de }
  const allow = ref => {
    if (!exclude) {
      return true
    }
    return !exclude.find(item => item === ref)
  }
  const [birthdate, setBirthdate] = useState(formValue.holder_birthdate)

  const onDateChange = date => {
    if (date) {
      const dateISO = new Date(date).setHours(12)
      const dateString = new Date(dateISO)
      formValue[names.birthdate] = dateString
      setDate(dispatch, names.birthdate, dateString)
      setBirthdate(date)
    }
  }

  const onAddressChange = ({ target: { value: StreetName } }) => {
    let rest = { 
      StreetName,
      STRID: '',
      HouseNo: '',
      HouseNoAddition: '',
      ZipCode: '',
      TownName: '',
      CountryCode: ''
    }

    setLine1(StreetName)

    if (!suggestionsRequest) {
      setAddressFromAPI(
        dispatch, 
        prefixName, 
        addressInfo 
          ? { StreetName } 
          : { StreetName, ...rest },
        form
      )
    }
  }

  const onAddressSelect = ({ suggestion }) => {
    setPreventSuggestionOpening(true)
    setLine1(suggestion?.value)
    setAddressFromAPI(
      dispatch, 
      prefixName, 
      {
        ...suggestion?.object
      },
      form
    )

    if (suggestionsRequest) {
      // close the form if it's an existing address 
      setOpenForm(false)
    }
  }

  const setRegistredAddress = () => {
    setAddressFromRegistredAddress(dispatch, prefixName, object, form)
    setOpenForm(false)
  }

  const labels = {
    title: t('user.title'),
    lang: t('user.locale'),
    first_name: t('user.firstName'),
    last_name: t('user.lastName'),
    email: t('user.email'),
    line: t('offer.insurance.line'),
    line_2: t('offer.insurance.line_2'),
    zip_code: t('offer.insurance.zip_code'),
    birthdate: t('user.birthdate'),
    city: t('offer.insurance.city'),
    home_phone: t('user.home_phone'),
    mobile_phone: t('user.mobile_phone'),
  }

  const names = generateNamesObject([
    'title',
    'address_id',
    'last_name',
    'first_name',
    'email',
    'line',
    'line_2',
    'zip_code',
    'city',
    'birthdate',
    'mobile_phone',
    'phone'
  ], prefixName)

  useEffect(() => {
    if (!suggestionsRequest && formValue[names.line]) {
      setLine1(formValue[names.line])
    }

    if (user) {
      const attr = user.attributes
      const data = {
        title: attr['custom:title'],
        first_name: attr.given_name,
        last_name: attr.family_name
      }
      setUserId(dispatch, prefixName, data, form)
    }

  }, [])

  useEffect(() => {
    if (concluded) {
      console.log('Should delete');
      setBirthdate(null)
      setLine1('')
      setSuggestions([])
      formValue[names.line] = ''
      formValue[names.birthdate] = null
    }
  }, [concluded])

  useEffect(() => {
    (async () => {
      if (preventSuggestionOpening) {
        setPreventSuggestionOpening(false)
        return false
      }
      setIsAddressLoading(true)
      setIsLoading && setIsLoading(true)
      if (isRegistred) {
        setRegistredAddress()
      } else if (!suggestionsRequest) {
    
        let 
          response,
          searchString = 
            typeof line1 === 'string' 
              ? addressSpliter(line1) 
              : null

        if (searchString?.length && line1?.length > 5) {
      
          const reqObject = {
            ONRP: 0,
            ZipCode: '',
            ZipAddition: '',
            TownName: '',
            STRID: 0,
            StreetName: searchString[1],
            HouseKey: 0,
            HouseNo: searchString[2].toString(),
            HouseNoAddition: ''
          } 

          try {
            response = await axios.post(process.env.REACT_APP_API_POST_ENDPOINT, JSON.stringify(reqObject))
          } catch (error) {
            throw error
          }
      
          const addressesFound = 
            response
            ?.data
            ?.body
            ?.data
            ?.QueryAutoComplete3Result
            ?.AutoCompleteResult
          
          if (addressesFound?.length) {
        
            setSuggestions([
              ...addressesFound.map(item => ({
                label: `${item.StreetName} ${item.HouseNo}${item.HouseNoAddition || ''}, ${item.ZipCode} ${item.TownName}`,
                value: `${item.StreetName} ${item.HouseNo}${item.HouseNoAddition}`,
                object: item
              }))
            ])
          } else {
            suggestions.length && setSuggestions([])
          }
        }
      }
      setIsAddressLoading(false)
      setIsLoading && setIsLoading(false)
    })()

  }, [line1])

  useEffect(() => {
    (async () => {
      setIsAddressLoading(true)
      setIsLoading && setIsLoading(true)
      if (isRegistred) {
        setRegistredAddress()
      } else if (suggestionsRequest) {
        let response,
        searchString = 
          typeof suggestionsRequest.line === 'string' 
            && addressSpliter(suggestionsRequest.line)

        const reqObject = {
          ONRP: 0,
          ZipCode: suggestionsRequest.zip_code || '',
          ZipAddition: '',
          TownName: suggestionsRequest.city || '',
          STRID: 0,
          StreetName: searchString[1] || '',
          HouseKey: 0,
          HouseNo: searchString[2]?.toString() || '',
          HouseNoAddition: suggestionsRequest.line_2 || ''
        }

        try {
          response = await axios.post(process.env.REACT_APP_API_POST_ENDPOINT, reqObject)
        } catch (error) {
          throw error
        }

        const addressesFound = 
          response
          ?.data
          ?.body
          ?.data
          ?.QueryAutoComplete3Result
          ?.AutoCompleteResult

        if (addressesFound?.length) {
          let houseNo = searchString[2].toString()
          const filteredAddressesFound =
            addressesFound
              .filter(item => parseInt(item.HouseNo) === parseInt(houseNo))

          if (filteredAddressesFound.length === 1) {
            const suggestion = filteredAddressesFound[0]
            // preselect when filtered addresses length === 1

            onAddressSelect({
              suggestion: {
                label: `${suggestion.StreetName} ${suggestion.HouseNo}, ${suggestion.ZipCode} ${suggestion.TownName}`,
                value: `${suggestion.StreetName} ${suggestion.HouseNo}${suggestion.HouseNoAddition}`,
                object: {
                  id: suggestionsRequest.id,
                  ...suggestion
                }
              }
            })

          } else {
            setLine1(suggestionsRequest.line)
            setSuggestions([
              ...addressesFound.map(item => ({
                label: `${item.StreetName} ${item.HouseNo}, ${item.ZipCode} ${item.TownName}`,
                value: `${item.StreetName} ${item.HouseNo}`,
                object: {
                  id: suggestionsRequest.id,
                  ...item
                }
              }))
            ])
          }

          if (!formValue[names.line]) {
            ref?.current?.focus()
          }
        }
        
      }
      setIsAddressLoading(false)
      setIsLoading && setIsLoading(false)
    })()

  }, [suggestionsRequest])

  if (!setIsLoading || (setIsLoading && !isAddressLoading)) return (
    <>
      {
        allow('id')
          &&
            <WidgetTightFlexibleGrid col='3'>
              <WidgetTightFormField
                background='light-1'
                label={labels.title}
                name={names.title}
                htmlFor={`select-${names.title}`}
                autocomplete='honorific-prefix'
                error={error}
                disabled={!!user}
              >
                <TitleSelect
                  name={names.title}
                  id={`select-${names.title}`}
                  autocomplete='honorific-prefix'
                  disabled={!!user}
                />
              </WidgetTightFormField>
              <WidgetTightFormField
                background='light-1'
                autocomplete='family-name'
                error={error}
                label={labels.last_name}
                htmlFor={`select-${names.last_name}`}
                name={names.last_name}
                disabled={!!user}
              >
                <TextInput
                  id={`select-${names.last_name}`}
                  name={names.last_name}
                  autocomplete='family-name'
                  disabled={!!user}
                />
              </WidgetTightFormField>
              <WidgetTightFormField
                background='light-1'
                label={labels.first_name}
                error={error}
                htmlFor={`select-${names.first_name}`}
                name={names.first_name}
                autocomplete='given-name'
                disabled={!!user}
              >
                <TextInput
                  id={`select-${names.first_name}`}
                  name={names.first_name}
                  autocomplete='given-name'
                  disabled={!!user}
                />
              </WidgetTightFormField>
            </WidgetTightFlexibleGrid>
      }
      {
        langSelect &&
          <WidgetTightFormField
            background='light-1'
            htmlFor='select-lang'
            label={labels.lang}
            name='lang'
            autocomplete='honorific-prefix'
            error={error}
          >
            <LangSelect
              name='lang'
              id='select-lang'
              autocomplete='honorific-prefix'
              disabled={!!user}
            />
          </WidgetTightFormField>
      }
      {
        allow('email')
          &&
            <WidgetTightFormField
              background='light-1'
              label={labels.email}
              error={error}
              htmlFor={`select-${names.email}`}
              name={names.email}
              autocomplete='email'
            >
              <TextInput
                id={`select-${names.email}`}
                name={names.email}
              />
            </WidgetTightFormField>
      }
      {
        allow('address')
          &&
            <>
              <WidgetTightFormField
                background='light-1'
                label={labels.line}
                loading={isAddressLoading}
                error={error}
                htmlFor={`select-${names.line}`}
                name={names.line}
                info={addressInfo}
                // autocomplete='address-line1'
              >
                <TextInput
                  ref={ref}
                  open={!!suggestionsRequest}
                  value={line1}
                  onChange={onAddressChange}
                  suggestions={suggestions}
                  onSuggestionSelect={onAddressSelect}
                />
              </WidgetTightFormField>
              <WidgetTightFormField
                  background='light-1'
                  label={labels.line_2}
                  loading={isAddressLoading}
                  error={error}
                  htmlFor={`select-${names.line_2}`}
                  name={names.line_2}
                  disabled={!addressInfo}
                >
                <TextInput
                  id={`select-${names.line_2}`}
                  name={names.line_2}
                  disabled={!addressInfo}
                />
              </WidgetTightFormField>

              <WidgetTightFlexibleGrid col='2'>
                <WidgetTightFormField
                  background='light-1'
                  label={labels.zip_code}
                  loading={isAddressLoading}
                  error={error}
                  htmlFor={`select-${names.zip_code}`}
                  name={names.zip_code}
                  disabled={!addressInfo}
                >
                  <TextInput
                    id={`select-${names.zip_code}`}
                    name={names.zip_code}
                    disabled={!addressInfo}
                  />
                </WidgetTightFormField>
                <WidgetTightFormField
                  background='light-1'
                  autocomplete='city'
                  label={labels.city}
                  loading={isAddressLoading}
                  error={error}
                  htmlFor={`select-${names.city}`}
                  name={names.city}
                  disabled={!addressInfo}
                >
                  <TextInput
                    id={`select-${names.city}`}
                    name={names.city}
                    disabled={!addressInfo}
                  />
                </WidgetTightFormField>
              </WidgetTightFlexibleGrid>
            </>
      }
      {
        allow('birthdate')
          &&
            <WidgetTightFormField
              name={names.birthdate}
              background='light-1'
              label={labels.birthdate}
              error={error}
              autocomplete='bday'
            >
              <DatePicker
                selected={birthdate}
                onChange={onDateChange}
                dateFormatCalendar='MMMM'
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                name={names.birthdate}
                maxDate={new Date()}
                locale={locales[i18n.language]}
                scrollableYearDropdown
                value={formValue[names.birthdate]}
                dateFormat='dd.MM.yyyy'
                className='datepicker'
              />
            </WidgetTightFormField>
      }
      {
        allow('tel')
          &&
            <WidgetTightFlexibleGrid col='2'>
              <WidgetTightFormField
                htmlFor={`select-${names.mobile_phone}`}
                name={names.mobile_phone}
                error={error}
                background='light-1'
                label={labels.mobile_phone}
                autocomplete='tel'
              >
                <TextInput
                  id={`select-${names.mobile_phone}`}
                  name={names.mobile_phone}
                />
              </WidgetTightFormField>
              <WidgetTightFormField
                htmlFor={`select-${names.phone}`}
                name={names.phone}
                error={error}
                background='light-1'
                label={labels.home_phone}
                autocomplete='tel'
              >
                <TextInput
                  id={`select-${names.phone}`}
                  name={names.phone}
                />
              </WidgetTightFormField>
            </WidgetTightFlexibleGrid>
      }
    </>
  )
  return null
}

export default WidgetTightAddressSingle
