module.exports = {
  nextDeliveryOn: 'Prossima consegna il {{date}}',
  date: 'Data di consegna',
  dateRecurring: 'Desidera personalizzare anche le consegne future?',
  dateSelector: 'Selezionare la nuova data di consegna',
  address: 'Indirizzo di consegna',
  editDeliveryDateFor: 'Modificare la data di consegna per {{nome}}',
  adjustAllDates: "Si, adattare l'indirizzo anche per le consegne successive.",
  adjustOneDate:
    "No, voglio cambiare l'indirizzo unicamente per questa consegna.",

  status: {
    pending: 'In attesa',
    planned: 'Previsto',
    failed: 'Fallimento del trattamento',
    cancelled: 'Cancellato',
    refunded: 'Rimborsato',
    standby: 'In attesa di pagamento',
    'payment-on-hold': 'In attesa di pagamento',
    'completed-paid': 'Inviato',
    'completed-unpaid': 'Inviato',
    'completed-refunded': 'Inviato',
  },
  editLater: 'Inserisca qui l\'indirizzo di consegna. E se sta per partire per la casa in montagna o se lascia il suo compagno dai vicini o dagli amici, non si preoccupi: può cambiare l\'indirizzo di consegna in qualsiasi momento tramite il suo conto cliente, anche per una singola consegna. Comodo, vero?',
  paymentMessage: 'Basta un clic per deliziare il vostro amico a quattro zampe.<br><br>Non si preoccupi! È possibile disdire il suo abbonamento in qualunque momento con un semplice clic nel suo conto cliente o tramite il nostro servizio clienti.<br><br>Inoltre, non esiti a contattarci per conoscere i metodi di pagamento o per richiedere assistenza al momento del pagamento.'
};