import * as yup from 'yup'
import differenceInYears from 'date-fns/differenceInYears'
import { ibanValidation } from '@animalia/actions'

const phoneRegExp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

const ifNullThen = schema => value => {
  if (!value) {
    return schema
  }
}

let offlineOfferSchema =  yup.object().shape({
  step: yup.string().required(),
  qid: yup.string().nullable(),
  pet_id: yup.number().nullable(),
  pet_species: yup.string().required('offer.error.pet_species_required').matches(/(cat|dog)/),
  pet_gender: yup.string().required('offer.error.pet_gender_required').matches(/(male|female)/),
  pet_name: yup.string()
    .required('offer.error.pet_name_required')
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase')
    .test('len', 'offer.error.more_char', val => val?.length >= 2),
  pet_birthdate: yup.date().max(new Date(), 'offer.error.invalid_date').required('offer.error.pet_birthdate_required'),
  pet_age_category: yup.string().nullable(),
  pet_animal_type: yup.string().nullable().matches(/(breed|bastard|unknown)/).required('offer.error.pet_is_purebred_required'),
  pet_animal_id: yup.string().when(['pet_animal_type', 'pet_other_race'], {
    is: (pet_animal_type, pet_other_race) => pet_animal_type !== 'unknown' && !pet_other_race,
    then: yup.string().required('offer.error.pet_animal_id_required')
  }),
  pet_animal_id_2: yup.string().when(['pet_animal_type', 'pet_other_race_2'], {
    is: (pet_animal_type, pet_other_race_2) => pet_animal_type == 'bastard' && !pet_other_race_2,
    then: yup.string().required('offer.error.pet_animal_id_required')
  }),
  pet_other_race: yup.string().transform(value => !value ? null : value).nullable(true).matches(/^[A-Z]/, 'offer.error.uppercase').matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase'),
  pet_other_race_2: yup.string().transform(value => !value ? null : value).nullable(true).matches(/^[A-Z]/, 'offer.error.uppercase').matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase'),
  pet_weight: yup.number().transform(value => !value ? null : value).nullable(true).when(['pet_animal_type', 'pet_other_race'], {
    is: (pet_animal_type , pet_other_race) => pet_animal_type !== 'breed' || !!pet_other_race,
    then: yup.number().required('offer.error.pet_weight_required').typeError('offer.error.pet_weight_number_required')
  }),
  insurance_franchise: yup.number().required('offer.error.general'),
  insurance_type: yup.string().required('offer.error.insurance_type_required').matches(/(compact|classic|comfort)/),
  insurance_cost: yup.number().required(),
  insurance_payment_frequency: yup.number().oneOf([ 1, 2, 4 ]).required('offer.error.insurance_payment_frequency_required'),
  insurance_enters_in_force_at: yup.date().min(new Date(), 'offer.error.invalid_date').required('offer.error.insurance_enters_in_force_at_required'),
  agency_code: yup.string().nullable(),
  promo_code: yup.string().nullable(),
  agent_code: yup.string().max(4, 'offer.error.max_char_4').nullable(),
  holder_title: yup.string().required('offer.error.title_required').matches(/(mr|ms|mrs)/),
  holder_first_name: yup.string().test('len', 'offer.error.more_char', val => val?.length >= 2).required('offer.error.first_name_required'),
  holder_last_name: yup.string().test('len', 'offer.error.more_char', val => val?.length >= 2).required('offer.error.last_name_required'),
  holder_post_address_id: yup.string().nullable(),
  holder_post_address_house_key: yup.string().nullable(),
  holder_post_address_strid: yup.string().nullable(),
  holder_line: yup.string().when('holder_address_id', ifNullThen(yup.string().required('offer.error.line_required'))),
  holder_line_2: yup.string().nullable(),
  holder_zip_code: yup.string().when('holder_address_id', ifNullThen(yup.string().required('offer.error.zip_code_required'))),
  holder_city:  yup.string().when('holder_address_id', ifNullThen(yup.string().required('offer.error.city_required'))),
  holder_country: yup.string().nullable(),
  holder_birthdate: yup.date()
    .required('offer.error.birthdate_required')
    .test('dob', 'offer.error.min_18', value => differenceInYears(new Date(), value) >= 18)
    .max(new Date(), 'offer.error.invalid_date'),
  holder_nationality: yup.string(),
  holder_phone: yup.string().nullable().when('holder_mobile_phone', ifNullThen(yup.string().matches(phoneRegExp, 'format: +41000000000').required('offer.error.holder_phone_required'))),
  holder_mobile_phone: yup.string().nullable().when('holder_phone', ifNullThen(yup.string().matches(phoneRegExp, 'format: +41000000000').required('offer.error.holder_phone_required'))),
  holder_iban: yup.string()
    .test('checkIban', 'offer.error.wrong_iban', ibanValidation)
    .nullable(),
  holder_bank_id: yup.number().nullable(),
  holder_is_vaudoise_customer: yup.boolean().nullable(),
  holder_is_pet_keeper: yup.boolean().nullable(),
  lang: yup.string().required('offer.error.general')
}, ['holder_phone', 'holder_mobile_phone'])

export default offlineOfferSchema