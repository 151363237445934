module.exports = {
  btnAdd: 'Tier hinzufügen',
  yourPets: 'Mein Rudel',
  yourPetServices: '{{name}}s Services',
  yourPetAbogourmand: '{{name}}s Gourmet-Abo',
  yourPetAssistance: '{{name}}s Tierhilfe',
  animal: 'Tier',
  cat: 'Katze',
  dog: 'Hund',
  size: 'Grösse',
  gender: 'Geschlecht',
  female: 'Weibchen',
  male: 'Männchen',
  animalTitle: 'Tier hinzufügen',
  animalEditTitle: 'Tier ändern',
  animalDescription: 'Stellen Sie uns Ihren vierbeinigen Freund vor.',

  passportTitle: 'Animalia Steckbrief',
  passportDescription: 'Beschreiben Sie uns Ihren Lieblings-Feinschmecker!',
  itsName: 'Name',
  noNameYet: 'Kein Name',
  bornOn: 'Geburtsdatum',
  birthdate: 'Geburtsdatum',
  breed: 'Rasse',
  breedDescription:
    'Rassenrein oder nicht, wir wissen, wie sehr Sie Ihn lieben! ',
  weight: 'Gewicht',
  catofbreed: 'Rassenkatze',
  dogofbreed: 'Rassenhund',
  kgWeight: 'Gewicht im Erwachsenenalter in kg',
  kgWeightMaxError: 'Bitte wählen Sie ein Gewicht unter 100 kg',
  adultWeightTitle: 'Gewicht im Erwachsenenalter',
  adultWeightDescription:
    'Seine Gesundheit lässt sich nicht in Zahlen fassen. Verraten Sie uns trotzdem, was auf der Waage steht?',
  chooseBreed: 'Rasse auswählen',
  dogBastard: 'Mischling',
  catBastard: 'Hauskatze',

  activityTitle: 'Jedem sein Rhythmus',
  activityDescription: 'Sportskanone oder Schlafmütze? Er ist eher...',
  activityLevel: 'Aktivität',
  passive: 'Wenig aktiv',
  passiveDescription:
    'Eher Sofa als Hunderennen – Ihr Vierbeiner bleibt auch gerne einfach mal gemützlich zu Hause.',
  normal: 'Gemässigt aktiv',
  normalDescription:
    'Er spielt schon gerne mal mit einer Schnur oder holt auch mal Stöckchen – Ihr Liebling mag Bewegung, wenn’s nicht zu anstrengend ist!',
  active: 'Sportskanone',
  activeDescription:
    'Lange Spaziergänge oder Dauerlauf – Ihr Vierbeiner bringt Sie so richtig ins Schwitzen.',

  sterilizedTitle: 'Kastriert?',
  sterilizedDescription: 'Wurde Ihr Begleiter kastriert?',
  sterilizedYes: 'Ja (Jautsch!)',
  sterilized: 'Kastriert',
  nonSterilized: 'Nicht kastriert',

  foodTypeStepTitle: 'Kroketten',
  foodTypeTitle: 'Menu à la Carte',
  foodTypeDescription:
    'Erzählen Sie uns ein bisschen mehr zu den Essensgewohnheiten Ihres Leckermäulchens.',
  standard: 'Standard',
  standardHelp:
    'Ihr Gastronom auf Pfoten leidet weder an Allergien noch an speziellen Intoleranzen? Er wird unsere Kroketten lieben! 100% natürlich, garantiert ohne Zusatzstoffe und vor allem glutenfrei – damit er auch in Zukunft so unkompliziert bleiben kann.',
  sensitive: 'Sensibel',
  sensitiveHelp:
    'Ihr Fellfreund ist ein wählerischer Geniesser mit sensiblem Magen? Wir haben genau was er braucht: ein feines, ausgewogenes Menu für eine sanfte Verdauung.',
  grainfree: 'Grain-free',
  'grain-free': 'Grain-free',
  grainfreeHelp:
    'Ihr vierbeiniger Begleiter ist auf Getreide allergisch oder hat Probleme, sie zu verdauen? Unsere Grain-free-Kroketten versorgen ihn trotzdem mit allen Nähr- und Ballaststoffen, die er braucht.',

  productStepTitle: 'Produkt wählen',
  productTitle: 'Napf füllen (mit Liebe und Kroketten)',
  productDescription:
    'Egal, welcher Geschmack, unsere Kroketten sind zum Fressen gut. Sie müssen nur noch entscheiden, was er am liebsten hat.',

  adjustQuantityStepTitle: 'Menge anpassen',
  adjustQuantityTitle: 'Zu Tisch!',
  adjustQuantityDescription:
    'Willkommen {{name}}! Wir sind hocherfreut, ihn kennenzulernen! Nach Ihren Informationen und in Zusammenarbeit mit unserem tierärztlichen Ernährungsberater, der unsere Kroketten in- und auswendig kennt, empfehlen wir Ihnen folgende Tagesration:',
  adjust80Comment:
    'Ui, da bleibt aber nicht mehr viel übrig! Ergänzen Sie sein Futter mit anderen Nahrungsmitteln? Aufgepasst, eine mehr als 20% kleinere Tagesration kann zu Mangelerscheinungen und Unterernährung führen und der Gesundheit Ihres vierbeinigen Freunds nachhaltig schaden. Sollen wir nicht doch ein paar Kroketten mehr für Ihren Liebling einpacken?',
  adjust90Comment:
    'Ihr vierbeiniger Mitbewohner isst wie ein Vögelchen? Vielleicht gibt’s hin und wieder auch was anderes als unsere feinen Kroketten? Dann verringern Sie die empfohlene Menge um 10%. Achten Sie aber darauf, dass die Tagesration eingehalten wird und ergänzen Sie das Futter nach seinen Vorlieben.',
  adjust100Comment:
    'Exakt die empfohlene Portion! Aber Sie kennen ihn besser als jeder andere: Zögern Sie nicht, Ihre Anpassungen vorzunehmen',
  adjust110Comment:
    'Sie haben ein grosses Leckermaul oder einen Supersportler? Erhöhen Sie seine Tagesration um 10%. Aber nicht übertreiben, gell?',
  adjust120Comment:
    'Das ist aber eine Menge Futter! Geben Sie Acht, eine mehr als 20% grössere Tagesration kann zu Übergewicht führen und der Gesundheit Ihres vierbeinigen Freunds nachhaltig schaden. Versuchen wir’s mit ein bisschen weniger Kroketten und dafür mit einer Extraportion Liebe?',

  summaryTitle: 'Das Gourmet-Abo',
  monthlyPrice: 'Preis pro Monat',

  upTo4kg: 'bis 4kg',
  between4and6kg: 'zwischen 4kg und 6kg',
  between6and8kg: 'zwischen 6kg und 8kg',
  from8kg: 'ab 8kg',

  // summary screen
  nextDeliveryDate: 'Nächste Lieferung',
  nextDeliveryOn: 'Bitte wählen Sie das Datum der ersten Lieferung',
  deliveryFrequency: 'Häufigkeit der Lieferungen',
  deliveredEachXDays: 'Alle {{days}} Tage',
  deleteOrderFor: 'Das Gourmet-Abo von {{name}} kündigen',
  product: 'Produkt',
  dailyQuantity: 'Tägliche Menge',
  order: 'Weiter zur Bestellung',

  // details and listing screens
  backToMyPets: 'Zurück zu meinen Tieren',
  deletePet: 'Profil von {{name}} löschen',
  byDeletingPetItWillCancelLinkedServices:
    'Bitte beachten Sie, dass die Gesundheitsversicherung von {{name}} (falls vorhanden) nicht gekündigt wird, wenn Sie sein / ihr Profil löschen.',
  reasonOfDeletion: 'Grund für die Löschung',
  myPetIsDead: 'Mein Tier ist verstorben',
  notInterrestedAnymore: 'Ich bin nicht mehr interessiert',
  thisPetCantBeDeleted:
    'Sie können das Profil von {{name}} nicht löschen, da noch mindestens ein Animalia-Service aktiv ist:',
  reasonAbo: 'aktives Gourmet-Abo oder eine noch ausstehende Lieferung',
  reasonAssurance: "Animalia Gesundheitsversicherung",
  // my account
  itsAbo: 'Sein Abo',
  calculate: 'Berechnen Sie das Gourmet-Abo Ihres vierbeinigen Freundes',
  calculateAgain: 'Berechnen Sie das Gourmet-Abo neu',
  petDataHasChanged: 'Die Angaben von {{name}} haben geändert',
  seeOrderDetails: 'Gourmet-Abo ansehen',
  seeInsuranceSettings: "Verbindung mit der Animalia Gesundheitsversicherung löschen",
  hasNoAccount: '{{name}} hat noch kein Gourmet-Abo',
  hasNoInsurance: '{{name}} hat noch keine Gesundheitsversicherung registriert',
  itsInsurance: 'Die Gesundheitsversicherung von {{name}}',
  policeNumber: 'Police Nr.',
  petDataHasChangedExtended:
    'Das Profil von {{name}} wurde geändert. Wir empfehlen Ihnen, das Gourmet-Abo neu zu berechnen, damit wir das Produkt und die Menge an seine Bedürfnisse anpassen können.',
  receiveOffer: 'Offerte für die Versicherung erhalten',
  askForEstimate: 'Verlangen Sie eine Offerte',
  calculator: 'Prämienrechner',
  hasNoOrder: '{{name}} hat noch kein Abonnement',
  adjustOrder: 'Möchten Sie das Abonnement von {{name}} anpassen?',
  petHasRemainingDeliveries:
    'Achtung: {{name}} hat noch offene Zahlungen und/oder Lieferungen.',
  newAbo: 'Ein neues Abo erstellen',
  temporaryDisabled: 'Aus technischen Gründen können Sie Fallanmeldungen und Rechnungen momentan nicht in der App übermitteln. In der Zwischenzeit reichen Sie bitte Ihre Rechnungen einfach über Ihr Konto auf unserer Webseite ein: ',
  petRaceSearchPlaceholder: 'Suchen',
  noInsurance: '{{name}} hat noch keine Versicherung',
  noAssistance: '{{name}} hat noch keine Assistance 24/7',
};
