module.exports = {
  pet: {
    add_pet: 'Aggiungere un animale',
    add_pet_caption: 'Conosciamoci meglio',
    species: 'Animale',
    dog: 'Cane',
    cat: 'Gatto',
    gender: 'Sesso',
    male: 'Maschio',
    female: 'Femmina',
    select_pet: 'Quale animale desidera assicurare?',
    select_pet_description: 'Lo selezioni qui sotto o crei un nuovo profilo',
    portrait: 'I dati del suo animale',
    portrait_caption: '',
    name: "Nome comune dell'animale",
    name_long: "Nome comune dell'animale",
    birthdate: "Data di nascita dell'animale",
    birthdate_tip: 'Assicuriamo gli animali a partire dai tre mesi.',
    is_pure_breed: '',
    race: 'Razza',
    pet_mixed: 'Incrocio',
    pet_breed: 'Razza pura',
    pet_not_breed: 'Incrocio',
    pet_unknown: 'Sconosciuta',
    fill_two_races: '',
    fill_race: 'Entrare la razza',
    are_two_races_known:
      'Connaissez-vous les deux races principales de votre compagnon?',
    first_race: 'Prima razza',
    second_race: 'Seconda razza',
    which_pet_weight: 'Peso approssimativo in kg',
    weight: 'Peso adulto in kg',
    pet_age_calcul_info:
      'L\'età dell\'animale viene calcolata come segue: "Anno corrente" - "Anno di nascita" (ad es. 2023 - 2017 = 6)',
  },
  insurance: {
    protection: 'Assicurazione',
    protection_type: 'Copertura',
    select_protection: '',
    classic_condensed: 'Classic',
    comfort_condensed: 'Comfort',
    compact_condensed: 'Compact',
    compact: 'Animalia Compact',
    compact_description: 'Copertura in caso di infortuni',
    classic: 'Animalia Classic',
    classic_description:
      'Copertura in caso di infortuni e di malattie acute e croniche',
    comfort: 'Animalia Comfort',
    comfort_description:
      'Copertura in caso di infortuni e di malattie acute, croniche, ereditarie o congenite',
    franchise: 'Franchigia',
    insurance: 'Assicurazione',
    payment: 'Pagamento',
    select_payment: '',
    annual: 'Annuale',
    annual_cost: 'Premio annuo totale',
    biannual: 'Semestrale',
    month: 'mese',
    monthly: 'Semestrale',
    monthly_cost: 'Premio mensile indicativo',
    monthly_cost_info: 'Premio mensile indicativo',
    monthly_cost_tip: '',
    quarterly: 'Trimestrale',
    enters_in_force_at: 'Inizio del contratto',
    agent_code: 'Codice agente',
    agency: 'Codice promozionale',
    agency_tip:
      'Se ha diritto ad uno sconto, questo sarà dedotto direttamente dalla sua fattura.',
    my_details: 'Coordinate',
    needed_details: '',
    new_address: 'Aggiungere un indirizzo',
    plan_not_available:
      'Questa copertura non è disponibile con questa franchigia',
    are_you_pet_keeper:
      "Êtes-vous le détenteur de l'animal ou prenez-vous l'assurance pour une personne tierce ?",
    im_keeper: "Je suis le détenteur de l'animal",
    im_not_keeper: "Je prends une assurance pour l'animal de quelqu'un d'autre",
    refund_account: 'Conto per i rimborsi',
    no_iban: 'IBAN',
    bank_name: 'Nome della banca',
    bic: '',
    line: 'Indirizzo',
    line_2: 'Suffisso indirizzo',
    zip_code: 'NPA',
    city: 'Località',
    conclude: 'Stipulare l’assicurazione',
    insurance_of: 'L’assicurazione di',
    insurance_owner: "Preneur d'assurance",
    complete_profile: 'Compléter le profil',
    offer_page_description: 'offer_page_description Lorem Ipsum....',
    concluded_description: 'concluded_description Lorem Ipsum....',
    offer_already_concluded:
      'Questa offerta è stata conclusa nel frattempo. Non può più concludere.',
    offer_concluded_line:
      'Un cordiale benvenuto da Animalia, {{pet_name}} fa ora parte della comunità Animalia, e noi siamo al suo fianco.',
    offer_concluded_line_2:
      'Riceverà i documenti della polizza e la fattura nei prossimi giorni per posta. A quel punto, potrà notificare i sinistri e spedire le fatture per {{pet_name}} tramite il suo conto Animalia.',
    offer_concluded_line_3:
      'Per ringraziarla per la sua fiducia, siamo lieti di offrirle buoni sconto e di proporle offerte dei nostri partner per il suo benessere e quello del suo fedele compagno.',
    offer_banner_cta: 'Rejoignez la communauté Animalia',
    offer_fressnapf: 'Scaricare buono MAXI ZOO',
    offer_shop: 'Scaricare buono shop Animalia',
    assistance: 'Assistenza 24/7',
    assistance_desc_sale:
      "Bene, ora è il momento di approfittare dell'offerta del Promozione Invernale con l'assicurazione complementare Assistenza 24/7. Approfittate dell'offerta con il codice di sconto*:​",
    assistance_btn: "Scoprite l'Assistenza 24/7",
    assistance_link: 'https://shop.animalia.ch/it/product/assistance-24-7/',
    offer_fressnapf_url:
      'https://prismic-io.s3.amazonaws.com/animalia/9999fa22-1964-4296-b14a-1c0d0d37d130_22-10-019_animalia_gutschein_130x85_it_lay_v1_020222_5.pdf',
    offer_shop_url:
      'https://prismic-io.s3.amazonaws.com/animalia/5e62d2a1-8969-49d8-85cc-33ec989b406b_21_ANIMALIA_SHOP_FLYER_A7_IT_V2.pdf',
    assistance_url: 'https://www.animalia.ch/it/servizio/assistance',
    tonivet_label: "Scoprite l'offerta esclusiva di croccantini Tonivet",
    tonivet_link:
      'https://shop.animalia.ch/it/croquettes-tonivet-lexcellence-veterinaire/',
    assistance_desc:
      "Con un supplemento di 79 franchi all'anno, potrete beneficiare di un'assistenza 24 ore su 24 per rispondere a qualsiasi domanda medica, di emergenza o amministrativa sul vostro animale domestico. Inoltre, potrete beneficiare di una copertura assicurativa aggiuntiva, come ad esempio i costi di trasporto del vostro animale in caso di emergenza. Non perdete l'occasione di scoprire tutto sull'assistenza 24 ore su 24, 7 giorni su 7.",
    cga:
      'Confermo di aver preso conoscenza delle <a href="https://prismic-io.s3.amazonaws.com/animalia/f5eae0c9-f863-4cc0-9fc4-49b2db912853_CGA_Animalia_2021_I_WEB.pdf" target="_blank">Condizioni generali d\'assicurazione </a>, le accetto e capisco che i premi cambiano con l\'età del mio animale domestico.',
    cga_1:
      'Dichiaro di aver risposto in tutta sincerità alle domande precedenti per convalidare legalmente il contratto Animalia con la Vaudoise.',
    cga_2:
      'Autorizzo la Vaudoise al trattamento delle informazioni fornite nel quadro della gestione del contratto, in particolare in caso di sinistro. La autorizzo inoltre a trasmetterle se necessario ai riassicuratori o ad altri assicuratori, e a richiedere informazioni presso questi ultimi o terzi.',
    breed_tip: 'I genitori sono della stessa razza',
    bastard_tip: 'I genitori sono di due razze diverse',
    unknown_tip:
      'I genitori sono incroci di più razze oppure sono di razze che non conosce',
    federal_timber_of: 'Bollo federale 5% di',
    surprime: 'Spese pagamento rateale',
    annual_cost_basic: 'Premio annuo',
    not_complete: 'Offerta assicurativa attuale',
    waiting:
      'La sua proposta d’assicurazione è stata presentata con successo. Vedrà qui il suo numero di polizza nei prossimi giorni.',
    fill_offer: "Veda l'offerta attuale e la completi",
    login_title: 'Ha già un conto cliente Animalia?',
    login_description:
      'Per completare la sua assicurazione, deve accedere o creare un conto cliente Animalia. Questo le darà accesso alle informazioni sul suo contratto di assicurazione. Inoltre, il suo conto cliente le permetterà di inviarci in qualsiasi momento le fatture per le cure necessarie alla sua palla di pelo.',
    login_yes: 'Sì, ho già un conto cliente',
    login_no: 'No, non ho ancora un conto cliente',
    save_success:
      'La conclusione è stata trasferita con successo. Il·la cliente riceverà la polizza nei prossimi giorni.',
    num: 'No.',
    api_found: 'Indirizzo confermato da La Posta Svizzera',
    api_not_found:
      'Questo indirizzo non è registrato dalla Posta Svizzera. La preghiamo di inserire un indirizzo valido.',
    start_date: 'Inizio del contratto',
    end_date: 'Fine del contratto (rinnovo tacito di anno in anno)',
    cga_min: 'CGA',
    maintenance_title: 'Manutenzione',
    maintenance_description:
      "Noti che la conclusione online non è attualmente disponibile fino a mezzanotte circa.  Ciò è dovuto alla manutenzione di un servizio della posta utilizzato per la convalida del suo indirizzo postale. <br><br> Può ancora richiedere un'offerta online.",
    maintenance_button: "Richieda un'offerta",
    discount_hotelcard_link: 'https://hotelcard.com/it/animalia-30',
    discount_hotelcard_label: "Scoprire l'offerta esclusiva Hotelcard",
  },
  error: {
    general: 'Compilare il campo',
    min_18: 'Deve avere almeno 18 anni',
    pet_species_required: 'Selezionare un tipo di animale',
    pet_gender_required: 'Selezionare il sesso dell’animale',
    pet_name_required: 'Inserire il nome dell’animale',
    pet_birthdate_required: 'Selezionare la data di nascita dell’animale',
    pet_is_purebred_required: 'Indicare se l’animale è di razza',
    pet_two_races_known_required:
      'Inserire le due razze dell’animale (se conosciute)',
    pet_animal_id_required: 'Selezionare una razza',
    pet_weight_required:
      'Si prega di indicare il peso approssimativo in kg del vostro animale',
    pet_weight_number_required: 'Il peso deve essere un numero',
    insurance_franchise_required: 'Selezionare la franchigia desiderata',
    insurance_type_required: 'Selezionare la copertura desiderata',
    insurance_payment_frequency_required:
      'Selezionare il ritmo di pagamento desiderato',
    insurance_enters_in_force_at_required:
      'Inserire la data desiderata per l’inizio del contratto',
    title_required: 'Selezionare un appellativo',
    first_name_required: 'Inserire il nome',
    last_name_required: 'Inserire il cognome',
    line_required: 'Inserire l’indirizzo',
    zip_code_required: 'Inserire l’NPA',
    city_required: 'Inserire la località',
    birthdate_required: 'Inserire la data di nascita',
    holder_phone_required: 'Inserire il numero di telefono',
    bank_not_found:
      'La banca non è stata trovata. Si prega di compilare manualmente i campi.',
    iban_required: 'Inserire l’IBAN (p.es. CH80 00000 0000 0000 0000 0)',
    iban_invalid: 'L’IBAN non è valido (p.es. CH80 00000 0000 0000 0000 0)',
    bank_name_required: 'Inserire il nome della banca.',
    bic_required: '',
    bank_address_required: '',
    bank_zip_code_required: 'Inserire l’NPA della banca',
    bank_city_required: 'Inserire la località della banca',
    max_char_4: 'Il codice agente è composto da 4 cifre.',
    email_required: '',
    email_invalid: 'Questo campo deve essere un indirizzo e-mail valido.',
    more_char: 'Questo campo deve contenere almeno 2 caratteri.',
    uppercase: 'La prima lettera deve essere maiuscola',
    all_uppercase: 'Questo campo non può contenere solo lettere maiuscole',
    wrong_iban:
      'Questo numero IBAN non è valido. La preghiamo di correggere il numero inserito.',
    only_one_uppercase:
      'Questo campo deve contenere solo una lettera maiuscola',
  },
  navigation: {
    calculate: 'Calcolare il premio',
    submit_offer: 'Stipulare l’assicurazione',
    send_offer_by_email: 'Inviare l’offerta per e-mail',
    next: 'Seguente',
  },
};
