module.exports = {
  nextDeliveryOn: 'Prochaine livraison le {{date}}',
  date: 'Date de livraison',
  dateRecurring: 'Aimeriez-vous également adapter les livraisons suivantes ?',
  dateSelector: 'Sélectionnez la nouvelle date de livraison',
  address: 'Adresse de livraison',
  editDeliveryDateFor: 'Editer la date de livraison pour {{name}}',
  adjustAllDates: 'Oui, ajuster les dates des livraisons suivantes.',
  adjustOneDate:
    "Non, j'aimerais uniquement changer la date de cette livraison.",
  adjustAllAddresses: 'Oui, ajuster les adresses des livraisons suivantes.',
  adjustOneAddress:
    "Non, j'aimerais uniquement changer l'adresse de cette livraison.",

  status: {
    pending: 'En attente',
    planned: 'Planifiée',
    failed: 'Echec de traitement',
    cancelled: 'Annulé',
    refunded: 'Remboursé',
    standby: 'Paiement en attente',
    'payment-on-hold': 'Paiement en cours',
    'completed-paid': 'Envoyé',
    'completed-unpaid': 'Envoyé',
    'completed-refunded': 'Envoyé',
  },
  editLater: 'Indiquez ici l\'adresse de livraison. Et si vous partez au chalet ou laissez votre compagnon chez des voisins ou des amis, pas de souci&nbsp;: vous pourrez changer l\'adresse de livraison en tout temps via votre compte client, même pour une seule livraison. Pratique, non ?',
  paymentMessage: 'Vous êtes à un dernier clic de réjouir votre boule de poils qui trépigne sûrement déjà d\'impatience. <br><br>Pas d\'inquiétude ! Vous pouvez à tout moment résilier votre abonnement par un simple clic dans votre compte client ou via notre service client. <br><br>Il vous est possible de nous contacter pour connaitre les moyens de paiement ou pour vous aider lors de votre paiement'
};