module.exports = {
  privacyPolicy: 'https://www.animalia.ch/de/datenschutzbestimmungen',
  cgv: 'https://www.animalia.ch/de/allgemeine-geschaftsbedingungen-agb',
  cgu: 'https://www.animalia.ch/de/allgemeine-nutzungsbedingungen-anb',
  insurance: {
    changeOwner:
      'https://assurance.animalia.ch/_files/content/pdf/tierhalterwechsel_de.pdf',
    changeBank:
      'https://assurance.animalia.ch/de/kontakt/uebermittlung-von-bank-oder-postverbindungen',
    sinister: 'https://assurance.animalia.ch/de/kontakt/schadenfallmeldung',
    changeAddress:
      'https://assurance.animalia.ch/de/kontakt/adress-oder-namensaenderung',
  },
  contact: 'https://www.animalia.ch/de/kontakt'
};
