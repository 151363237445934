import ATI57 from './apple-touch-icon-57x57.png'
import ATI72 from './apple-touch-icon-72x72.png'
import ATI76 from './apple-touch-icon-76x76.png'
import ATI114 from './apple-touch-icon-114x114.png'
import ATI120 from './apple-touch-icon-120x120.png'
import ATI144 from './apple-touch-icon-144x144.png'
import ATI152 from './apple-touch-icon-152x152.png'
import ATI180 from './apple-touch-icon-180x180.png'
import ATI from './apple-touch-icon.png'
import ATISVG from './apple-touch-icon.svg'

const maskIcon = { img: ATISVG }

const appleTouchIcons = [
  {
    img: ATI,
  },
  {
    sizes: "57x57",
    img: ATI57,
  },
  {
    sizes: "72x72",
    img: ATI72,
  },
  {
    sizes: "76x76",
    img: ATI76,
  },
  {
    sizes: "114x114",
    img: ATI114,
  },
  {
    sizes: "120x120",
    img: ATI120,
  },
  {
    sizes: "144x144",
    img: ATI144,
  },
  {
    sizes: "152x152",
    img: ATI152,
  },
  {
    sizes: "180x180",
    img: ATI180,
  }
]

export {
  appleTouchIcons,
  maskIcon
}