import React from 'react';
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {appleTouchIcons, maskIcon} from './icons';

const MetaProvider = ({children}) => {
  return (
    <HelmetProvider>
      <Helmet>
        {appleTouchIcons.map((icon, index) =>
          icon.sizes ? (
            <link
              key={`appleicon_${index}`}
              rel="apple-touch-icon"
              href={icon.img}
              sizes={icon.sizes}
            />
          ) : (
            <link
              key={`appleicon_${index}`}
              rel="apple-touch-icon"
              href={icon.img}
            />
          )
        )}
        <link rel="mask-icon" href={maskIcon.img} />

        {/* OneTrust */}
        {/* <script
          type="text/javascript"
          src="https://cdn.cookielaw.org/consent/59d39534-ce4f-4793-882e-23ef9907100d/OtAutoBlock.js"></script> */}
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="59d39534-ce4f-4793-882e-23ef9907100d"></script>
        <script type="text/javascript">
          {`function OptanonWrapper() { }`}
        </script>
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default MetaProvider;
