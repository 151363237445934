import React from 'react'
import styles from './WidgetTightButton.module.sass'
import { Button } from 'grommet'
import { Spinner } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const WidgetTightButton = ({ label, isLoading, fixedWidth, disabled, ...rest }) => {

  const labelContent = () => isLoading
    ? <Spinner 
        animation='border' 
        variant='light' 
        size='sm' 
      />
    : label
  
  const buttonStyle = fixedWidth 
    && { width: '200px' }

  return (
    <div className={styles.container}>
      <Button
        size='small'
        style={buttonStyle}
        label={labelContent()}
        disabled={disabled || isLoading}
        { ...rest }
      />
    </div>
  )
}

export default WidgetTightButton
