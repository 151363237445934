module.exports = {
  or: 'ou',
  add: 'Ajouter',
  save: 'Enregistrer',
  send: 'Envoyer',
  edit: 'Modifier',
  delete: 'Supprimer',
  previous: 'Précédent',
  next: 'Suivant',
  iAcceptCGU: "J'accepte les",
  CGV: 'Conditions générales de vente (CGV)',
  CGU: "Conditions générales d'utilisation (CGU)",
  privacy_policy: 'Politique de confidentialité',
  missingData: 'Donnée manquante',
  readmore: 'En savoir plus',
  yes: 'Oui',
  no: 'Non',
  close: 'Fermer',
  back: 'Retour',
  backToAnimals: 'Retour à "Mes Animaux"',
  draft: 'Pas encore enregistré',
  calculating: 'Calcul en cours',
  allfeesincluded: 'Taxes et frais de port inclus',
  finished: 'Terminé',
  printOrder: 'Imprimer la commande',
  deliveries: 'Livraisons',
  plannedDeliveries: 'Prochaines livraisons',
  previousDeliveries: 'Livraisons passées',
  batchID: 'Numéro de commande',
  status: 'Statut',
  verify: 'Vérifier',
  year: 'Année',
  month: 'Mois',
  day: 'Jour',
  months: {
    1: 'Janvier',
    2: 'Février',
    3: 'Mars',
    4: 'Avril',
    5: 'Mai',
    6: 'Juin',
    7: 'Juillet',
    8: 'Août',
    9: 'Septembre',
    10: 'Octobre',
    11: 'Novembre',
    12: 'Décembre',
  },
  immediately: 'Immédiatement',
  informations: 'Informations',
  aboGourmand: 'Abo Gourmand',
  theAboGourmand: "L'Abo Gourmand",
  theInsurance: "L'Assurance",
  administrative: 'Modification administrative',
  missingAddress: "Il n'y a pas d'adresse enregistrée.",
  missingAddressFor: "Il n'y a pas d'adresse enregistrée pour {{name}}.",
  missingCard: "Il n'y a pas de moyen de paiement enregistré.",
  fix: 'Corriger le problème',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  fillIn: 'Renseigner',
  wantToKnowMore: 'Vous souhaitez en savoir plus ?',
  moreDetails: 'Plus de détails',
  date: 'Date',
  service: 'Service',
  noResults: 'Pas de résultats',
  required: 'Champ obligatoire',
  your_comment: 'Remarques',

  cardIsFailing: 'En échec',
  twintAccount: 'Compte Twint',
  powerpayAccount: 'Paiement par facture (PowerPay)',

  oldbrowser: {
    title: "Oups ! Votre navigateur Web n'est pas à jour.",
    text:
      "Pour plus de sécurité et une meilleure expérience sur notre site, nous vous recommandons de mettre à jour votre navigateur ou d'en utiliser un autre.",
    button: 'Je mets à jour mon navigateur',
  },
  weDidNotReceiveYourPayment:
    "Nous n'avons pas reçu votre paiement pour cette commande.",
  contactUs: 'Contact',
  powerpay: {
    title: 'Vérifiez vos informations de paiement',
    subtitle:
      'Vous recevrez la facture gratuitement par e-mail. Avec l\'achat sur compte, vous acceptez les <a href="https://www.powerpay.ch/fr/agb" target="_blank">conditions</a> suivantes de POWERPAY (powerpay.ch/fr/agb), à condition d\'avoir une adresse e-mail valide.',
    next: 'Payer',
    cancel: 'Annuler',
  },
  other: 'Autre',
};
