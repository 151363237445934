import React from 'react'
import { RadioButtonGroup } from 'grommet'
import styles from './WidgetTightRadioButtonGroup.module.sass'
import classNames from 'classnames'

const valueKeyInitialConfig = { 
  key: 'value', 
  reduce: true 
}

const WidgetTightRadioButtonGroup = ({ valueKey, grid, double, mobileDirection, gridDouble, ...rest }) => {
  const gap = 'small'
  const valueKeyConfig = valueKey ? valueKey : valueKeyInitialConfig
  const getClasses = () => {
    const classes = [
      styles.container,
      grid && !double && styles.grid,
      grid && double && styles.gridDouble,
      mobileDirection == 'column' && styles.gridColumn,
      gridDouble && styles.gridDoubleMobile,
    ].filter(Boolean)
    return classNames(classes)
  }
  return (
    <div className={getClasses()}>
      <RadioButtonGroup 
        { ...rest } 
        valueKey={valueKeyConfig}
        gap={gap}
      />
    </div>
  )
}

export default WidgetTightRadioButtonGroup