import { setActiveStep, setInsuranceValues, selectClassicPrice } from './calculatorContext'
import { insuranceValues } from '@animalia/assets'
import { createOffer } from '@animalia/services'
import { matchKey } from './utils'

// calculator view 
export const hasCodeAgent = form => !!form.agent_code

// navigation

// if first step is active, return boolean
export const isFirst = activeStep => {
  return activeStep === 'widget-step-1'
}

export const isFormSend = activeStep => {
  return activeStep === 'offer-sent'
}

// go to previous step 
export const prevStep = (dispatch, steps, activeStep) => {
  if (isFirst(activeStep)) return false
  const activeStepIndex = steps.findIndex(item => item.ref === activeStep)
  const prevStepIndex = activeStepIndex - 1
  const prevStepRef = steps[prevStepIndex].ref
  setActiveStep(dispatch, prevStepRef) 
}

// check form, and go to insurance step 
export const insuranceStep = async (dispatch, form) =>  {
  // calculatorSchema
  setActiveStep(dispatch, 'widget-step-2')
}

export const getInsuranceValues = async (dispatch, form) => {
  const filteredInsuranceValues = 
    insuranceValues
      .filter(matchKey(form, 'pet_species'))
      .filter(matchKey(form, 'pet_age_category'))
  setInsuranceValues(dispatch, filteredInsuranceValues)
}

// check form, and go to client step 
export const clientStep = async (dispatch, form) => {
  setActiveStep(dispatch, 'widget-step-3-guest')
  /* next slide when useEffect(() => {}, [selectedInsuranceValues]) catch changes */
}

export const raceStep = async (dispatch, form) => {
  setActiveStep(dispatch, 'widget-step-1-2')
}

// on form submit 
export const submitForm = async ({
  dispatch, 
  data, 
  closeModalAndGoTo, 
  petId, 
  goToOfferSent, 
  language, 
  pet, 
  user, 
  setIsLoading,
  setOfferQuid,
  step
}) => {

  const quid = await createOffer({
    form: data,
    petId: petId, 
    language: language, 
    pet: pet, 
    user: user, 
    newStep: step
  })
  
  setIsLoading(false)

  if (quid) {
    if (setOfferQuid) {
      setOfferQuid(quid)
    }

    if (closeModalAndGoTo) {
      // if user conclude insurance directly form myaccount
      closeModalAndGoTo(quid, petId)
    } else if (data?.value?.step === 'widget-step-2' && !goToOfferSent) {
      // if user conclude insurance
      if ( window.location !== window.parent.location ) {
        // The page is in an iFrames
        window.parent.location.href = `${process.env.REACT_APP_MY_ACCOUNT_URL}/public/redirect/${quid}?lang=${language}`
      } 
      else { 
        // The page is not in an iFrame
        window.location.href = `${process.env.REACT_APP_MY_ACCOUNT_URL}/public/redirect/${quid}?lang=${language}`
      }
    } else {
      // if user want to get offer by mail 
      setActiveStep(dispatch, 'offer-sent')
    }
  }

}

export const onDeductibleChange = (dispatch, form) => event => {
  const value = parseInt(event.target.value)
  const isDeductibleNot500 = value !== 50000
  const isBasicOffer = form.insurance_type === 'compact'

  // to avoid the disabled plan to be selected 
  if (isDeductibleNot500 && isBasicOffer) {
    selectClassicPrice(dispatch, value)
  }
}

export const getAgeCategoryLabel = key => {
  switch (key) {
    case '2-':
      return 'calculator.yearsRange.u2'
    case '3-4':
      return 'calculator.yearsRange.u4'
    case '5-6':
      return 'calculator.yearsRange.u6'
    case '7-8':
      return 'calculator.yearsRange.u8'
    case '9-10':
      return 'calculator.yearsRange.u10'
    case '11-12':
      return 'calculator.yearsRange.u12'
    case '13+':
      return 'calculator.yearsRange.o13'
    default:
      break;
  }
}