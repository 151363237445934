import Pet from './pet.js';
import Order from './order.js';
import Delivery from './delivery.js';

function User(pets, i18n, t) {
  let self = {
    petsList: pets,

    /**
     * Checks that the user has completed his mandatory profile
     */
    hasCompleteProfile: () => {},

    /**
     * Get all user pet's
     */
    pets: () => self.petsList.map(pet => Pet(pet, 18n, t)),

    /**
     * Get all user orders
     */
    orders: () =>
      self.petsList.reduce((acc, pet) => {
        const petOrders = pet.orders.map(o => Order(o));
        acc.push(...petOrders);
        return acc;
      }, []),

    /**
     * Get all user deliveries
     */
    deliveries: () =>
      self.orders().reduce((acc, order) => {
        const deliveries = order.deliveries.map(d => Delivery(d));
        acc.push(...deliveries);
        return acc;
      }, []),

    /**
     * Get active orders
     */
    activeOrders: () => self.orders().filter(order => order.isActive()),

    /**
     * Get planned deliveries
     */
    plannedDeliveries: () =>
      self.deliveries().filter(delivery => delivery.isPlanned()),

    canDeleteAccount: () => {
      return (
        self.activeOrders().length === 0 &&
        self.plannedDeliveries().length === 0
      );
    },
  };

  return self;
}

export default User;
