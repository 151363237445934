module.exports = {
  myAccount: 'Il mio conto',
  editMyProfile: 'Modificare il mio profilo',
  title: 'Titolo *',
  firstName: 'Nome *',
  lastName: 'Cognome *',
  firstAndLastName: 'Nome & Cognome *',
  email: 'E-mail *',
  phone: 'Telefono',
  home_phone: 'Telefono fisso',
  mobile_phone: 'Cellulare',
  birthdate: 'Data di nascita',
  subscribeToMailchimp:
    "Desidero ricevere le newsletter Animalia (consigli, concorsi, offerte, novità ecc.)",
  subscribeToMailchimpInfo:
    "Abbonandosi, l’utente accetta la politica di confidenzialità e il trasferimento di dati a un sistema esterno per l’invio della newsletter Animalia.",
  mrs: 'Signora',
  mr: 'Signor',
  locale: 'Lingua di comunicazione *',
  myPets: 'I miei animali',
  seeMyPets: 'Vedere i miei animali',
  myInsurance: 'La mia assicurazione',
  declareSinister: 'Annunciare un sinistro',
  sendInvoice: 'Inviare una fattura',
  editInsurance: 'Modificare la mia assicurazione',
  myAboGourmand: 'Il mio Abbonamento Gourmet',
  editMyAboGourmand: 'Gestire il mio Abbonamento Gourmet',
  editMyData: 'Gestire i miei dati',
  myInvoices: 'Le mie fatture Abbonamento Gourmet',
  myInvoicesText: "Dare un'occhiata alle mie ultime transazioni",
  myCards: 'Le mie modalità di pagamento Abbonamento Gourmet',
  myCardsText: 'In che modo desiderate pagare ?',
  myAddresses: 'I miei inidirizzi di spedizione Abbonamento Gourmet',
  myAddressesText: 'Cognome, indirizzo & co.',
  modalEditMyAddressTitle: "Cambiare l'indirizzo di consegna",
  logout: 'Disconnettersi',
  delete: {
    button: 'Cancellare il mio account Animalia',
    title: 'Cancellare il mio account Animalia',
    content:
      'Se cancella il suo conto Animalia, tutti i dati associati (i suoi dati e quelli dei suoi animali) saranno cancellati. Non possiamo recuperarli in seguito e dovrà creare un nuovo account.  È sicuro di volerlo fare?',
    confirm: 'Sì, cancello i miei dati',
    cancel: 'No, sto mantenendo il mio account',
  },
};
