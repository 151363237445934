import moment from 'moment';

const gaData = [
  {
    name: 'Insurance conclusion form',
    slug: 'offer-screen',
    steps: [
      {
        id: '1.b',
        slug: 'type',
        name: 'Pet type',
      },
      {
        id: '2.b',
        slug: 'portrait',
        name: 'Pet portrait',
      },
      {
        id: '3.b',
        slug: 'races',
        name: 'Pet race',
      },
      {
        id: '4.b',
        slug: 'insurance-type',
        name: 'Insurance selection',
      },
      {
        id: '5.b',
        slug: 'payment-type',
        name: 'Payment type',
      },
      {
        id: '6.b',
        slug: 'contact-details',
        name: 'Contact details',
      },
      {
        id: '7.b',
        slug: 'contract-step-summary',
        name: 'Contract summary',
      },
      {
        id: '8.b',
        slug: 'offer-concluded',
        name: 'Insurance concluded',
      },
    ],
  },
  {
    name: 'Abo Gourmand conclusion form from myaccount',
    slug: 'ag-conclusion-myaccount',
    steps: [
      {
        id: '1.c.2',
        slug: 'food-type',
        name: 'Food type',
      },
      {
        id: '2.c.2',
        slug: 'product',
        name: 'Product choice',
      },
      {
        id: '3.c.2',
        slug: 'quantity',
        name: 'Quantity',
      },
      {
        id: '4.c.2',
        slug: 'summary',
        name: 'Summary',
      },
      {
        id: '5.c.2',
        slug: 'address',
        name: 'Address',
      },
      {
        id: '6.c.2',
        slug: 'cards',
        name: 'Payment Type',
      },
      {
        id: '7.c.2',
        slug: 'thankyou',
        name: 'Food Abo concluded',
      },
    ],
  },
  {
    name: 'Abo Gourmand connexion form 1',
    slug: 'ag-connexion-1',
    steps: [
      {
        id: '1.g',
        slug: 'login',
        name: 'Customer account connexion',
      },
    ],
  },
  {
    name: 'Abo Gourmand connexion 2',
    slug: 'ag-connexion-2',
    steps: [
      {
        id: '1.g.2',
        slug: 'signup',
        name: 'Customer account creation',
      },
      {
        id: '2.g.2',
        slug: 'personal',
        name: 'Customer account personal data',
      },
      {
        id: '3.g.2',
        slug: 'confirmation',
        name: 'Account control code',
      },
    ],
  },
  {
    name: 'Accident form',
    slug: 'accident',
    steps: [
      {
        id: '1.e.2',
        slug: 'animal-select',
        name: 'Selection animal',
      },
      {
        id: '1.f',
        slug: 'type',
        name: 'Disaster type',
      },
      {
        id: '2.f',
        slug: 'disaster_title',
        name: 'Disaster title',
      },
      {
        id: '3.f',
        slug: 'accident_diagnosis',
        name: 'Accident diagnosis',
      },
      {
        id: '4.f',
        slug: 'accident_treatment',
        name: 'Accident treatment',
      },
      {
        id: '5.f',
        slug: 'accident_date',
        name: 'Accident date',
      },
      {
        id: '6.f',
        slug: 'how_accident_happened',
        name: 'Accident story',
      },
      {
        id: '7.f',
        slug: 'any_third_party',
        name: 'Accident third party',
      },
      {
        id: '1.f.2',
        slug: 'who_is_the_third_party',
        name: 'Personal data third party',
      },
      {
        id: '1.f.4',
        slug: 'comments_on_disaster',
        name: 'Disaster comments',
      },
      {
        id: '1.f.5',
        slug: 'summary',
        name: 'Disaster summary',
      },
      {
        id: '1.g',
        slug: 'intro',
        name: 'Sinister type',
        formName: 'Add Invoice new sinister',
      },
      {
        id: '13.e.2',
        slug: 'documents',
        name: 'Pet folder',
      },
      {
        id: '1.g.2',
        name: 'Existing disaster',
        slug: 'existing_disaster',
        formName: 'Add invoice existing sinister from myaccount',
      },
    ],
  },
  {
    name: 'Illness form',
    slug: 'illness',
    steps: [
      {
        id: '1.e.2',
        slug: 'animal-select',
        name: 'Selection animal',
      },
      {
        id: '2.e.2',
        slug: 'type',
        name: 'Disaster type',
      },
      {
        id: '3.e.2',
        slug: 'disaster_title',
        name: 'Disaster title',
      },
      {
        id: '4.e.2',
        slug: 'health_diagnosis',
        name: 'Health diagnosis',
      },
      {
        id: '5.e.2',
        slug: 'health_treatment',
        name: 'Health treatment',
      },
      {
        id: '6.e.2',
        slug: 'comments_on_disaster',
        name: 'Illness form comments',
      },
      {
        id: '7.e.2',
        slug: 'summary',
        name: 'Illness form summary',
      },
      {
        id: '8.e.2',
        slug: 'intro',
        name: 'Selection action',
      },
      {
        id: '9.e.2',
        slug: 'documents',
        name: 'Pet folder',
      },
    ],
  },
  {
    name: 'Add Invoice new sinister',
    slug: 'add-invoice-new-sinister',
    steps: [
      {
        id: '1.e.2',
        name: 'Selection animal',
        slug: 'animal-select',
      },
      {
        id: '2.e.2',
        name: 'Sinister type',
        slug: 'intro',
      },
      {
        id: '3.e.2',
        name: 'Disaster type',
        slug: 'disaster_title',
      },
    ],
  },
  {
    name: 'End add invoice',
    slug: 'end-add-invoice',
    steps: [
      {
        id: '2.g.5',
        name: 'Invoice detail',
        slug: 'details',
      },
      {
        id: '1.g.7',
        name: 'Invoice year',
        slug: 'year',
      },
      {
        id: '2.g.7',
        name: 'Invoice summary',
        slug: 'summary',
      },
      {
        id: '3.g.7',
        name: 'Invoice sending information',
        slug: 'confirmation',
      },
    ],
  },
  {
    name: 'Add invoice existing sinister',
    slug: 'add-invoice-existing-sinister',
    steps: [
      {
        id: '1.e.2',
        name: 'Selection animal',
        slug: 'animal-select',
      },
      {
        id: '2.e.2',
        name: 'Sinister type',
        slug: 'intro',
      },
      {
        id: '3.e.2',
        name: 'Exisiting disaster',
        slug: 'existing_disaster',
      },
      {
        id: '4.e.2',
        name: 'Existing sinister form summary',
        slug: 'summary',
      },
      {
        id: '5.e.2',
        name: 'Selection action',
        slug: 'intro',
      },
    ],
  },
  {
    name: 'Add existing sinister',
    slug: 'add-existing-sinister',
    steps: [
      {
        id: '1.e.2',
        name: 'Selection animal',
        slug: 'animal-select',
      },
      {
        id: '2.e.2',
        name: 'Sinister type',
        slug: 'intro',
      },
      {
        id: '3.e.2',
        name: 'Existing disaster',
        slug: 'existing_disaster',
      },
      {
        id: '4.e.2',
        name: 'Exisiting disaster title',
        slug: 'existing_disaster_title',
      },
      {
        id: '5.e.2',
        name: 'Add existing sinister',
        slug: 'intro',
      },
    ],
  },
  {
    name: 'Insurance Connexion form 1',
    slug: 'insurance-connexion-form-1',
    steps: [
      {
        id: '1.d',
        name: 'Animal select',
        slug: 'animal-select',
      },
    ],
  },
  {
    name: 'Insurance Connexion form 2',
    slug: 'insurance-connexion-form-2',
    steps: [
      {
        id: '1.d.2',
        name: 'Customer account available',
        slug: 'validate',
      },
      {
        id: '2.d.2',
        name: 'Customer account not available',
        slug: 'validate',
      },
      {
        id: '1.d',
        name: 'Animal select',
        slug: 'animal-select',
      },
      {
        id: '1.e.2',
        name: 'Customer account available',
        slug: 'already-account',
      },
    ],
  },
  {
    name: 'Insurance Connexion form 3',
    slug: 'insurance-connexion-form-3',
    steps: [
      {
        id: '1.e.3',
        name: 'Customer account connexion',
        slug: 'login',
      },
    ],
  },
  {
    name: 'Insurance Connexion form 4',
    slug: 'insurance-connexion-form-4',
    steps: [
      {
        id: '1.e.4',
        name: 'Customer account creation',
        slug: 'signup',
      },
      {
        id: '2.e.4',
        name: 'Customer account personal data',
        slug: 'personal',
      },
      {
        id: '3.e.4',
        name: 'Account control code',
        slug: 'confirmation',
      },
    ],
  },
  {
    name: 'Request offer',
    slug: 'request-offer',
    steps: [
      {
        id: '1.a',
        name: 'Pet info',
        slug: 'widget-step-1',
      },
      {
        id: '2.a',
        name: 'Insurance type',
        slug: 'widget-step-2',
      },
      {
        id: '3.a',
        name: 'Personal info',
        slug: 'widget-step-3',
      },
      {
        id: '3.a',
        name: 'Personal info',
        slug: 'widget-step-3-guest',
      },
      {
        id: '4.a',
        name: 'Request offer confirmation',
        slug: 'offer-sent',
      },
    ],
  },
  // ready items
  {
    name: 'Insurance Request',
    slug: 'start-widget',
    steps: [
      {
        id: 1,
        name: 'animal-step',
        slug: 'widget-step-1',
      },
      {
        id: 2,
        name: 'insurance-step',
        slug: 'widget-step-2',
      },
      {
        id: 3,
        name: 'client-step',
        slug: 'widget-step-3-guest',
      },
      {
        id: 4,
        name: 'offer-sent',
        slug: 'offer-sent',
      },
    ],
  },
  {
    name: 'Start Widget from my account',
    slug: 'start-widget-my-account',
    steps: [
      {
        id: '1.a.2',
        name: 'Pet info',
        slug: 'widget-step-1',
      },
      {
        id: '2.a.2',
        name: 'Insurance type',
        slug: 'widget-step-2',
      },
      {
        id: '1.b.2',
        name: 'Insurance type',
        formName: 'Request insurance offer from MyAccount',
        slug: 'offer-sent',
      },
    ],
  },
  {
    name: 'Insurance indirect conclusion',
    slug: 'insurance-indirect-conclusion',
    steps: [
      {
        id: '1.e',
        name: 'Animal-select',
        slug: 'animal-select',
      },
      {
        id: '2.c',
        name: 'Pet type',
        slug: 'type',
      },
      {
        id: '3.c',
        name: 'Pet portrait',
        slug: 'portrait',
      },
      {
        id: '4.c',
        name: 'Pet race',
        slug: 'races',
      },
      {
        id: '5.c',
        name: 'Insurance selection',
        slug: 'insurance-type',
      },
      {
        id: '6.c',
        name: 'Payment type',
        slug: 'payment-type',
      },
      {
        id: '7.c',
        name: 'Contact details',
        slug: 'contact-details',
      },
      {
        id: '8.c',
        name: 'Contract summary',
        slug: 'contract-step-summary',
      },
      {
        id: '9.c',
        name: 'Insurance concluded',
        slug: 'offer-concluded',
      },
    ],
  },
  {
    name: 'Insurance Subscription',
    slug: 'insurance-subscription',
    steps: [
      {
        id: 1,
        name: 'animal-steps-type',
        slug: 'type',
      },
      {
        id: 2,
        name: 'animal-steps-portrait',
        slug: 'portrait',
      },
      {
        id: 3,
        name: 'animal-steps-races',
        slug: 'races',
      },
      {
        id: 4,
        name: 'insurance-steps-type',
        slug: 'insurance-type',
      },
      {
        id: 5,
        name: 'insurance-steps-payment-type',
        slug: 'payment-type',
      },
      {
        id: 6,
        name: 'insurance-steps-contact-details',
        slug: 'contact-details',
      },
      {
        id: 7,
        name: 'insurance-steps-contract-step-summary',
        slug: 'contract-step-summary',
      },
      {
        id: 8,
        name: 'offer-concluded',
        slug: 'offer-concluded',
      },
    ],
  },
  {
    name: 'Insurance indirect conclusion myaccount',
    slug: 'insurance-indirect-conclusion-myaccount',
    steps: [
      {
        id: '1.c',
        name: 'Offer',
        formName: 'Insurance indirect conclusion',
        slug: 'public-offer',
      },
      {
        id: '2.c',
        name: 'Pet type',
        slug: 'type',
      },
      {
        id: '3.c',
        name: 'Pet portrait',
        slug: 'portrait',
      },
      {
        id: '4.c',
        name: 'Pet race',
        slug: 'races',
      },
      {
        id: '5.c',
        name: 'Insurance selection',
        slug: 'insurance-type',
      },
      {
        id: '6.c',
        name: 'Payment type',
        slug: 'payment-type',
      },
      {
        id: '7.c',
        name: 'Contact details',
        slug: 'contact-details',
      },
      {
        id: '8.c',
        name: 'Contract summary',
        slug: 'contract-step-summary',
      },
      {
        id: '9.c',
        name: 'Insurance concluded',
        slug: 'offer-concluded',
      },
    ],
  },
  {
    name: 'Abo Gourmand conclusion',
    slug: 'abo-gourmand-conclusion',
    steps: [
      {
        id: '1.f',
        name: 'Pet type',
        slug: 'type',
      },
      {
        id: '2.f',
        name: 'Pet portrait',
        slug: 'portrait',
      },
      {
        id: '3.f',
        name: 'Pet race',
        slug: 'races',
      },
      {
        id: '4.f',
        name: 'Pet activity',
        slug: 'activity',
      },
      {
        id: '5.f',
        name: 'Pet sterilisation',
        slug: 'sterilized',
      },
      {
        id: '6.f',
        name: 'Food type',
        slug: 'food-type',
      },
      {
        id: '7.f',
        name: 'Product choice',
        slug: 'product',
      },
      {
        id: '8.f',
        name: 'Quantity',
        slug: 'quantity',
      },
      {
        id: '9.f',
        name: 'Summary',
        slug: 'summary',
      },
      {
        id: '10.f',
        name: 'Address',
        slug: 'address',
      },
      {
        id: '11.f',
        name: 'Payment type',
        slug: 'cards',
      },
      {
        id: '12.f',
        name: 'Abo gourmand conclusion',
        slug: 'thankyou',
      },
    ],
  },
  {
    name: 'Abo Gourmand conclusion myaccount',
    slug: 'abo-gourmand-conclusion-myaccount',
    steps: [
      {
        id: '1.f.2',
        name: 'Food type',
        slug: 'food-type',
      },
      {
        id: '2.f.2',
        name: 'Product choice',
        slug: 'product',
      },
      {
        id: '3.f.2',
        name: 'Quantity',
        slug: 'quantity',
      },
      {
        id: '4.f.2',
        name: 'Summary',
        slug: 'summary',
      },
      {
        id: '5.f.2',
        name: 'Address',
        slug: 'address',
      },
      {
        id: '6.f.2',
        name: 'Payment type',
        slug: 'cards',
      },
      {
        id: '7.f.2',
        name: 'Abo gourmand conclusion',
        slug: 'thankyou',
      },
    ],
  },
  {
    name: 'Abo Gourmand connexion 1',
    slug: 'abo-gourmand-connexion-1',
    steps: [
      {
        id: '1.g',
        name: 'Customer account connexion',
        slug: 'login',
      },
    ],
  },
  {
    name: 'Abo Gourmand connexion 2',
    slug: 'abo-gourmand-connexion-2',
    steps: [
      {
        id: '1.g.2',
        name: 'Customer account creation',
        slug: 'signup',
      },
      {
        id: '2.g.2',
        name: 'Customer account personal data',
        slug: 'personal',
      },
      {
        id: '3.g.2',
        name: 'Account control code',
        slug: 'confirmation',
      },
    ],
  },
  {
    name: 'Account creation',
    slug: 'account-creation',
    steps: [
      {
        id: '1.h',
        name: 'Customer account creation',
        slug: 'signup',
      },
      {
        id: '2.h',
        name: 'Customer account personal data',
        slug: 'personal',
      },
      {
        id: '3.h',
        name: 'Account control code',
        slug: 'confirmation',
      },
      {
        id: '4.h',
        name: 'Account created',
        slug: 'account-created',
      },
    ],
  },
  {
    name: 'Add a pet',
    slug: 'add-pet',
    steps: [
      {
        id: '1.h.2',
        name: 'Pet type',
        slug: 'type',
      },
      {
        id: '2.h.2',
        name: 'Pet portrait',
        slug: 'portrait',
      },
      {
        id: '3.h.2',
        name: 'Pet breed',
        slug: 'races',
      },
      {
        id: '4.h.2',
        name: 'Pet activity',
        slug: 'activity',
      },
      {
        id: '5.h.2',
        name: 'Pet sterilisation',
        slug: 'sterilized',
      },
      {
        id: '6.h.2',
        name: 'Pet summary',
        slug: 'summary',
      },
    ],
  },
  {
    name: 'Edit a pet',
    slug: 'edit-pet',
    steps: [
      {
        id: '1.h.3',
        name: 'Pet type',
        slug: 'type',
      },
      {
        id: '2.h.3',
        name: 'Pet portrait',
        slug: 'portrait',
      },
      {
        id: '3.h.3',
        name: 'Pet breed',
        slug: 'races',
      },
      {
        id: '4.h.3',
        name: 'Pet activity',
        slug: 'activity',
      },
      {
        id: '5.h.3',
        name: 'Pet sterilization',
        slug: 'sterilized',
      },
      {
        id: '6.h.3',
        name: 'Pet summary',
        slug: 'summary',
      },
    ],
  },
  {
    name: 'Add medical file',
    slug: 'add-medical-file',
    steps: [
      {
        id: '1.g.3',
        name: 'Exisiting disaster title',
        slug: 'intro',
        formName: 'Add invoice existing sinister outside myaccount',
      },
      {
        id: '1.h.1',
        name: 'Add medical file',
        slug: 'add_files',
      },
      {
        id: '2.h.1',
        name: 'Add medical file title',
        slug: 'title',
      },
      {
        id: '3.h.1',
        name: 'Medical file summary',
        slug: 'summary',
      },
    ],
  },
  {
    name: 'Add new invoice',
    slug: 'add-new-invoice',
    steps: [
      {
        id: '1.g.4',
        name: 'Selection invoice type',
        slug: 'intro',
        formName: 'Add invoice vaccines/prevention',
      },
      {
        id: '1.g.5',
        name: 'Invoice add files',
        slug: 'add_files',
      },
      {
        id: '1.g.6',
        name: 'Invoice selection',
        slug: 'existing',
      },
      {
        id: '2.g.5',
        name: 'Invoice detail',
        slug: 'details',
      },
      {
        id: '1.g.7',
        name: 'Invoice year',
        slug: 'year',
      },
      {
        id: '2.g.7',
        name: 'Invoice summary',
        slug: 'summary',
      },
      {
        id: '3.g.7',
        name: 'Invoice sending confimation',
        formName: 'End add invoice',
        slug: 'confirmation',
      },
    ],
  },
];

export const getGaData = (id, step, key = 'id') => {
  return gaData?.find(i => i.slug === id)?.steps?.find(i => i.slug === step)?.[
    key
  ];
};

export const getGaDataId = (id, step) => {
  return gaData?.find(i => i.slug === id)?.steps?.find(i => i.slug === step)
    ?.id;
};

export const getGaDataName = (id, step) => {
  return gaData?.find(i => i.slug === id)?.steps?.find(i => i.slug === step)
    ?.name;
};

export const getGaDataFormName = (id, step) => {
  if (step) {
    const formName = gaData
      ?.find(i => i.slug === id)
      ?.steps?.find(i => i.slug === step)?.formName;

    if (formName) {
      return formName;
    }
  }

  return gaData?.find(i => i.slug === id)?.name;
};

export const getGaDataPetAgeWidget = value => {
  switch (value) {
    case '2-':
      return '3 months to 2 years';
    case '3-4':
      return '3 years to 4 years';
    case '5-6':
      return '5 years to 6 years';
    case '7-8':
      return '7 years to 8 years';
    case '9-10':
      return '9 years to 10 years';
    case '11-12':
      return '11 years to 12 years';
    case '13+':
      return '13 years and more';
    default:
      return null;
  }
};

export const getGaDataPetAge = birthdate => {
  const ages = [
    '3 months to 2 years',
    '3 years to 4 years',
    '5 years to 6 years',
    '7 years to 8 years',
    '9 years to 10 years',
    '11 years to 12 years',
    '13 years and more',
  ];
  // return the correct age range, depending on the birthdate. If the birthdate is not valid, return null
  const age = !!birthdate && moment().diff(moment(birthdate), 'years');
  if (age == 0 && typeof age == 'number') return ages[0];
  if (!age) return '';
  if (age < 3) return ages[0];
  if (age < 5) return ages[1];
  if (age < 7) return ages[2];
  if (age < 9) return ages[3];
  if (age < 11) return ages[4];
  if (age < 13) return ages[5];
  if (age >= 13) return ages[6];
  return '';
};

export const getGaDataFranchise = value => {
  if (value == 250 || value == 500 || value == 1000) return value;
  return value / 100;
};
