import React, { useEffect, useContext } from 'react'
import { WidgetTightButton, WidgetTightFormField, WidgetTightOfferFieldContainer, WidgetTightAddressSingle, Caption } from '@animalia/components'
import { CalculatorContext } from '@animalia/contexts'
import { setEmailOffer } from '@animalia/actions'
import { useTranslation } from 'react-i18next'
import { Box, TextInput } from 'grommet'
import styles from './InsuranceStep.module.sass'

const InsuranceStep = ({ formValue, error, goToPrevStep, isLoading }) => {
  const { dispatch } = useContext(CalculatorContext)
  const { t } = useTranslation()

  // labels 
  const labels = {
    petName: t('offer.pet.name'),
    next: t('offer.navigation.next'),
    editForm: t('calculator.navigation.prev'),
    conditions: t('calculator.conditions')
  }

  // use effects 
  useEffect(() => {
    // if we reach this step, user fields should be required  
    setEmailOffer(dispatch, true)
    return () => {
      // set false when we leave
      setEmailOffer(dispatch, false)
    }

  }, [])

  return (
    <WidgetTightOfferFieldContainer
      noMargins
      onClick={goToPrevStep}
      backText={labels.editForm}
    >
      {/* pet name  */}
      <WidgetTightFormField
        htmlFor='select-pet_name'
        name='pet_name'
        background='light-1'
        label={labels.petName}
        error={error}
      >
        <TextInput
          id='select-pet_name'
          type='search'
          name='pet_name'
        />
      </WidgetTightFormField>

      
      <WidgetTightAddressSingle 
        prefixName='holder' 
        formValue={formValue}
        error={error}
        exclude={['birthdate', 'address']}
      />
      <Box pad={{ vertical: 'small' }}>
        <Caption>
          <div
            style={{ fontSize: '0.65rem' }}
            className={styles.html}
            dangerouslySetInnerHTML={{ __html: labels.conditions }}
          />
        </Caption>
      </Box>
      <Box pad={{ top: 'small' }}>
        <WidgetTightButton
          fill
          primary
          form='form'
          type='submit'
          label={labels.next}
          isLoading={isLoading}
        />
      </Box>
    </WidgetTightOfferFieldContainer>
  )
}

export default InsuranceStep
