import * as yup from 'yup'
import differenceInYears from 'date-fns/differenceInYears'

const phoneRegExp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
const ifNullThen = schema => value => {
  if (!value) {
    return schema
  }
}

let ownerSchema =  yup.object().shape({
  title: yup.string().required('offer.error.title_required').matches(/(mr|ms|mrs)/),
  first_name: yup.string()
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase')
    .test('len', 'offer.error.more_char', val => val?.length >= 2)
    .required('offer.error.first_name_required'),
  last_name: yup.string()
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase')
    .test('len', 'offer.error.more_char', val => val?.length >= 2)
    .required('offer.error.last_name_required'),
  post_address_strid: yup.string().nullable(),
  post_address_house_key: yup.string().nullable(),
  address_id:  yup.string().nullable(),
  line: yup.string().required('offer.error.line_required'),
  line_2: yup.string().nullable(),
  zip_code: yup.string().required('offer.error.zip_code_required'),
  city:  yup.string().required('offer.error.city_required'),
  country: yup.string().nullable(),
  birthdate: yup.date()
    .typeError('offer.error.invalid_date')
    .test('dob', 'offer.error.min_18', value => differenceInYears(new Date(), value) >= 18)
    .max(new Date(), 'offer.error.invalid_date')
    .required('offer.error.birthdate_required'),
  email: yup.string().required('offer.error.general'),
  phone: yup.string().nullable().when('mobile_phone', ifNullThen(yup.string().matches(phoneRegExp, 'format: +41000000000').required('offer.error.holder_phone_required'))),
  mobile_phone: yup.string().nullable().when('phone', ifNullThen(yup.string().matches(phoneRegExp, 'format: +41000000000').required('offer.error.holder_phone_required'))),
  transferred_at: yup.date()
    .typeError('offer.error.invalid_date')
    .min(new Date(new Date().setHours(0,0,0,0)), 'offer.error.invalid_date')
    .required('offer.error.birthdate_required'),
  keep_insurance: yup.boolean().typeError('offer.error.general').required('offer.error.general'),
  other_concerned_pets: yup.array().of(yup.number()).nullable()
}, ['phone', 'mobile_phone'])

export default ownerSchema