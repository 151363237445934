import React from 'react'
import { Box } from 'grommet'
import ReactTooltip from 'react-tooltip'
import { CircleInfo } from '@animalia/icons'
import styles from './OfferTip.module.sass'
import classNames from 'classnames'

const OfferTip = ({ icon, plain, content, color = 'currentColor', fill = false, margin, align = 'center', place = 'top' }) => {
  if (typeof icon === 'undefined') {
    icon = <CircleInfo color={color} />
  }

  const classes = plain ? classNames([styles.container, styles.plain]) : styles.container
  return (
    <>
      <Box
        data-html
        className={classes}
        align={align}
        justify={align}
        fill={fill}
        margin={margin}
        width={fill === false ? '15px' : '100%'}
        data-tip={content}
        data-multiline="true">
        {icon}
      </Box>
      <ReactTooltip
        className={styles.tooltip}
        place={place}
        type="dark"
        effect="solid"
      />
    </>
  )
}

export default OfferTip

