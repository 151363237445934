module.exports = {
  privacyPolicy: 'https://www.animalia.ch/privacy-policy',
  cgv: 'https://www.animalia.ch/conditions-generales-de-vente',
  cgu: 'https://www.animalia.ch/conditions-generales-d-utilisation',
  insurance: {
    changeOwner:
      'https://assurance.animalia.ch/_files/content/pdf/chgmt_de_detenteur_fr.pdf',
    changeBank:
      'https://assurance.animalia.ch/fr/contact/transmission-de-coordonnees-bancaires-ou-postales',
    sinister:
      'https://assurance.animalia.ch/fr/contact/declaration-de-sinistre',
    changeAddress:
      'https://assurance.animalia.ch/fr/contact/changement-d-adresse-ou-de-nom',
  },
  contact: 'https://www.animalia.ch/contact'
};
