import * as yup from 'yup';
import i18n from 'i18next';

const STATUS_NEW = 'new';
const STATUS_SENDING_FILES = 'sending_files';
const STATUS_FILES_MERGED = 'files_merged';
const STATUS_FILES_SENT = 'files_sent';
const STATUS_SENT = 'sent';
const STATUS_SCANNED = 'files_scanned';
const TYPE_BILL = 'bill';
const TYPE_MEDICAL_RECORD = 'medical_record';

const medicalFileShape = yup.object().shape({
  label: yup.string().required(),
  type: yup.string().oneOf([TYPE_BILL]),
});

const billShape = yup.object().shape({
  label: yup.string().required(),
  type: yup.string().oneOf([TYPE_MEDICAL_RECORD]),
  amount: yup.number().integer().min(500).required(),
  involves_multiple_pets: yup.boolean().required(),
  involves_multiple_claims: yup.boolean().required(),
  involves_a_vaccine: yup.boolean().required(),
  billing_years: yup.array().of(yup.number()),
  claims: yup.array().of(yup.number()),
});

function Document(doc) {
  let self = {
    ...doc,
    isBill: () => self.type === TYPE_BILL,
    isMedicalRecord: () => self.type === TYPE_MEDICAL_RECORD,
    /**
     * Check if the file has been sent to Laravel (not Documentum)
     * This means that the file is ready to be displayed to the user.
     */
    isSent: () =>
      self.status === STATUS_FILES_MERGED ||
      self.status === STATUS_SENT ||
      self.status === STATUS_SCANNED ||
      self.status === STATUS_FILES_SENT,

    isCreated() {
      return typeof this.id !== 'undefined';
    },

    /**
     * Check if the document is under the processing time
     */
    isUnderProcessingTime(processing_time) {
      const estimatedDate = new Date();
      estimatedDate.setDate(estimatedDate.getDate() + processing_time);
      estimatedDate.setHours(0, 0, 0);

      const sentDate = new Date(doc.updated_at);
      sentDate.setHours(0, 0, 0);

      return sentDate < estimatedDate;
    },

    /**
     * Gets the estimated date of processing
     */
    getEstimatedProcessingDate(processing_time) {
      const date = new Date(doc.updated_at);
      date.setDate(date.getDate() + processing_time);
      date.setHours(0, 0, 0);

      return date.toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    getAmount() {
      if (self.isCreated()) {
        return parseFloat(this.amount / 100).toFixed(2);
      } else {
        return parseFloat(this.amount).toFixed(2);
      }
    },

    /**
     * Get a summary of filled fields
     */
    getSummary: () => {
      let fields = null;
      if (self.isBill()) {
        fields = billShape.fields;
      } else if (self.isMedicalRecord()) {
        fields = medicalFileShape.fields;
      }

      if (!fields) {
        return {};
      }

      return Object.keys(fields).reduce((acc, field) => {
        if (typeof self[field] !== 'undefined') {
          let value = self[field];

          /**
           * we apply some format to specific fields
           */
          switch (field) {
            case 'type':
              return acc;
              break;

            case 'label':
              if (self.isMedicalRecord()) {
                acc.push({key: 'label_medicalFile', value: value});
                return acc;
              }
              break;

            case 'billing_years':
              value = value.join(', ');
              break;

            case 'involves_multiple_pets':
            case 'involves_multiple_claims':
            case 'involves_a_vaccine':
              value = value ? i18n.t('common.yes') : i18n.t('common.no');
              break;

            case 'amount':
              const amount = self.getAmount();
              if (amount === 'NaN') {
                return acc;
              }
              value = 'CHF ' + amount.replace('.00', '.-');
              break;
          }

          acc.push({key: field, value: value});
        }
        return acc;
      }, []);
    },

    formatForAPI: () => {
      let formatted = self;

      if (typeof self.amount !== 'undefined') {
        formatted.amount = self.getAmount() * 100;
      }

      return formatted;
    },

    formatAmount: () => {
      return `CHF ${self.getAmount().replace('.00', '.-')}`;
    },
  };

  return self;
}

export default Document;
