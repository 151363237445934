module.exports = {
  noInvoices: 'Keine Rechnungen vorhanden',
  noInvoicesDescription: 'In Ihrem Konto sind noch keine Rechnungen vorhanden.',
  refno: 'Bestellung',
  total: 'Preis',

  paymentModal: {
    successTitle: 'Vielen Dank für Ihre Zahlung',
    successMessage: 'Wir haben Ihre Zahlung erhalten und danken Ihnen dafür.',
    failureTitle: 'Ups! Zahlung fehlgeschlagen',
    failureMessage: 'Ihr Zahlungsmittel scheint nicht zu funktionieren. Bitte registrieren Sie ein anderes Zahlungsmittel und probieren Sie es danach nochmals.',
  },
  payNow: 'Jetzt bezahlen',
  closeFaildModal: 'Nicht jetzt',
  payementMethod: 'Anderes Zahlungsmittel hinzufügen',
  status: {
    pending: 'Ausstehend',
    failed: 'Fehler',
    completed: 'Bezahlt',
    'no-default-card': 'Kein Zahlmittel gespeichert',
    refunded: 'Zurückerstattet',
  },
};
