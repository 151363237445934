module.exports = {
  holder: 'Stipulante',
  pet_type: 'Tipo di animale',
  age_category: 'Fascia d’età dell’animale',
  quote_part: 'Aliquota',
  quote_part_tip:
    'Una volta raggiunta la franchigia, Animalia copre il 90% delle spese. Il 10% restante è a suo carico.',
  quote_part_value: '10%',
  franchise_title: 'Franchigia e aliquota',
  insurance_prime: 'Premio',
  tooltip_compact_fix: "Premio fisso, indipendentemente dall'età", // TODO: tooltip de insurance_prime quand c'est une assurance compact
  prestations: 'Prestazioni per anno civile',
  prestations_tip: '(secondo le condizioni generali)',
  medical_cost_coverage: 'Copertura delle spese mediche',
  medical_cost_coverage_value: 'Illimitata',
  prevention_cost: 'Spese di prevenzione',
  prevention_cost_tip: 'senza franchigia e aliquota',
  prevention_cost_value: 'CHF 100.-',
  alt_medicinal: 'Medicine alternative',
  alt_medicinal_value: 'CHF 600.-',
  housing_cost: 'Spese di alloggio',
  housing_cost_value: 'CHF 200.-',
  investment_cost: 'Spese di ricovero',
  investment_cost_value: 'CHF 150.-',
  freight_cost: 'Spese di trasporto',
  freight_cost_value: 'CHF 100.-',
  waiting_period: 'Periodo di carenza',
  accident: 'Infortunio',
  accident_value: 'Nessuno',
  illness: 'Malattia',
  illness_value: '30 giorni',
  compact_title_description:
    'Con Animalia Compact, ha scelto la soluzione ideale!',
  health_title_description:
    'Con Animalia Classic, ha scelto la soluzione ideale!',
  confort_title_description:
    'Con Animalia Comfort, ha scelto la soluzione ideale!',
  description_classic:
    'Copertura contro gli infortuni e le malattie acute e croniche', // TODO: @Calvin la description est différente pour compact et comfort
  description_comfort:
    'Copertura in caso di infortuni e di malattie acute, croniche, ereditarie o congenite', // TODO: @Calvin la description est différente pour compact et comfort
  description_compact: 'Copertura in caso di infortuni', // TODO: @Calvin la description est différente pour compact et comfort
  conditions_title: 'Condizioni applicabili',
  conditions_description: 'Condizioni generali Animalia, edizione 1.11.2021',
  conditions_url:
    'https://prismic-io.s3.amazonaws.com/animalia/f5eae0c9-f863-4cc0-9fc4-49b2db912853_CGA_Animalia_2021_I_WEB.pdf',
  conditions_info:
    'Attenzione! Le malattie preesistenti non sono coperte (vedi art. B5 delle condizioni generali d’assicurazione).',
  law_application:
    '<p>L’adeguamento del premio alla fascia d’età superiore avviene il 1° gennaio dell’anno in cui l’animale compie tre, rispettivamente cinque, sette, nove, undici e tredici anni.</p>',
  offer_validity: 'Validità dell’offerta',
  offer_validity_description:
    'Questa offerta si basa sulle informazioni in nostro possesso. Rimane valido fino a {{validity_date}}.', // TODO: @Calvin Cette date doit être calculée -> date de la création de l'offre + 90 jours
  offer_conclusion:
    'Animalia, inoltre, è un prodotto della Vaudoise Assicurazioni e quindi lei beneficia della ridistribuzione degli utili grazie alle nostre origini mutualistiche.',
  offer_conclusion_2: 'Assicuri PETNAME per vivere lunghi anni di serenità.',
  go_further: 'Le assicuro!',
  print: 'Stampare l’offerta ',
  federal_timber: 'Bollo federale',
  federal_timber_tip:
    'Il bollo federale si applica al premio annuo (compresi i eventuali supplementi per pagamento rateale).',
  total: 'Premio annuo totale',
};
