module.exports = {
  or: 'oder',
  add: 'Neu',
  save: 'Speichern',
  send: 'Senden',
  edit: 'Ändern',
  delete: 'Löschen',
  previous: 'Zurück',
  next: 'Weiter',
  iAcceptCGU: 'Ich akzeptiere die',
  CGV: 'Allgemeinen Geschäftsbedingungen (AGB)',
  CGU: ' Allgemeinen Nutzungsbedingungen (ANB)',
  privacy_policy: 'Datenschutzbestimmungen',
  missingData: 'Fehlende Information',
  readmore: 'Mehr erfahren',
  yes: 'Ja',
  no: 'Nein',
  close: 'Schliessen',
  back: 'Zurück',
  backToAnimals: 'Zurück zu "Mein Rudel"',
  draft: 'Noch nicht gespeichert',
  calculating: 'Berechnung läuft',
  allfeesincluded: 'Steuern und Versandkosten inbegriffen',
  finished: 'Fertig',
  printOrder: 'Bestellung ausdrucken',
  deliveries: 'Nächste Lieferungen',
  plannedDeliveries: 'Nächste Lieferungen',
  previousDeliveries: 'Vergangene Lieferungen',
  batchID: 'Bestellnummer',
  status: 'Status',
  verify: 'Prüfen',
  year: 'Jahr',
  month: 'Monat',
  day: 'Tag',
  months: {
    1: 'Januar',
    2: 'Februar',
    3: 'März',
    4: 'April',
    5: 'Mai',
    6: 'Juni',
    7: 'Juli',
    8: 'August',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Dezember',
  },
  immediately: 'Sofort',
  informations: 'Informationen',
  aboGourmand: 'Gourmet-Abo',
  theAboGourmand: 'Das Gourmet-Abo',
  theInsurance: 'Die Versicherung',
  administrative: 'Administrative Änderung',
  missingAddress: 'Keine Adresse vorhanden.',
  missingAddressFor: 'Es gibt keine registrierte Adresse für {{name}}.',
  missingCard: 'Kein Zahlungsmittel vorhanden.',
  fix: 'Problem korrigieren',
  cancel: 'Abbrechen',
  confirm: 'Bestätigen',
  fillIn: 'Ausfüllen',
  wantToKnowMore: 'Möchten Sie mehr erfahren?',
  moreDetails: 'Mehr Infos',
  date: 'Datum',
  service: 'Service',
  noResults: 'Kein Ergebnis',
  required: 'Obligatorisches Feld',
  your_comment: 'Bemerkungen',

  cardIsFailing: 'Fehler',
  twintAccount: 'Twint-Konto',
  powerpayAccount: 'Bezahlung auf Rechnung (PowerPay)',

  oldbrowser: {
    title: 'Uuups! Ihr Webbrowser ist nicht aktuell.',
    text:
      'Für mehr Sicherheit und ein besseres Nutzererlebnis auf unserer Webseite empfehlen wir Ihnen, Ihren Webbrowser zu aktualisieren oder einen anderen Browser zu verwenden.',
    button: 'OK, ich aktualisiere meinen Browser',
  },

  weDidNotReceiveYourPayment:
    'Wir haben Ihre Zahlung für diese Bestellung nicht erhalten.',
  contactUs: 'Kontakt',
  powerpay: {
    title: 'Bitte überprüfen Sie Ihre Zahlungsangaben',
    subtitle:
      'Sie erhalten die Rechnung kostenlos per Email. Mit Kauf auf Rechnung akzeptieren Sie die <a href="https://www.powerpay.ch/de/agb" target="_blank">AGB</a> von POWERPAY (powerpay.ch/de/agb), vorausgesetzt ist eine gültige Email Adresse.',
    next: 'Bezahlen',
    cancel: 'Abbrechen',
  },
  other: 'Andere',
};
