import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import lang from '@animalia/lang'

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translation: {
          ...lang.fr,
        },
      },
      de: {
        translation: {
          ...lang.de,
        },
      },
      it: {
        translation: {
          ...lang.it,
        },
      },
    },
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },

    detection: {
      lookupQuerystring: 'lang',
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie', 'localStorage'],
      cookieDomain: 'localhost',
    },
  })
