import classNames from 'classnames'
import { Box } from 'grommet'
import React from 'react'
import { Caption } from '../..'
import styles from './WidgetSelectGroup.module.sass'

const WidgetSelectGroup = (option, index, options, { active, disabled, selected }) => {
  const containerClasses = classNames([
    styles.container,
    (active || selected) && styles.select
  ])

  return (
    <div 
      className={containerClasses}
      background='light-1'
    >
      <div className={styles.content}>
        <Caption big>{ option.label }</Caption>
      </div>
    </div>
  )
}

export default WidgetSelectGroup
