import * as yup from 'yup';

export default yup.object().shape({
  species: yup
    .string()
    .required()
    .matches(/(cat|dog)/),
  gender: yup
    .string()
    .required()
    .matches(/(male|female)/),
  name: yup.string().required(),
  birthdate: yup.date().required(),
  animal_type: yup.string().oneOf(['breed', 'bastard']).required(),
  animal: yup.object().when('animal_type', {
    is: 'breed',
    then: yup.object().required(),
    otherwise: yup.object().nullable(),
  }),
  weight: yup.number().when('animal_type', {
    is: 'bastard',
    then: yup.number().integer().positive().max(100).required(),
    otherwise: yup.number().nullable(),
  }),
  activity: yup.string().oneOf(['passive', 'normal', 'active']).required(),
  sterilized: yup.string().oneOf(['0', '1']).required(),
  options: yup.string().oneOf(['none', 'sensitive', 'grain-free']).required(),
  product: yup.number().required(),
  percentageQuantity: yup.number().oneOf([80, 90, 100, 110, 120]).required(),
});
