import { GroupFormLayout, Title, WidgetSelect, WidgetSelectGroup, WidgetTightButton, WidgetTightFlexibleGrid, WidgetTightFormField, WidgetTightOfferFieldContainer, WidgetTightRadioButtonGroup, WidgetTightRadioGroup } from "@animalia/components"
import { Box, Select, TextInput } from "grommet";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { axios } from '@animalia/instances'
import { getErrors } from "@animalia/actions";

const RaceStep = ({ formValue, dispatch, goToPrevStep, goToNextStep, error }) => {
  const { t, i18n } = useTranslation()
  const [races, setRaces] = useState([])
  const [search, setSearch] = useState('')
  const [search2, setSearch2] = useState('')
  const [zipCodes, setZipCodes] = useState([])
  const [zipcodeValue, setZipcodeValue] = useState(formValue.holder_zip_code)
  
  const onSearchHandle = text => {
    setSearch(text)
  }

  const onSearchHandle2 = text => {
    setSearch2(text)
  }

  const labels = {
    suptitle: t('calculator.race.suptitle'),
    calculate: t('calculator.navigation.goToInsuranceStep'),
    editForm: t('calculator.navigation.prev'),
    pet_breed: t('calculator.race.pet_breed'),
    pet_mixed: t('calculator.race.pet_mixed'),
    pet_unknown: t('calculator.race.pet_unknown'),
    breed: t('calculator.race.breed'),
    breed_2: t('calculator.race.breed_2'),
    zipcode: t('calculator.race.zipcode'),
    city: t('calculator.race.city'),
  }

  const valueKeyConfig = {
    key: 'value',
    reduce: true,
  }

  const breedTypeOptions = formValue.pet_species === 'dog'
    ? [
      {
        label: labels.pet_breed,
        value: 'breed'
      },
      {
        label: labels.pet_mixed,
        value: 'mixed'
      },
      {
        label: labels.pet_unknown,
        value: 'unknown'
      }
    ] : [
      {
        label: labels.pet_breed,
        value: 'breed'
      },
      {
        label: labels.pet_unknown,
        value: 'unknown'
      }
    ]
  
  const filteredOptions = (elements, search) => elements.filter(item => !search || item.label.toLowerCase().includes(search.toLowerCase())
    // || item.keywords?.toLowerCase().includes(search.toLowerCase()
  )

  const onZipCodeChange = async (event) => {
    setZipcodeValue(event.target.value)
    if (event.target.value.length < 2) {
      setZipCodes([])
      return
    }

    const reqObject = {
      ONRP: 0,
      ZipCode: event.target.value,
      ZipAddition: '',
      TownName: '',
      STRID: 0,
      StreetName: '',
      HouseKey: 0,
      HouseNo: '',
      HouseNoAddition: ''
    }
    const response = await axios.post(process.env.REACT_APP_API_POST_ENDPOINT, JSON.stringify(reqObject))
    if (response.status === 200 && response.data) {
      const citiesFound = 
          response
          ?.data
          ?.body
          ?.data
          ?.QueryAutoComplete3Result
          ?.AutoCompleteResult
          ?.map(item => ({
            city: item.TownName,
            value: item.ZipCode,
            label: `${item.ZipCode} ${item.TownName}`
          }))
          ?.filter((item, pos, self) => self.indexOf(item) == pos)

      setZipCodes(citiesFound)
    }
  }

  const onZipCodeSuggestionSelect = ({ suggestion }) => {
    const { city, value: zipcode } = suggestion
    setZipcodeValue(zipcode)

    setZipCodes([])
  
    dispatch({
      type: 'SET_FORM',
      data: {
        holder_zip_code: zipcode,
        holder_city: city
      }
    })
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get('/animals?ul=' + i18n.language.substr(0, 2))
        setRaces([
          ...response.data.animals
            .filter(item => item.species === formValue.pet_species)
            .sort((a, b) => a.race.localeCompare(b.race))
            .map(item => ({value: item.id, label: item.race})),
          {
            value: -1,
            label: 'Autres',
          },
        ])
      } catch (error) {
        throw new Error(error)
      }
    
    })()
  }, [])
  

  return (
    <WidgetTightOfferFieldContainer
      suptitle={labels.suptitle}
      onClick={goToPrevStep}
      noBack={false}
      backText={labels.editForm}
    >
      <div>
        <Box pad={{ vertical: 'small' }}>
          <Title>
            <div style={{ fontSize: '0.875rem', textAlign: 'center' }}> { labels.breed } </div>
          </Title>
        </Box>
        <Box pad={{ vertical: 'small' }}>
          <WidgetTightFormField
            stretch
            htmlFor='select-race'
            name='pet_breed'
            error={error}
          >
            <WidgetTightRadioButtonGroup
              id='select-deductible'
              name='pet_breed'
              direction='row'
              justify='center'
              labelKey='label'
              options={breedTypeOptions}
              children={WidgetTightRadioGroup}
            />
          </WidgetTightFormField>
          <Box fill gap='small'>
            {
              formValue.pet_breed === 'breed' || formValue.pet_breed === 'mixed'
                ? (
                  <WidgetTightFormField
                    htmlFor="select-pet_breeds"
                    horizontalFill
                    error={error}
                    name="pet_animal_id"
                    label={labels.breed}
                    background="light-1"
                    // error={error}
                  >
                    <Select
                      id="select-pet_animal_id"
                      name="pet_animal_id"
                      valueKey={valueKeyConfig}
                      searchPlaceholder="Rechercher"
                      options={filteredOptions(races, search)}
                      children={WidgetSelectGroup}
                      onSearch={onSearchHandle}
                    />
                  </WidgetTightFormField>
                )
                : null
            }
            {
              formValue.pet_breed === 'mixed'
                ? (
                  <WidgetTightFormField
                    htmlFor="select-pet_breeds_2"
                    horizontalFill
                    name="pet_animal_id_2"
                    label={labels.breed_2}
                    background="light-1"
                    error={error}
                  >
                    <Select
                      id="select-pet_animal_id_2"
                      name="pet_animal_id_2"
                      valueKey={valueKeyConfig}
                      searchPlaceholder="Rechercher"
                      options={filteredOptions(races, search2)}
                      children={WidgetSelectGroup}
                      onSearch={onSearchHandle2}
                    />
                  </WidgetTightFormField>
                )
                : null
            }
          </Box>
        </Box>
        <Box pad={{ vertical: 'small' }}>
          <Title>
            <div style={{ fontSize: '0.875rem', textAlign: 'center' }}> Adresse </div>
          </Title>
        </Box>
        <Box pad={{ vertical: 'small' }}>
          <WidgetTightFlexibleGrid col='2'>
            <WidgetTightFormField
              background='light-1'
              label={labels.zipcode}
              name="holder_zip_code"
              htmlFor="select-holder_zip_code"
              autocomplete='honorific-prefix'
              error={error}
            >
              <TextInput
                // name="holder_zip_code"
                id="select-holder_zip_code"
                autocomplete='honorific-prefix'
                suggestions={zipCodes}
                onChange={onZipCodeChange}
                value={zipcodeValue}
                onSuggestionSelect={(event) => onZipCodeSuggestionSelect(event)}
              />
            </WidgetTightFormField>
            <WidgetTightFormField
              background='light-1'
              label={labels.city}
              htmlFor="select-holder_city"
              autocomplete='honorific-prefix'
              error={error}
              name="holder_city"
            >
              <TextInput
                disabled={true}
                value={formValue.holder_city}
                id="select-holder_city"
                autocomplete='honorific-prefix'
              />
            </WidgetTightFormField>
          </WidgetTightFlexibleGrid>
        </Box>
      </div>
      <Box>
        <WidgetTightButton
          fill
          primary
          onClick={goToNextStep('calculate')}
          label={labels.calculate}
        />
      </Box>
    </WidgetTightOfferFieldContainer>
  )
}

export default RaceStep