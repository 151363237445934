import * as yup from 'yup';
import Document from './document.js';
import i18n from 'i18next';

const TYPE_ACCIDENT = 'accident';
const TYPE_HEALTH = 'health';
const TYPE_VACCINE = 'vaccine';
const TYPE_EXTERNAL = 'external';

const THIRD_PARTY_STATUS_KNOWN = 'known';
const THIRD_PARTY_STATUS_UNKNOWN = 'unknown';
const THIRD_PARTY_STATUS_NONE = 'none';

const thirdPartyShape = yup.object().shape({
  type: yup.string().oneOf(['accident_third_party']),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  street: yup.string().nullable(),
  zip_code: yup.string().nullable(),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  email: yup.string().nullable(),
  phone: yup.string().nullable(),
});

const basicClaimShape = {
  type: yup
    .string()
    .oneOf([TYPE_ACCIDENT, TYPE_HEALTH, TYPE_VACCINE, TYPE_EXTERNAL]),
  label: yup.string().required(),
  is_external: yup.boolean().required(),
};

const vaccineShape = yup.object().shape({
  ...basicClaimShape,
  comment: yup.string().nullable(),
});

const illnessShape = yup.object().shape({
  ...basicClaimShape,
  diagnosis: yup.string().nullable(),
  symptoms: yup.string().nullable(),
  treatment: yup.string().required(),
  comment: yup.string().nullable(),
});

const accidentShape = yup.object().shape({
  ...basicClaimShape,
  diagnosis: yup.string().required(),
  treatment: yup.string().required(),
  occurred_at: yup.date().required(),
  interval: yup.string().required(),
  sequence: yup.string().required(),
  third_party_status: yup
    .string()
    .oneOf([
      THIRD_PARTY_STATUS_KNOWN,
      THIRD_PARTY_STATUS_UNKNOWN,
      THIRD_PARTY_STATUS_NONE,
    ]),
  comment: yup.string().nullable(),
});

const accidentWithThirdPartyShape = yup.object().shape({
  ...basicClaimShape,
  diagnosis: yup.string().required(),
  occurred_at: yup.date().required(),
  interval: yup.string().required(),
  sequence: yup.string().required(),
  third_party_status: yup
    .string()
    .oneOf([
      THIRD_PARTY_STATUS_KNOWN,
      THIRD_PARTY_STATUS_UNKNOWN,
      THIRD_PARTY_STATUS_NONE,
    ]),
  third_party: thirdPartyShape,
  comment: yup.string().nullable(),
});

const externalShape = yup.object().shape({
  ...basicClaimShape,
  claim_year: yup.number().required(),
});

function Claim(claim) {
  let self = {
    ...claim,

    init: () => {
      if (typeof self.is_external === 'undefined') {
        self.is_external = false;
      }

      if (typeof self.documents !== 'undefined') {
        self.documents = self.documents.map(d => Document(d));
      }

      if (self.isCreated()) {
        if (self.third_party === null) {
          delete self.third_party;
        }
        // hasDiagnostic is not saved in database but we can find what is
        // its value from diagnostic field
        if (self.isIlness() && typeof self.hasDiagnostic === 'undefined') {
          self.hasDiagnostic = self.diagnosis ? 'yes' : 'no';
        }
      }
    },

    /**
     * Check if the claim is of type vaccine
     */
    isVaccine: () => self.type === TYPE_VACCINE,

    /**
     * Check if the claim is of type illness
     */
    isIlness: () => self.type === TYPE_HEALTH,

    /**
     * Check if the claim is of type accident
     */
    isAccident: () => self.type === TYPE_ACCIDENT,

    /**
     * Check if the claim is of type external
     */
    isExternal: () => self.is_external === true,

    /**
     * Check if the claim is of type external
     */
    isCreated: () => typeof self.id !== 'undefined',

    getLabel: () => {
      return self.isVaccine() ? i18n.t('insurance.types.vaccine') : self.label;
    },

    /**
     * for Accidents, there could be a third party
     * Check if there is a third party
     */
    hasThirdParty: () => {
      if (!self.isAccident()) {
        return false;
      }

      if (
        typeof self.third_party_status !== 'undefined' &&
        self.third_party_status === THIRD_PARTY_STATUS_KNOWN
      ) {
        return true;
      }

      return false;
    },

    /**
     * prepare the claim to be used in a form
     */
    formatForForm: () => {
      if (self.isCreated()) {
        const date = new Date(self.occurred_at);
        self.date = date;
        self.hour = date.toLocaleString('en-us', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });
        self.interval = {value: self.interval};
      }
    },

    /**
     * prepare the claim to be sent to the API
     */
    formatForAPI: () => {
      if (
        self.isAccident() &&
        !self.isExternal() &&
        typeof self.date !== 'undefined'
      ) {
        self.occurred_at = new Date(self.date);
        //accidents needs to concatenate date
        if (typeof self.hour !== 'undefined') {
          const [hours, minutes] = self.hour.split(':');

          if (hours && minutes) {
            self.occurred_at.setHours(hours);
            self.occurred_at.setMinutes(minutes);
          }
        }
      }

      if (
        self.isAccident() &&
        !self.isExternal() &&
        typeof self.interval !== 'undefined' &&
        typeof self.interval.value !== 'undefined'
      ) {
        self.interval = self.interval.value;
      }

      if (typeof self.third_party === 'undefined' && self.hasThirdParty()) {
        self.third_party = {
          type: 'accident_third_party',
          first_name: self.first_name || null,
          last_name: self.last_name || null,
          street: self.street || null,
          zip_code: self.zip_code || null,
          city: self.city || null,
          country: self.country || null,
          email: self.email || null,
          phone: self.phone || null,
        };
      }

      if (!self.hasThirdParty()) {
        delete self.third_party;
      }

      return self;
    },

    getDetails: () => {
      const items = [];

      items.push(
        new Date(self.created_at).toLocaleDateString(i18n.language, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      );

      items.push(i18n.t('insurance.types.' + self.type));

      items.push(
        i18n.t('insurance.xInvoices', {
          count: self.documents.length,
        })
      );

      return items.join(' | ');
    },

    /**
     * Make sure the claim is in a valid shape
     */
    validate: () => {
      try {
        if (self.isExternal()) {
          externalShape.validateSync(self);
        } else if (self.isVaccine()) {
          vaccineShape.validateSync(self);
        } else if (self.isIlness()) {
          illnessShape.validateSync(self);
        } else if (self.isAccident()) {
          if (self.hasThirdParty()) {
            accidentWithThirdPartyShape.validateSync(self);
          } else {
            accidentShape.validateSync(self);
          }
        } else {
          return false;
        }
      } catch (err) {
        console.error(err);
        return false;
      }

      return true;
    },

    /**
     * Get a summary of filled fields
     */
    getSummary: () => {
      let fields = basicClaimShape.fields;
      if (self.isExternal()) {
        fields = externalShape.fields;
      } else if (self.isVaccine()) {
        fields = vaccineShape.fields;
      } else if (self.isIlness()) {
        fields = illnessShape.fields;
      } else if (self.isAccident()) {
        if (self.hasThirdParty()) {
          fields = accidentWithThirdPartyShape.fields;
        } else {
          fields = accidentShape.fields;
        }
      }

      if (!fields) {
        return [];
      }

      self.formatForAPI();

      return Object.keys(fields).reduce((acc, field) => {
        if (typeof self[field] !== 'undefined') {
          let value = self[field];

          /**
           * we apply some format to specific fields
           */
          switch (field) {
            // fields we dont want to display
            case 'is_external':
              return acc;
              break;

            case 'type':
              value = i18n.t('insurance.types.' + value);
              break;

            case 'third_party':
              value = self.formatThirdParty(value);
              if (value.length === 0) {
                return acc;
              }
              break;

            case 'occurred_at':
              let params = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              };

              const date = new Date(value);
              if (
                date.getHours() + date.getMinutes() !== 0 ||
                typeof self.hour !== 'undefined'
              ) {
                params.hour = '2-digit';
                params.minute = '2-digit';
              }

              value = date.toLocaleDateString(i18n.language, params);
              break;

            case 'third_party_status':
              value = i18n.t('insurance.disaster.anyThirdParty.' + value);
              break;

            case 'interval':
              const label =
                typeof value.value !== 'undefined' ? value.value : value;
              value = i18n.t('insurance.disaster.date.' + label);
              break;
          }

          acc.push({key: field, value: value});
        }
        return acc;
      }, []);
    },

    formatThirdParty: person => {
      if (!person) {
        return '';
      }
      if (!self.hasThirdParty()) {
        return '';
      }

      const {
        last_name,
        first_name,
        street,
        zip_code,
        city,
        country,
        email,
        phone,
      } = person;
      let lines = [];

      if (last_name || first_name) {
        let name = [];
        if (last_name) {
          name.push(last_name);
        }
        if (first_name) {
          name.push(first_name);
        }
        lines.push(name.join(' '));
      }

      if (street) {
        lines.push(street);
      }

      if (zip_code || city) {
        let area = [];
        if (zip_code) {
          area.push(zip_code);
        }
        if (city) {
          area.push(city);
        }
        lines.push(area.join(' '));
      }

      if (country) {
        lines.push(country);
      }

      if (email) {
        lines.push(email);
      }

      if (phone) {
        lines.push(phone);
      }

      return lines.join('<br>');
    },
  };

  self.init();

  return self;
}

export default Claim;
