module.exports = {
  holder: "Preneur d'assurance",
  pet_type: "Type d'animal",
  age_category: "Catégorie d'âge de l'animal",
  quote_part: 'Quote part',
  quote_part_tip:
    'Une fois la franchise atteinte, Animalia prend en charge 90 % des frais. Le 10 % restant est à votre charge',
  quote_part_value: '10%',
  franchise_title: 'Franchise et quote part',
  insurance_prime: "Prime d'assurance",
  tooltip_compact_fix: "Prime fixe, quel que soit l'âge",
  prestations: 'Prestations par année civile',
  prestations_tip: '(selon les conditions générales)',
  medical_cost_coverage: 'Couverture des frais médicaux',
  medical_cost_coverage_value: 'Illimité',
  prevention_cost: 'Frais de prévention',
  prevention_cost_tip: 'hors franchise et quote-part',
  prevention_cost_value: 'CHF 100.-',
  alt_medicinal: 'Médecines alternatives',
  alt_medicinal_value: 'CHF 600.-',
  housing_cost: 'Frais de logement',
  housing_cost_value: 'CHF 200.-',
  investment_cost: 'Frais de placement',
  investment_cost_value: 'CHF 150.-',
  freight_cost: 'Frais de transport',
  freight_cost_value: 'CHF 100.-',
  waiting_period: 'Délai de carence',
  accident: 'Accident',
  accident_value: 'Aucun',
  illness: 'Maladie',
  illness_value: '30 jours',
  compact_title_description:
    'Avec Animalia Compact, vous avez choisi la bonne formule !',
  classic_title_description:
    'Avec Animalia Classic, vous avez choisi la bonne formule !',
  comfort_title_description:
    'Avec Animalia Comfort, vous avez choisi la bonne formule !',
  description_classic: `Couverture en cas d'accidents et de maladies aigues et chroniques`, // TODO: @Calvin la description est différente pour compact et comfort. CLASSIC
  description_comfort: `Couverture en cas d'accidents et de maladies aigues, chroniques, héréditaires ou congénitales`, // TODO: @Calvin la description est différente pour compact et comfort. COMFORT
  description_compact: `Couverture en cas d'accidents`, // TODO: @Calvin la description est différente pour compact et comfort COMPACT
  conditions_title: 'Conditions applicables',
  conditions_description: 'Conditions Générales Animalia, Edition 1.11.2021',
  conditions_url:
    'https://prismic-io.s3.amazonaws.com/animalia/7c3008e5-cd69-4b3f-beef-b249c413c4e6_CGA_Animalia_2021_F_WEB.pdf',
  conditions_info:
    "Attention! Les maladies préexistantes ne sont pas prises en charge (cf. Art. B5 des conditions générales d'assurance)",
  law_application:
    "<p>L'adaptation de la prime à la classe d'âge supérieure s'opère le 1er janvier de l'année où l'animal atteint l'âge de trois, respectivement cinq, sept, neuf, onze et treize ans.</p>",
  offer_validity: "Validité de l'offre",
  offer_validity_description:
    "Cette offre est établie sur la base des indications en notre possession. Elle reste valable jusqu'au {{validity_date}}.", // TODO: @Calvin Cette date doit être calculée -> date de la création de l'offre + 90 jours
  offer_conclusion:
    'Animalia est un produit Vaudoise Assurances et à ce titre vous profitez de la redistribution des bénéfices aux clients grâce à nos origines mutualistes.',
  offer_conclusion_2:
    'Assurez {{pet_name}} dès maintenant pour de longues années de sérénité.', // TODO: @Calvin nom de l'animal a ajouter dynamiquement
  go_further: "Je l'assure !",
  print: "Imprimer l'offre",
  federal_timber: 'Timbre fédéral',
  federal_timber_tip:
    "Le timbre fédéral s'applique à la prime annuelle (y compris les suppléments éventuels pour paiement fractionné).",
  total: 'Prime annuelle totale',
};
