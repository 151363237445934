import React from 'react';
import styled, {css} from 'styled-components';

const Input = styled.input`
  display: none;

  &:checked + label {
    color: #db9a2f;
    background-color: #fff9e5;

    .check {
      background-color: #db9a2f;

      &:before {
        content: '';
        display: block;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(45deg) translate(2px, -2px);
        transform-origin: center;
        height: 8px;
        width: 4px;
      }
    }
  }
`;

const Label = styled.label`
  color: #b2b6c1;
  border: 2px solid currentColor;
  border-radius: 2px;
  padding: 15px;
  font-weight: 500;
  font-size: 14px;
  display : ${props => (props.hasDescription ? 'grid' : 'block')};
  grid-template-columns: ${props =>
    props.iconSmall ? '80px 1fr' : '125px 1fr'};
  user-select:none;
  cursor:pointer;
}
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: ${props => (props.iconSmall ? 'flex-start' : 'center')};

  svg {
    margin: ${props => (props.iconMargin ? '20px 0' : null)};
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 2px solid currentColor;
  padding-left: 15px;
  font-size: 12px;
`;

const LabelWithCheck = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  align-items: cented;
  line-height: 1;
`;

const Check = styled.div`
  border: 2px solid currentColor;
  width: 12px;
  height: 12px;
  flex: 0 0 12px;
  margin-right: 5px;
  border-radius: 20px;
`;

export default ({
  name,
  value,
  label,
  description,
  icon,
  iconMargin = true,
  iconSmall,
  onChange,
  checked,
}) => {
  return (
    <>
      <Input
        type="radio"
        id={`${name}-${value}`}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <Label
        htmlFor={`${name}-${value}`}
        iconSmall={iconSmall}
        hasDescription={typeof description !== 'undefined'}>
        <Left iconSmall={iconSmall} iconMargin={iconMargin}>
          {icon}
          <LabelWithCheck>
            <Check className="check" />
            {label}
          </LabelWithCheck>
        </Left>
        {description && <Right>{description}</Right>}
      </Label>
    </>
  );
};
