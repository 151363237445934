import { convertPriceToCents, generateNamesObject, matchKey } from '..'
import { axios } from '@animalia/instances'
import { Pet } from '@animalia/models'
import { insuranceValues } from '@animalia/assets'

const calculateAge = (birthday, insuranceStart) => {
  let birth = birthday.getTime 
    ? birthday 
    : new Date(birthday)

  let birthYear = birth.getUTCFullYear()
  let insStart = insuranceStart &&
    insuranceStart?.getTime
      ? insuranceStart
      : new Date(insuranceStart)
  
  let insStartYear = insStart?.getUTCFullYear && insStart.getUTCFullYear()
  
  let age = (insStartYear ? insStartYear : new Date().getUTCFullYear()) - birthYear

  return age
}

export const findAndSetAgeRange = (dispatch, data, insuranceStart) => {
  let yearsRange
  const age = calculateAge(data, insuranceStart)

  if (age <= 2) {
    yearsRange = '2-'
  } else if (age <= 4) {
    yearsRange = '3-4'
  } else if (age <= 6) {
    yearsRange = '5-6'
  } else if (age <= 8) {
    yearsRange = '7-8'
  } else if (age <= 10) {
    yearsRange = '9-10'
  } else if (age <= 12) {
    yearsRange = '11-12'
  } else {
    yearsRange = '13+'
  }

  if (dispatch) {
    dispatch({
      type: 'SET_YEARS_RANGE',
      data: yearsRange
    })
  } else {
    return yearsRange
  }
}

export const setBankId = (dispatch, data) => {
  dispatch({
    type: 'SET_BANK_ID',
    data: data
  })
}

export const setDate = (dispatch, name, value, form = 'form') => {
  dispatch({
    type: 'SET_FORM',
    form: form,
    data: {
      [name]: value
    }
  })
}

export const setAddressee = (dispatch, prefix) => {
  dispatch({
    type: 'SET_ADDRESSEE',
    prefix: prefix
  })
}

export const setAddress = (dispatch, prefix, data) => {
  if (!data?.id) {
    data.title = ''
    data.first_name = ''
    data.last_name = ''
    data.line = ''
    data.line_2 = ''
    data.zip_code = ''
    data.city = ''
  }

  dispatch({
    type: 'SET_ADDRESS',
    data: data,
    prefix: prefix
  })
}

export const setAddressFromAPI = (dispatch, prefix, data, form) => {
  const names = generateNamesObject([
    'address_id',
    'post_address_id',
    'post_address_strid',
    'post_address_house_key',
    'line',
    'line_2',
    'zip_code',
    'city',
    'country'
  ], prefix)

  let obj = {
    [names.address_id]: data.id || null,
    [names.post_address_strid]: data.STRID?.toString(),
    [names.post_address_house_key]: data.HouseKey?.toString(),
    [names.line]: data.StreetName || data.HouseNo
      ? `${data.StreetName || ''} ${data.HouseNo || ''}${data.HouseNoAddition || ''}`
      : '',
    [names.zip_code]: data.ZipCode || '',
    [names.city]: data.TownName || '',
    [names.country]: data.CountryCode || '',
  }

  dispatch({
    type: 'SET_ADDRESS_FROM_API',
    form: form,
    data: obj
  })
}

export const setAddressFromRegistredAddress = (dispatch, prefix, data, form) => {
  const names = generateNamesObject([
    'address_id',
    'post_address_strid',
    'post_address_house_key',
    'line',
    'line_2',
    'zip_code',
    'city',
    'country'
  ], prefix)

  let obj = {
    [names.address_id]: data.id || null,
    [names.post_address_strid]: data.post_address_strid,
    [names.post_address_house_key]: data.post_address_house_key,
    [names.line]: `${data.line || ''}`,
    [names.line_2]: data.line_2 || '',
    [names.zip_code]: data.zip_code || '',
    [names.city]: data.city || '',
    [names.country]: data.country || '',
  }

  dispatch({
    type: 'SET_ADDRESS_FROM_API',
    form: form || 'form',
    data: obj
  })
}

export const setUserId = (dispatch, prefix, data, form) => {
  const names = generateNamesObject(['title', 'first_name', 'last_name'], prefix)

  dispatch({
    type: 'SET_USER_ID',
    form: form,
    data: {
      [names.title]: data.title,
      [names.first_name]: data.first_name,
      [names.last_name]: data.last_name,
    }
  })
} 

export const prefillEditForm = (dispatch, data, form) => {
  dispatch({
    type: 'SET_FORM',
    data: data,
    form: form
  })
}

export const prefillForm = (user, dispatch, data = {}, form, formValue) => {  
  const birthdate = data?.birthdate && new Date(data.birthdate)
  const species = data.species || formValue?.pet_species
  const gender = data.gender || formValue?.pet_gender
  const animalType = data.animal_type || formValue?.pet_animal_type
  const petOtherRace = data?.other_race || formValue?.pet_other_race
  const petOtherRace2 = data?.other_race_2 || formValue?.pet_other_race_2
  const holderBirthdate = user?.attributes?.birthdate && new Date(user.attributes.birthdate)
  const phoneNumber = user?.attributes?.phone_number
  const email = user?.attributes?.email
  const isChipped = !!data.is_chipped
  const chipNumber = data.chip_number
  const weightKg = data.weight 
    ? (parseInt(data.weight) / 1000) 
    : null
  
  const animalId = petOtherRace
    ? 'other'
    : data?.animal_id || formValue?.pet_animal_id

  const animalId2 = petOtherRace2
    ? 'other'
    : data?.animal_id_2 || formValue?.pet_animal_id_2
  
  let dataObj = {
    prefill: true,
    pet_id: data.id,
    pet_species: species,
    pet_gender: gender,
    pet_name: data.name,
    pet_birthdate: birthdate,
    pet_animal_type: animalType,
    pet_animal_id: animalId,
    pet_animal_id_2: animalId2,
    pet_other_race: petOtherRace,
    pet_other_race_2: petOtherRace2,
    pet_weight: weightKg,
    pet_is_chipped: isChipped,
    pet_chip_number: chipNumber,
    holder_birthdate: holderBirthdate,
    holder_mobile_phone: phoneNumber,
    holder_email: email
  }

  Object.entries(dataObj).forEach(([key, value]) => {
    if (!value) {
      delete dataObj[key]
    }
  })

  dispatch({
    type: 'SET_FORM',
    form: form || 'form',
    data: dataObj
  })
}

export const prefillFormFromOffer = (user, dispatch, data, petId) => {
  const holderBirthdate = user?.attributes?.birthdate && new Date(user.attributes.birthdate)
  const phoneNumber = user?.attributes?.phone_number
  
  let filteredData = data,
    rest = {
      holder_birthdate: filteredData.holder_birthdate && new Date(filteredData.holder_birthdate),
      holder_mobile_phone: user?.attributes?.phone_number,
      holder_email: user?.attributes?.email,
      agent_code: filteredData?.agent_code?.slice(0, 4),
      pet_birthdate: filteredData.pet_birthdate && new Date(filteredData.pet_birthdate),
      keeper_birthdate: filteredData.keeper_birthdate && new Date(filteredData.keeper_birthdate),
      insurance_enters_in_force_at: filteredData.insurance_enters_in_force_at && new Date(filteredData.insurance_enters_in_force_at)
    }

  Object.keys(filteredData).forEach(key => {
    if (!filteredData[key]) {
      delete filteredData[key]
    }
  })

  Object.keys(rest).forEach(key => {
    if (!rest[key]) {
      delete rest[key]
    }
  })

  const animalId = !!filteredData.pet_other_race
    ? 'other'
    : filteredData.pet_animal_id

  const animalId2 = !!filteredData.pet_other_race_2
    ? 'other'
    : filteredData.pet_animal_id_2

  dispatch({
    type: 'SET_FORM',
    form: 'form',
    data: {
      ...filteredData,
      ...rest,
      pet_id: parseInt(petId),
      pet_animal_id: animalId,
      pet_animal_id_2: animalId2,
      holder_birthdate: holderBirthdate,
      holder_mobile_phone: phoneNumber
    }
  })
}

export const resetValue = (dispatch, data) => {
  dispatch({
    type: 'RESET_VALUE',
    data: data
  })
}

export const setTypedSelect = (dispatch, key, data, objectKey) => {
  if (objectKey && !!data) {
    dispatch({
      type: 'SET_TYPED_SELECT',
      key: key,
      data: data,
      objectKey: objectKey
    })
  } else {
    dispatch({
      type: 'SET_TYPED_SELECT',
      key: key,
      data: data
    })
  }
}

export const getErrors = async (form, validator, fields) => {
  if (!validator || !fields?.length) {
    return null
  }

  const errors = await Promise.all(
    fields.map(async field => {
      try {
        await validator.validateAt(field, form)
      } catch (error) {
        return error
      }
    })
  )

  return errors.filter(item => !!item)
}

export const setIbanForm = (dispatch, prefixName, data, form) => {

  const names = generateNamesObject([
    'bank_id',
    'bank_name',
    'bank_zip_code',
    'bank_city',
  ], prefixName)

  const changes = {
    [names.bank_name]: data?.bank_long_name || null,
    [names.bank_id]: data?.id || null,
    [names.bank_zip_code]: data?.zip_code || null,
    [names.bank_city]: data?.place || null
  }

  dispatch({
    type: 'SET_FORM',
    form: form || 'form',
    data: changes
  })
  
}

export const clearAddressFields = (dispatch, prefix) => {
  const names = generateNamesObject([
    'line',
    'line_2',
    'city',
    'zip_code',
    'country'
  ], prefix)
  dispatch({
    type: 'SET_FORM',
    form: 'form',
    data: {
      [names.line]: '',
      [names.line_2]: '',
      [names.city]: '',
      [names.zip_code]: '',
      [names.country]: ''
    }
  })
}

export const fetchOrderToPets = async (pets, callback) => {
  let callbackResponse

  if (Array.isArray(pets)) {
    callbackResponse = []

    for (let index = 0; index < pets.length; index++) {
      let pet = pets[index]
      const quoteStatus = await Pet(pet).quoteStatus(axios)
      pet.quoteConcluded = quoteStatus === 'concluded'
  
      callbackResponse.push(pet)
    }

  } else {
    let pet = pets
    const quoteStatus = await Pet(pet).quoteStatus(axios)
    pet.quoteConcluded = quoteStatus === 'concluded'
    
    callbackResponse = pet
  }

  callback(callbackResponse)
}

export const updateSelectedOffers = async (formValue, dispatch, lang) => {
  let breeds = []

  if (!!formValue.pet_animal_id && !!formValue.pet_animal_id_2) {
    breeds = [formValue.pet_animal_id, formValue.pet_animal_id_2]
  } else if (!!formValue.pet_animal_id) {
    breeds = [formValue.pet_animal_id]
  }

  const data = {
    lang: lang?.substring(0, 2),
    holder_zip_code: formValue.holder_zip_code,
    holder_city: formValue.holder_city,
    pet_species: formValue.pet_species,
    pet_age_category: formValue.pet_age_category || formValue.pet_birthdate,
    pet_breeds: breeds,
    insurance_type: formValue.insurance_type,
    insurance_franchise: formValue.insurance_franchise,
    insurance_payment_frequency: formValue?.insurance_payment_frequency
  }

  const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/quotes/calculate-premiums`, data)
  if (response?.status !== 200) {
    return new Error('Error, could not fetch premiums')
  }

  const premiums = response.data.premiums

  if (premiums?.length && premiums.length == 1) {
    const offer = premiums[0]
    let data = {
      offer: offer,
      insurance_franchise: offer.insurance_franchise,
      insurance_type: offer.insurance_type,
      insurance_cost: offer.annual_premium.total_amount,
      insurance_month_cost: offer.monthly_premium.net_amount
    }

    if (
      (formValue.insurance_payment_frequency === 2 && offer.annual_premium.net_amount < 20000) || 
      (formValue.insurance_payment_frequency === 4 && offer.annual_premium.net_amount < 40000)
    ) {
      data.insurance_payment_frequency = 1 
    }

    dispatch({
      type: 'SET_FORM',
      form: 'form',
      data: data
    })
  }

  // if (formValue.insurance_payment_frequency) {
  //   const offerObj =
  //     insuranceValues
  //       .filter(matchKey(formValue, 'pet_species'))
  //       .filter(matchKey(formValue, 'pet_age_category'))
  //       .find(matchKey(formValue, 'insurance_franchise'))

  //   const price = formValue?.insurance_type && offerObj?.[formValue.insurance_type]
  //   const priceToCents = Math.round(convertPriceToCents(price) / 5) * 5
    
  //   let data = {
  //     insurance_month_cost: priceToCents
  //   }

  //   if (
  //     (formValue.insurance_payment_frequency === 2 && priceToCents*12 < 20000) || 
  //     (formValue.insurance_payment_frequency === 4 && priceToCents*12 < 40000)
  //   ) {
  //     data.insurance_payment_frequency = 1 
  //   }

  //   dispatch({
  //     type: 'SET_FORM',
  //     form: 'form',
  //     data: data
  //   })

  // }
}