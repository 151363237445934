module.exports = {
  btnAdd: 'Aggiungere un animale',
  yourPets: 'I miei animali',
  yourPetServices: 'I servizi di {{name}}',
  yourPetAbogourmand: "L'Abbonamento Gourmet di {{name}}",
  yourPetAssistance: 'L\'assistenza Animaliera di {{name}}',
  animal: 'Animale',
  cat: 'Gatto',
  dog: 'Cane',
  size: 'Taglia',
  gender: 'Sesso',
  female: 'Femmina',
  male: 'Maschio',
  animalTitle: 'Aggiungere un animale',
  animalEditTitle: 'Inserire un animale',
  animalDescription: 'Conosciamoci meglio',

  passportTitle: 'Passaporto Animalia',
  passportDescription: "Indicare l'identità del vostro buongustaio preferito.",
  itsName: 'Nome',
  noNameYet: 'Senza nome',
  bornOn: 'Data di nascita',
  birthdate: 'Data di nascita',
  breed: 'Razza',
  breedDescription: 'Con o senza pedigree, sappiamo quanto lo amate!',
  weight: 'Peso',
  catofbreed: 'Gatto di razza',
  dogofbreed: 'Cane di razza',
  kgWeight: 'Peso adulto in kg',
  kgWeightMaxError: 'Si prega di inserire un peso inferiore a 100 kg',
  adultWeightTitle: 'Peso da adulto',
  adultWeightDescription:
    'Anche se sappiamo che il suo benessere è una priorità per voi, sapere cosa indica la bilancia ci è molto utile!',
  chooseBreed: 'Scelta della razza',
  dogBastard: 'Incrocio',
  catBastard: 'Incrocio',

  activityTitle: 'Ognuno ha il suo ritmo',
  activityDescription:
    'Piuttosto sportivo o da pisolino sul divano? Il mio compagno a quattro zampe è...',
  activityLevel: 'Attività fisica',
  passive: 'Poco attivo',
  passiveDescription:
    "Più da divano che da corsa all'aperto, il vostro compagno è piuttosto un tipo casalingo?",
  normal: 'Moderato',
  normalDescription:
    'Giocare con un laccio o riportare il bastone va bene per un momento! Al vostro amico a quattro zampe piace muoversi ma senza stancarsi troppo.',
  active: 'Molto attivo',
  activeDescription:
    'Lunghe passeggiate e corsa controvento, adora portarvi allo sfinimento!',

  sterilizedTitle: 'Sterilizzato?',
  sterilizedDescription: 'Il vostro compagno è passato in sala operatoria?',
  sterilizedYes: 'Si (Ouch!)',
  sterilized: 'Sterilizzato',
  nonSterilized: 'Non sterilizzato',

  foodTypeStepTitle: 'Alimentazione',
  foodTypeTitle: 'Menù alla carta',
  foodTypeDescription:
    'Diteci di più sulla dieta del vostro piccolo o grande goloso.',
  standard: 'Standard',
  standardHelp:
    "Il vostro buongustaio a quattro zampe non soffre di nessuna allergia o intolleranza particolare? Allora adorerà le nostre crocchette 100 % naturali, garantite senza addittivi e soprattutto senza glutine, permettendo di prevenire qualsiasi richio d'intolleranza in futuro.",
  sensitive: 'Sensibile',
  sensitiveHelp:
    'Il vostro compagno a quattro zampe è un gourmet con uno stomaco piuttosto fragile? Abbiamo quello di cui avete bisogno: un menù delizioso ed equilibrato facile da digerire.',
  grainfree: 'Senza cereali',
  Cereali:
    'Il vostro compagno a quattro zampe è allergico o digerisce male i cereali? La nostra gamma di crocchette senza cereali contiene tutti i nutrimenti e le fibre di cui ha bisogno, il tutto tenendo conto della sua digestione.',
  grainfreeHelp:
    'Il vostro compagno a quatro zampe è allergico o fa fatica a digerire i cereali? La nostra gamma di crocchette senza cereali contiene tutti i nutrimenti e le fibre di cui ha bisogno, il tutto tenendo conto della sua digestione.',

  productStepTitle: 'Scelta del prodotto',
  productTitle: "Riempite la scodella (d'amore e crocchete)",
  productDescription:
    'Le nostre crocchette sono deliziose. Non resta altro che lasciarvi tentare!',

  adjustQuantityStepTitle: 'Adeguare la quantità',
  adjustQuantityTitle: 'A tavola!',
  adjustQuantityDescription:
    'Benvenuto {{name}}! Piacere di conoscerti! Secondo quanto ci ha riferito il tuo padrone e in collaborazione con il nostro veterinario nutrizionista, che conosce le nostre crocchette sulla punta delle dita, ecco la tua dose giornaliera raccommandata:',
  adjust80Comment:
    "Attenzione, non sono molte crochette! Completate la sua dieta con altri alimenti? Una dose inferiore del 20 % a quella raccommandata può provocare delle carenze e una sottoalimentazione al vostro compagno a quattro zampe e questo può danneggiare la sua salute. E se aggiungessimo qualche crocchetta nei nostri pacchtti d'amore?",
  adjust90Comment:
    'Il vostro amico peloso ha poco appetito? Ci sono altre prelibatezze oltre alle nostre deliziose crocchette? Allora avete la possibilità di ridurre la dose raccommandata del 10 %. Tuttavia, sarebbe opportuno rispettare la dose giornagliera raccomandata compensando con altri alimenti.',
  adjust100Comment:
    'Così scegliete esattamente la dose raccomandata! Ma visto che lo conoscete meglio di chiunque altro, non esitate ad adeguare la quantità di crocchette!',
  adjust110Comment:
    'Vivete con gran goloso o un gran sportivo? Potete aggiungere 10 % alla sua dose giornaliera. Senza esagerare, ovviamente!',
  adjust120Comment:
    'WOW! Sono tante crocchette! Attenzione al fatto che una razione superiore del 20 % a quella raccomandata può portare al sovrappeso e danneggiare la salute del vostro compagno a quattro zampe. E se mettessimo più amore e meno crocchette nella sua ciotola?',

  summaryTitle: "L'Abbonamento Gourmet",
  monthlyPrice: 'Prezzo al mese',

  upTo4kg: 'fino a 4kg',
  between4and6kg: 'da 4kg a 6kg',
  between6and8kg: 'da 6kg a 8kg',
  from8kg: 'da 8kg',

  // summary screen
  nextDeliveryDate: 'Prossima consegna',
  nextDeliveryOn: 'Prossima consegna il',
  deliveryFrequency: 'Frequenza di consegna',
  deliveredEachXDays: 'Consegna tutti i {{days}} giorni',
  deleteOrderFor: 'Disdire il mio abbonamento per {{name}}',
  product: 'Prodotto',
  dailyQuantity: 'Quantità giornaliera',
  order: "Proseguire con l'ordine",

  // details and listing screens
  backToMyPets: 'Ritornare ai miei animali',
  deletePet: 'Cancellare il profilo di {{name}}',
  byDeletingPetItWillCancelLinkedServices:
    "La preghiamo di notare che l'assicurazione sanitaria di {{name}} (se presente) non sarà cancellata se cancella il suo profilo.",
  reasonOfDeletion: "Le vostre ragioni per disdire l'abbonamento?",
  myPetIsDead: 'Il mio animale è venuto a mancare',
  notInterrestedAnymore: 'Non sono più interessato',
  thisPetCantBeDeleted:
    "Non può cancellare il profilo di {{name}} perché uno o più dei seguenti servizi sono ancora collegati al suo animale:",
  reasonAbo: 'Abbonamento Gourmet attivo o in attesa di consegna',
  reasonAssurance: "Collegamento a Animalia Assicurazione sanitaria",
  // my account
  itsAbo: 'Il suo Abbonamento',
  calculate:
    'Calcolate l’Abbonamento Gourmet del vostro compagno a quattro zampe',
  calculateAgain: 'Ricalcolare l’Abbonamento Gourmet',
  petDataHasChanged: 'I dati di {{name}} sono cambiati',
  seeOrderDetails: "Vedere i dettagli dell'Abbonamento Gourmet",
  seeInsuranceSettings: "Rimuova il collegamento con l'assicurazione Animalia",
  hasNoAccount: "{{name}} non ha ancora l'Abbonamento Gourmet",
  hasNoInsurance: '{{name}} non è ancora assicurato',
  itsInsurance: "L'assicurazione sanitaria di {{name}}",
  policeNumber: 'No di polizza',
  petDataHasChangedExtended:
    "Il profilo di {{name}} è stato modificato. Vi raccomandiamo di ricalcolare l'Abbonamento Gourmet per ottenere una nuova raccomandazione sulle quantità.",
  receiveOffer: "Richiedere un'offerta d'assicurazione",
  askForEstimate: "Chiedere un'offerta",
  calculator: 'Calcolatore',
  hasNoOrder: '{{name}} non ha ancora un abbonamento',
  adjustOrder: "Vuoi modificare l'abbonamento di {{name}}?",
  petHasRemainingDeliveries:
    'Attenzione: {{name}} ha pagamenti e/o consegne in sospeso',
  newAbo: 'Creare un nuovo abbonamento',
  temporaryDisabled: 'Per ragioni tecniche, le funzioni di dichiarazione dei sinistri e di invio delle fatture sono temporaneamente disabilitate su questa applicazione. Nel frattempo, può dichiarare i sinistri e inviare le fatture sul nostro sito web nel suo conto cliente: ',
  petRaceSearchPlaceholder: 'Ricercare',
  noInsurance: '{{name}} non ha ancora un\'assicurazione',
  noAssistance: '{{name}} non ha ancora l\'Assistenza 24/7',
};
