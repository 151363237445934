import React from 'react';

export default ({color = 'currentColor'}) => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 7.96875L5 4.63542L1 1.30208"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
