import * as yup from 'yup';

export default yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  line: yup.string().required(),
  line_2: yup.string().nullable(),
  zip_code: yup.number().required(),
  city: yup.string().required(),
});
