import React, {useContext} from 'react';
import {ThemeContext, FormField as GrommetFormField} from 'grommet';
import styled from 'styled-components';

const StyledFormField = styled(GrommetFormField)`
  background-color: #f6f6f6;

  > label {
    font-family: GothamBook;
    font-size: 14px;
    font-weight: 500;
  }
`;

const FormField = ({ ...rest }) => (
  <ThemeContext.Extend
    value={{
      global: {
        focus: {
          border: {
            color: 'brand',
          },
        },
      },
      formField: {
        border: {size: '3px', color: '#B2B6C1'},
        extend: 'span {font-size: 12px;line-height:1.2}',
        label: {
          margin: {
            bottom: '-4px',
          },
        },
      },
    }}>
    <StyledFormField {...rest} />
  </ThemeContext.Extend>
);

export default FormField;
