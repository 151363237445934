import React from 'react';
import {Box} from 'grommet';
import styled from 'styled-components';
import {BigTitle, Caption} from '../..';

const Header = styled(Box)`
  margin: 20px 0;
  width: 100%;
  max-width: 670px;
  margin: 35px auto;
`;

const SupTitle = styled.div`
  font-size: 12px;
  line-height: 1;
  text-align: ${props => (props.textAlign ? props.textAlign : null)};
  font-family: GothamBook;
  margin-bottom: 5px;
  color: #b2b6c1;
`;
const Title = styled.h1`
  font-size: 23px;
  line-height: 34.5px;
  margin: 0 0 8px;
  font-weight: 500;
  text-align: ${props => (props.textAlign ? props.textAlign : null)};
`;
const SubTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: ${props => (props.textAlign ? props.textAlign : null)};
  font-family: GothamBook;
  color: #292929;
`;

const PageHeader = ({
  children,
  suptitle,
  title,
  subtitle,
  textAlign = 'center',
}) => {
  return (
    <Header align="center">
      {typeof suptitle === 'object' ? (
        <SupTitle textAlign={textAlign}>{suptitle}</SupTitle>
      ) : (
        suptitle && (
          <SupTitle
            textAlign={textAlign}
            dangerouslySetInnerHTML={{__html: suptitle}}
          />
        )
      )}
      {typeof title === 'object' ? (
        <div style={{marginBottom: '20px'}}>
          <BigTitle center>{title}</BigTitle>
        </div>
      ) : (
        <div style={{marginBottom: '20px'}}>
          <BigTitle dangerouslySetInnerHTML={{__html: title}} />
        </div>
      )}
      {typeof subtitle === 'object' ? (
        <SubTitle textAlign={textAlign}>{subtitle}</SubTitle>
      ) : (
        <SubTitle
          textAlign={textAlign}
          style={{fontWeight: 100}}
          dangerouslySetInnerHTML={{__html: subtitle}}
        />
      )}
      {children}
    </Header>
  );
};

export default PageHeader;
