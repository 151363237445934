import React, { useEffect } from 'react'
import { Grid } from 'grommet'

const WidgetTightFlexibleGrid = ({ children, col, vertical }) => {

  const columnsConfig = () => {
    let array = []
    for (let index = 0; index < parseInt(col); index++) {
      array[index] = '1fr'
    }
    return array
  }

  const gapConfig = {
    row: 'small', 
    column: 'xsmall' 
  }
  
  return (
    <Grid 
      columns={columnsConfig()} 
      gap={gapConfig}
      margin={{ vertical: vertical }}
    >
      { children }
    </Grid>
  )
}

export default WidgetTightFlexibleGrid
