module.exports = {
  calculatorTitle: 'Gesundheitsversicherung',
  calculatorDescription: 'Berechnen Sie mit drei Klicks die Versicherungsprämie für Ihren liebsten Vierbeiner.',
  petSpecies: '',
  petAgeCategory: 'Alter', // Dropdown, selection d'âge
  ageCategory: 'im Alter von', // InsuranceType Step suptitle / format: {{ pet_species }} im Alter von {{ pet_age_category }}
  conditions: 'Wenn Sie eine Offerte anfordern oder online abschliessen, bearbeiten die Vaudoise Versicherungen Ihre persönlichen Daten auf Servern in Deutschland für das Erstellen, Speichern und Bereitstellen im Internet der Offerte und in den USA (bei Mandrill von <a href="https://www.brevo.com/de/legal/privacypolicy/" target="_blank">MailChimp</a>) für den Versand von E-Mails und damit verbundene statistische und Performance-Analysen. Ihre Daten werden innerhalb von 30 Tagen nach zwei erfolglosen Erinnerungen gelöscht. Diese Informationen ergänzen die <a href="https://www.vaudoise.ch/de/service-center/rechtliche-hinweise/datenschutz" target="_blank">Datenschutzerklärung der Vaudoise Versicherungen</a>, die im Übrigen anwendbar ist.', 
  thanks: 'Vielen Dank für Ihre Offertanfrage', // NOTE: titre remerciement après envoie de l'offre depuis le widget
  offer_in_mailbox: 'Wir haben Ihnen ein Mail mit dem Link auf die Offerte für {{pet_name}} geschickt. Sie können die Details der Versicherung (Leistungen, Prämien, Bedingungen) der Offerte entnehmen, sie ausdrucken, oder auch gleich die Versicherung für {{pet_name}} online abschliessen.', // NOTE: texte remerciement après envoie de l'offre depuis le widget
  plus_8_warning: 'Ihr Vierbeiner ist älter als 8 Jahre alt. Bitte beachten Sie, dass Krankheiten, die bereits vor dem Abschluss der Versicherung bestehen, nicht versichert werden können.',
  yearsRange: {
    u2: '3 Monate bis 2 Jahre',
    u4: '3 bis 4 Jahre',
    u6: '5 bis 6 Jahre',
    u8: '7 bis 8 Jahre',
    u10: '9 bis 10 Jahre',
    u12: '11 bis 12 Jahre',
    o13: '13 Jahre und älter'
  },
  race: {
    suptitle: 'Rasse und Wohnort',
    pet_mixed: 'Mischling',
    pet_breed: 'Rasse',
    pet_unknown: 'Unbekannt',
    breed: 'Rasse',
    breed_2: 'Zweite Rasse',
    zipcode: 'Postleitzahl',
    city: 'Ort'
  },
  navigation: {
    goToInsuranceStep: 'Prämie berechnen',
    submitFromInsuranceStep: 'Versicherung abschliessen',
    sendOfferByEmail: 'Offerte per E-Mail erhalten',
    prev: 'Kriterien ändern',
    next: 'Weiter',
    ok: 'Zurück zum Prämienrechner', // NOTE: label du bouton de la page de remerciement après envoie de l'offre depuis le widget, Karin: dès que le bouton menera à la landingpage de l'offre, le texte sera: 'Offerte ansehen'
    seeOffer: 'Offerte ansehen'
  },
  user: {
    petName: 'Name Ihres Tieres',
    lastName: 'Ihr Name *',
    firstName: 'Ihr Vorname *',
    emailAddress: 'E-Mailadresse',
    phoneNumber: 'Telefonnummer',
  },
  offer_conclusion_1: 'Sie können die Details der Versicherung für {{pet_name}} (Leistungen, Prämien, Bedingungen) der Offerte entnehmen, diese ausdrucken, oder auch gleich die Versicherung online abschliessen.',
  offer_conclusion_2: 'PS: Wir haben Ihnen ebenfalls ein Mail mit dem Link auf die Offerte geschickt.'
}


