module.exports = {
  pet: {
    add_pet: 'Ajouter un animal',
    add_pet_caption: 'Faisons plus ample connaissance',
    species: 'Animal',
    dog: 'Chien',
    cat: 'Chat',
    gender: 'Sexe',
    male: 'Mâle',
    female: 'Femelle',
    select_pet: 'Quel animal souhaitez-vous assurer ?',
    select_pet_description:
      'Sélectionnez-le ci-dessous ou créez un nouveau profil.',
    portrait: 'Données de votre animal',
    portrait_caption: '',
    name: "Nom usuel de l'animal",
    name_long: "Nom usuel de l'animal",
    birthdate: 'Sa date de naissance',
    birthdate_tip: 'Nous assurons les animaux dès 3 mois.',
    is_pure_breed: '',
    race: 'Race',
    pet_mixed: 'Croisée', // si c'est le titre en dessus des 3 possibilités (pure race, croisééée, inconnue) --> enlever
    pet_breed: 'Pure race',
    pet_not_breed: 'Croisée',
    pet_unknown: 'Inconnue',
    fill_two_races: '',
    fill_race: 'Renseigner la race',
    first_race: 'Première race',
    second_race: 'Deuxième race',
    which_pet_weight: 'Poids approximatif en kg',
    weight: "Poids à l'âge adulte en kg",
    chipped: 'Est-il pucé ?',
    chip_number: 'Numéro de la puce (Recommandé)',
    pet_age_calcul_info:
      'L\'âge de l\'animal est calculé comme suit : "Année actuelle" - "Année de naissance" (par ex. 2023 - 2017 = 6)',
  },
  insurance: {
    protection: 'Assurance',
    protection_type: 'Couverture',
    classic_condensed: 'Classic',
    comfort_condensed: 'Comfort',
    compact_condensed: 'Compact',
    compact: 'Animalia Compact',
    compact_description: "Couverture en cas d'accidents",
    classic: 'Animalia Classic',
    classic_description:
      "Couverture en cas d'accidents et de maladies aigues et chroniques",
    comfort: 'Animalia Comfort',
    comfort_description:
      "Couverture en cas d'accidents et de maladies aigues, chroniques, <b>héréditaires ou congénitales</b>",
    select_protection: '',
    franchise: 'Franchise',
    insurance: 'Assurance',
    payment: 'Paiement',
    select_payment: '',
    annual: 'Annuel',
    annual_cost: 'Prime annuelle totale',
    biannual: 'Semestriel',
    month: 'mois',
    monthly: 'Semestriel',
    monthly_cost: 'Prime mensuelle indicative',
    monthly_cost_info: 'Prime mensuelle indicative',
    monthly_cost_tip: '',
    quarterly: 'Trimestriel',
    enters_in_force_at: 'Début du contrat',
    agent_code: 'Code agent',
    agency_tip:
      'Si vous avez droit à une réduction, celle-ci sera directement déduite de votre facture.',
    agency: 'Code promo',
    my_details: 'Vos coordonnées',
    needed_details: '',
    new_address: 'Ajouter une adresse',
    plan_not_available:
      "Cette couverture n'est pas disponible avec cette franchise",
    are_you_pet_keeper:
      "Êtes-vous le détenteur de l'animal ou prenez-vous l'assurance pour une personne tierce ?",
    im_keeper: "Je suis le détenteur de l'animal",
    im_not_keeper: "Je prends une assurance pour l'animal de quelqu'un d'autre",
    refund_account: 'Compte pour les remboursements',
    no_iban: 'No IBAN',
    bank_name: 'Nom de la banque',
    bic: '',
    keeper_address: 'Adresse du détenteur', // Titre de champ lié à "Adresse"
    line: 'Adresse', // Titre de champ lié à "Adresse"
    line_2: "Complément d'adresse", // Titre de champ lié à "Adresse"
    zip_code: 'Code postal', // Titre de champ lié à "Adresse"
    city: 'Localité', // Titre de champ lié à "Adresse"
    conclude: "Conclure l'assurance",
    insurance_of: "L'assurance de",
    insurance_owner: "Preneur d'assurance",
    complete_profile: 'Compléter le profil',
    offer_page_description: 'offer_page_description Lorem Ipsum....', //c'est quoi, ce texte? Il est affiché où?
    concluded_description: 'concluded_description Lorem Ipsum....', //c'est quoi, ce texte? Il est affiché où?
    offer_already_concluded:
      'Cette offre a été conclu entre-temps. Vous ne pouvez plus la conclure.',
    offer_concluded_line:
      '{{pet_name}} fait maintenant partie de la communauté Animalia et nous sommes à vos côtés.',
    offer_concluded_line_2:
      "Vous recevrez votre police d'assurance et un bulletin de versement dans les prochains jours par courrier postal. Vous pourrez alors déclarer les sinistres et envoyer les factures pour {{pet_name}} par le biais de votre compte client Animalia.",
    offer_concluded_line_3:
      'Pour vous remercier de votre confiance, nous sommes heureux de vous offrir des bons de réduction et de vous proposer des offres partenaires pour le bien de votre boule de poil et pour vous-même.',
    offer_banner_cta: 'Rejoignez la communauté Animalia',
    offer_fressnapf: 'Télécharger le bon MAXI ZOO',
    assistance: 'Assistance 24/7',
    assistance_btn: "Découvrir l'Assistance 24/7",
    assistance_link: 'https://shop.animalia.ch/product/assistance-24-7/',
    assistance_title:
      "Une assistance 24/7 pour une tranquillité d'esprit totale",
    assistance_desc:
      "Pour seulement CHF 79.- supplémentaire par année, vous profitez d'une assistance permanente pour répondre à vos questions médicales, urgentes, ou administratives concernant votre animal. De plus vous bénéficiez de couverture d'assurance complémentaire comme par exemple les frais de transports de l'animal en cas d'urgence. Ne manquez pas cette occasion et découvrez tout sur l'assistance 24/7.​",
    assistance_desc_sale:
      "Il est temps maintenant de profiter de la Promotion d'hiver. Utilisez le code rabais pour bénéficier de cette offre avec le code rabais*",
    offer_shop: 'Télécharger le bon pour notre shop Animalia',
    offer_fressnapf_url:
      'https://prismic-io.s3.amazonaws.com/animalia/694a1fa9-f0bd-4b9f-85c4-a453f826a6cc_22-10-019_animalia_gutschein_130x85_fr_lay_v1_020222_5.pdf',
    offer_shop_url:
      'https://prismic-io.s3.amazonaws.com/animalia/08ef4fc2-3668-4295-b8a9-eb88f5f27593_21_ANIMALIA_SHOP_FLYER_A7_FR.pdf',
    tonivet_label: 'Télécharger le bon pour les croquettes Tonivet',
    tonivet_link:
      'https://shop.animalia.ch/croquettes-tonivet-lexcellence-veterinaire/',
    cga:
      'J’ai pris connaissance des <a href="https://prismic-io.s3.amazonaws.com/animalia/7c3008e5-cd69-4b3f-beef-b249c413c4e6_CGA_Animalia_2021_F_WEB.pdf" target="_blank">Conditions générales d\'assurance (CGA)</a>, les accepte et je comprends que les primes évoluent avec l’âge de mon animal.',
    cga_1:
      'Je déclare avoir répondu en toute sincérité aux questions qui précèdent, afin de valider légalement le contrat Animalia avec la Vaudoise.',
    cga_2:
      "Je donne mon accord à la Vaudoise pour le traitement des renseignements fournis dans le cadre de la gestion du contrat, notamment en cas de sinistre. Je l'autorise à les transmettre si nécessaire aux réassureurs ou d'autres assureurs, ainsi qu'à recueillir des renseignements auprès de ces derniers ou de tiers.",
    breed_tip: 'Les deux parents de votre animal sont de la même race',
    bastard_tip: 'Les parents de votre animal sont de races différentes',
    unknown_tip:
      "Les parents de votre animal résultent d'un croisement de trois races ou plus, ou vous ne connaissez pas les races",
    federal_timber_of: 'Timbre fédéral 5% de',
    surprime: 'Frais paiement fractionné',
    annual_cost_basic: 'Prime annuelle de base',
    client: 'Le client',
    not_complete: "Offre d'assurance en cours",
    waiting:
      "Votre proposition d'assurance a été envoyée avec succès. Vous allez voir ici le numéro de votre police ces prochains jours.",
    fill_offer: "Voir l'offre en cours et la finaliser",
    login_title: 'Avez-vous déjà un compte Animalia ?',
    login_description:
      "Pour terminer la conclusion de votre assurance, vous devez vous connecter ou créer un compte client Animalia. Vous aurez ainsi accès à vos conclusions en cours et aux informations relatives à votre contrat d'assurance. De plus, votre compte client vous permettra par la suite de nous transmettre en tout temps les factures de traitement de votre boule de poil.",
    login_yes: "Oui, j'ai déjà un compte client",
    login_no: "Non, je n'ai pas encore de compte client",
    save_success:
      'La conclusion a été transférée avec succès. Le·la client·e recevra la police ces prochains jours.',
    num: 'No.',
    api_found: 'Adresse validée par la Poste Suisse',
    api_not_found:
      "Cette adresse n'est pas répertoriée par la Poste Suisse. Veuillez svp entrer une adresse valable.",
    start_date: 'Début du contrat',
    end_date: "Fin du contrat (renouvellement tacite d'année en année)",
    cga_min: 'CGA',
    maintenance_title: 'Maintenance',
    maintenance_description:
      "Nous vous informons que la conclusion online n'est pas disponible en ce moment jusqu'à minuit environ.  Ceci est dû à une maintenance d'un service de la poste utilisé pour la validation de votre adresse postale.",
    maintenance_button: 'Demander une offre',
    discount_hotelcard_link: 'https://hotelcard.com/fr/animalia-30',
    discount_hotelcard_label: "Découvrez l'offre exclusive  Hotelcard",
  },
  error: {
    general: 'Veuillez remplir le champ',
    invalid_date: 'Veuillez sélectionner une date valide',
    min_18: "Vous devez être âgé d'au minimum 18 ans",
    pet_species_required: "Veuillez sélectionner un type d'animal",
    pet_gender_required: 'Veuillez sélectionner le genre de votre animal',
    pet_name_required: 'Veuillez remplir le nom usuel de votre animal',
    pet_birthdate_required:
      'Veuillez sélectionner la date de naissance de votre animal',
    pet_is_purebred_required: 'Veuillez indiquer si votre animal est de race',
    pet_two_races_known_required:
      'Veuillez indiquer si vous connaissez les deux races de votre compagnon',
    pet_animal_id_required: 'Veuillez sélectionner une race',
    pet_weight_required:
      'Veuillez indiquer le poids approximatif en kg de votre compagnon',
    pet_weight_number_required: 'Veuillez indiquer un poids valide',
    insurance_franchise_required:
      'Veuillez sélectionner le montant de franchise désiré',
    insurance_type_required: 'Veuillez sélectionner la couverture souhaitée',
    insurance_payment_frequency_required:
      'Veuillez sélectionner la fréquence de paiement souhaitée',
    insurance_enters_in_force_at_required:
      'Veuillez entrer la date du début du contrat souhaitée',
    title_required: 'Veuillez sélectionner un titre',
    first_name_required: 'Veuillez entrer votre prénom',
    last_name_required: 'Veuillez entrer votre nom de famille',
    line_required: 'Veuillez entrer votre adresse',
    __code_required: 'Veuillez entrer un code postal',
    city_required: 'Veuillez entrer une ville',
    birthdate_required: 'Veuillez entrer une date de naissance',
    holder_phone_required: 'Veuillez entrer un numéro de téléphone',
    bank_not_found:
      "La banque n'a pas été trouvée. Veuillez remplir les champs manuellement.",
    iban_required:
      'Veuillez entrer votre no IBAN (ex. CH80 00000 0000 0000 0000 0)',
    iban_invalid:
      'Votre no. IBAN est invalide (ex. CH80 00000 0000 0000 0000 0)',
    bank_name_required: 'Veuillez indiquer le nom de votre banque',
    bic_required: '',
    bank_address_required: '',
    bank___code_required: 'Veuillez entrer le code postal de votre banque',
    bank_city_required: 'Veuillez entrer la ville de votre banque',
    max_char_4: 'Le code agent est constitué de 4 chiffres',
    email_required: '', // TODO: Karin, chand d'erreur au caus un l'utilisteur n'insert pas d'email (widget)
    email_invalid: 'Ce champ doit être un email valide.',
    more_char: 'Ce champs doit contenir au minimum 2 caractères.',
    firstname_error: "Le prénom n'est pas valide",
    lastname_error: "Le nom n'est pas valide",
    uppercase: 'La première lettre doit être en majuscule',
    all_uppercase: 'Ce champs ne peut pas contenir que des majuscules',
    wrong_iban:
      "Ce no IBAN n'est pas valable. Veuillez svp corriger la saisie.",
    zip_code_required: 'Veuillez entrer un code postal',
    only_one_uppercase: 'Ce champs doit contenir uniquement une majuscule',
    no_special_case: 'Ce champs ne doit pas contenir de caractères spéciaux',
  },
  navigation: {
    calculate: 'Calculer la prime',
    submit_offer: "Conclure l'assurance",
    send_offer_by_email: "Recevoir l'offre par email",
    next: 'Suivant',
  },
};
