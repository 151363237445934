module.exports = {
  firstDeliveryDate: 'Première livraison le',
  lastDelivery: 'Dernière livraison',
  selectLastDelivery: 'Sélectionnez votre dernière date de livraison',
  validateDate: 'Valider la date',
  deleteOrder: 'Résilier',
  cancelled: 'Abonnement annulé',
  couponCodeLabel: 'Code rabais',
  couponCodePlaceholder: 'Votre code rabais',
  invalidCoupon: 'Code rabais pas valable',
  editOrder: "Modifier l'abonnement",
  showAllProducts: 'Montrer d\'autres variétés',
  showRecommandedProducts: 'Afficher les produits recommandés',
  notRecommendedTooltip: 'Vous pouvez bien entendu commander ces croquettes. Cependant, selon vos indications, nous vous recommandons un autre produit.',
  confirmModification:
    "Suite à vos modifications, votre Abo Gourmand a changé de prix. Veuillez svp confirmer que vous êtes d'accord.",
  couponDetailsUnlimited: 'Vous bénéficiez de {{discount}} de rabais.',
  couponDetails:
    'Vous bénéficiez de {{discount}} de rabais pendant {{months}} mois. Ensuite, le prix sera adapté au prix officiel.',
  priceWithoutDiscount: '(Prix sans rabais : {{price}})',
  payAmount: 'Payer {{price}}',
  error: {
    pleaseAcceptTerms: 'Veuillez svp accepter les Conditions générales',
    pleaseChooseADeliveryDate: 'Veuillez svp choisir la date pour la première livraison',
  },
  pricePerMonth: 'Prix par mois',
  price: 'Prix',
  totalPricePerMonth: 'Prix total par mois',
  discount: 'Rabais',
  thankyou: {
    title: 'Merci et Bravo !',
    text:
      "Merci d'avoir choisi l'Abo Gourmand ! Votre commande est validée et nous commençons déjà à préparer son repas conçu avec des produits de haute qualité et riches en protéines. Le paquet arrivera dans quelques jours devant votre porte. Votre boule de poils va se lécher les babines et vous remerciera d'avoir fait le bon choix. Nous sommes heureux de vous compter parmi notre communauté de Pattes-Lovers !",
    button: 'Voir ma commande',
    subText:
      'Une question ? Nous sommes là pour vous via notre chat online ou par téléphone au 0800 815 915',
  },
  mfgroupErrorUnavailable: "Vous ne pouvez pas utiliser ce moyen de paiement pour le moment. Veuillez svp en choisir un autre.",
  addPaymentMethodError: "Ce moyen de paiement n'est pas valide. Veuillez svp en saisir un autre."
};
