module.exports = {
  myaccount: 'Espace Client Animalia',
  'create-ag': {
    title: 'Calculer un Abo Gourmand',
    subtitle: 'Pour lequel de vos boules de poils aimeriez-vous calculer un Abo Gourmand ?',
    cta: 'Calculer son Abo Gourmand'
  },
  'edit-ag': {
    title: 'Modifier l\'Abo Gourmand',
    subtitle: 'Pour lequel de vos boules de poils aimeriez-vous modifier l\'Abo Gourmand ?',
    cta: 'Modifier son Abo Gourmand'
  },
  'calculate-insurance': {
    title: 'Calculer l\'assurance santé',
    subtitle: 'Pour lequel de vos boules de poils aimeriez-vous calculer une assurance santé ?',
    cta: 'Calculer son assurance'
  },
  'declare-sinister': {
    title: 'Déclaration d\'un sinistre',
    subtitle: 'Pour lequel de vos boules de poils aimeriez-vous déclarer un sinistre ?',
    cta: 'Déclarer le sinistre'
  },
  'send-invoice': {
    title: 'Remboursement d\'une facture',
    subtitle: 'Pour lequel de vos boules de poils aimeriez-vous nous envoyer une facture pour remboursement ?',
    cta: 'Envoyer la facture'
  },
  'edit-insurance': {
    title: 'Modification de l\'assurance',
    subtitle: 'Pour lequel de vos boules de poils aimeriez-vous modifier l\'assurance santé ?',
    cta: 'Modifier son assurance'
  },
};
