module.exports = {
  holder: 'Versicherungsnehmer',
  pet_type: 'Tierart',
  age_category: 'Alterskategorie des Tieres',
  quote_part: 'Eigener Anteil',
  quote_part_tip:
    'Sobald der Selbstbehalt erreicht ist, übernimmt Animalia 90% der Kosten. Die restlichen 10% tragen immer Sie',
  quote_part_value: '10%',
  franchise_title: 'Selbstbehalt und eigener Anteil',
  insurance_prime: 'Versicherungsprämie',
  tooltip_compact_fix: 'Fixprämie, unabhängig vom Alter des Tieres', // tooltip de insurance_prime quand c'est une assurance compact', // tooltip de insurance_prime quand c'est une assurance compact
  prestations: 'Leistungen pro Kalenderjahr',
  prestations_tip: '(gemäss den allgemeinen Versicherungsbedingungen)',
  medical_cost_coverage: 'Deckung der medizinischen Kosten',
  medical_cost_coverage_value: 'Unbegrenzt',
  prevention_cost: 'Präventionskosten',
  prevention_cost_tip: 'ohne Selbstbehalt und eigenen Anteil',
  prevention_cost_value: 'CHF 100.-',
  alt_medicinal: 'Alternative Medizin',
  alt_medicinal_value: 'CHF 600.-',
  housing_cost: 'Unterkunftskosten',
  housing_cost_value: 'CHF 200.-',
  investment_cost: 'Unterbringungskosten',
  investment_cost_value: 'CHF 150.-',
  freight_cost: 'Transportkosten',
  freight_cost_value: 'CHF 100.-',
  waiting_period: 'Karenzfrist',
  accident: 'Unfall',
  accident_value: 'keine',
  illness: 'Krankheit',
  illness_value: '30 Tage',
  compact_title_description: 'Mit Animalia Compact treffen Sie eine gute Wahl!',
  classic_title_description: 'Mit Animalia Classic treffen Sie eine gute Wahl!',
  comfort_title_description: 'Mit Animalia Comfort treffen Sie eine gute Wahl!',
  description_compact: `Deckung bei Unfall`,
  description_classic: `Deckung bei Unfall und bei akuten und chronischen Krankheiten`,
  description_comfort: `Deckung bei Unfall, akuten, chronischen und angeborenen Krankheiten sowie bei Erbkrankheiten`,
  conditions_title: 'Anwendbare Bedingungen',
  conditions_description:
    'Allgemeine Versicherungsbedingungen Animalia, Ausgabe 1.11.2021',
  conditions_url:
    'https://prismic-io.s3.amazonaws.com/animalia/3a327fa3-f270-44dc-8eab-88252b5c10c7_CGA_Animalia_2021_D_WEB.pdf',
  conditions_info:
    'Achtung: Erbkrankheiten und/oder Geburtsgebrechen sind nicht versichert. (gemäss Art. B5 der Allgemeinen Versicherungsbedingungen)',
  law_application:
    '<p>Die Anpassung der Prämie an die nächsthöhere Altersklasse erfolgt am 1. Januar des Jahres, in dem das Tier das Alter von drei bzw. fünf, sieben, neun, elf und dreizehn Jahren erreicht.</p>',
  offer_validity: 'Gültigkeit der Offerte',
  offer_validity_description:
    'Diese Offerte basiert auf den uns vorliegenden Angaben. Sie ist bis am {{validity_date}} gültig.', // TODO: @Calvin Cette date doit être calculée -> date de la création de l'offre + 90 jours
  offer_conclusion:
    'Animalia ist ein Angebot der Vaudoise Versicherungen. Dank ihrer genossenschaftlichen Wurzeln gibt die Vaudoise einen Teil ihres Gewinns an ihre Kund:innen weiter. Als Animalia-Versicherte:r profitieren auch Sie davon.',
  offer_conclusion_2:
    'Versichern Sie {{pet_name}} jetzt für ein unbeschwertes Leben.', // TODO: @Calvin nom de l'animal a ajouter dynamiquement
  go_further: 'Versicherung abschliessen', // TODO: @Calvin nom de l'animal a ajouter dynamiquement
  print: 'Offerte Ausdrucken',
  federal_timber: 'Eidg. Stempelsteuer',
  federal_timber_tip:
    'Die Eidgenössische Stempelsteuer wird auf der Jahresprämie (inkl. eventuelle Zuschläge für Ratenzahlung) berechnet.',
  total: 'Jahresgesamtprämie',
};
