module.exports = {
  error: 'Fehler',
  warning: 'Achtung',

  apiError: 'Es gab ein Fehler während der Verarbeitung ihrer Anfrage.',
  fieldValidationError: 'Ein oder mehrere Felder sind ungültig.',

  petNotFound: 'Das Tier wurde nicht gefunden',
  petsNotFound: 'Es wurde kein Tier gefunden',

  insuranceNumberDelete: {
    title: 'Verbindung mit der Policennummer von {{name}} trennen',
    content:
      'Achtung : Sie trennen lediglich die Verbindung mit der Policennummer von {{name}}. Der Versicherungsvertrag bleibt bestehen. Falls Sie diesen kündigen möchten, tun Sie dies bitte gemäss den Allgemeinen Versicherungsbedingungen (AVB).',
  },

  card: {
    cancelled: 'Zahlung abgebrochen',
    error: 'Bitte prüfen Sie die Informationen Ihres Zahlungsmittels.',
  },

  latePayment: {
    title: 'Ups, Sie haben noch eine offene Rechnung!',
    content:
      'Eine Rechnung ist noch offen. Bitte bezahlen Sie diese bevor Sie ein neues Gourmet-Abo abschliessen.',
  },

  quitWarning: {
    title: 'Möchten Sie das Formular wirklich verlassen?',
    description:
      'Wenn Sie zurück zu ‘{{destination}}’ gehen, verlieren Sie alle Ihre Eingaben.',
    continue: 'Formular verlassen',
    cancel: 'Auf der Seite bleiben',
  },
};
