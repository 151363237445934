module.exports = {
  yourPetAssistance: 'L\'Assistance 24/7 de {{name}}',
  policeNumber: 'No de police {{number}}',
  noAssistanceSubtitle: '{{name}} n\'a pas encore l\'Animalia Assistance 24/7',
  cga: 'CGA',
  contractStartDate: 'Début du contrat',
  contractEndDate: ' Fin du contrat (renouvellement tacite d\'année en année)',
  callHelpLine: 'Appeler l\'Infoline 24/7',
  declareSinister: 'Déclarer un cas d\'assistance pour {{name}}',
  deleteAssistance: 'Résilier Animalia Assistance 24/7 de {{name}}',
  moreInformations: 'Plus d\'informations concernant Animalia Assistance 24/7',
  createAssistance: 'Conclure Animalia Assistance 24/7 pour CHF 79.-/an',
  policy: "Police d'assurance Assistance 24/7",
  moreInformationsLink: 'https://www.animalia.ch/service/assistance',
  createAssistanceLink: 'https://shop.animalia.ch/product/assistance-24-7/',
  deleteAssistanceLink: 'mailto:contact@animalia.ch',
  callHelpLineLink: 'tel:+41225937326',
  declareSinisterLink: 'https://shop.animalia.ch/mon-compte/declaration-de-sinistre/',
}