module.exports = {
  BACK_TO_SIGN_IN: 'Zurück zum Login',
  CHANGE_PASSWORD: 'Passwort ändern',
  CHANGE_PASSWORD_ACTION: 'Ändern',
  CODE_LABEL:
    'Geben Sie hier bitte den Code ein, den wir Ihnen per E-Mail gesandt haben',
  CODE_PLACEHOLDER: 'Code eingeben',
  CONFIRM: 'Bestätigen',
  CONFIRM_SIGN_UP_CODE_LABEL: 'Code bestätigen',
  CONFIRM_SIGN_UP_CODE_PLACEHOLDER: 'Bitte geben Sie Ihren Code ein',
  CONFIRM_SIGN_UP_HEADER_TEXT: 'Anmeldung bestätigen',
  CONFIRM_SIGN_UP_LOST_CODE: 'Code verloren?',
  CONFIRM_SIGN_UP_RESEND_CODE: 'Neuen Code senden',
  CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT: 'Bestätigen',
  CONFIRM_SMS_CODE: 'SMS Code bestätigen',
  CONFIRM_TOTP_CODE: 'TOTP Code bestätigen',
  CREATE_ACCOUNT_TEXT: 'Konto erstellen',
  EMAIL_LABEL: 'E-Mailadresse *',
  EMAIL_PLACEHOLDER: 'E-Mailadresse eingeben',
  EMAIL_ERROR: 'Bitte geben Sie eine gültige E-Mailadresse ein',
  FORGOT_PASSWORD_TEXT: 'Passwort vergessen?',
  LESS_THAN_TWO_MFA_VALUES_MESSAGE: 'Weniger als zwei mfa-Typen verfügbar',
  NEW_PASSWORD_LABEL: 'Neues Passwort',
  NEW_PASSWORD_PLACEHOLDER: 'Neues Passwort festlegen',
  NO_ACCOUNT_TEXT: 'Noch kein Animalia-Konto?',
  PASSWORD_LABEL: 'Passwort *',
  PASSWORD_PLACEHOLDER: 'Bitte geben Sie Ihr Passwort ein',
  PHONE_LABEL: 'Telefonnummer *',
  PHONE_PLACEHOLDER: '79 555 55 55',
  QR_CODE_ALT: 'QR-Code',
  RESET_PASSWORD_TEXT: 'Passwort zurücksetzen',
  RESET_YOUR_PASSWORD: 'Bitte setzen Sie Ihr Passwort zurück',
  SELECT_MFA_TYPE_HEADER_TEXT: 'MFA-Typ auswählen',
  SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT: 'Überprüfen',
  SEND_CODE: 'Code senden',
  SETUP_TOTP_REQUIRED: 'TOTP muss konfiguriert werden',
  SIGN_IN_ACTION: 'Einloggen',
  SIGN_IN_HEADER_TEXT: 'Login Animalia-Konto',
  SIGN_IN_TEXT: 'Einloggen',
  SIGN_IN_WITH_AMAZON: 'Login mit Amazon',
  SIGN_IN_WITH_AUTH0: 'Login mit Auth0',
  SIGN_IN_WITH_AWS: 'Login mit AWS',
  SIGN_IN_WITH_FACEBOOK: 'Login mit Facebook',
  SIGN_IN_WITH_GOOGLE: 'Login mit Google',
  SIGN_OUT: 'Ausloggen',
  SIGN_UP_EMAIL_PLACEHOLDER: 'E-Mail',
  SIGN_UP_HAVE_ACCOUNT_TEXT: 'Haben Sie schon ein Animalia-Konto?',
  SIGN_UP_HEADER_TEXT: 'Neues Konto erstellen',
  SIGN_UP_PASSWORD_PLACEHOLDER: 'Passwort',
  SIGN_UP_SUBMIT_BUTTON_TEXT: 'Animalia-Konto erstellen',
  SIGN_UP_ALREADY_BUTTON_TEXT: 'Ich habe schon ein Konto',
  SIGN_UP_USERNAME_PLACEHOLDER: 'Benutzername',
  SUCCESS_MFA_TYPE: 'Ihr MFA Typ ist jetzt:',
  TOTP_HEADER_TEXT: 'Scannen und geben Sie den Überprüfungscode ein',
  TOTP_LABEL: 'Geben Sie den Sicherheitscode ein:',
  TOTP_SETUP_FAILURE: 'TOTP Setup hat nicht geklappt',
  TOTP_SUBMIT_BUTTON_TEXT: 'Sicherheits-Token überprüfen',
  TOTP_SUCCESS_MESSAGE: 'TOTP-Setup war erfolgreich!',
  UNABLE_TO_SETUP_MFA_AT_THIS_TIME: 'MFA konnte nicht konfiguriert werden',
  USERNAME_LABEL: 'Benutzername *',
  USERNAME_PLACEHOLDER: 'Benutzernamen eingeben',
  VERIFY_CONTACT_EMAIL_LABEL: 'E-Mail',
  VERIFY_CONTACT_HEADER_TEXT:
    'Für die Wiederherstellung des Animalia-Kontos brauchen wir überprüfte Kontaktinformationen',
  VERIFY_CONTACT_PHONE_LABEL: 'Telefonnummer',
  VERIFY_CONTACT_SUBMIT_LABEL: 'Schicken',
  VERIFY_CONTACT_VERIFY_LABEL: 'Überprüfen',
  PASSWORD_HELP:
    'Mindestens 8 Zeichen mit mindestens einer Zahl, einem Grossbuchstaben, einem Kleinbuchstaben und einem Sonderzeichen.',
  checkFormat:
    'Bitte stellen Sie sicher, dass das Passwort den Minimalanforderungen entspricht',
  checkFormatPhone: 'Bitte überprüfen Sie das Format der Telefonnummer',
  personnalInfo: 'Persönliche Informationen',
  connectWithGoogle: 'Login mit Google',
  signinWithGoogle: 'Login mit Google',
  connectWithFacebook: 'Login mit Facebook',
  signinWithFacebook: 'Login mit Facebook',
  connectWithApple: 'Login mit Apple',
  signinWithApple: 'Login mit Apple',

  /**
   * Cognito error messages
   */
  error: 'Ein Fehler ist aufgetreten',
  AliasExistsException:
    'Diese E-Mail-Adresse existiert bereits. Bitte loggen Sie sich ein oder wählen Sie eine andere Adresse.',
  errorEmailAlreadyExists:
    'Diese E-Mail-Adresse existiert bereits. Bitte loggen Sie sich ein oder wählen Sie eine andere Adresse.',
  Userdoesnotexist: 'Der Benutzer oder das Passwort ist falsch.',
  Incorrectusernameorpassword: 'Der Benutzer oder das Passwort ist falsch.',
  Usernameclientidcombinationnotfound:
    'Diese E-Mail-Adresse ist bei uns nicht registriert.',
  Invalidverificationcodeprovidedpleasetryagain: 'Ungültiger Bestätigungscode.',
  Anaccountwiththegivenemailalreadyexists:
    'E-Mail-Adresse oder Passwort ungültig.',
  Invalidemailaddressformat: 'Die E-Mail-Adresse ist ungültig',
  Cannotresetpasswordfortheuserasthereisnoregisteredverifiedemailorphonenumber:
    'Ihr Konto wurde nie bestätigt. Darum können Sie leider das Passwort nicht zurücksetzen.',
  verificationSentTitle: "Bestätigen Sie die neue E-Mailadresse",
  verificationSentAgain: 'Der Code wurde an Ihre neue E-Mailadresse gesendet.',
  uneditableBecauseLinkedToYourSocialAccount: 'können diese Information nicht ändern, da sie an Ihr Social Media-Konto gebunden ist.',
  uneditableBecauseLinkedToYourSocialAccountAlt: 'Sie haben sich mit einem Social Login (Google, Fabebook, Apple) mit Animalia verbunden. Deshalb können Sie Ihre Daten nicht hier ändern. Bitte ändern Sie diese direkt in Ihrem Google-, Facebook- oder Applekonto.'
};
