import * as yup from 'yup'

const phoneRegExp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

let calculatorSchema =  yup.object().shape({
  pet_species: yup.string().required('offer.error.pet_species_required').matches(/(cat|dog)/),
  pet_age_category: yup.string().required('offer.error.general'),
  insurance_franchise: yup.number().required('offer.error.general'),
  insurance_type: yup.string().required('offer.error.insurance_type_required').matches(/(compact|classic|comfort)/, 'offer.error.general'),
  insurance_payment_frequency: yup.string().matches(/(annual|biannual|quarterly)/).required('offer.error.insurance_payment_frequency_required'),
  insurance_enters_in_force_at: yup.date().min(new Date()).required('offer.error.insurance_enters_in_force_at_required'),
  holder_id: yup.string().nullable(),
  pet_breed: yup.string().required('offer.error.general'),
  pet_animal_id: yup.string().when(['pet_breed'], {
    is: (pet_breed) => pet_breed !== 'unknown',
    then: yup.string().required('offer.error.pet_animal_id_required')
  }),
  pet_animal_id_2: yup.string().when(['pet_breed'], {
    is: (pet_breed) => pet_breed == 'mixed',
    then: yup.string().required('offer.error.pet_animal_id_required')
  }),
  pet_name: yup.string()
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase')
    .test('len', 'offer.error.more_char', val => val?.length >= 2)
    .required('offer.error.pet_name_required'),
  holder_title: yup.string().required('offer.error.title_required').matches(/(mr|ms|mrs)/),
  holder_first_name: yup.string()
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase')
    .test('len', 'offer.error.more_char', val => val?.length >= 2)
    .required('offer.error.first_name_required'),
  holder_last_name: yup.string()
    .matches(/^[A-Z]/, 'offer.error.uppercase')
    .matches(/^(?:[^A-Z]*[A-Z]){0,1}[^A-Z]*$/, 'offer.error.only_one_uppercase')
    .test('len', 'offer.error.more_char', val => val?.length >= 2)
    .required('offer.error.last_name_required'),
  holder_email: yup.string().email('offer.error.email_invalid').required('offer.error.general'),
  holder_mobile_phone: yup.string().matches(phoneRegExp, 'format: +41000000000').required('offer.error.holder_phone_required'),
  holder_phone: yup.string().nullable(),
  holder_zip_code: yup.string().required('offer.error.zip_code_required'),
  holder_city: yup.string().required('offer.error.city_required'),
})

export default calculatorSchema