import React from 'react'
import styles from './Caption.module.sass'
import classNames from 'classnames'

const Caption = ({ children, html, bold, align = 'left', faded, error }) => {

  const classes = [
    styles.container,
    bold && styles.bold,
    faded && styles.faded,
    error && styles.error
  ].filter(item => !!item)
  
  if (html) return (
    <div 
      style={{ textAlign: align }}  
      className={classNames(classes)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  ) 

  return (
    <div 
      style={{ textAlign: align }}  
      className={classNames(classes)}
    >
        { children }
    </div>
  )
}

export default Caption
