import React, { useContext } from 'react';
import {Text, Menu} from 'grommet';
import {withTranslation} from 'react-i18next';
import i18next from 'i18next';
import { UserContext } from '@animalia/contexts';

const LangMenu = () => {
  const currentLanguage = i18next.language.substr(0, 2);
  const { user } = useContext(UserContext);
  if (['fr', 'de', 'it'].indexOf(currentLanguage) === -1) {
    i18next.changeLanguage('fr');
  }

  return (
    <Menu
      a11yTitle="Lang"
      dropAlign={{top: 'bottom', right: 'right'}}
      label={
        <Text size="14px" style={{lineHeight: 1}}>
          {currentLanguage.toUpperCase()}
        </Text>
      }
      items={[
        {
          label: <Text size=".8em">FR</Text>,
          onClick: () => {
            i18next.changeLanguage('fr');
          },
        },
        {
          label: <Text size=".8em">DE</Text>,
          onClick: () => {
            i18next.changeLanguage('de');
          },
        },
        {
          label: <Text size=".8em">IT</Text>,
          onClick: () => {
            i18next.changeLanguage('it');
          },
        },
      ].filter(l => l.label !== i18next.language)}
    />
  );
};
export default withTranslation()(LangMenu);
