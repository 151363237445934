import React, {createContext, useReducer, useMemo} from 'react';
import {reducer, initialState} from './userReducer.js';

const UserContext = createContext();

const UserContextProvider = ({children}) => {
  const [user, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(
    () => {
      return {user, dispatch};
    },
    [user, dispatch],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export {
  UserContext, 
  UserContextProvider
}
