const initialState = {
 address: {
    address_id: null,
    title: '',
    first_name: '',
    last_name: '',
    line: '',
    line_2: '',
    zip_code: '',
    city: '',
    post_address_strid: null,
    post_address_house_key: null,
    address_id: null,
    update_delivery_addresses_for: null
 },
 owner: {
    title: '',
    first_name: '',
    last_name: '',
    line: '',
    line_2: '',
    zip_code: '',
    city: '',
    birthdate: null,
    post_address_strid: null,
    post_address_house_key: null,
    mobile_phone: '',
    phone: '',
    email: '',
    transferred_at: null,
    keep_insurance: '',
    other_concerned_pets: null
 },
 'bank-details': {
    iban: 'CH',
    bank_id: null,
    bank_name: null,
    bank_zip_code: null,
    bank_city: null,
 }
}

let form

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_FORM' :
    return {
      ...state,
      form: {
        ...state.form,
        step: action.data.step,
        validate: false
      },
      activeForm: { 
        route: action.data.route, 
        step: action.data.step
      }
    }

    case 'SET_FORM' :
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          ...action.data
        }
      }

    case 'RESET_FORMS' :
      return initialState

    case 'SET_INSURANCE_VALUES' :

      return {
        ...state,
        selectedInsuranceValues: [
          ...action.data
        ]
      }

    case 'SELECT_HEALTH' :
      
      return {
        ...state,
        form: {
          ...state.form,
          insurance_franchise: action.data,
          insurance_type: 'classic'
        }
      }

    case 'SET_YEARS_RANGE' :
      return {
        ...state,
        form: {
          ...state.form,
          pet_age_category: action.data
        },
      }

    case 'SET_BANK_ID' :
      return {
        ...state,
        form: {
          ...state.form,
          holder_bank_id: action.data
        },
      }

    case 'SET_ADDRESS' :
      return {
        ...state,
        address: {
          ...state.address,
          address_id: action.data.id,
          line: action.data.line,
          line_2: action.data.line_2,
          zip_code: action.data.zip_code,
          city: action.data.city
        },
      }

    case 'SET_ADDRESS_FROM_API' :
      form = action.form || 'form'
      return {
        ...state,
        [form]: {
          ...state[form],
          ...action.data
        }
      }

    case 'SET_USER_ID' :
      form = action.form || 'form'
      return {
        ...state,
        [form]: {
          ...state[form],
          ...action.data
        }
      }

    case 'SET_ADDRESSEE' :
      const firstname = state.form[`${action.prefix}_first_name`]
      const lastname = state.form[`${action.prefix}_last_name`]
      return {
        ...state,
        form: {
          ...state.form,
          [`${action.prefix}_addressee`]: `${firstname ? firstname + ' ' : ''}${lastname ? lastname : ''}` 
        },
      }

    case 'RESET_VALUE' :
      return {
        ...state,
        form: {
          ...state.form,
          [action.data]: undefined
        },
      } 

    case 'SET_TYPED_SELECT' :
      return {
        ...state,
        form: {
          ...state.form,
          [action.key]: action.data
        }
      }

    default :
      throw new Error()
  }
}

export {
  initialState, 
  reducer
}
