import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { it, fr, de  } from 'date-fns/locale'
import {useTranslation} from 'react-i18next';
import {Auth} from 'aws-amplify';
import {
  Anchor,
  Grid,
  Select,
  Form,
  FormField,
  TextInput,
  Text,
  Heading,
  Box,
  Button,
} from 'grommet';
import PasswordField from './passwordField.js';
import styled from 'styled-components';
import getError from './error.js';

const PhoneInput = styled(TextInput)`
  border: 0;
  &:focus {
    box-shadow: none;
  }
`;

const locales = {
  it,
  fr,
  de,
};

const Login = ({onSubmit, defaultValues}) => {
  const [birthdate, setBirthdate] = useState();
  const [value, setValue] = React.useState({
    ...defaultValues,
    phone_prefix: {
      value: '+41',
      label: '+41',
    },
  });
  const {t, i18n} = useTranslation();
  const [error, setError] = useState();

  return (
    <>
      <Heading>{t('auth.personnalInfo')}</Heading>
      <Form
        messages={{
          required: t('common.required'),
        }}
        value={value}
        onChange={nextValue => setValue(nextValue)}
        onSubmit={({value}) => {
          let formValue = {
            'custom:title': value['custom:title'].value,
            family_name: value.family_name,
            given_name: value.given_name,
            phone_number:
              value.phone_prefix.value +
              value.phone_number.replace(/[^0-9+]/i, ''),
            locale: value.locale.value || '',
            birthdate: '',
          };

          if (typeof birthdate !== 'undefined') {
            birthdate.setHours(12, 0, 0);
            formValue.birthdate = birthdate.toISOString().slice(0, 10);
          }

          onSubmit(formValue);
        }}>
        <Grid gap="small">
          <FormField
            htmlFor="custom:title"
            name="custom:title"
            background="light-1"
            required
            label={t('user.title')}>
            <Select
              id="custom:title"
              name="custom:title"
              labelKey="label"
              valueKey="value"
              plain
              options={[
                {
                  value: 'mrs',
                  label: t('user.mrs'),
                },
                {
                  value: 'mr',
                  label: t('user.mr'),
                },
              ]}
            />
          </FormField>
          <FormField
            htmlFor="given_name"
            name="given_name"
            background="light-1"
            required
            label={t('user.firstName')}>
            <TextInput name="given_name" id="given_name" />
          </FormField>
          <FormField
            htmlFor="family_name"
            name="family_name"
            background="light-1"
            required
            label={t('user.lastName')}>
            <TextInput name="family_name" id="family_name" />
          </FormField>
          <FormField
            background="light-1"
            name="phone_number"
            label={t('auth.PHONE_LABEL')}
            validate={value => {
              if (value.replace(/\s+/g, '').length < 7) {
                return t('auth.checkFormatPhone');
              }

              if (/[^0-9]/.test(value.replace(/\s+/g, ''))) {
                return t('auth.checkFormatPhone');
              }

              if (isNaN(parseInt(value))) {
                return t('auth.checkFormatPhone');
              }
            }}
            required>
            <Box
              direction="row"
              gap="small"
              align="center"
              pad={{horizontal: 'small'}}>
              <Select
                id="phone_prefix"
                name="phone_prefix"
                labelKey="label"
                valueKey="value"
                style={{width: '80px'}}
                plain
                options={[
                  {
                    value: '+41',
                    label: '+41',
                  },
                  {
                    value: '+423',
                    label: '+423',
                  },
                  {
                    value: '+33',
                    label: '+33',
                  },
                  {
                    value: '+49',
                    label: '+49',
                  },
                  {
                    value: '+43',
                    label: '+43',
                  },
                  {
                    value: '+39',
                    label: '+39',
                  },
                ]}
              />
              <PhoneInput
                name="phone_number"
                id="phone"
                type="tel"
                placeholder={t('auth.PHONE_PLACEHOLDER')}
              />
            </Box>
          </FormField>
          <FormField
            name="birthdate"
            background="light-1"
            label={t('user.birthdate')}>
            <DatePicker
              selected={birthdate}
              onChange={date => {
                if (date) {
                  date.setHours(12, 0, 0);
                  setBirthdate(date);
                }
              }}
              value={value.birthdate}
              dateFormatCalendar="MMMM"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
              locale={locales[i18n.language]}
              scrollableYearDropdown
              dateFormat="dd.MM.yyyy"
              className="datepicker"
            />
          </FormField>
          <FormField
            htmlFor="locale"
            name="locale"
            background="light-1"
            required
            label={t('user.locale')}>
            <Select
              id="locale"
              name="locale"
              labelKey="label"
              valueKey="value"
              plain
              options={[
                {
                  value: 'it',
                  label: 'Italiano',
                },
                {
                  value: 'de',
                  label: 'Deutsch',
                },
                {
                  value: 'fr',
                  label: 'Français',
                },
              ]}
            />
          </FormField>
          <Button type="submit" label={t('common.save')} />
        </Grid>
        {error && (
          <Box textAlign="center" margin={{top: 'medium'}}>
            <Text textAlign="center" color="status-critical">
              {t(error)}
            </Text>
          </Box>
        )}
      </Form>
    </>
  );
};

export default Login;
