import React from 'react'
import WidgetTightRadioButton from './WidgetTightRadioButton'
import ArrowAnchor from '../arrowAnchor/arrowAnchor'
import styles from './WidgetTightRadioGroup.module.sass'

const WidgetTightRadioGroup = (option, state) => {

  const hasLink = option.link && option.labelLink
  const openUrlNewTab = () => {
    window.open(option.link, '_blank').focus()
  }
  return (
    <div className={styles.container}>
      <WidgetTightRadioButton
        { ...option }
        readOnly
        marginBottom={hasLink ? '30px' : '0px'}
        checked={state.checked}
        info={option.tip}
      />

      {
        hasLink &&
          <ArrowAnchor
            absolute
            pin='right'
            label={option.labelLink}
            onClick={openUrlNewTab}
          />
      }
    </div>
  )
}

export default WidgetTightRadioGroup