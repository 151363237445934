module.exports = {
  btnAdd: 'Ajouter un animal',
  yourPets: 'Mes animaux',
  yourPetServices: 'Les services de {{name}}',
  yourPetAbogourmand: "L'Abo Gourmand de {{name}}",
  yourPetAssistance: 'L\'assistance Animalière de {{name}}',
  animal: 'Animal',
  cat: 'Chat',
  dog: 'Chien',
  size: 'Taille',
  gender: 'Sexe',
  female: 'Femelle',
  male: 'Mâle',
  animalTitle: 'Ajouter un animal',
  animalEditTitle: 'Editer un animal',
  animalDescription: 'Faisons plus ample connaissance',

  passportTitle: 'Portrait Animalia',
  passportDescription: 'Déclinez l’identité de votre gourmand préféré.',
  itsName: "Nom de l'animal",
  noNameYet: 'Pas de nom',
  bornOn: 'Date de naissance',
  birthdate: 'Date de naissance',
  breed: 'Race',
  breedDescription: 'Avec ou sans pedigrée, on sait combien vous l’aimez !',
  weight: 'Poids',
  catofbreed: 'Chat de race',
  dogofbreed: 'Chien de race',
  kgWeight: 'Poids à l\'âge adulte en kg',
  kgWeightMaxError: 'Veuillez saisir un poids en dessous de 100 kg',
  adultWeightTitle: 'Poids à l’âge adulte',
  adultWeightDescription:
    'Si son bien-être pèse lourd dans toutes vos décisions, on aimerait quand même connaître les chiffres de la balance !',
  chooseBreed: 'Choix de la race',
  dogBastard: 'Croisé',
  catBastard: 'Chat de maison',

  activityTitle: 'À chacun son rythme',
  activityDescription:
    'Sport d’extérieur ou sieste sur le canapé ? Il est plutôt...',
  activityLevel: "Taux d'activité",
  passive: 'Peu actif',
  passiveDescription:
    'Plus canapé que course endiablée, votre compagnon est plutôt du genre à rester à la maison.',
  normal: 'Modéré',
  normalDescription:
    'Se promener pour se dégourdir les pattes une à deux fois par jour ! Votre poilu aime se bouger mais sans trop se fatiguer.',
  active: 'Très actif',
  activeDescription:
    'Longues promenades et courses effrénées, il adore vous épuiser !',

  sterilizedTitle: 'Stérilisé ?',
  sterilizedDescription:
    'Votre compagnon est-il passé sur la table d’opération ?',
  sterilizedYes: 'Oui (ouille!)',
  sterilized: 'Stérilisé',
  nonSterilized: 'Non stérilisé',

  foodTypeStepTitle: 'Alimentation',
  foodTypeTitle: 'Menu à la carte',
  foodTypeDescription:
    'Dites-nous en plus sur le régime de votre petit ou grand gourmand.',
  standard: 'Standard',
  standardHelp:
    'Votre gastronome sur pattes ne souffre d’aucune allergie ou intolérance particulière ? Il va adorer nos croquettes 100 % naturelles, garanties sans additifs et surtout sans gluten, pour prévenir tout risque d’intolérance dans l’avenir.',
  sensitive: 'Sensible',
  sensitiveHelp:
    'Votre poilu est un fin gourmet à l’estomac plutôt fragile ? Nous avons ce qu’il lui faut : un menu délicieux et équilibré pour une digestion tout en douceur.',
  grainfree: 'Sans céréales',
  'grain-free':
    'Votre compagnon à quatre pattes y est allergique ou les digère mal ? Notre gamme de croquettes sans céréales lui apportent tous les nutriments et les fibres dont il a besoin tout en respectant sa digestion.',
  grainfreeHelp:
    'Votre compagnon à quatre pattes y est allergique ou les digère mal ? Notre gamme de croquettes sans céréales lui apportent tous les nutriments et les fibres dont il a besoin tout en respectant sa digestion.',

  productStepTitle: 'Choix du produit',
  productTitle: 'Remplissez sa gamelle (d’amour et de croquettes)',
  productDescription:
    'Nos croquettes sont à croquer. Il ne vous reste plus qu’à vous décider !',

  adjustQuantityStepTitle: 'Ajustement de la quantité',
  adjustQuantityTitle: 'À table!',
  adjustQuantityDescription:
    'Bienvenue à {{name}} ! Nous sommes ravis de faire sa connaissance ! D’après les informations que vous nous avez données et en collaboration avec notre vétérinaire nutritionniste qui connaît nos croquettes sur le bout des griffes, nous vous recommandons la ration journalière suivante :',
  adjust80Comment:
    'Alors là, ça ne fait pas beaucoup de croquettes à croquer ! Complétez-vous sa gamelle avec d’autres aliments ? Attention tout de même, une ration inférieure de 20 % à celle recommandée peut entraîner des carences et une sous-alimentation chez votre compagnon à quatre pattes et détériorer sa santé. Et si on ajoutait quelques croquettes dans nos paquets d’amour ?',
  adjust90Comment:
    'Votre colocataire a un appétit de moineau ? Il y a autre chose que nos délicieuses croquettes à croquer au menu ? Alors vous pouvez diminuer la ration proposée de 10 %. Veillez toutefois à respecter la ration journalière totale recommandée, en compensant avec d’autres aliments.',
  adjust100Comment:
    "C’est pile-poil la portion recommandée ! Mais puisque vous le connaissez mieux que quiconque, n’hésitez pas à l'ajuster !",
  adjust110Comment:
    'Vous vivez avec un grand gourmand ou un grand sportif ? Vous pouvez ajouter 10 % à sa ration journalière. Sans abuser, bien entendu !',
  adjust120Comment:
    'Alors là, ça en fait des croquettes à croquer ! Attention tout de même, une ration supérieure de 20 % à celle recommandée peut engendrer un surpoids chez votre compagnon à quatre pattes et détériorer sa santé. Et si on mettait encore un peu plus d’amour et un peu moins de croquettes dans son paquet ?',

  summaryTitle: "L'Abo Gourmand",
  monthlyPrice: 'Prix par mois',

  upTo4kg: "jusqu'à 4kg",
  between4and6kg: 'de 4kg à 6kg',
  between6and8kg: 'de 6kg à 8kg',
  from8kg: 'dès 8kg',

  // summary screen

  nextDeliveryDate: 'Votre prochaine livraison',
  nextDeliveryOn: 'Choisissez la date de la première livraison',
  deliveryFrequency: 'Fréquence des livraisons',
  deliveredEachXDays: 'Livré tous les {{days}} jours',
  deleteOrderFor: 'Résilier mon abonnement pour {{name}}',
  product: 'Produit',
  dailyQuantity: 'Quantité journalière',
  order: 'Poursuivre la commande',

  // details and listing screens
  backToMyPets: 'Retour à "Mes animaux"',
  deletePet: 'Supprimer le profil de {{name}}',
  byDeletingPetItWillCancelLinkedServices:
    "Nous vous rendons attentif au fait que l'assurance santé de {{name}} ne sera pas résiliée (si elle ou il en a une) par la suppression de son profil.",
  reasonOfDeletion: 'Raison de la suppression',
  myPetIsDead: 'Mon animal est décédé',
  notInterrestedAnymore: 'Je ne suis plus intéressé',
  thisPetCantBeDeleted:
    'Vous ne pouvez pas supprimer le profil de {{name}} car un ou plusieurs services ci-dessous sont encore liés à votre boule de poils :',
  reasonAbo: 'Abo Gourmand actif ou livraison en attente',
  reasonAssurance: "Liaison avec l'assurance santé Animalia",
  // my account
  itsAbo: 'Son abo',
  calculate: 'Calculez l’Abo Gourmand de votre compagnon',
  calculateAgain: 'Recalculez l’Abo Gourmand',
  petDataHasChanged: 'Les données de {{name}} ont changé',
  seeOrderDetails: "Voir les détails de l'Abo Gourmand",
  seeInsuranceSettings: "Supprimer liaison avec l'assurance Animalia",
  hasNoAccount: "{{name}} n'a pas encore d'Abo Gourmand",
  hasNoInsurance: "{{name}} n'a pas encore d'assurance santé enregistré",
  itsInsurance: "L'assurance santé de {{name}}",
  policeNumber: 'No de police',
  petDataHasChangedExtended:
    "Le profil de {{name}} a été modifié. Nous vous recommandons de recalculer l'Abo Gourmand pour une nouvelle recommandation de produit et de quantités.",
  receiveOffer: "Demander une offre pour l'Assurance santé",
  askForEstimate: 'Demander une offre',
  calculator: 'Calculateur',
  hasNoOrder: '{{name}} n’a pas encore d’abonnement',
  adjustOrder: "Vous souhaitez ajuster l'abonnement de {{name}} ?",
  petHasRemainingDeliveries:
    'Attention: {{name}} a encore des livraisons et/ou paiements en attente.',
  newAbo: 'Créer un nouvel abonnement',
  temporaryDisabled: 'Pour des raisons techniques, les fonctions pour la declaration de sinistre et l\'envoi des facture sont momentanément désactivées sur cette application. En attendant, vous pouvez déclarer les sinistres et envoyer les factures sur notre site web dans votre compte client: ',
  petRaceSearchPlaceholder: 'Rechercher',
  noInsurance: '{{name}} n’a pas encore d’assurance',
  noAssistance: '{{name}} n’a pas encore d’assistance 24/7',
};
