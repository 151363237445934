import * as yup from 'yup'
import { ibanValidation } from '@animalia/actions'

let bankDetailsSchema =  yup.object().shape({
  iban: yup.string()
    .test('checkIban', 'offer.error.wrong_iban', ibanValidation)
    .required('offer.error.iban_required'),
  bank_id: yup.string().nullable(),
  bank_name: yup.string().nullable(),
  bank_zip_code: yup.string().nullable(),
  bank_city: yup.string().nullable()
})

export default bankDetailsSchema