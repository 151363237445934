module.exports = {
  or: 'o',
  add: 'Aggiungere',
  save: 'Registrare',
  send: 'Invia',
  edit: 'Modificare',
  delete: 'Cancellare',
  previous: 'Precedente',
  next: 'Seguente',
  iAcceptCGU: 'Acceto le',
  CGV: 'Condizioni generali di vendita',
  CGU: 'Condizioni di utilizzo',
  privacy_policy: 'Informativa sulla privacy',
  missingData: 'Dati mancanti',
  readmore: 'Saperne di più',
  yes: 'Si',
  no: 'No',
  close: 'Chiudere',
  back: 'Indietro',
  backToAnimals: 'Indietro a "I miei animali"',
  draft: 'Non ancora salvato',
  calculating: 'Colcolo corrente',
  allfeesincluded: 'Tasse e spedizione incluse',
  finished: 'Finito',
  printOrder: "Stampa l'ordine",
  deliveries: 'Consegne',
  plannedDeliveries: 'Prossime consegne',
  previousDeliveries: 'Consegne effettuate',
  batchID: "Numero d'ordine",
  status: 'Stato',
  verify: 'Controllare',
  year: 'Anno',
  month: 'Mese',
  day: 'Giorno',
  months: {
    1: 'Gennaio',
    2: 'Febbraio',
    3: 'Marzo',
    4: 'Aprile',
    5: 'Maggio',
    6: 'Giugno',
    7: 'Luglio',
    8: 'Agosto',
    9: 'Settembre',
    10: 'Ottobre',
    11: 'Novembre',
    12: 'Dicembre',
  },
  immediately: 'Immediatamente',
  informations: 'Informazioni',
  aboGourmand: 'Abbonamento Gourmet',
  theAboGourmand: 'Il Abbonamento Gourmet',
  theInsurance: "L'Assucurazione",
  administrative: 'Amministrativo',
  missingAddress: 'Nessun indirizzo registrato.',
  missingAddressFor: 'Nessun indirizzo registrato per {{name}}.',
  missingCard: 'Nessun mezzo di pagamento registrato.',
  fix: 'Risolvere il problema',
  cancel: 'Annullare',
  confirm: 'Confermare',
  fillIn: 'Compilare',
  wantToKnowMore: 'Volete saperene di più ?',
  moreDetails: 'Maggiori informazioni',
  date: 'Data',
  service: 'Servizio',
  noResults: 'Nessun risultato',
  required: 'Obbligatorio',
  your_comment: 'Osservazioni',

  cardIsFailing: 'Fallimento',
  twintAccount: 'Conto Twint',
  powerpayAccount: 'Pagamento con fattura (PowerPay)',

  oldbrowser: {
    title: 'Oops! Il tuo browser non è aggiornato.',
    text:
      'Per maggiore sicurezza e una migliore esperienza sul nostro sito, ti preghiamo di aggiornare il tuo browser.',
    button: 'Aggiorno il mio browser',
  },

  weDidNotReceiveYourPayment:
    'Non abbiamo ricevuto il pagamento per questo ordine.',
  contactUs: 'Contatto',
  powerpay: {
    title: 'Controllare i dati di pagamento',
    subtitle:
      'Riceverà la fattura gratuita via e-mail. Con l\'acquisto su fattura si accettano i termini e le <a href="https://www.powerpay.ch/it/agb" target="_blank">condizioni</a> di POWERPAY (powerpay.ch/it/agb), se si dispone di un indirizzo e-mail valido.',
    next: 'Paga',
    cancel: 'Annullare',
  },
  other: 'Altro',
};
