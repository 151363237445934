module.exports = {
  yourPetAssistance: 'Die Assistance 24/7 von {{name}}',
  policeNumber: 'Police Nr. {{number}}',
  noAssistanceSubtitle: '{{name}} hat noch keine Animalia Assistance 24/7',
  cga: 'AVB',
  contractStartDate: 'Vertragsbeginn',
  contractEndDate: 'Vertragsablauf (stillschweigende Verlängerung um jeweils ein Jahr)',
  callHelpLine: 'Die Infoline 24/7 anrufen',
  declareSinister: 'Einen Assistance-Fall für {{name}} melden',
  deleteAssistance: 'Animalia Assitance 24/7 von {{name}} kündigen',
  moreInformations: 'Mehr Infos zu Animalia Assistance 24/7',
  createAssistance: 'Animalia Assistance 24/7 abschliessen für CHF 79.- /Jahr ',
  policy: "Versicherungspolice Assistance 24/7",
  moreInformationsLink: ' https://www.animalia.ch/de/service/assistance',
  createAssistanceLink: 'https://shop.animalia.ch/de/product/assistance-24-7/',
  deleteAssistanceLink: 'mailto:contact@animalia.ch',
  callHelpLineLink: 'tel:+41225937326',
  declareSinisterLink: 'https://shop.animalia.ch/de/mon-compte/declaration-de-sinistre/',
}