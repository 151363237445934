import * as yup from 'yup'

let personalDataSchema = yup.object().shape({
  name: yup.string().required('offer.error.general'),
  breeding_type: yup.string().required('offer.error.general'),
  breeds: yup.array().of(yup.string()).required('offer.error.general'),
  zip_code: yup.string().required('offer.error.general'),
  city: yup.string().required('offer.error.general'),
  street: yup.string().required('offer.error.general'),
  street_number: yup.string().required('offer.error.general'),
  country: yup.string().required('offer.error.general'),
  email: yup.string().required('offer.error.general'),
  post_address_house_key: yup.number().required('offer.error.general'),
  post_address_strid: yup.number().required('offer.error.general'),
  phone: yup.string().required('offer.error.general'),
  message: yup.string().required('offer.error.general')
})

export default personalDataSchema