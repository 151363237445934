module.exports = {
  firstDeliveryDate: 'Prima consegna',
  lastDelivery: 'Ultima consegna',
  selectLastDelivery: 'Selezionate la vostra ultima data di consegna',
  validateDate: 'Validare la data',
  deleteOrder: 'Rescindere il contratto',
  cancelled: 'Abbonamento annullato',
  couponCodeLabel: 'Buono',
  couponCodePlaceholder: 'Il codice del vostro buono',
  invalidCoupon: 'Codice del buono non valido',
  editOrder: 'Modifica abbonamento',
  showAllProducts: 'Mostra altre varietà',
  showRecommandedProducts: 'Mostra i prodotti consigliati',
  notRecommendedTooltip: 'Naturalmente può ordinare queste crocchette. Tuttavia, in base alle sue specifiche, le consigliamo un prodotto diverso.',
  confirmModification:
    "In seguito alle modifiche, il prezzo del vostro Abbonamento Gourmet non è più lo stesso. Ci confermi per favore che è d'accordo con questo cambiamento di tariffa.",
  couponDetailsUnlimited: 'Beneficiate del {{discount}} di sconto.',
  couponDetails:
    "Beneficiate del {{discount}} di sconto durante {{months}} mesi. In seguito, la tariffa verrà automaticamente adattata al prezzo standard del'abbonamento.",
  priceWithoutDiscount: '(Prezzo senza sconto: {{price}})',
  payAmount: 'Paga {{price}}',
  error: {
    pleaseAcceptTerms: 'La preghiamo di accettare le Condizioni generali',
    pleaseChooseADeliveryDate: 'La preghiamo di scegliere la data della sua prima consegna',
  },
  pricePerMonth: 'Prezzo al mese',
  price: 'Prezzo',
  totalPricePerMonth: 'Prezzo mensile totale',
  discount: 'Sconto',
  thankyou: {
    title: 'Grazie e congratulazioni! ',
    text:
      "Grazie per aver scelto l'Abbonamento Gourmet! ! Il vostro ordine è confermato e stiamo già iniziando a preparare il suo pasto realizzato con prodotti di alta qualità e ricchi di proteine. Il vostro cucciolo a quattro zampe se ne leccherà i baffi e vi ringrazierà per aver fatto la scelta giusta. Siamo felici e onorati di avervi come parte della nostra comunità di amanti degli animali!",
    button: 'Visualizzare il mio ordine',
    subText:
      'Avete una domanda? Siamo qui per voi e lieti di potervi aiutare tramite la nostra chat online o per telefono al numero 0800 815 915.',
  },
  mfgroupErrorUnavailable: "Al momento non è possibile utilizzare questo metodo di pagamento. Per favore, sceglietene un altro metodo.",
  addPaymentMethodError: "Questo metodo di pagamento non è valido. Si prega di inserirne un altro."
};
