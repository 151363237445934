import React, 
  { 
    createContext, 
    useReducer, 
    useMemo 
  } from 'react'
  
import { 
  reducer, 
  initialState 
} from './calculatorReducer.js'

const CalculatorContext = createContext()

const CalculatorContextProvider = ({ children }) => {
  const [ calculator, dispatch ] = useReducer(reducer, initialState)

  const contextValue = useMemo(
    () => ({ calculator, dispatch }),
    [calculator, dispatch],
  )

  return (
    <CalculatorContext.Provider value={contextValue}>
      {children}
    </CalculatorContext.Provider>
  )
}

export {
  CalculatorContext, 
  CalculatorContextProvider
}
