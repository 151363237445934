const initialState = {
  loginChecked: false,
  fullPageViewChecked: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case 'LOGIN_CHECKED' :
      return {
        ...state,
        loginChecked: true
      }
    case 'FULL_PAGE_VIEW_CHECKED' :
      return {
        ...state,
        fullPageViewChecked: true
      }
  
    default :
      throw new Error()
  }
}

export {
  initialState, 
  reducer
}
