import React from 'react'
import styles from './Title.module.sass'

const Title = ({ children, align = 'left', size = '19px', ...rest }) => {
  return (
    <div 
      { ...rest }
      style={{ textAlign: align, fontSize: size }}
      className={styles.container}
    >
      { children }
    </div>
  )
}

export default Title
