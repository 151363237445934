import React from 'react';
import {Box, Grid, Select} from 'grommet';
import {useTranslation} from 'react-i18next';
import {
  WidgetTightOfferFieldContainer,
  WidgetTightRadioButton,
  WidgetFormField,
  WidgetTightFormField,
  WidgetSelect,
  WidgetTightButton,
  Caption,
  OfferTip,
} from '@animalia/components';
import {Cat, Dog} from '@animalia/icons';

const AnimalStep = ({formValue, goToNextStep, error}) => {
  const {t} = useTranslation();

  const labels = {
    title: t('calculator.calculatorTitle'),
    description: t('calculator.calculatorDescription'),
    petSpecies: t('calculator.petSpecies'),
    petAgeCategory: t('calculator.petAgeCategory'),
    calculate: t('calculator.navigation.goToInsuranceStep'),
    next: t('calculator.navigation.next'),
    over8: t('calculator.plus_8_warning'),
    petAgeCalculInfo: t('offer.pet.pet_age_calcul_info'),
  };

  const isOver8 =
    formValue?.pet_age_category === '9-10' ||
    formValue?.pet_age_category === '11-12' ||
    formValue?.pet_age_category === '13+';

  const yearsRangeOptions = [
    {
      value: '2-',
      label: t('calculator.yearsRange.u2'),
    },
    {
      value: '3-4',
      label: t('calculator.yearsRange.u4'),
    },
    {
      value: '5-6',
      label: t('calculator.yearsRange.u6'),
    },
    {
      value: '7-8',
      label: t('calculator.yearsRange.u8'),
    },
    {
      value: '9-10',
      label: t('calculator.yearsRange.u10'),
    },
    {
      value: '11-12',
      label: t('calculator.yearsRange.u12'),
    },
    {
      value: '13+',
      label: t('calculator.yearsRange.o13'),
    },
  ];

  const valueKeyConfig = {
    key: 'value',
    reduce: true,
  };

  return (
    <WidgetTightOfferFieldContainer
      logo
      noBack
      title={labels.title}
      subtitle={labels.description}>
      <WidgetTightFormField
        stretch
        hasComponent
        horizontalFill
        centered
        name="pet_species"
        error={error}
        contentProps={{fill: 'horizontal'}}
        component={({onChange, value}) => (
          <Grid
            gap="small"
            style={{marginBottom: '6px'}}
            columns={['1fr', '1fr']}>
            <WidgetTightRadioButton
              flex
              value="dog"
              name="pet_species"
              onChange={onChange}
              checked={value === 'dog'}
              label={t('animal.dog')}
              icon={<Dog />}
            />
            <WidgetTightRadioButton
              flex
              name="pet_species"
              value="cat"
              onChange={onChange}
              checked={value === 'cat'}
              label={t('animal.cat')}
              icon={<Cat />}
            />
          </Grid>
        )}
      />

      <Box direction="horizontal" gap="medium">
        <Box fill>
          <WidgetTightFormField
            htmlFor="select-yearsRange"
            horizontalFill
            name="pet_age_category"
            label={labels.petAgeCategory}
            background="light-1"
            error={error}>
            <Select
              id="select-yearsRange"
              name="pet_age_category"
              style={{border: 'solid 0px #000'}}
              labelKey="label"
              valueKey={valueKeyConfig}
              options={yearsRangeOptions}
            />
          </WidgetTightFormField>
        </Box>
        <OfferTip
          plain
          content={labels.petAgeCalculInfo}
          color="rgb(178, 182, 193)"
        />
      </Box>

      {isOver8 && (
        <Box pad={{vertical: 'small'}}>
          <Caption error>
            <div style={{fontSize: '0.65rem'}}>{labels.over8}</div>
          </Caption>
        </Box>
      )}

      <Box>
        {/* <WidgetTightButton
          fill
          primary
          onClick={goToNextStep('calculate')}
          label={labels.calculate}
        /> */}
        <WidgetTightButton
          fill
          primary
          onClick={goToNextStep('races')}
          label={labels.next}
        />
      </Box>
    </WidgetTightOfferFieldContainer>
  );
};

export default AnimalStep;
