module.exports = {
  BACK_TO_SIGN_IN: "Tonare alla pagina d'accesso",
  CHANGE_PASSWORD: 'Cambiare la Password',
  CHANGE_PASSWORD_ACTION: 'Cambiare',
  CODE_LABEL: 'Codice di verifica (e-mail)',
  CODE_PLACEHOLDER: 'Inserire il codice',
  CONFIRM: 'Confermare',
  CONFIRM_SIGN_UP_CODE_LABEL: 'Codice di verifica',
  CONFIRM_SIGN_UP_CODE_PLACEHOLDER: 'Inserire il vostro codice',
  CONFIRM_SIGN_UP_HEADER_TEXT: "Confermare l'iscrizione",
  CONFIRM_SIGN_UP_LOST_CODE: 'Avete perso il vostro codice?',
  CONFIRM_SIGN_UP_RESEND_CODE: 'Inviare nuovamente il codice',
  CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT: 'Confermare',
  CONFIRM_SMS_CODE: 'Confermare il codice SMS',
  CONFIRM_TOTP_CODE: 'Confermare il codice TOTP',
  CREATE_ACCOUNT_TEXT: 'Creare un conto',
  EMAIL_LABEL: 'Indirizzo Email*',
  EMAIL_PLACEHOLDER: 'Inserire il vostro indirizzo email',
  EMAIL_ERROR: 'Inserire un indirizzo email valido',
  FORGOT_PASSWORD_TEXT: 'Password dimenticata?',
  LESS_THAN_TWO_MFA_VALUES_MESSAGE: 'Meno di due tipi di mfa disponibili',
  NEW_PASSWORD_LABEL: 'Nuova password',
  NEW_PASSWORD_PLACEHOLDER: 'Scegliere una nuova password',
  NO_ACCOUNT_TEXT: 'Non avete un conto?',
  PASSWORD_LABEL: 'Password *',
  PASSWORD_PLACEHOLDER: 'Inserire la vosta password',
  PHONE_LABEL: 'Numero di telefono*',
  PHONE_PLACEHOLDER: '79 555 55 55',
  QR_CODE_ALT: 'codice qr',
  RESET_PASSWORD_TEXT: 'Cambiare password',
  RESET_YOUR_PASSWORD: 'Cambiare la vostra password',
  SELECT_MFA_TYPE_HEADER_TEXT: 'Selezionare il tipo di MFA',
  SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT: 'Verificare',
  SEND_CODE: 'Send Code',
  SETUP_TOTP_REQUIRED: 'Il TOTP necessita una configurazione',
  SIGN_IN_ACTION: 'Accedere',
  SIGN_IN_HEADER_TEXT: 'Accedere al suo conto',
  SIGN_IN_TEXT: 'Accedere',
  SIGN_IN_WITH_AMAZON: 'Accedre con Amazon',
  SIGN_IN_WITH_AUTH0: 'Accedere con Auth0',
  SIGN_IN_WITH_AWS: 'Accedre con AWS',
  SIGN_IN_WITH_FACEBOOK: 'Accedre con Facebook',
  SIGN_IN_WITH_GOOGLE: 'Accedre con Google',
  SIGN_OUT: 'Disconnettersi',
  SIGN_UP_EMAIL_PLACEHOLDER: 'Email',
  SIGN_UP_HAVE_ACCOUNT_TEXT: 'Avete già un conto?',
  SIGN_UP_HEADER_TEXT: 'Creare un nuovo conto',
  SIGN_UP_PASSWORD_PLACEHOLDER: 'Password',
  SIGN_UP_SUBMIT_BUTTON_TEXT: 'Creare un conto',
  SIGN_UP_ALREADY_BUTTON_TEXT: 'Ho già un conto',
  SIGN_UP_USERNAME_PLACEHOLDER: 'Nome utente',
  SUCCESS_MFA_TYPE: "L'impostazione è riuscita! Il vostro tipo di MFA è ora:",
  TOTP_HEADER_TEXT: 'Scansionare e poi inserire il codice di verifica',
  TOTP_LABEL: 'Inserire il codice di sicurezza:',
  TOTP_SETUP_FAILURE: "L'impostazione del TOTP non è riuscita",
  TOTP_SUBMIT_BUTTON_TEXT: 'Verificare il token di sicurezza',
  TOTP_SUCCESS_MESSAGE: 'Impostazione del TOTP riuscita!',
  UNABLE_TO_SETUP_MFA_AT_THIS_TIME:
    'Impostazione non riuscita! Impossibile configurare il MFA in questo momento',
  USERNAME_LABEL: 'Nome utente *',
  USERNAME_PLACEHOLDER: 'Inserire il vostro nome utente',
  VERIFY_CONTACT_EMAIL_LABEL: 'Email',
  VERIFY_CONTACT_HEADER_TEXT:
    'Il recupero del conto richiede informazioni di contatto verificate',
  VERIFY_CONTACT_PHONE_LABEL: 'Numero di telefono',
  VERIFY_CONTACT_SUBMIT_LABEL: 'Inviare',
  VERIFY_CONTACT_VERIFY_LABEL: 'Verificare',
  PASSWORD_HELP:
    'minimo 8 caratteri con almeno un numero, una maiuscola, una minuscola e un carattere speciale.',
  checkFormat: 'Assicuratevi che la password rispetti il formato richiesto',
  checkFormatPhone: 'Si prega di controllare il formato del numero di telefono',
  personnalInfo: 'Informazioni personali',
  connectWithGoogle: 'Login con Google',
  signinWithGoogle: 'Login con Google',
  connectWithFacebook: 'Login con Facebook',
  signinWithFacebook: 'Login con Facebook',
  connectWithApple: 'Login con Apple',
  signinWithApple: 'Login con Apple',

  /**
   * Cognito error messages
   */
  error: "C'è stato un errore",
  AliasExistsException:
    'Questo indirizzo e-mail esiste già. Effettua il login al suo account o scegli un altro indirizzo.',
  errorEmailAlreadyExists:
    'Questo indirizzo e-mail esiste già. Effettua il login al suo account o scegli un altro indirizzo.',
  Userdoesnotexist: "L'utente o la password non sono corretti.",
  Incorrectusernameorpassword: 'L\'utente o la password non sono corretti.',
  Usernameclientidcombinationnotfound: 'Non conosciamo questa email.',
  Invalidverificationcodeprovidedpleasetryagain:
    'Codice di verifica non valido.',
  Anaccountwiththegivenemailalreadyexists: 'Email o password non sono validi',
  Invalidemailaddressformat: "L'email non è valida",
  Cannotresetpasswordfortheuserasthereisnoregisteredverifiedemailorphonenumber:
    'Non può cambiare queste informazioni perché sono legate al suo account di social network.',
  verificationSentTitle: "Confermi il nuovo indirizzo e-mail",
  verificationSentAgain: 'Il codice è stato inviato al suo nuovo indirizzo e-mail.',
  uneditableBecauseLinkedToYourSocialAccountAlt: 'Si è registrato con un login sociale (Google, Facebook, Apple). Non può cambiare i suoi dati qui. Li cambi direttamente nel suo account Google / Facebook / Apple.'
};
