import React from 'react';
import styled, {css} from 'styled-components';

const Input = styled.input`
  display: none;

  &:checked + label {
    color: #db9a2f;
    border-color: #db9a2f;
    background-color: #fff9e5;
  }
`;

const Label = styled.label`
  color: #b2b6c1;
  border: 2px solid #b2b6c1;
  border-radius: 2px;
  padding: 12px 20px;
  font-weight: 500;
  font-size: ${props => (props.hasDetail ? '16px' : '18px')};
  user-select:none;
  cursor:pointer;
}
`;

const Detail = styled.div`
  font-family: GothamBook;
  font-size: 12px;
`;

export default ({
  name,
  value,
  label,
  details,
  icon,
  iconMargin = true,
  iconSmall,
  onChange,
  checked,
}) => {
  return (
    <>
      <Input
        type="radio"
        id={value}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <Label htmlFor={value} hasDetail={typeof details !== 'undefined'}>
        {details && <Detail>{details}</Detail>}
        {label}
      </Label>
    </>
  );
};
