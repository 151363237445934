import {grommet} from 'grommet';
import {deepMerge} from 'grommet/utils';

export default deepMerge(grommet, {
  global: {
    font: {
      size: '18px',
      height: '20px',
    },
    focus: {
      border: {
        color: 'transparent',
      },
    },
    colors: {
      brand: '#FFCF38',
      brandLight: '#FFF9E5',
      brandDark: '#DB9A2F',
      focus: 'brand',
      insurance: '#73D02F',
      assistance:'#4692D2',
      background: {dark: '#292929', light: '#FFFFFF'},
      abo: '#D1283D',
      // assistance: '#2A82BE',
      border: '#EBEBEB',
      error: '#D1283D',
      errorLight: '#FBE1E3',
      text: {light: '#292929'},
      ['status-critical']: '#D1283D',
      ['status-warning']: '#DB9A2F',
      ['status-ok']: '#84B804',
      ['status-validate']: '#93c13a3d',
      ['accent-1']: '#D1283D',
      ['light-4']: '#b4b4b4',
      ['dark-3']: '#777',
      ['graph-0']: 'brand',
      white: '#FFFFFF',
      shop: '#3ac6c9'
    },
    sizes: {
      xmedium: '16px'
    }
  },
  heading: {
    level: {
      '1': {
        font: {},
        small: {
          size: '26px',
          height: '32px',
          maxWidth: '624px',
        },
        medium: {
          size: '34px',
          height: '40px',
          maxWidth: '816px',
        },
        large: {
          size: '50px',
          height: '56px',
          maxWidth: '1200px',
        },
        xlarge: {
          size: '66px',
          height: '72px',
          maxWidth: '1584px',
        },
      },
    },
  },
  anchor: {
    color: '#DB9A2F',
    textDecoration: 'underline',
  },
  button: {
    padding: {
      vertical: '15px',
      horizontal: '25px',
    },
    border: {
      width: '2px',
      radius: '2px',
    },
    size: {small: {border: {radius: '2px'}}},

    primary: {
      extend: 'color: #fff;',
      radius: '2px',
    },
  },
  select: {
    icons: {
      color: 'dark-2',
    },
  },
  formField: {
    border: {color: 'rgba(0,0,0,0)'},
    extend: 'span {font-size: 12px;line-height:1.2}'
  },
  accordion: {
    icons: {color: '#8D919C'},
    heading: {
      margin: 'small',
    },
    border: {
      color: 'transparent',
    },
  },
  checkBox: {
    size: '15px',
    radius: '2px',
    color: 'dark-1',
    extend: 'align-items: flex-start',
    check: {
      extend: 'margin-top:0.2em',
    },
    border: {
      color: 'dark-1',
    },
  },
  radioButton: {
    size: '20px',
    extend: 'flex: 0 0 20px',
    icon: {
      size: '20px',
      extend: 'flex: 0 0 20px',
    },
  },
});
