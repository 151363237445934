import {
  getGaDataFormName,
  getGaDataFranchise,
  getGaDataId,
  getGaDataName,
  getGaDataPetAge,
  getGaDataPetAgeWidget,
} from '@animalia/actions';
import {
  Caption,
  Title,
  WidgetTightButton,
  WidgetTightOfferFieldContainer,
} from '@animalia/components';
import {Box} from 'grommet';
import React, {useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

const OfferSent = ({
  formValue,
  goToPrevStep,
  goToFirstStep,
  closeModalAndGoTo,
  offerQuid,
  pet,
}) => {
  const {i18n, t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const gaForm = !!pet ? 'start-widget-my-account' : 'start-widget';
  const labels = {
    back: t('calculator.navigation.prev'),
    thanks: t('calculator.thanks'),
    offer_in_mailbox: (
      <Trans
        i18nKey="calculator.offer_in_mailbox"
        values={{pet_name: formValue.pet_name}}
      />
    ),
    ok: t('calculator.navigation.ok'),
    offer_conclusion_1: (
      <Trans
        i18nKey="calculator.offer_conclusion_1"
        values={{pet_name: formValue.pet_name || pet.name}}
      />
    ),
    offer_conclusion_2: t('calculator.offer_conclusion_2'),
    seeOffer: t('calculator.navigation.seeOffer'),
  };

  const onClick = () => {
    if (!!closeModalAndGoTo) {
      closeModalAndGoTo();
    } else {
      goToFirstStep();
    }
  };

  const goToOffer = () =>
    window.open(
      `${process.env.REACT_APP_MY_ACCOUNT_URL}/public/offer/${offerQuid}`
    );

  const goToOfferPage = () =>
    history.push({
      pathname: `/public/offer/${offerQuid}`,
      state: {
        from: window.location.pathname,
      },
    });

  useEffect(() => {
    if(window.dataLayer) {
      window.dataLayer.push({
        event: 'request_insurance_offer',
        page_title: document.title,
        page_location: window.location.href,
        page_referrer: window.location.href,
        page_language: i18n.language?.slice(0, 2),
        logged_in: !!pet,
        form_name: 'Insurance Request',
        step_id: 4,
        step_name: 'offer-sent',
        type_of_pet: formValue.pet_species,
        age_of_pet: pet?.birthdate
          ? getGaDataPetAge(pet?.birthdate)
          : getGaDataPetAgeWidget(formValue.pet_age_category),
        franchise: getGaDataFranchise(formValue.insurance_franchise),
        first_prime: formValue.insurance_cost ? formValue.insurance_cost / 100 : null,
        insurance_coverage: formValue.insurance_type,
        agent_code: formValue.agent_code || '0000',
      });
    }
  }, []);

  if (!!closeModalAndGoTo)
    return (
      <WidgetTightOfferFieldContainer backText={labels.back}>
        <Box pad={{vertical: '50px'}} alignContent="center" justify="center">
          <Title align="center">{labels.thanks}</Title>
          <br />
          <Caption align="center">{labels.offer_conclusion_1}</Caption>
          <br />
          <br />
          <Caption align="center">{labels.offer_conclusion_2}</Caption>
          <br />
          <br />
          <WidgetTightButton
            primary
            fill
            label={labels.seeOffer}
            onClick={goToOfferPage}
          />
        </Box>
      </WidgetTightOfferFieldContainer>
    );

  return (
    <WidgetTightOfferFieldContainer
      centered
      backText={labels.back}
      title={labels.thanks}
      subtitle={labels.offer_in_mailbox}>
      <WidgetTightButton
        primary
        fill
        label={labels.seeOffer}
        onClick={goToOffer}
      />
    </WidgetTightOfferFieldContainer>
  );
};

export default OfferSent;
