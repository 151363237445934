module.exports = {
  yourPetAssistance: 'L\'Assistance 24/7 di {{name}}',
  policeNumber: 'No di polizza {{number}}',
  noAssistanceSubtitle: '{{name}} non ha ancora Animalia Assitance 24/7',
  cga: 'CGA',
  contractStartDate: 'Inizio del contratto',
  contractEndDate: 'Fine del contratto (rinnovo tacito di anno in anno)',
  callHelpLine: 'Chiamare l\'Infoline 24/7',
  declareSinister: 'Dichiarare un caso di assistenza per {{name}}',
  deleteAssistance: 'Cancellare l\'Assistance Animalia 24/7 da',
  moreInformations: 'Ulteriori informazioni su Animalia Assistance 24/7',
  createAssistance: 'Concludere Animalia Assistance 24/7 per CHF 79.00/anno',
  policy: "Polizza assicurativa Assistenza 24/7",
  moreInformationsLink: 'https://www.animalia.ch/it/service/assistance',
  createAssistanceLink: 'https://shop.animalia.ch/it/product/assistance-24-7/',
  deleteAssistanceLink: 'mailto:contact@animalia.ch',
  callHelpLineLink: 'tel:+41225937326',
  declareSinisterLink: 'https://shop.animalia.ch/it/mon-compte/declaration-de-sinistre/',
}