import { Select } from 'grommet'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './TitleSelect.module.sass'

const TitleSelect = ({ name, disabled }) => {
  const { t } = useTranslation()

  const valueKeyConfig = { 
    key: 'value', 
    reduce: true 
  }
  
  const options = [
    {
      label: t('user.mr'),
      value: 'mr'
    },
    {
      label: t('user.mrs'),
      value: 'mrs'
    }
  ]

  return (
    <div className={styles.container}>
      <Select
        plain
        labelKey='label'
        name={name}
        disabled={disabled}
        valueKey={valueKeyConfig}
        options={options}
      />
    </div>
  )
}

export default TitleSelect
