import React from 'react';
import {theme} from '@animalia/components';

export default ({color = 'currentColor'}) => {
  return (
    <svg
      style={{display: 'block'}}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 0.520264C3.80603 0.520264 0 4.32629 0 9.02026C0 13.7142 3.80603 17.5203 8.5 17.5203C13.194 17.5203 17 13.7142 17 9.02026C17 4.32629 13.194 0.520264 8.5 0.520264ZM9.10714 13.1185C9.10714 13.202 9.03884 13.2703 8.95536 13.2703H8.04464C7.96116 13.2703 7.89286 13.202 7.89286 13.1185V7.95776C7.89286 7.87428 7.96116 7.80598 8.04464 7.80598H8.95536C9.03884 7.80598 9.10714 7.87428 9.10714 7.95776V13.1185ZM8.5 6.59169C8.26168 6.58683 8.03477 6.48874 7.86795 6.31848C7.70112 6.14822 7.60769 5.91935 7.60769 5.68098C7.60769 5.44261 7.70112 5.21374 7.86795 5.04348C8.03477 4.87322 8.26168 4.77513 8.5 4.77026C8.73832 4.77513 8.96523 4.87322 9.13206 5.04348C9.29888 5.21374 9.39231 5.44261 9.39231 5.68098C9.39231 5.91935 9.29888 6.14822 9.13206 6.31848C8.96523 6.48874 8.73832 6.58683 8.5 6.59169Z"
        fill={color}
      />
    </svg>
  );
};
