module.exports = {
  deliveryAddress: 'Indirizzo di consegna',
  billingAddress: 'Indirizzo di fatturazione',
  defineNewAddress: 'Impostare il nuovo indirizzo',
  addNewCard: 'Aggiungere una carta di credito o PostFinance',
  addNewTwint: 'Aggiungere un conto Twint',
  addNewPowerPay: 'Aggiungere un conto PowerPay',
  paymentMethod: 'Modalità di pagamento',
  firstname: 'Nome *',
  lastname: 'Cognome *',
  line1: 'Indirizzo *',
  line2: "Indicazioni aggiuntive sull'indirizzo",
  zipcode: 'NPA *',
  city: 'Località *',
  state: 'Stato',
  country: 'Paese',
  thisIsThesePetsHouse: 'Questa è la casa di',
};
