import React, {useContext} from 'react';
import {Box, Text, Grid, Button} from 'grommet';
import * as Icons from 'grommet-icons';
import {useHistory} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';

const WidgetTightBackLink = ({
  to = '/',
  onClick,
  text,
  askForConfirmation,
  destinationLabel = '',
  ...rest
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const showConfirmModal = () => {
    return <>
      <Grid gap="small">
        <Text>
          <Trans
            i18nKey="message.quitWarning.description"
            values={{destination: destinationLabel}}
          />
        </Text>
      </Grid>
      <Button
        label={t('message.quitWarning.continue')}
        primary
        onClick={onClick ? onClick  : () => history.push({
          pathname: to,
          state: {
            from: window.location.pathname
          }
        })}
      />
      <Button
        label={t('message.quitWarning.cancel')}
        primary
      />
    </>
  }

  return (
    <Box
      hoverIndicator
      border="bottom"
      direction="row"
      align="center"
      height="35px"
      style={{fontSize: '0.65rem', minHeight: '35px'}}
      pad="small"
      gap="small"
      {...rest}
      onClick={() => {
        askForConfirmation ? showConfirmModal() : onClick ? onClick()  : history.push({
          pathname: to,
          state: {
            from: window.location.pathname
          }
        });
      }}>
      <Icons.FormPrevious />
      <Text style={{lineHeight: 1}} size="0.675rem">
        {text}
      </Text>
    </Box>
  );
};

export default WidgetTightBackLink;
