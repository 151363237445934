import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from 'react-router';
import {Auth} from 'aws-amplify';
import PasswordField from './passwordField.js';
import {
  CheckBox,
  Anchor,
  Grid,
  Form,
  FormField,
  TextInput,
  Heading,
  Select,
  Box,
  Text,
  Button,
} from 'grommet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {it, fr, de} from 'date-fns/locale';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Tip} from '@animalia/components';
import ForgotPassword from './forgotPassword.js';
import Login from './login.js';
import Edit from './edit.js';
import SocialLogin from './socialLogin.js';
import getError from './error.js';
import differenceInYears from 'date-fns/differenceInYears';

import {captureMessage, setTag} from '@sentry/browser';
import {getGaDataFormName, getGaDataId, getGaDataName} from '@animalia/actions';

const PhoneInput = styled(TextInput)`
  border: 0;
  &:focus {
    box-shadow: none;
  }
`;
const captureError = (err, email, message) => {
  setTag('error', err);
  setTag('email', email);
  setTag('error_location', message);
  captureMessage('authentification error');
};

export default function ({
  initialStep = 'login',
  onSubmit,
  defaultValues,
  aboGourmand = false,
  breeder,
}) {
  const isLoaded = useRef(false);
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const [step, setStep] = useState(initialStep);
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [emailExistsError, setEmailExistsError] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [givenNameError, setGivenNameError] = useState(false);
  const [familyNameError, setFamilyNameError] = useState(false);
  const [password, setPassword] = useState();
  const [birthdateError, setBirthdateError] = useState(false);
  const [birthdate, setBirthdate] = useState();
  const phoneRegExp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
  const gaForm = aboGourmand ? 'ag-connexion-1' : 'insurance-connexion-form-3';
  const [userlocale, setUserlocale] = useState({
    value: 'fr',
    label: 'Français',
  });
  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
    phone_number: '',
    phone_prefix: {
      value: '+41',
      label: '+41',
    },
    ['custom:title']: '',
    ['custom:subscribeToMailchimp']: false,
    given_name: '',
    family_name: '',
    birthdate: '',
    locale: '',
  });

  const locales = {
    it,
    fr,
    de,
  };

  let isCheckingEmail = false;

  const GA4pageView = referer => {
    if (isLoaded.current) {
      window.dataLayer.push({
        event: 'page_view',
        page_title: document.title,
        page_location: window.location.href,
        page_referrer: referer,
        page_language: i18n.language?.slice(0, 2),
        logged_in: false,
        // hostname_of_parent_window: window.opener?.location?.hostname,
        form_name: '',
        step_id: '',
        step_name: '',
      });
    }
    isLoaded.current = true;
  };

  // useEffect(() => {
  // if (step == 'login') {
  //   GA4pageView(location?.state?.from
  //     ? window.location.origin + location?.state?.from
  //     : document.referrer);

  //   window.dataLayer.push({
  //     event: 'enter_step',
  //     page_title: document.title,
  //     page_location: window.location.href,
  //     page_referrer: location?.state?.from
  //       ? window.location.origin + location?.state?.from
  //       : document.referrer,
  //     page_language: i18n.language?.slice(0, 2),
  //     logged_in: false,
  //     form_name: getGaDataFormName(gaForm),
  //     form_error: false,
  //     step_id: getGaDataId(gaForm, 'login'),
  //     step_name: getGaDataName(gaForm, 'login'),
  //     type_of_pet: "",
  //     age_of_pet: "",
  //     franchise: "",
  //     first_prime: null,
  //     insurance_coverage: "",
  //     disaster_type: ""
  //   });
  // }

  // if (step == 'personal') {
  //   GA4pageView(window.location.href); // not very clean but we can assume the previous page is on the same url as there is no way of getting there otherwise
  //   window.dataLayer.push({
  //     event: 'enter_step',
  //     page_title: document.title,
  //     page_location: window.location.href,
  //     page_referrer: window.location.href, // not very clean but we can assume the previous page is on the same url as there is no way of getting there otherwise
  //     page_language: i18n.language?.slice(0, 2),
  //     logged_in: false,
  //     // hostname_of_parent_window: window.opener?.location?.hostname,
  //     form_name: getGaDataFormName(
  //       aboGourmand ? 'ag-connexion-2' : 'account-creation'
  //     ),
  //     form_error: false,
  //     step_id: getGaDataId(
  //       aboGourmand ? 'ag-connexion-2' : 'account-creation',
  //       'personal'
  //     ),
  //     step_name: getGaDataName(
  //       aboGourmand ? 'ag-connexion-2' : 'account-creation',
  //       'personal'
  //     ),
  //     type_of_pet: '',
  //     age_of_pet: '',
  //     franchise: '',
  //     first_prime: null,
  //     insurance_coverage: '',
  //     disaster_type: '',
  //   });
  // }

  //   if (step == 'signup') {
  //     GA4pageView(window.location.href); // not very clean but we can assume the previous page is on the same url as there is no way of getting there otherwise

  //     window.dataLayer.push({
  //       event: 'enter_step',
  //       page_title: document.title,
  //       page_location: window.location.href,
  //       page_referrer: window.location.href, // not very clean but we can assume the previous page is on the same url as there is no way of getting there otherwise
  //       page_language: i18n.language?.slice(0, 2),
  //       logged_in: false,
  //       // hostname_of_parent_window: window.opener?.location?.hostname,
  //       form_name: getGaDataFormName(
  //         aboGourmand ? 'ag-connexion-2' : 'account-creation'
  //       ),
  //       form_error: false,
  //       step_id: getGaDataId(
  //         aboGourmand ? 'ag-connexion-2' : 'account-creation',
  //         'signup'
  //       ),
  //       step_name: getGaDataName(
  //         aboGourmand ? 'ag-connexion-2' : 'account-creation',
  //         'signup'
  //       ),
  //       type_of_pet: '',
  //       age_of_pet: '',
  //       franchise: '',
  //       first_prime: null,
  //       insurance_coverage: '',
  //       disaster_type: '',
  //     });
  //     history.pushState('from', window.location.href, window.location.href);
  //   }

  //   if (step == 'confirm') {
  //     GA4pageView(window.location.href); // not very clean but we can assume the previous page is on the same url as there is no way of getting there otherwise

  //     window.dataLayer.push({
  //       event: 'sign_up',
  //       logged_in: false,
  //     });

  //     window.dataLayer.push({
  //       event: 'enter_step',
  //       page_title: document.title,
  //       page_location: window.location.href,
  //       page_referrer: location?.state?.from
  //         ? window.location.origin + location?.state?.from
  //         : window.location.href,
  //       page_language: i18n.language?.slice(0, 2),
  //       logged_in: false,
  //       // hostname_of_parent_window: window.opener?.location?.hostname,
  //       form_name: getGaDataFormName(
  //         aboGourmand ? 'ag-connexion-2' : 'account-creation'
  //       ),
  //       form_error: false,
  //       step_id: getGaDataId(
  //         aboGourmand ? 'ag-connexion-2' : 'account-creation',
  //         'confirmation'
  //       ),
  //       step_name: getGaDataName(
  //         aboGourmand ? 'ag-connexion-2' : 'account-creation',
  //         'confirmation'
  //       ),
  //       type_of_pet: '',
  //       age_of_pet: '',
  //       franchise: '',
  //       first_prime: null,
  //       insurance_coverage: '',
  //       disaster_type: '',
  //     });
  //   }
  // }, [step]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const signin = urlParams.get('newUser');

    if (signin) {
      setStep('signup');
    }

    switch (i18n.language.substr(0, 2)) {
      case 'it':
        setUserlocale({
          value: 'it',
          label: 'Italiano',
        });
        break;

      case 'de':
        setUserlocale({
          value: 'de',
          label: 'Deutsch',
        });
        break;

      case 'fr':
      default:
        setUserlocale({
          value: 'fr',
          label: 'Français',
        });

        break;
    }
  }, [i18n.language]);

  const formMessages = {
    required: t('common.required'),
  };

  if (step === 'personal' && emailExistsError !== null) {
    setStep('signup');
  }

  // while allowing letters, spaces and accents
  // each part of the first or last name must be at least 2 characters long
  const nameRegexp = /^[a-zA-ZÀ-úÀ-ÿ -]{2,30}$/;
  // chromium regexp for emails
  const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <Grid gap="small" margin="auto" style={{width: '100%', maxWidth: '500px'}}>
      {step === 'forgotPassword' && (
        <ForgotPassword
          onSuccess={() => {
            setStep('login');
          }}
        />
      )}

      {step === 'login' && (
        <Login
          breeder={breeder}
          setStep={setStep}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      )}

      {/*
      this may be shown if the user has logged in using social identity.
      If there are some missing infos, the user will be shown this form
      */}
      {step === 'edit' && (
        <Edit onSubmit={onSubmit} defaultValues={defaultValues} />
      )}

      {['signup', 'personal'].indexOf(step) !== -1 && (
        <Form
          validate="blur"
          messages={formMessages}
          noValidate
          onValidate={({errors}) => {
            for (const key in errors) {
              window.dataLayer.push({
                event: 'generate_form_error',
                page_title: document.title,
                page_location: window.location.href,
                page_referrer: window.location.href,
                page_language: i18n.language?.slice(0, 2),
                logged_in: false,
                // hostname_of_parent_window: window.opener?.location?.hostname,
                form_name: getGaDataFormName('account-creation'),
                form_error: `${key}: ${errors[key]}`,
                step_id: getGaDataId('account-creation', step),
                step_name: getGaDataName('account-creation', step),
                type_of_pet: '',
                age_of_pet: '',
                franchise: '',
                first_prime: null,
                insurance_coverage: '',
                disaster_type: '',
              });
            }
          }}
          onChange={value => {
            setFormValue(value);
          }}
          onSubmit={({value}) => {
            if (emailExistsError !== null) {
              return false;
            }

            if (!nameRegexp.test(value.given_name)) {
              setGivenNameError(t('offer.error.firstname_error'));
              return false;
            }

            if (!nameRegexp.test(value.family_name)) {
              setFamilyNameError(t('offer.error.lastname_error'));
              return false;
            }

            if (!emailRegexp.test(value.email)) {
              setEmailError(t('offer.error.email'));
              return false;
            }

            if (step === 'signup') {
              // next step
              setStep('personal');
              return;
            }

            const familyName =
              value.family_name.charAt(0).toUpperCase() +
              value.family_name.toLowerCase().slice(1);
            const givenName =
              value.given_name.charAt(0).toUpperCase() +
              value.given_name.toLowerCase().slice(1);

            value.email = value.email.trim();
            let attributes = {
              email: value.email,
              phone_number:
                value.phone_prefix.value +
                value.phone_number.replace(/\s+/g, ''),
              'custom:title': value['custom:title'].value,
              'custom:subscribeToMailchimp': value[
                'custom:subscribeToMailchimp'
              ]
                ? '1'
                : '0',
              locale: value.locale.value,
              family_name: familyName,
              given_name: givenName,
            };

            if (birthdate && differenceInYears(new Date(), birthdate) < 18) {
              setBirthdateError(t('offer.error.min_18'));
              return false;
            }

            if (typeof birthdate !== 'undefined') {
              attributes = {
                ...attributes,
                birthdate: birthdate.toISOString().slice(0, 10),
              };
            }

            Auth.signUp({
              username: value.email,
              password: value.password,
              attributes,
            })
              .then(({user}) => {
                setEmail(value.email);
                setPassword(value.password);
                setStep('confirm');
              })
              .catch(err => {
                captureError(err, value?.email, 'onSignUpError');
                if (err.code === 'UsernameExistsException') {
                  // we try to login using the password
                  Auth.signIn(value.email, value.password).catch(e => {
                    if (e.code === 'UserNotConfirmedException') {
                      Auth.resendSignUp(value.email)
                        .then(e => {
                          setEmail(value.email);
                          setPassword(value.password);
                          setStep('confirm');
                        })
                        .catch(e => {
                          captureError(e, value?.email, 'resendSignUp');
                          setError(getError(e));
                        });
                    } else {
                      setError('auth.Anaccountwiththegivenemailalreadyexists');
                    }
                  });
                } else {
                  setError(getError(err));
                }
              });
          }}>
          {step === 'signup' && (
            <>
              <Heading>{t('auth.SIGN_UP_HEADER_TEXT')}</Heading>
              <Grid gap="small">
                <FormField
                  name="email"
                  background="light-1"
                  label={t('auth.EMAIL_LABEL')}
                  error={emailExistsError ?? emailError}
                  validate={value => {
                    if (isCheckingEmail) {
                      return;
                    }
                    isCheckingEmail = true;
                    Auth.signIn(value, '123')
                      .catch(err => {
                        captureError(err, value?.email || email, 'signIn');
                        if (err.code !== 'UserNotFoundException') {
                          setEmailExistsError(
                            t('auth.errorEmailAlreadyExists')
                          );
                        } else {
                          setEmailExistsError(null);
                        }
                      })
                      .finally(() => {
                        isCheckingEmail = false;
                      });
                  }}
                  required>
                  <TextInput
                    name="email"
                    type="email"
                    value={formValue.email}
                    placeholder={t('auth.EMAIL_PLACEHOLDER')}
                  />
                </FormField>
                <PasswordField value={formValue.password} />
                <FormField
                  background="light-1"
                  name="phone_number"
                  label={t('auth.PHONE_LABEL')}
                  validate={value => {
                    if (value.replace(/\s+/g, '').length < 7) {
                      return t('auth.checkFormatPhone');
                    }
                    if (phoneRegExp.test(value.replace(/\s+/g, ''))) {
                      return t('auth.checkFormatPhone');
                    }

                    if (/[^0-9]/.test(value.replace(/\s+/g, ''))) {
                      return t('auth.checkFormatPhone');
                    }

                    if (isNaN(parseInt(value))) {
                      return t('auth.checkFormatPhone');
                    }
                  }}
                  required>
                  <Box
                    direction="row"
                    gap="small"
                    align="center"
                    pad={{horizontal: 'small'}}>
                    <Select
                      id="phone_prefix"
                      name="phone_prefix"
                      labelKey="label"
                      valueKey="value"
                      value={formValue['phone_prefix']}
                      style={{width: '80px'}}
                      plain
                      options={[
                        {
                          value: '+41',
                          label: '+41',
                        },
                        {
                          value: '+423',
                          label: '+423',
                        },
                        {
                          value: '+33',
                          label: '+33',
                        },
                        {
                          value: '+49',
                          label: '+49',
                        },
                        {
                          value: '+43',
                          label: '+43',
                        },
                        {
                          value: '+39',
                          label: '+39',
                        },
                      ]}
                    />
                    <PhoneInput
                      value={formValue.phone_number}
                      name="phone_number"
                      id="phone"
                      type="tel"
                      placeholder={t('auth.PHONE_PLACEHOLDER')}
                    />
                  </Box>
                </FormField>
                <Button
                  primary
                  type="submit"
                  label={t('auth.SIGN_UP_SUBMIT_BUTTON_TEXT')}
                />
                <Button
                  onClick={() => {
                    setStep('login');
                  }}
                  label={t('auth.SIGN_UP_ALREADY_BUTTON_TEXT')}
                />
              </Grid>

              <Box margin={{top: 'large'}}>
                <Text textAlign="center">{t('common.or')}</Text>
              </Box>

              <Box align="start" margin={{top: 'large'}}>
                <SocialLogin signIn breeder={breeder} />
              </Box>
            </>
          )}
          {step === 'personal' && (
            <>
              <Heading>{t('auth.personnalInfo')}</Heading>
              <Grid gap="small">
                <FormField
                  htmlFor="custom:title"
                  name="custom:title"
                  background="light-1"
                  required
                  label={t('user.title')}>
                  <Select
                    id="custom:title"
                    name="custom:title"
                    labelKey="label"
                    valueKey="value"
                    value={formValue['custom:title']}
                    plain
                    options={[
                      {
                        value: 'mrs',
                        label: t('user.mrs'),
                      },
                      {
                        value: 'mr',
                        label: t('user.mr'),
                      },
                    ]}
                  />
                </FormField>
                <FormField
                  htmlFor="given_name"
                  name="given_name"
                  background="light-1"
                  required
                  error={givenNameError}
                  label={t('user.firstName')}
                  validate={{
                    regexp: nameRegexp,
                    message: t('offer.error.more_char'),
                  }}>
                  <TextInput
                    name="given_name"
                    id="given_name"
                    value={formValue.given_name}
                  />
                </FormField>
                <FormField
                  htmlFor="family_name"
                  name="family_name"
                  error={familyNameError}
                  background="light-1"
                  required
                  label={t('user.lastName')}
                  validate={{
                    regexp: nameRegexp,
                    message: t('offer.error.more_char'),
                  }}>
                  <TextInput
                    name="family_name"
                    value={formValue.family_name}
                    id="family_name"
                  />
                </FormField>
                <FormField
                  name="birthdate"
                  background="light-1"
                  label={t('user.birthdate')}
                  error={birthdateError}>
                  <DatePicker
                    selected={birthdate}
                    onChange={date => {
                      if (date) {
                        date.setHours(12, 0, 0);
                        setBirthdate(date);
                      }
                    }}
                    dateFormatCalendar="MMMM"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                    locale={locales[i18n.language]}
                    scrollableYearDropdown
                    value={formValue.birthdate}
                    dateFormat="dd.MM.yyyy"
                    className="datepicker"
                  />
                </FormField>
                <FormField
                  htmlFor="locale"
                  name="locale"
                  background="light-1"
                  required
                  label={t('user.locale')}>
                  <Select
                    id="locale"
                    name="locale"
                    labelKey="label"
                    valueKey="value"
                    plain
                    value={userlocale}
                    onChange={e => {
                      setUserlocale(e.value);
                    }}
                    options={[
                      {
                        value: 'it',
                        label: 'Italiano',
                      },
                      {
                        value: 'de',
                        label: 'Deutsch',
                      },
                      {
                        value: 'fr',
                        label: 'Français',
                      },
                    ]}
                  />
                </FormField>
                <FormField>
                  <Box direction="row" align="start" gap="xsmall">
                    <CheckBox
                      name="custom:subscribeToMailchimp"
                      value={formValue['custom:subscribeToMailchimp']}
                      label={t('user.subscribeToMailchimp')}
                    />
                    <Tip content={t('user.subscribeToMailchimpInfo')} />
                  </Box>
                </FormField>

                <Anchor
                  margin={{bottom: 'medium'}}
                  href={t('urls.privacyPolicy')}
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('common.privacy_policy')}{' '}
                </Anchor>
                <Grid gap="small" columns={['1fr', '1fr']}>
                  <Button
                    onClick={() => {
                      setStep('signup');
                    }}
                    color="light-4"
                    label={t('common.back')}
                  />
                  <Button
                    type="submit"
                    label={t('auth.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT')}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Form>
      )}
      {step === 'confirm' && (
        <>
          <Heading>{t('auth.CONFIRM_SIGN_UP_HEADER_TEXT')}</Heading>
          <Text>{t('auth.CODE_LABEL')}</Text>
          <Form
            validate="blur"
            messages={formMessages}
            onValidate={({errors}) => {
              for (const key in errors) {
                window.dataLayer.push({
                  event: 'generate_form_error',
                  page_title: document.title,
                  page_location: window.location.href,
                  page_referrer: window.location.href,
                  page_language: i18n.language?.slice(0, 2),
                  logged_in: false,
                  // hostname_of_parent_window: window.opener?.location?.hostname,
                  form_name: getGaDataFormName('account-creation'),
                  form_error: `${key}: ${errors[key]}`,
                  step_id: getGaDataId('account-creation', 'confirmation'),
                  step_name: getGaDataName('account-creation', 'confirmation'),
                  type_of_pet: '',
                  age_of_pet: '',
                  franchise: '',
                  first_prime: null,
                  insurance_coverage: '',
                  disaster_type: '',
                });
              }
            }}
            onSubmit={({value}) => {
              Auth.confirmSignUp(email, value.code)
                .then(() => {
                  Auth.signIn(email, password).catch(() => {
                    setStep('login');
                  });
                })
                .catch(e => {
                  captureError(e, value?.email || email, 'confirmSignUp');
                  setError(getError(e));
                  window.dataLayer.push({
                    event: 'generate_form_error',
                    page_title: document.title,
                    page_location: window.location.href,
                    page_referrer: window.location.href,
                    page_language: i18n.language?.slice(0, 2),
                    logged_in: false,
                    // hostname_of_parent_window: window.opener?.location?.hostname,
                    form_name: getGaDataFormName('account-creation'),
                    form_error: t(getError(e)),
                    step_id: getGaDataId('account-creation', 'confirmation'),
                    step_name: getGaDataName(
                      'account-creation',
                      'confirmation'
                    ),
                    type_of_pet: '',
                    age_of_pet: '',
                    franchise: '',
                    first_prime: null,
                    insurance_coverage: '',
                    disaster_type: '',
                  });
                });
            }}>
            <Grid gap="small">
              <FormField
                name="code"
                background="light-1"
                label={t('auth.CONFIRM_SIGN_UP_CODE_LABEL')}
                required>
                <TextInput
                  type="number"
                  inputmode="numeric"
                  name="code"
                  placeholder={t('auth.CODE_PLACEHOLDER')}
                />
              </FormField>
              <Button
                type="submit"
                label={t('auth.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT')}
              />
              <Box align="center" margin={{top: 'large'}}>
                <Anchor
                  label={t('auth.CONFIRM_SIGN_UP_RESEND_CODE')}
                  fill="horizontal"
                  color="text-color"
                  style={{textDecoration: 'underline'}}
                  onClick={() => {
                    Auth.resendSignUp(email)
                      .then(e => console.log('ok'))
                      .catch(e => {
                        captureError(e, email, 'resendSignUp');
                        setError(getError(e));
                      });
                  }}
                />
              </Box>
            </Grid>
          </Form>
        </>
      )}
      {error && (
        <Box textAlign="center" margin={{top: 'medium'}}>
          <Text textAlign="center" color="status-critical">
            {t(error)}
          </Text>
        </Box>
      )}
    </Grid>
  );
}
