module.exports = {
  error: 'Errore',
  warning: 'Attenzione',

  apiError: 'Si è verificato un errore durante il processo della richiesta.',
  fieldValidationError: 'Uno o più campi non sono validi.',

  petNotFound: "L'animale ricercato non è stato trovato",
  petsNotFound: 'Non ci sono animali',

  insuranceNumberDelete: {
    title: 'Cancellare il raccordo con il numero di poizza di {{name}}',
    content:
      ' Attenzione: cancelli il collegamento solo con il numero di polizza di {{nome}}. Il contratto di assicurazione rimane in vigore. Se desidera annullarlo, La preghiamo di consultare le Condizioni generali di assicurazione (CGA).',
  },

  card: {
    cancelled: 'Operazione annullata',
    error: 'Verificare le informazioni della vostra carta.',
  },

  latePayment: {
    title: 'Oops, ha ancora una fattura aperta!',
    content:
      'Una fattura non è stata pagata. Si prega di pagare prima di entrare in un nuovo Abbonamente Gourmet.',
  },
};
