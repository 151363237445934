import React from 'react'
import ReactDOM from 'react-dom'
import { I18n } from 'aws-amplify'
import lang from '@animalia/lang'
import App from './App'

I18n.putVocabularies({
  fr: lang.fr.auth,
  de: lang.de.auth,
  it: lang.it.auth,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)