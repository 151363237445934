module.exports = {
  myaccount: 'Conto cliente Animalia',
  'create-ag': {
    title: 'Calcolare un Abbonamente Gourmet',
    subtitle: 'Per quale dei suoi animali desidera calcolare un Abbonamento Gourmet?',
    cta: 'Calcolare il suo Abo Gourmand'
  },
  'edit-ag': {
    title: 'Modificare l\'Abbonnamente Gourmet',
    subtitle: 'Per quale dei suoi animali desidera modificare l\'Abbonamento Gourmet?',
    cta: 'Modificare il suo Abbonamente Gourmet'
  },
  'calculate-insurance': {
    title: 'Calcolare l\'assicurazione',
    subtitle: 'Per quale dei suoi animali desidera calcolare l\'assicurazione?',
    cta: 'Calcolare l\'assicurazione'
  },
  'declare-sinister': {
    title: 'Annunciare un sinistro',
    subtitle: 'Per quale dei suoi animali desidera annunciare un sinistro?',
    cta: 'Annunciare il sinistro'
  },
  'send-invoice': {
    title: 'Inviare una fattura',
    subtitle: 'Per quale dei suoi animali desidera inviarci una fattura per il rimborso?',
    cta: 'Inviare la fattura'
  },
  'edit-insurance': {
    title: 'Modificare l\'assicurazione',
    subtitle: 'Per quale dei suoi animali desidera modificare l\'assicurazione?',
    cta: 'Modificare l\'assicurazione'
  },
};
