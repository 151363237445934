module.exports = {
  title: "Züchterin/Züchter",
  breederSpace: "Züchter-Bereich",
  login: {
    createBreederAccount: "Ein Züchter-Konto erstellen",
  },
  species: {
    cat: "Katze",
    dog: "Hund",
  },
  personal_data: {
    breeder_infos: "Informationen zur Züchterin / zum Züchter",
    personal_data: "Personendaten",
    social_reason: "Firma",
    social_reason_label: "Name der Zucht bzw. Firma",
    races: "Rassen",
    breeding: "Zucht",
    tel: "Telefon",
    email: "E-Mail",
    message: "Mitteilung",
    send_request: "Anfrage senden",
  },
  confirmation: {
    askSent: "Ihre Anfrage wurde erfolgreich versendet",
    askSentMessage: "Wir werden Sie so schnell wie möglich kontaktieren.",
    backToHome: "Home",
  },
  declined: {
    askDeclined: "Anfrage abgelehnt",
    askDeclinedMessage: "Leider wurde Ihre Anfrage abgelehnt, da sie nicht allen unseren Aufnahmekriterien entspricht. <br/><br/>",
    backToHome: "Home",
  },
  dashboard: {
    myBreederSpace: "Mein Züchter-Bereich",
    myCoordinates: "Meine Angaben",
    myAttestations: "Meine Nachweise",
    breeding: "Zucht",
    race: "Rasse",
    address: "Adresse",
    conventionNumber: "Nummer der Vereinbarung",
    email: "E-Mail",
    howToEdit: "Wie bearbeite ich meine Angaben?",
    client: "Kundin/Kunde",
    petName: "Name des Tiers",
    attestationNumber: "Nachweis-Nummer",
    insuranceDate: "Versicherungsdatum",
    attestation: "Nachweis",
    cga: "AVB",
    declareNewPet: "Ein neues Tier melden",
    howEditCertificate: "Wie kann ich einen Nachweis bearbeiten?",
    doWantEditACertificate: "Möchten Sie einen Nachweis bearbeiten?",
    editCertificateInformations: "Um einen Nachweis zu bearbeiten, kontaktieren Sie uns bitte unter <a href='mailto:breeders@animalia.ch?subject={{agentCode}} – {{breederName}}'>breeders@animalia.ch</a>. Geben Sie dabei die Nummer des betreffenden Nachweises an, welche Änderungen vorgenommen werden sollen und weshalb.",
    doWantEditInformations: "Möchten Sie die Angaben zu Ihrer Zucht ändern?",
    editInformations: "Bitte kontaktieren Sie uns unter <a href='mailto:breeders@animalia.ch?subject={{agentCode}} – {{breederName}}'>breeders@animalia.ch</a>, um die Angaben zu Ihrer Zucht zu ändern.",
  },
  certificates: {
    myClient: "Mein Kunde",
    theyPet: "Sein Tier",
    title: "Anrede",
    mr: "Herr",
    mrs: "Frau",
    communicationLanguage: "Korrespondenzsprache",
    firstname: "Vorname",
    lastname: "Name",
    email: "E-Mail",
    birthdate: "Geburtsdatum",
    pet: "Tier",
    petName: "Name des Tiers",
    race: "Rasse",
    exitDate: "Datum des Austritts",
    gender: "Geschlecht",
    male: "Männchen",
    female: "Weibchen",
    dog: "Hund",
    cat: "Katze",
    createCertificate: "Nachweis erstellen",
    cga1: "Die Züchterin oder der Züchter bestätigt, von der Kundin oder dem Kunden formell berechtigt zu sein, die oben genannten Informationen an die Vaudoise weiterzugeben",
    cga2: "Achtung: Sobald der Nachweis validiert wurde, kann er nur noch von Animalia bearbeitet werden.",
    created: "Ihr Nachweis wurde erfolgreich erstellt.",
    createdMessage: "Sie finden sie in Ihrem Züchter-Konto.",
    backToBreederSpace: "Zurück zu meinem Züchter-Bereich",
    petNameAlreadyExist: "Ein Tier mit diesem Namen existiert bereits.",
    petBirthdateTooOldMax120Days: "Das Geburtsdatum des Tiers darf nicht länger als 120 Tage zurückliegen.",
    petExitDateMax120DaysBirthdate: "Das Datum, an dem das Tier aus der Zucht austritt, muss innerhalb von 120 Tagen nach seiner Geburt liegen.",
    petBirthdateMin6Months: "Das Geburtsdatum des Tieres muss mindestens 6 Monate zurückliegen.",
    petExitDateMax56DaysBirthdate: "Das Austrittsdatum des Tieres muss innerhalb von 56 Tage nach seiner Geburt liegen.",
    petExitDateMax180DaysBirthdate: "Das Austrittsdatum des Tieres muss innerhalb von 180 Tage nach seiner Geburt liegen.",
    petNameTip: "Vom Kunden angegebener Name des Tieres",
  },
}
