import React from 'react';
import {theme} from '@animalia/components';

export default ({
  color = 'dark',
  accent = 'accent-1',
  width = 165,
  height = 30,
}) => {
  const logocolor = theme.global.colors.background[color];
  const logoaccent = theme.global.colors[accent];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 104 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5282 5.12481H22.0681V6.96916C22.9194 5.90511 24.1253 4.84106 25.9696 4.84106C28.7362 4.84106 30.3677 6.68541 30.3677 9.5938V18.0352H25.7568V11.0125C25.7568 9.5938 25.0475 8.8135 23.9125 8.8135C22.7775 8.8135 21.9972 9.5938 21.9972 11.0125V18.0352H17.4573V5.12481H17.5282Z"
        fill={logocolor}
      />
      <path
        d="M32.7792 5.12475H37.3191V18.1061H32.7792V5.12475ZM32.7083 0.655762H37.461V3.98977H32.7083V0.655762Z"
        fill={logocolor}
      />
      <path
        d="M39.8733 5.12481H44.4132V6.89822C45.2645 5.83417 46.4704 4.84106 48.2438 4.84106C49.9463 4.84106 51.294 5.62136 52.0034 6.96916C53.1384 5.6923 54.4152 4.84106 56.3305 4.84106C59.097 4.84106 60.7286 6.54354 60.7286 9.5938V18.1062H56.1887V11.0835C56.1887 9.66474 55.4793 8.95537 54.4152 8.95537C53.3512 8.95537 52.5709 9.66474 52.5709 11.0835V18.1062H48.031V11.0835C48.031 9.66474 47.3216 8.95537 46.2576 8.95537C45.1935 8.95537 44.4132 9.66474 44.4132 11.0835V18.1062H39.8733V5.12481Z"
        fill={logocolor}
      />
      <path
        d="M70.6593 13.4246V12.8571C70.2337 12.6443 69.5243 12.5024 68.8859 12.5024C67.5381 12.5024 66.8287 13.1408 66.8287 14.1339V14.2049C66.8287 15.0561 67.4671 15.6236 68.4603 15.6236C69.7371 15.5527 70.6593 14.7014 70.6593 13.4246ZM62.4307 14.4177C62.4307 11.7221 64.4878 10.3034 67.5381 10.3034C68.744 10.3034 69.879 10.5162 70.6593 10.7999V10.5871C70.6593 9.23934 69.808 8.45904 68.1056 8.45904C66.7578 8.45904 65.6937 8.74278 64.5588 9.1684L63.6366 5.97626C65.1262 5.40877 66.6869 4.98315 68.8859 4.98315C71.2268 4.98315 72.7874 5.55064 73.8514 6.54375C74.8445 7.53686 75.2702 8.81372 75.2702 10.6581V18.1064H70.8012V16.7586C69.879 17.7517 68.6731 18.3901 66.9706 18.3901C64.346 18.3901 62.4307 16.9714 62.4307 14.4177Z"
        fill={logocolor}
      />
      <path
        d="M82.0798 0.655762H77.5399V18.1061H82.0798V0.655762Z"
        fill={logocolor}
      />
      <path
        d="M84.7053 5.12475H89.2453V18.1061H84.7053V5.12475ZM84.6344 0.655762H89.3871V3.98977H84.6344V0.655762Z"
        fill={logoaccent}
      />
      <path
        d="M99.3891 13.4241V12.8566C98.9635 12.6438 98.2542 12.5019 97.6157 12.5019C96.2679 12.5019 95.5586 13.1404 95.5586 14.1335V14.2044C95.5586 15.0556 96.197 15.6231 97.1901 15.6231C98.467 15.5522 99.3891 14.701 99.3891 13.4241ZM91.1605 14.4172C91.1605 11.7216 93.2177 10.3029 96.2679 10.3029C97.4739 10.3029 98.6088 10.5157 99.3891 10.7994V10.5866C99.3891 9.23885 98.5379 8.45855 96.8354 8.45855C95.4876 8.45855 94.4236 8.7423 93.2886 9.16791L92.3664 5.97577C93.8561 5.40828 95.4167 4.98267 97.6157 4.98267C99.9566 4.98267 101.517 5.55015 102.581 6.54326C103.574 7.53637 104 8.81323 104 10.6576V18.1059H99.531V16.7581C98.6798 17.7512 97.4029 18.3896 95.7005 18.3896C93.0758 18.3896 91.1605 16.9709 91.1605 14.4172Z"
        fill={logoaccent}
      />
      <path
        d="M10.7185 13.4253V12.8578C10.2929 12.645 9.58351 12.5031 8.94508 12.5031C7.59729 12.5031 6.88792 13.1416 6.88792 14.1347V14.2056C6.88792 15.0569 7.52635 15.6243 8.51946 15.6243C9.79632 15.5534 10.7185 14.7022 10.7185 13.4253ZM2.48987 14.4184C2.48987 11.7228 4.54702 10.3041 7.59729 10.3041C8.80321 10.3041 9.93819 10.5169 10.7185 10.8007V10.5879C10.7185 9.24007 9.86725 8.45977 8.16478 8.45977C6.81699 8.45977 5.75294 8.74352 4.61796 9.16913L3.69579 5.97699C5.18545 5.4095 6.74605 4.98389 8.94508 4.98389C11.286 4.98389 12.8466 5.55138 13.9106 6.54448C14.9037 7.53759 15.3294 8.81445 15.3294 10.6588V18.1071H10.8604V16.7593C10.0091 17.7524 8.73227 18.3909 7.0298 18.3909C4.40515 18.3909 2.48987 16.9721 2.48987 14.4184Z"
        fill={logocolor}
      />
      <path
        d="M4.97244 14.772L1 16.0489M12.7045 13.2114L16.6769 11.9346L12.7045 13.2114ZM12.7045 14.772L16.6769 16.0489L12.7045 14.772ZM4.97244 13.2114L1 11.9346L4.97244 13.2114Z"
        stroke={logoaccent}
        strokeMiterlimit="10"
      />
    </svg>
  );
};
