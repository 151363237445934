import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormField } from 'grommet'
import styles from './WidgetTightFormField.module.sass'
import classNames from 'classnames'
import { OfferTip } from '../..'

const WidgetTightFormField = ({ 
  name, 
  stretch, 
  background,
  error, 
  disabled, 
  centered, 
  tip, 
  hasComponent, 
  close,
  horizontalFill,
  loading,
  validate,
  unknown,
  RightIcon,
  ...rest
 }) => {
  const { t } = useTranslation()
  const [statementColor, setStatementColor] = useState(background)

  const getError = () => 
    error?.length
      ? error.find(item => item?.path === name) ? t(error.find(item => item?.path === name).message) : false
      : false
  
  const getClasses = () => {
    const cls = [
      styles.container,
      hasComponent && styles.hasComponent,
      centered && styles.centered,
      stretch && styles.stretch,
      background && styles.border,
      loading && styles.loading,
      getError() && styles.error,
      disabled && styles.disabled,
      horizontalFill && styles.hFill,
      validate && styles.validate,
      unknown && styles.unknown,
      disabled && validate && styles.validate,
      disabled && unknown && styles.unknown
    ]
    return classNames(
      cls.filter(item => !!item)
    )
  }

  const containerClasses = getClasses()

  useEffect(() => {
    if (
      (background && (getError() || unknown)) ||
      (background && (getError() || unknown) && disabled)
    ) {
      setStatementColor('errorLight')
    } else if (
      (background && validate) ||
      (background && validate && disabled)
    ) {
      setStatementColor('status-validate')
    } else if (background && disabled) {
      setStatementColor('light-5')
    } else if (background) {
      setStatementColor(background)
    }
  }, [
    background, 
    error, 
    disabled, 
    validate,
    unknown
  ])
  
  return (
    <div className={containerClasses}>
      <FormField 
        margin='2xl'
        name={name}
        background={statementColor}
        disabled={disabled}
        error={getError()}
        { ...rest }
      />
      {
        tip && 
          <span className={styles.tip}>
            <OfferTip content={tip} />
          </span>
      }

      {
        RightIcon &&
          <span className={styles.tip}>
            <RightIcon />
          </span>
      }

      {
        close &&
          <span className={styles.close} onClick={close}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z" fill="black"/>
            </svg>
          </span>
      }
    </div>
    
  )
}

export default WidgetTightFormField
