module.exports = {
  firstDeliveryDate: 'Erste Lieferung am',
  lastDelivery: 'Letzte Lieferung am',
  selectLastDelivery: 'Wählen Sie Ihre letzte Lieferung aus',
  validateDate: 'Bestätigen Sie das Datum',
  deleteOrder: 'Kündigen',
  cancelled: 'Gourmet-Abo aufgehoben',
  couponCodeLabel: 'Vorteilscode',
  couponCodePlaceholder: 'Ihr Vorteilscode',
  invalidCoupon: 'Vorteilscode nicht gültig',
  editOrder: 'Abonnement ändern',
  showAllProducts: 'Andere Sorten anzeigen',
  showRecommandedProducts: 'Nur empfohlene Produkte anzeigen',
  notRecommendedTooltip: 'Selbstverständlich dürfen Sie diese Kroketten bestellen. Gemäss Ihren Angaben empfehlen wir Ihnen jedoch ein anderes Produkt.',
  confirmModification:
    'Durch die Anpassungen Ihres Gourmet-Abos hat der Preis geändert. Bitte bestätigen Sie, dass Sie damit einverstanden sind.',
  couponDetailsUnlimited: 'Sie profitieren von {{discount}} Ermässigung.',
  couponDetails:
    'Sie profitieren von {{discount}} Ermässigung während {{months}} Monaten. Danach passen wir den Preis automatisch an den Standardpreis an.',
  priceWithoutDiscount: '(Preis ohne Rabatt: {{price}})',
  payAmount: '{{price}} bezahlen',
  error: {
    pleaseAcceptTerms: 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen',
    pleaseChooseADeliveryDate: 'Bitte wählen Sie das erste Lieferdatum',
  },
  pricePerMonth: 'Preis pro Monat',
  price: 'Preis',
  totalPricePerMonth: 'Total Preis pro Monat',
  discount: 'Rabatt',
  thankyou: {
    title: 'Merci und herzliche Gratulation!',
    text:
      'Vielen Dank, dass Sie sich für das Gourmet-Abo entschieden haben! Ihr Vierbeiner leckt sich schon jetzt die Lefzen und dankt Ihnen ebenfalls, dass Sie die richtige Wahl getroffen haben. Ihre Bestellung ist validiert und wir beginnen gleich, sein Festmahl mit qualitativen und proteinreichen Zutaten zuzubereiten. Das leckere Paket trifft in den nächsten Tagen bei Ihnen zu Hause ein. Wir freuen uns, Sie in unserer Pfoten-Lover-Community begrüssen zu dürfen!',
    button: 'Meine Bestellung ansehen',
    subText:
      'Haben Sie eine Frage? Wir beantworten sie gerne online via Live-Chat oder per Telefon unter der Nummer 0800 815 915.',
  },
  mfgroupErrorUnavailable: "Sie können dieses Zahlmittel im Moment nicht benutzen. Bitte wählen Sie ein anderes Zahlmittel aus.",
  addPaymentMethodError: "Dieses Zahlmittel ist nicht gültig. Bitte geben Sie ein anderes Zahlmittel ein."
};
