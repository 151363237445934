module.exports = {
  noInvoices: 'Pas de factures',
  noInvoicesDescription: "Il n'y a pas encore de facture pour votre compte.",
  refno: 'No de commande',
  total: 'Prix',

  paymentModal: {
    successTitle: 'Merci pour votre paiement',
    successMessage: 'Nous avons bien reçu votre paiement et vous en remercions.',
    failureTitle: 'Oups! Echec de paiement',
    failureMessage: 'Votre moyen de paiement actuel ne semble pas fonctionner. Veuillez svp enregistrer un autre moyen de paiement et revenez sur cette page pour ressayer.',
  },
  payNow: 'Payer maintenant',
  closeFaildModal: 'Pas maintenant',
  payementMethod: 'Ajouter un nouveau moyen de paiement',
  status: {
    pending: 'En attente',
    failed: 'Echec de traitement',
    completed: 'Payé',
    'no-default-card': 'Pas de moyen de paiement enregistré',
    refunded: 'Remboursé',
  },
};
