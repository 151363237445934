import React from 'react';

export default () => (
  <svg
    width="80"
    height="64"
    viewBox="0 0 80 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.8885 43.6524C29.9134 43.6524 31.5549 41.1628 31.5549 38.0917C31.5549 35.0206 29.9134 32.531 27.8885 32.531C25.8636 32.531 24.2221 35.0206 24.2221 38.0917C24.2221 41.1628 25.8636 43.6524 27.8885 43.6524Z"
      fill="currentColor"
    />
    <path
      d="M51.2312 43.7746C53.2561 43.7746 54.8976 41.285 54.8976 38.2139C54.8976 35.1428 53.2561 32.6532 51.2312 32.6532C49.2063 32.6532 47.5648 35.1428 47.5648 38.2139C47.5648 41.285 49.2063 43.7746 51.2312 43.7746Z"
      fill="currentColor"
    />
    <path
      d="M52 38C52.5523 38 53 37.3284 53 36.5C53 35.6716 52.5523 35 52 35C51.4477 35 51 35.6716 51 36.5C51 37.3284 51.4477 38 52 38Z"
      fill="white"
    />
    <path
      d="M29 38C29.5523 38 30 37.3284 30 36.5C30 35.6716 29.5523 35 29 35C28.4477 35 28 35.6716 28 36.5C28 37.3284 28.4477 38 29 38Z"
      fill="white"
    />
    <path
      d="M39.5843 57.8535C38.7166 57.8535 37.9955 57.2791 37.7633 56.4848C36.8834 56.9736 35.8201 57.4991 34.5736 57.9513C34.3902 58.0246 34.1947 58.0857 33.9992 58.1468C33.9869 58.1468 33.9625 58.1591 33.9503 58.1591C33.7792 58.2202 33.5959 58.2691 33.4125 58.3302H33.4003C32.7281 58.5257 32.0193 58.6968 31.2738 58.8435C31.1149 58.8679 30.9683 58.9046 30.8094 58.929C30.6505 58.9535 30.4916 58.9779 30.3328 59.0023C31.7871 59.6378 32.9115 60.2367 33.6692 60.6767C35.3191 61.6299 35.8079 62.1065 37.0911 62.5099C38.6066 62.9743 39.8409 62.9009 40.1465 62.8765C40.7209 62.8276 42.1141 62.6565 43.5684 61.7766C44.4117 61.2633 44.4606 61.0066 45.2794 60.4322C45.976 59.9434 47.0759 59.3445 48.6892 58.9901C45.5972 58.5379 43.1162 57.438 41.4175 56.4725C41.1853 57.2547 40.452 57.8535 39.5843 57.8535Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M8.67664 41.9658C8.72552 46.9643 10.8765 52.525 15.1784 55.7514C19.9325 59.32 26.202 59.1978 27.8885 59.1612C28.8907 59.1367 29.7339 59.0512 30.3083 58.9778C30.4672 58.9534 30.6261 58.9289 30.785 58.9045C30.9316 58.8801 31.0905 58.8556 31.2494 58.8189C31.9093 58.6967 32.6182 58.5379 33.3759 58.3057H33.3881C33.5592 58.2568 33.7425 58.1957 33.9258 58.1346C33.9381 58.1346 33.9625 58.1223 33.9747 58.1223C34.1703 58.0612 34.3536 58.0001 34.5491 57.9268C35.7957 57.4746 36.8712 56.9613 37.7389 56.4602C38.5211 56.0203 39.1321 55.617 39.5843 55.2992C40.0243 55.6047 40.6231 56.0081 41.3808 56.448C43.0796 57.4135 45.5605 58.5134 48.6525 58.9656C49.3002 59.0512 50.2291 59.1489 51.3534 59.1856C53.6877 59.2467 59.5295 59.3934 64.0391 56.228C68.7321 52.9283 70.7486 46.9276 70.6509 41.6847"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M49.6913 3.33427C45.0839 1.08555 40.6964 1 39.8654 1C39.1443 1 34.6958 1.03666 29.905 3.33427"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M33.3881 58.3301C33.402 58.3301 33.402 58.3301 33.3881 58.3301C33.402 58.3301 33.402 58.3301 33.3881 58.3301Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M30.3083 58.9901C30.4672 58.9656 30.6261 58.9412 30.785 58.9167C30.6261 58.9412 30.4794 58.9656 30.3083 58.9901Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M37.7633 56.4847C36.8834 56.9736 35.8201 57.4991 34.5735 57.9513C35.8201 57.4991 36.8834 56.9736 37.7633 56.4847Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M33.938 58.1591C33.9504 58.1591 33.9752 58.1467 33.9876 58.1467C33.9752 58.1467 33.9628 58.1467 33.938 58.1591Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M39.5843 57.8536C40.4521 57.8536 41.1853 57.2669 41.4053 56.4603C40.1954 56.4725 38.9733 56.4725 37.7634 56.4848C37.9956 57.2669 38.7166 57.8536 39.5843 57.8536Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M39.6088 55.3115C39.1566 55.6293 38.5333 56.0448 37.7634 56.4725C38.9733 56.4603 40.1954 56.4603 41.4053 56.4481C40.6476 56.0326 40.0488 55.6293 39.6088 55.3115Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M14.0784 28.9868C15.0195 28.1679 16.5227 27.1169 17.256 25.076C17.7326 23.7561 17.6471 22.6928 17.6226 21.8984C17.6226 21.8984 17.5126 17.1565 20.0791 12.0969C21.8145 8.69941 24.4421 6.90288 26.2997 5.52187C27.4852 4.62971 28.7073 3.90866 29.905 3.33426C28.0963 2.88207 25.4076 2.49099 22.3889 3.19982C20.9102 3.54202 17.256 4.64193 11.2675 11.8769C7.55227 16.3622 0.879443 24.6238 1.00166 34.6086C1.05054 38.4583 2.07713 39.9982 3.07928 40.8414C4.79026 42.2713 7.1123 42.2224 8.67663 41.9658C8.65219 40.0226 8.9455 38.1772 9.55657 36.564C11.6097 31.1377 14.0784 28.9868 14.0784 28.9868Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M68.7321 11.7304C62.7437 4.49538 59.0895 3.39546 57.6107 3.05327C54.3476 2.28333 51.4634 2.79662 49.6669 3.29769C49.6669 3.30992 49.6791 3.32214 49.6791 3.33436C51.0112 3.98209 52.3678 4.82536 53.6632 5.88861C55.1542 7.12296 57.5496 8.90727 59.175 12.1337C61.0205 15.8001 61.436 20.3464 61.6071 22.1429C61.7904 24.0983 61.656 24.355 61.9737 25.3205C62.9636 28.278 65.2613 28.9746 67.3511 31.5533C69.1476 33.7654 69.7098 36.0141 70.162 37.7862C70.4675 38.9961 70.6386 40.316 70.6631 41.697C72.1541 42.027 74.9405 42.357 76.9204 40.7071C77.9225 39.8638 78.9491 38.3239 78.998 34.4742C79.1324 24.4772 72.4596 16.2156 68.7321 11.7304Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M35.1602 48.7242C35.0258 49.2252 34.8302 50.3252 35.2824 51.5351C36.2357 54.0526 39.1566 54.5659 39.3154 54.5904C41.3931 54.4926 43.1896 53.4294 43.9595 51.7795C44.4362 50.7529 44.3995 49.7752 44.3262 49.213C43.3851 48.7853 41.8208 48.2231 39.8043 48.1131C37.7756 48.0031 36.1501 48.4064 35.1602 48.7242Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </svg>
);
