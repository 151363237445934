module.exports = {
  BACK_TO_SIGN_IN: 'Retour au login',
  CHANGE_PASSWORD: 'Modifier le mot de passe',
  CHANGE_PASSWORD_ACTION: 'Modifier',
  CODE_LABEL: 'Entrez le code reçu par e-mail',
  CODE_PLACEHOLDER: 'Entrer le code',
  CONFIRM: 'Confirmer',
  CONFIRM_SIGN_UP_CODE_LABEL: 'Confirmation du code',
  CONFIRM_SIGN_UP_CODE_PLACEHOLDER: 'Entrez votre code',
  CONFIRM_SIGN_UP_HEADER_TEXT: 'Confirmez votre inscription',
  CONFIRM_SIGN_UP_LOST_CODE: 'Avez-vous perdu votre code ?',
  CONFIRM_SIGN_UP_RESEND_CODE: 'Renvoyer un code',
  CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT: 'Confirmer',
  CONFIRM_SMS_CODE: 'Confirmer le code SMS',
  CONFIRM_TOTP_CODE: 'Confirmer le code TOTP',
  CREATE_ACCOUNT_TEXT: 'Créer compte',
  EMAIL_LABEL: 'Adresse e-mail *',
  EMAIL_PLACEHOLDER: 'Entrez votre adresse e-mail',
  EMAIL_ERROR: 'Veuillez entrer une adresse e-mail valide',
  FORGOT_PASSWORD_TEXT: 'Avez-vous oublié votre mot de passe ?',
  LESS_THAN_TWO_MFA_VALUES_MESSAGE: 'Moins que deux types mfa à disposition',
  NEW_PASSWORD_LABEL: 'Nouveau mot de passe',
  NEW_PASSWORD_PLACEHOLDER: 'Choisir un nouveau mot de passe',
  NO_ACCOUNT_TEXT: 'Pas encore de compte Animalia ?',
  PASSWORD_LABEL: 'Mot de passe *',
  PASSWORD_PLACEHOLDER: 'Entrez votre mot de passe',
  PHONE_LABEL: 'No de téléphone *',
  PHONE_PLACEHOLDER: '79 555 55 55',
  QR_CODE_ALT: 'Code QR',
  RESET_PASSWORD_TEXT: 'Réinitialiser le mot de passe',
  RESET_YOUR_PASSWORD: 'Réinitialisez votre mot de passe',
  SELECT_MFA_TYPE_HEADER_TEXT: 'Selectionner votre type MFA',
  SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT: 'Vérifier',
  SEND_CODE: 'Envoyer le code par e-mail',
  SETUP_TOTP_REQUIRED: "le TOTP a besoin d'être configuré",
  SSIGN_UP_HEADER_TEXTIGN_IN_ACTION: 'Connexion',
  SIGN_IN_HEADER_TEXT: 'Connexion à votre compte Animalia',
  SIGN_IN_TEXT: 'Connexion',
  SIGN_IN_WITH_AMAZON: 'Connexion avec Amazon',
  SIGN_IN_WITH_AUTH0: 'Connexion avec Auth0',
  SIGN_IN_WITH_AWS: 'Connexion avec AWS',
  SIGN_IN_WITH_FACEBOOK: 'Connexion avec Facebook',
  SIGN_IN_WITH_GOOGLE: 'Connexion avec Google',
  SIGN_OUT: 'Déconnexion',
  SIGN_UP_EMAIL_PLACEHOLDER: 'e-mail',
  SIGN_UP_HAVE_ACCOUNT_TEXT: 'Avez-vous déjà un compte Animalia ?',
  SIGN_UP_HEADER_TEXT: 'Créer un nouveau compte',
  SIGN_UP_PASSWORD_PLACEHOLDER: 'Mot de passe',
  SIGN_UP_SUBMIT_BUTTON_TEXT: 'Créer le compte',
  SIGN_UP_ALREADY_BUTTON_TEXT: 'J\'ai déjà un compte',
  SIGN_UP_USERNAME_PLACEHOLDER: "Nom d'utilisateur",
  SUCCESS_MFA_TYPE: 'Votre type MFA a été crée avec succès:',
  TOTP_HEADER_TEXT: 'Scannez et entrez le code de vérification',
  TOTP_LABEL: 'Entrez le code de sécurité:',
  TOTP_SETUP_FAILURE: "le setup TOTP n'a pas réussi",
  TOTP_SUBMIT_BUTTON_TEXT: 'Vérifier le token de sécurité',
  TOTP_SUCCESS_MESSAGE: 'Le setup TOTP a été fait avec succès!',
  UNABLE_TO_SETUP_MFA_AT_THIS_TIME: "MFA n'a pas pu être configuré",
  USERNAME_LABEL: "Nom d'utilisateur *",
  USERNAME_PLACEHOLDER: "Saisissez votre nom d'utilisateur",
  VERIFY_CONTACT_EMAIL_LABEL: 'E-mail',
  VERIFY_CONTACT_HEADER_TEXT:
    "Pour la récuperation du compte, nous avons besoin d'informations de contact verifiées",
  VERIFY_CONTACT_PHONE_LABEL: 'Numéro de téléphone',
  VERIFY_CONTACT_SUBMIT_LABEL: 'Envoyer',
  VERIFY_CONTACT_VERIFY_LABEL: 'Vérifier',
  PASSWORD_HELP:
    'Au min. 8 caractères avec au minimum un nombre, un majuscule, un minuscule et un caractère spécial.',
  checkFormat: 'Veuillez vérifier le format du mot de passe',
  checkFormatPhone: 'Veuillez vérifier le format du numéro de téléphone',
  personnalInfo: 'Informations personnelles',
  connectWithGoogle: 'Se connecter avec Google',
  signinWithGoogle: 'S\'inscrire avec Google',
  connectWithFacebook: 'Se connecter avec Facebook',
  signinWithFacebook: 'S\'inscrire avec Facebook',
  connectWithApple: 'Se connecter avec Apple',
  signinWithApple: 'S\'inscrire avec Apple',

  /**
   * Cognito error messages
   */
  error: 'Une erreur est survenue',
  AliasExistsException:
    'Cette adresse e-mail existe déjà. Veuillez vous connecter à votre compte ou choisir une autre adresse.',
  errorEmailAlreadyExists:
    'Cette adresse e-mail existe déjà. Veuillez vous connecter à votre compte ou choisir une autre adresse.',
  Userdoesnotexist: "L'utilisateur ou le mot de passe est incorrect.",
  Incorrectusernameorpassword: "L'utilisateur ou le mot de passe est incorrect.",
  Usernameclientidcombinationnotfound:
    "Cette adresse e-mail n'est pas enregistrée chez nous.",
  Invalidverificationcodeprovidedpleasetryagain:
    'Code de vérification invalide.',
  Anaccountwiththegivenemailalreadyexists:
    "L'adresse e-mail ou le mot de passe n'est pas valable",
  Invalidemailaddressformat: "L'adresse e-mail n'est pas valable",
  Cannotresetpasswordfortheuserasthereisnoregisteredverifiedemailorphonenumber:
    "Votre compte n'a jamais été confirmé. Vous ne pouvez donc malheureusement pas récupérer votre mot de passe.",
  verificationSentTitle: "Confirmez la nouvelle adresse e-mail",
  verificationSentAgain: 'Le code vous a été renvoyé à votre nouvelle adresse e-mail.',
  uneditableBecauseLinkedToYourSocialAccount:'Vous ne pouvez pas changer cette information car elle est liée à votre compte de réseau social.',
  uneditableBecauseLinkedToYourSocialAccountAlt: 'Vous vous êtes connecté·e avec un social login (Google, Facebook, Apple). Vous ne pouvez donc pas changer vos données ici. Veuillez svp les changer directement dans votre compte Google / Facebook / Apple.'
};
