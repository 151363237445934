import React, {useContext, useEffect, useState} from 'react';
import {CalculatorContext} from '@animalia/contexts';
import {
  WidgetTightInsuranceTypeSelect,
  WidgetTightButton,
  WidgetTightOfferFieldContainer,
  WidgetTightRadioGroup,
  WidgetTightRadioButtonGroup,
  WidgetTightFormField,
  Title,
  WidgetTightOfferRadio,
} from '@animalia/components';
import {useTranslation} from 'react-i18next';
import {Box, Grid} from 'grommet';
import {getAgeCategoryLabel, hideOfferScreens} from '@animalia/actions';
import moment from 'moment';
import {axios} from '@animalia/instances';
import {fr} from 'date-fns/locale';
import {currencyCHFFormat} from '@animalia/actions/actions/utils';
import {Spinner} from 'react-bootstrap';

const ClientStep = ({
  formValue,
  error,
  dispatch,
  pet,
  goToPrevStep,
  goToNextStep,
  isLoading,
}) => {
  const {
    calculator: {selectedInsuranceValues},
  } = useContext(CalculatorContext);
  const {t, i18n} = useTranslation();
  const [premiums, setPremiums] = useState([]);
  const [loading, setLoading] = useState(true);
  const widgetFromMyaccount = !!pet;
  const nextStep = goToNextStep(
    widgetFromMyaccount ? 'sendOfferByMailMyAccount' : 'sendOfferByMail'
  );

  const labels = {
    suptitle: `${t(`animal.${formValue.pet_species}`)} ${t(
      'calculator.ageCategory'
    )} ${t(getAgeCategoryLabel(formValue.pet_age_category))}`,
    title: t('offer.insurance.franchise'),
    submit_offer: t('calculator.navigation.submitFromInsuranceStep'),
    send_offer_by_email: t('calculator.navigation.sendOfferByEmail'),
    editForm: t('calculator.navigation.prev'),
    insurance_franchise: t('offer.insurance.franchise'),
    deductible: t('offer.insurance.franchise'),
    insurance: t('offer.insurance.protection_type'),
  };

  const franchises = premiums
    ?.map(premium => ({
      value: premium.insurance_franchise,
      label: `CHF ${premium.insurance_franchise / 100}.-`,
    }))
    ?.sort((a, b) => a?.value - b?.value)
    ?.filter(
      (value, index, self) =>
        self.findIndex(v => v.value === value.value) === index
    );

  const matchingPremium = premiums.find(
    premium =>
      premium.insurance_franchise == formValue.insurance_franchise &&
      premium.insurance_type == formValue.insurance_type
  );

  const round = val => {
    return Math.round(val / 5) * 5;
  };

  const insuranceValues = formValue?.insurance_franchise
    ? premiums
        .filter(
          premium =>
            premium.insurance_franchise === formValue.insurance_franchise
        )
        .map(premium => ({
          value: premium.insurance_type,
          label: t(`offer.insurance.${premium.insurance_type}_condensed`),
          price_per_month: new Intl.NumberFormat('en-GB', {
            maximumSignificantDigits: 5,
          }).format(round(premium.monthly_premium.net_amount) / 100),
          info: t(`offer.insurance.${premium.insurance_type}_description`),
          disabled: !premium.insurance_type,
          data: premium,
        }))
        .filter(
          (value, index, self) =>
            self.findIndex(v => v.value === value.value) === index
        )
    : [];

  useEffect(() => {
    (async () => {
      try {
        const data = {
          lang: i18n.language?.substring(0, 2),
          holder_zip_code: formValue.holder_zip_code,
          holder_city: formValue.holder_city,
          pet_species: formValue.pet_species,
          pet_age_category: formValue.pet_age_category,
          pet_breeds:
            formValue.pet_breed == 'breed'
              ? [formValue.pet_animal_id]
              : formValue.pet_breed == 'mixed'
              ? [formValue.pet_animal_id, formValue.pet_animal_id_2]
              : [],
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/quotes/calculate-premiums`,
          data
        );

        if (response?.status !== 200) {
          return new Error('Error, could not fetch premiums');
        }

        const premiums = response.data.premiums;
        setPremiums(premiums);
        setLoading(false);
      } catch (error) {
        throw new Error(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    // insurance_cost
    if (!!matchingPremium) {
      dispatch({
        type: 'SET_FORM',
        data: {
          insurance_cost: matchingPremium?.annual_premium?.total_amount,
        },
      });
    } else {
      dispatch({
        type: 'SET_FORM',
        data: {
          insurance_cost: '',
          insurance_type: '',
        },
      });
    }
  }, [matchingPremium]);

  return (
    <WidgetTightOfferFieldContainer
      suptitle={labels.suptitle}
      onClick={goToPrevStep}
      noBack={widgetFromMyaccount}
      backText={labels.editForm}>
      {/* <WidgetTightInsuranceTypeSelect
        layout={false}
        form={formValue}
        error={error}
        selectedInsuranceValues={selectedInsuranceValues}
        dispatch={dispatch}
      /> */}
      <Title>
        <div style={{fontSize: '0.875rem', textAlign: 'center'}}>
          {' '}
          {labels.title}{' '}
        </div>
      </Title>
      <Box pad={{vertical: 'small'}}>
        {loading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px',
            }}>
            <Spinner animation="border" variant="warning" size="md" />
          </div>
        ) : (
          <WidgetTightFormField
            stretch
            htmlFor="select-insurance_franchise"
            name="insurance_franchise"
            error={error}>
            <WidgetTightRadioButtonGroup
              id="select-insurance_franchise"
              name="insurance_franchise"
              direction="row"
              justify="center"
              labelKey="label"
              {...(franchises.length > 3 && { gridDouble: true })}
              options={franchises}
              children={WidgetTightRadioGroup}
            />
          </WidgetTightFormField>
        )}
      </Box>
      {insuranceValues.length ? (
        <>
          <Title>
            <div style={{fontSize: '0.875rem', textAlign: 'center'}}>
              {' '}
              {labels.insurance}{' '}
            </div>
          </Title>
          <Box pad={{vertical: 'small'}}>
            <WidgetTightFormField
              stretch
              htmlFor="select-insurance_type"
              name="insurance_type"
              error={error}>
              <WidgetTightRadioButtonGroup
                id="select-insurance_type"
                name="insurance_type"
                direction="row"
                justify="center"
                labelKey="label"
                options={insuranceValues}
                mobileDirection="column"
                // children={WidgetTightRadioGroup}
                children={WidgetTightOfferRadio}
              />
            </WidgetTightFormField>
          </Box>
        </>
      ) : null}
      <Box pad={{top: 'small'}}>
        <Grid gap={'small'}>
          {!hideOfferScreens(moment) && (
            <WidgetTightButton
              fill
              primary
              form="form"
              type="submit"
              label={labels.submit_offer}
              isLoading={isLoading}
            />
          )}
          <WidgetTightButton
            fill
            onClick={() => {
              nextStep();
            }}
            label={labels.send_offer_by_email}
          />
        </Grid>
      </Box>
    </WidgetTightOfferFieldContainer>
  );
};

export default ClientStep;
