import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { CalculatorContext } from '@animalia/contexts'
import { setCodeAgentAndPetId } from '@animalia/actions'
import { hasCodeAgent } from '@animalia/actions'
import Stepper from './stepper/Stepper'
import { useTranslation } from 'react-i18next'

const CalculatorView = () => {
  const { calculator: { form }, dispatch } = useContext(CalculatorContext)
  const location = useLocation()
  const { locale } = useParams()
  const { i18n } = useTranslation()
  const [viewState, setViewState] = useState(null)
  const [params] = useState(new URLSearchParams(location.search))

  // use effects 
  useEffect(() => {
    // before showing view, check if there is a valid code agent  
    setCodeAgentAndPetId(dispatch, params)  
  }, [])

  useEffect(() => {
    // handle locale lang change 
    if (locale) {
      i18n.changeLanguage(locale)
    } else {
      i18n.changeLanguage('fr')
    }
  }, [locale])

  useEffect(() => {
    // when code agent set in form, update view
    if (hasCodeAgent(form)) {

      // code agent is defined 
      setViewState('ready')
    } else {

      // code agent is undefined
      setViewState('undefined')
    }
  }, [form.agent_code])


  switch (viewState) {
    case 'ready' :
      return (
        <Stepper i18n={i18n} />
      )
    case 'undefined' :
      return (
        <div>Please check the code agent parameter</div>
      )
    default :
      return (
        <div>Loading</div>
      )
  }
}

export default CalculatorView
