import React, {useEffect, useRef} from 'react';
import {BrowserRouter as Switch, Route, useParams} from 'react-router-dom';
import {
  CalculatorContextProvider,
  GtmEventsContextProvider,
} from '@animalia/contexts';
import CalculatorView from './views/calculator/CalculatorView';
import {Grommet} from 'grommet';
import {MetaProvider, theme} from '@animalia/components';
import 'sass-reset';
import './i18n';
import {useTranslation} from 'react-i18next';
import TagManager from 'react-gtm-module';

const App = () => {
  const base = '/:locale(fr|de|it)?';
  const {i18n} = useTranslation();
  // get url parameters
  const params = new URLSearchParams(window.location.search);
  const codeAgent = params.get('codeAgent');

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-P3W98L5',
      dataLayer: {
        page_title: document.title,
        page_location: window.location.href,
        page_referrer: document.referrer,
        page_language: i18n.language?.slice(0, 2),
        form_name: 'Insurance Request',
        step_id: 1,
        step_name: 'animal-step',
        agent_code: codeAgent,
      },
    };

    if (window == window.top) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  if (window == window.top) {
    return (
      <MetaProvider>
        <Grommet theme={theme}>
          <Switch>
            <Route path={`${base}/calculator`}>
              <GtmEventsContextProvider>
                <CalculatorContextProvider>
                  <CalculatorView />
                </CalculatorContextProvider>
              </GtmEventsContextProvider>
            </Route>
          </Switch>
        </Grommet>
      </MetaProvider>
    );
  }

  return (
    <Grommet theme={theme}>
      <Switch>
        <Route path={`${base}/calculator`}>
          <GtmEventsContextProvider>
            <CalculatorContextProvider>
              <CalculatorView />
            </CalculatorContextProvider>
          </GtmEventsContextProvider>
        </Route>
      </Switch>
    </Grommet>
  );
};

export default App;
